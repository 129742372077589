import { useMutation } from "@tanstack/react-query"
import * as React from "react"
import { useHistory } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ROUTE, formatRoutePath } from "../../../../routes"
import {
    TrustIntegration,
    TrustIntegrationService,
} from "../../../services/TrustIntegration.service"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { TrustIntegrationForm } from "../form/TrustIntegrationForm.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import trustStyles from "../../../../pages/trust/Trust.module.scss"

export function CreateTrustIntegration() {
    const localization = useServiceLocalization()
    const history = useHistory()
    const trustIntegrationService = new TrustIntegrationService()

    const {
        mutate: onSubmit,
        isLoading: isSubmitLoading,
        error: trustIntegrationError,
    } = useMutation(
        ["create-trust-integration"],
        (trustIntegration: TrustIntegration) => {
            return trustIntegrationService.create(trustIntegration)
        },
        {
            onSuccess: (trustIntegration: TrustIntegration) => {
                history.push(
                    formatRoutePath(ROUTE.INTEGRATIONS_DETAILS, {
                        id: encodeID(trustIntegration.id),
                    })
                )
            },
        }
    )

    const errors = [typeof trustIntegrationError === "string" && trustIntegrationError]

    function onCancel(): void {
        history.push(ROUTE.INTEGRATIONS)
    }

    return (
        <section aria-labelledby={Id.HEADING} className={trustStyles.section}>
            <header className={trustStyles.header}>
                <PageHeading id={Id.HEADING}>
                    {localization.getString("addIntegration")}
                </PageHeading>
            </header>
            <TrustIntegrationForm
                onCancel={onCancel}
                submitText={localization.getString("addIntegration")}
                onSubmit={onSubmit}
                isSubmitLoading={isSubmitLoading}
                errors={errors}
            />
        </section>
    )
}

enum Id {
    HEADING = "heading",
}
