import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTE } from "../../../../../../routes"
import { Loader } from "../../../../../components/loader/Loader.component"
import { TrustProfileCreate } from "./trustProfileCreate/TrustProfileCreate.view"
import { TrustProfileList } from "./trustProfileList/TrustProfileList.view"
import { TrustProfileOverview } from "./trustProfileOverview/TrustProfileOverview.view"
import { useFeatureFlags } from "../../../../../../hooks/useFeatureFlags.hook"

export function TrustProfile(): JSX.Element {
    const { data: featureFlags } = useFeatureFlags()

    if (!featureFlags) return <Loader center medium />

    return (
        <Switch>
            {featureFlags.adminConsole.canCreateTrustProfile ? (
                <Route path={ROUTE.PROFILES_CREATE} component={TrustProfileCreate} />
            ) : (
                <Redirect from={ROUTE.PROFILES_CREATE} to={ROUTE.PROFILES} />
            )}
            <Route path={ROUTE.PROFILES_DETAILS}>
                <TrustProfileOverview
                    canChangeTrustProfile={featureFlags.adminConsole.canChangeTrustProfile}
                />
            </Route>
            <Route>
                <TrustProfileList
                    canCreateTrustProfile={featureFlags.adminConsole.canCreateTrustProfile}
                    canReorderTrustProfiles={featureFlags.adminConsole.canReorderTrustProfiles}
                />
            </Route>
        </Switch>
    )
}
