import React from "react"
import { ConfigureAccessGroup } from "./ConfigureAccessGroup.component"
import { PolicyAccessExtended, PolicySpecExtended } from "../../../v3/services/Policy.service"
import styles from "./AccessGroup.module.scss"
import { FormLabel } from "../../../components/form/FormLabel.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { emptyPolicyAccess } from "./helper"

export interface Props {
    disabled?: boolean
    policySpec: PolicySpecExtended
    onPolicySpecChange: (policySpec: PolicySpecExtended) => void
}

export function AccessGroup(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const onAccessGroupChange = (policyAccess: PolicyAccessExtended, index: number) => {
        const updatedPolicyAccess: PolicyAccessExtended[] = [...props.policySpec.access]
        updatedPolicyAccess[index] = policyAccess

        props.onPolicySpecChange({
            ...props.policySpec,
            access: updatedPolicyAccess,
        })
    }

    const onDeleteAccessGroup = (index: number) => {
        props.onPolicySpecChange({
            ...props.policySpec,
            access: props.policySpec.access.filter((_, i) => i !== index),
        })
    }

    const onAddAccessGroup = () => {
        props.onPolicySpecChange({
            ...props.policySpec,
            access: [...props.policySpec.access, emptyPolicyAccess],
        })
    }

    return (
        <div className={styles.accessGroup}>
            <div className={styles.labelContainer}>
                <FormLabel
                    labelClassName={styles.labelClassName}
                    label={localization.getString("accessGroups")}
                ></FormLabel>
                {!props.disabled && (
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        onClick={onAddAccessGroup}
                        icon={IconType.PLUS}
                        className={styles.addButton}
                        type="button"
                    >
                        {localization.getString("addAccessGroup")}
                    </Button>
                )}
            </div>

            {props.policySpec.access.map((policyAccess: PolicyAccessExtended, index: number) => (
                <ConfigureAccessGroup
                    policyAccess={policyAccess}
                    disabled={props.disabled}
                    onAccessGroupChange={(policyAccess) => onAccessGroupChange(policyAccess, index)}
                    onDeleteAccessGroup={() => onDeleteAccessGroup(index)}
                    id={index}
                    key={index}
                />
            ))}
        </div>
    )
}
