import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { decodeID, encodeID } from "../../../../pre-v3/utils/Url.util"
import { useActionBar } from "../../../../pre-v3/services/ActionBar.service"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Loader } from "../../../components/loader/Loader.component"
import {
    useAttachMspOrg,
    useDetachMspOrg,
    useEditOrg,
    useGetAssignMspOptions,
    useGetOrgById,
} from "../../../services/SuperAdmin.service"
import { OrgManagementSpecificationForm } from "../form/OrgManagementSpecificationForm.component"

export function EditOrg() {
    const localization = useServiceLocalization()
    const params: UrlParams = useParams()
    const history = useHistory()

    const {
        data: orgInfo,
        isFetching: isOrgInfoLoading,
        error: orgInfoError,
    } = useGetOrgById(decodeID(params.id))

    const {
        data: assignMspOption,
        isLoading: isAssignMspOptionLoading,
        error: assignMspOptionError,
    } = useGetAssignMspOptions(orgInfo?.isMspOrg)
    const { error: attachMspOrgError } = useAttachMspOrg()
    const { error: detachMspOrgError } = useDetachMspOrg()

    const orgId = orgInfo?.id || ""

    const {
        mutate: onSubmit,
        isLoading: isSubmitLoading,
        error: editOrgInfoError,
    } = useEditOrg({
        onSuccess: () => {
            history.push(
                formatRoutePath(ROUTE.ORG_MANAGEMENT_DETAILS, {
                    id: encodeID(orgId),
                })
            )
        },
    })

    const errors = [
        typeof orgInfoError === "string" && orgInfoError,
        typeof editOrgInfoError === "string" && editOrgInfoError,
        typeof assignMspOptionError === "string" && assignMspOptionError,
        typeof attachMspOrgError === "string" && attachMspOrgError,
        typeof detachMspOrgError === "string" && detachMspOrgError,
    ]

    useActionBar({
        title: localization.getString("editOrg"),
        items: [
            {
                label: localization.getString("superAdmin"),
            },
            {
                label: localization.getString("orgManagement"),
                href: ROUTE.ORG_MANAGEMENT,
            },
            {
                label: orgInfo?.orgName || "",
                href: formatRoutePath(ROUTE.ORG_MANAGEMENT_DETAILS, { id: params.id }),
            },
        ],
    })

    function onCancel(): void {
        history.push(
            formatRoutePath(ROUTE.ORG_MANAGEMENT_DETAILS, {
                id: params.id,
            })
        )
    }

    return (
        <Loader isLoading={isOrgInfoLoading || isAssignMspOptionLoading} center medium>
            <OrgManagementSpecificationForm
                onCancel={onCancel}
                submitText={localization.getString("editOrg")}
                onSubmit={onSubmit}
                isSubmitLoading={isSubmitLoading}
                errors={errors}
                initialValue={orgInfo}
                assignMspOptions={assignMspOption}
                isSonicWallProvisioned={orgInfo?.isSonicWallProvisioned}
            />
        </Loader>
    )
}

interface UrlParams {
    id: string
}
