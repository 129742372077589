import {
    QueryClient,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { LocalizationService } from "../../pre-v3/services/localization/Localization.service"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import { ClusterApi } from "../api/Cluster.api"
import {
    PeerAccessTierReq,
    ServiceTunnelApi,
    ServiceTunnelReq,
    ServiceTunnelDetailsRes,
    ServiceTunnelRes,
    ServiceTunnelSpecJson,
    TunnelPolicyAttachmentRes,
    AttachedPolicy,
} from "../api/ServiceTunnel.api"
import { PolicyAttachment, PolicyType } from "./Policy.service"
import { EdgeClusters, getEdgeClustersFromRes } from "./shared/Cluster"
import { ApiFunction } from "./shared/QueryKey"
import { RegisteredService, RegisteredServiceStatus } from "./shared/RegisteredService"
import { ApplicationsApi, ApplicationSearch } from "../api/Applications.api"
import { AccessTierGroupApi } from "../api/AccessTierGroup.api"
import { AccessTierApi } from "../api/AccessTier.api"
import { ConnectorApi, ConnectorReq, ConnectorRes } from "../api/Connector.api"

class ServiceTunnelService {
    enableATG: boolean

    constructor(enableATG: boolean) {
        this.enableATG = enableATG
    }

    public getNetworks(): Promise<Network[]> {
        return Promise.all([
            this.connectorApi.getConnectors(),
            this.accessTierApi.getAccessTiers(),
            this.enableATG
                ? this.accessTiersGroupApi.getAccessTierGroups()
                : { access_tier_groups: [] },
        ]).then(([connectorsRes, accessTiersRes, accessTierGroupRes]) => {
            const accessTierInATG = accessTierGroupRes.access_tier_groups
                .map((atg) => atg.access_tier_ids)
                .flat()

            const connectorsNetworks: ConnectorNetwork[] = connectorsRes.satellites.map(
                (connector) => {
                    return {
                        id: connector.id,
                        name: connector.name,
                        clusterName: getClusterName(connector),
                        privateIpRanges: connector.cidrs,
                        privateDomains: connector.domains,
                        type: "connector",
                    }
                }
            )

            const accessTiersNetworks: AccessTierNetwork[] = accessTiersRes.access_tiers.reduce<
                AccessTierNetwork[]
            >((acc, accessTier) => {
                if (this.enableATG && accessTierInATG.includes(accessTier.id)) return acc

                if (
                    accessTier.id &&
                    accessTier.tunnel_enduser &&
                    accessTier.edge_type === EdgeType.PRIVATE_EDGE
                ) {
                    return [
                        ...acc,
                        {
                            id: accessTier.id,
                            name: accessTier.name,
                            clusterName: accessTier.cluster_name,
                            privateIpRanges: accessTier.tunnel_enduser?.cidrs ?? [],
                            privateDomains: accessTier.tunnel_enduser?.domains ?? [],
                            type: "accesstier",
                        },
                    ]
                }
                return acc
            }, [])

            const accessTierGroupNetworks: AccessTierGroupNetwork[] =
                accessTierGroupRes.access_tier_groups.map((atGroup) => {
                    return {
                        id: atGroup.id,
                        name: atGroup.name,
                        clusterName: atGroup.cluster_name,
                        privateIpRanges: atGroup.tunnel_enduser?.cidrs ?? [],
                        privateDomains: atGroup.tunnel_enduser?.domains ?? [],
                        type: "accessTierGroup",
                    }
                })

            if (this.enableATG) {
                return [...accessTiersNetworks, ...accessTierGroupNetworks, ...connectorsNetworks]
            }
            return [...accessTiersNetworks, ...connectorsNetworks]
        })
    }

    public async getServiceTunnel(id: string): Promise<ServiceTunnelDetail> {
        const [tunnelRes, policyAttachment, networks, applications] = await Promise.all([
            this.serviceTunnelApi.getServiceTunnelById(id),
            this.getPolicyAttachmentForServiceTunnel(id),
            this.getNetworks(),
            this.getApplicationsForServiceTunnel(),
        ])

        const serviceTunnel = maybeMapServiceTunnelResToServiceTunnelDetails(
            tunnelRes,
            networks,
            applications
        )

        if (!serviceTunnel) {
            return Promise.reject(this.localization.getString("serviceTunnelSpecCouldNotBeParsed"))
        }

        return {
            ...serviceTunnel,
            policyAttachment: policyAttachment && {
                ...policyAttachment,
                serviceName: serviceTunnel.name,
                serviceId: serviceTunnel.id || "",
            },
            status: this.getServiceTunnelStatus(policyAttachment),
        }
    }

    private getPolicyAttachmentForServiceTunnel(
        tunnelId: string
    ): Promise<PolicyAttachmentForServiceTunnel | undefined> {
        return this.serviceTunnelApi
            .getPolicyAttachmentByTunnelId(tunnelId)
            .then(this.mapPolicyAttachmentResForServiceTunnel)
            .catch((error) => {
                if (error === this.localization.getString("policyNotFound")) {
                    // Note: Policy attachment is optional, so suppressing policy not found error
                    return undefined
                }

                throw error
            })
    }

    public async deleteServiceTunnel(serviceTunnel: ServiceTunnelDetail) {
        const policyId = serviceTunnel.policyAttachment?.policyId

        if (policyId) {
            await this.serviceTunnelApi.detachPolicyFromTunnel(serviceTunnel.id!, policyId)
        }

        return this.serviceTunnelApi.deleteServiceTunnel(serviceTunnel.id!)
    }

    public async createServiceTunnel(tunnel: ServiceTunnelDetail): Promise<ServiceTunnelDetail> {
        let req: ServiceTunnelReq
        try {
            req = this.mapServiceTunnelToServiceTunnelReq(tunnel)
        } catch (e) {
            return Promise.reject(e)
        }

        const [res, networks, applications] = await Promise.all([
            this.serviceTunnelApi.createServiceTunnel(req),
            this.getNetworks(),
            this.getApplicationsForServiceTunnel(),
        ])

        const createdServiceTunnel: ServiceTunnelDetail | undefined =
            maybeMapServiceTunnelResToServiceTunnelDetails(res, networks, applications)

        if (!createdServiceTunnel) {
            return Promise.reject(this.localization.getString("serviceTunnelSpecCouldNotBeParsed"))
        }

        let policyAttachment: PolicyAttachmentForServiceTunnel | undefined

        if (tunnel.policyAttachment?.policyId) {
            await this.serviceTunnelApi.attachPolicyToTunnel(
                res.id,
                tunnel.policyAttachment.policyId,
                tunnel.policyAttachment.enabled
            )
            policyAttachment = await this.getPolicyAttachmentForServiceTunnel(res.id)
        }

        return {
            ...createdServiceTunnel,
            policyAttachment: policyAttachment && {
                ...policyAttachment,
                serviceName: createdServiceTunnel.name,
                serviceId: createdServiceTunnel.id || "",
            },
            status: this.getServiceTunnelStatus(policyAttachment),
        }
    }

    public async updateServiceTunnel(
        serviceTunnel: ServiceTunnelDetail,
        oldPolicy?: PolicyAttachment
    ): Promise<ServiceTunnelDetail> {
        let req: ServiceTunnelReq
        try {
            req = this.mapServiceTunnelToServiceTunnelReq(serviceTunnel)
        } catch (e) {
            return Promise.reject(e)
        }

        const [res, networks, applications] = await Promise.all([
            this.serviceTunnelApi.updateTunnel(serviceTunnel.id!, req),
            this.getNetworks(),
            this.getApplicationsForServiceTunnel(),
        ])

        const updatedServiceTunnel: ServiceTunnelDetail | undefined =
            maybeMapServiceTunnelResToServiceTunnelDetails(res, networks, applications)

        if (!updatedServiceTunnel) {
            return Promise.reject(this.localization.getString("serviceTunnelSpecCouldNotBeParsed"))
        }

        const oldPolicyId = oldPolicy?.policyId
        const newPolicyId = serviceTunnel.policyAttachment?.policyId
        let policyAttachment: PolicyAttachmentForServiceTunnel | undefined =
            serviceTunnel.policyAttachment

        if (!oldPolicyId && newPolicyId) {
            // Add policy
            await this.serviceTunnelApi.attachPolicyToTunnel(
                res.id,
                newPolicyId,
                Boolean(serviceTunnel.policyAttachment?.enabled)
            )

            policyAttachment = await this.getPolicyAttachmentForServiceTunnel(res.id)
        } else if (oldPolicyId && !newPolicyId) {
            // Delete policy
            await this.serviceTunnelApi.detachPolicyFromTunnel(res.id, oldPolicyId)

            policyAttachment = undefined
        } else if (
            oldPolicyId !== newPolicyId ||
            oldPolicy?.enabled !== serviceTunnel.policyAttachment?.enabled
        ) {
            // update policy
            await this.serviceTunnelApi.detachPolicyFromTunnel(res.id, oldPolicyId!)

            await this.serviceTunnelApi.attachPolicyToTunnel(
                res.id,
                newPolicyId!,
                Boolean(serviceTunnel.policyAttachment?.enabled)
            )

            policyAttachment = await this.getPolicyAttachmentForServiceTunnel(res.id)
        }

        return {
            ...updatedServiceTunnel,
            policyAttachment: policyAttachment && {
                ...policyAttachment,
                serviceName: updatedServiceTunnel.name,
                serviceId: updatedServiceTunnel.id || "",
            },
            status: this.getServiceTunnelStatus(policyAttachment),
        }
    }

    public async getApplicationsForServiceTunnel(): Promise<Application[]> {
        const applicationApi: ApplicationsApi = new ApplicationsApi()
        const search: ApplicationSearch = {
            skip: 0,
            limit: 1000,
            bnn_tunnel: true,
        }

        return applicationApi.getApplications(search).then((response) => {
            return response.application_inventory
                ? response.application_inventory.map((app) => {
                      return {
                          id: app.application_id,
                          name: app.application_name,
                      }
                  })
                : []
        })
    }

    private localization: LocalizationService = new LocalizationService()
    private serviceTunnelApi: ServiceTunnelApi = new ServiceTunnelApi()

    private accessTiersGroupApi: AccessTierGroupApi = new AccessTierGroupApi()
    private accessTierApi: AccessTierApi = new AccessTierApi()
    private connectorApi: ConnectorApi = new ConnectorApi()

    private mapServiceTunnelToServiceTunnelReq(tunnel: ServiceTunnelDetail): ServiceTunnelReq {
        return {
            metadata: {
                name: tunnel.name,
                description: tunnel.description || "",
                friendly_name: tunnel.name,
                autorun: Boolean(tunnel.autorun),
                lock_autorun: Boolean(tunnel.isAutorunLocked),
                tags: {
                    description_link: tunnel.descriptionLink || "",
                    icon: tunnel.icon || "",
                },
            },
            spec: {
                peer_access_tiers: this.getPeerAccessTiersReq(tunnel),
                name_resolution:
                    tunnel.extra.nameResolution?.name_servers || tunnel.dnsSearchDomains?.length
                        ? {
                              ...tunnel.extra.nameResolution,
                              dns_search_domains: tunnel.dnsSearchDomains?.length
                                  ? tunnel.dnsSearchDomains
                                  : undefined,
                          }
                        : undefined,
            },
        }
    }

    getPublicInfoReq = (
        peer: PeerAccessTierReq,
        publicInfoInclude: PublicInfo,
        PublicInfoExclude: PublicInfo
    ) => {
        const updatedPeer = { ...peer }
        updatedPeer.public_cidrs = {
            include: publicInfoInclude.ipRanges,
            exclude: PublicInfoExclude.ipRanges,
        }

        updatedPeer.public_domains = {
            include: publicInfoInclude.domains,
            exclude: PublicInfoExclude.domains,
        }

        updatedPeer.applications = {
            include: publicInfoInclude.applications.map((app) => app.id),
            exclude: PublicInfoExclude.applications.map((app) => app.id),
        }

        return updatedPeer
    }

    private getPeerAccessTiersReq(tunnel: ServiceTunnelDetail): PeerAccessTierReq[] | undefined {
        if (!tunnel.networkSettings.length) return undefined

        const peers: PeerAccessTierReq[] = []

        for (const networkSetting of tunnel.networkSettings) {
            if (networkSetting.type === NetworkSettingType.PRIVATE_EDGE) {
                const peer: PeerAccessTierReq = {
                    cluster: networkSetting.network.clusterName,
                    access_tiers: [networkSetting.network.name],
                }

                const peerInfo = this.getPublicInfoReq(
                    peer,
                    networkSetting.publicIncludeInfo,
                    networkSetting.publicExcludeInfo
                )

                peers.push(peerInfo)
            } else if (networkSetting.type === NetworkSettingType.PRIVATE_EDGE_ATG) {
                const peer: PeerAccessTierReq = {
                    cluster: networkSetting.network.clusterName,
                    access_tier_group: networkSetting.network.name,
                    access_tiers: [],
                    connectors: [],
                }
                const peerInfo = this.getPublicInfoReq(
                    peer,
                    networkSetting.publicIncludeInfo,
                    networkSetting.publicExcludeInfo
                )

                peers.push(peerInfo)
            } else {
                const peer: PeerAccessTierReq = {
                    cluster: tunnel.globalEdgeClusterName || "",
                    connectors: networkSetting.networks.map((c) => c.name),
                    access_tiers: ["*"],
                }
                const peerInfo = this.getPublicInfoReq(
                    peer,
                    networkSetting.publicIncludeInfo,
                    networkSetting.publicExcludeInfo
                )

                peers.push(peerInfo)
            }
        }

        const globalEdge = peers.find(
            (p): p is PeerAccessTierReq => p.access_tiers?.[0] === "*" && !p.access_tier_group
        )

        const globalEdgePeer: PeerAccessTierReq = {
            cluster: tunnel.globalEdgeClusterName || "",
            access_tiers: ["*"],
            connectors: globalEdge?.connectors ?? [],
            public_cidrs: {
                include: globalEdge?.public_cidrs?.include ?? [],
                exclude: globalEdge?.public_cidrs?.exclude ?? [],
            },
            public_domains: {
                include: globalEdge?.public_domains?.include ?? [],
                exclude: globalEdge?.public_domains?.exclude ?? [],
            },
            applications: {
                include: globalEdge?.applications?.include ?? [],
                exclude: globalEdge?.applications?.exclude ?? [],
            },
        }

        /**
         * Logic:
         * 1. no peers, Something is wrong
         * 3. no AT peers, no connector peers, but yes global edge? use connector-less global edge
         * 4. yes AT peers, no connector peers, return all peers except global edge
         * 5. yes AT peers or yes connector peers, return all peers
         */
        if (peers.length === 0) {
            throw this.localization.getString("serviceTunnelNoPeersError")
        } else if (peers.length === 0 && globalEdge?.connectors?.length === 0) {
            if (tunnel.globalEdgeClusterName) {
                return [globalEdgePeer]
            } else {
                throw this.localization.getString("serviceTunnelNoClusterNameError")
            }
        } else if (peers.length > 1 && isGlobalEdgeEmpty(globalEdgePeer)) {
            return peers.filter((p) => p.access_tiers?.[0] !== "*")
        } else if (
            peers.length === 1 &&
            peers.find((p) => p.access_tiers?.[0] === "*" && !p.access_tier_group)
        ) {
            return [globalEdgePeer]
        } else {
            return peers
        }
    }

    private mapPolicyAttachmentResForServiceTunnel(
        res: TunnelPolicyAttachmentRes
    ): PolicyAttachmentForServiceTunnel {
        return {
            policyId: res.policy_id,
            policyName: res.policy_name,
            enabled: res.enabled === "TRUE",
            attachedAt: DateUtil.convertLargeTimestamp(res.attached_at || 0),
            type: PolicyType.TUNNEL,
        }
    }

    private getServiceTunnelStatus(
        policyAttachment?: PolicyAttachmentForServiceTunnel
    ): RegisteredServiceStatus {
        if (!policyAttachment?.policyId) return RegisteredServiceStatus.NO_POLICY

        return policyAttachment.enabled
            ? RegisteredServiceStatus.POLICY_ENFORCING
            : RegisteredServiceStatus.POLICY_PERMISSIVE
    }
}

function isGlobalEdgeEmpty(globalEdge: PeerAccessTierReq): boolean {
    const publicInfo = [
        ...(globalEdge.applications?.include ?? []),
        ...(globalEdge.public_domains?.include ?? []),
        ...(globalEdge.public_cidrs?.include ?? []),
        ...(globalEdge.applications?.exclude ?? []),
        ...(globalEdge.public_domains?.exclude ?? []),
        ...(globalEdge.public_cidrs?.exclude ?? []),
    ]

    return globalEdge.connectors?.length === 0 && publicInfo.length === 0
}

const serviceName = "ServiceTunnelService"

export function useGetServiceTunnels(
    enableATG: boolean,
    options?: QueryOptions<ServiceTunnel[]>
): UseQueryResult<ServiceTunnel[]> {
    const serviceTunnelApi = new ServiceTunnelApi()

    return useQuery({
        ...options,
        queryKey: [ApiFunction.GET_SERVICE_TUNNELS, serviceName],
        queryFn: async (): Promise<ServiceTunnel[]> => {
            const { service_tunnels } = await serviceTunnelApi.getServiceTunnels()

            if (!enableATG) {
                return service_tunnels.reduce<ServiceTunnel[]>((acc, tunnel) => {
                    if (
                        tunnel.spec?.spec.peer_access_tiers?.some((pat) => {
                            return pat.access_tier_group !== ""
                        })
                    )
                        return acc

                    return [...acc, mapServiceTunnelResToServiceTunnel(tunnel)]
                }, [])
            }

            return service_tunnels.map(mapServiceTunnelResToServiceTunnel)
        },
    })
}

export function useGetServiceTunnel(
    id: string,
    enableATG: boolean,
    options?: QueryOptions<ServiceTunnelDetail>
): UseQueryResult<ServiceTunnelDetail> {
    const queryClient = useQueryClient()

    const query = useQuery({
        ...options,
        queryKey: [ApiFunction.GET_SERVICE_TUNNEL, id, serviceName],
        queryFn: () => helperGetServiceTunnel(queryClient, id, enableATG),
    })

    return {
        ...query,
        refetch: () => {
            queryClient.removeQueries({ queryKey: [ApiFunction.GET_SERVICE_TUNNEL] })
            return query.refetch()
        },
    }
}

export function useGetApplicationListForServiceTunnel(
    enableATG: boolean,
    options?: QueryOptions<Application[]>
) {
    const serviceTunnelService = new ServiceTunnelService(enableATG)
    return useQuery<Application[], string>({
        ...options,
        queryKey: ["serviceTunnelService.getApplicationsForServiceTunnel"],
        queryFn: () => serviceTunnelService.getApplicationsForServiceTunnel(),
    })
}

export function helperGetServiceTunnel(
    queryClient: QueryClient,
    id: string,
    enableATG: boolean
): Promise<ServiceTunnelDetail> {
    const serviceTunnelService = new ServiceTunnelService(enableATG)

    return queryClient.ensureQueryData({
        queryKey: [ApiFunction.GET_SERVICE_TUNNEL, id, serviceName],
        queryFn: () => serviceTunnelService.getServiceTunnel(id),
    })
}

export function useCreateServiceTunnel(
    enableATG: boolean,
    options?: QueryOptions<ServiceTunnelDetail, string, ServiceTunnelDetail>
) {
    const serviceTunnelService = new ServiceTunnelService(enableATG)

    const queryClient = useQueryClient()

    return useMutation<ServiceTunnelDetail, string, ServiceTunnelDetail>({
        ...options,
        mutationFn: serviceTunnelService.createServiceTunnel.bind(serviceTunnelService),
        onSuccess: (data, params, context) => {
            queryClient.removeQueries({ queryKey: [ApiFunction.GET_SERVICE_TUNNELS] })
            options?.onSuccess?.(data, params, context)
        },
    })
}

export function useDeleteServiceTunnel(
    enableATG: boolean,
    options?: QueryOptions<void, string, ServiceTunnelDetail>
) {
    const serviceTunnelService = new ServiceTunnelService(enableATG)

    const queryClient: QueryClient = useQueryClient()

    return useMutation<void, string, ServiceTunnelDetail>({
        ...options,
        mutationFn: serviceTunnelService.deleteServiceTunnel.bind(serviceTunnelService),
        onSuccess: (data, variables) => {
            queryClient.removeQueries({ queryKey: [ApiFunction.GET_SERVICE_TUNNELS] })
            variables.id &&
                queryClient.removeQueries({
                    queryKey: [ApiFunction.GET_SERVICE_TUNNEL, variables.id],
                })
            options?.onSuccess?.(data)
        },
    })
}

export function useUpdateServiceTunnel(
    enableATG: boolean,
    options?: QueryOptions<ServiceTunnelDetail, string, UpdateServiceTunnelParams>
) {
    const serviceTunnelService = new ServiceTunnelService(enableATG)
    const queryClient: QueryClient = useQueryClient()
    return useMutation<ServiceTunnelDetail, string, UpdateServiceTunnelParams>({
        ...options,
        mutationFn: (params: UpdateServiceTunnelParams) =>
            serviceTunnelService.updateServiceTunnel(params.serviceTunnel, params.oldPolicy),
        onSuccess: (data, variables) => {
            queryClient.removeQueries({ queryKey: [ApiFunction.GET_SERVICE_TUNNELS] })
            variables.serviceTunnel.id &&
                queryClient.removeQueries({
                    queryKey: [ApiFunction.GET_SERVICE_TUNNEL, variables.serviceTunnel.id],
                })
            options?.onSuccess?.(data)
        },
    })
}

export function useGetNetworks(enableATG: boolean, options?: QueryOptions<Network[]>) {
    const serviceTunnelService = new ServiceTunnelService(enableATG)
    return useQuery<Network[], string>({
        ...options,
        queryKey: ["serviceTunnelService.getNetworks"],
        queryFn: () => {
            return serviceTunnelService.getNetworks()
        },
    })
}

// TODO: This will be removed once we refactor the code for BC-12889
export function useGetEdgeClusters(): UseQueryResult<EdgeClusters> {
    const clusterApi = new ClusterApi()

    return useQuery({
        queryKey: ["serviceTunnelService.getClusters"],
        queryFn: async () => {
            const getClustersRes = await clusterApi.getClusters()
            return getEdgeClustersFromRes(getClustersRes)
        },
    })
}

export function isValidNetworkSetting(networkSetting: NetworkSetting): boolean {
    return (
        networkSetting.type === NetworkSettingType.PRIVATE_EDGE ||
        networkSetting.type === NetworkSettingType.PRIVATE_EDGE_ATG ||
        networkSetting.publicIncludeInfo.applications.length > 0 ||
        networkSetting.publicIncludeInfo.domains.length > 0 ||
        networkSetting.publicIncludeInfo.ipRanges.length > 0 ||
        networkSetting.networks.length > 0
    )
}

export interface UpdateServiceTunnelParams {
    serviceTunnel: ServiceTunnelDetail
    oldPolicy?: PolicyAttachment
}

type PolicyAttachmentForServiceTunnel = Omit<PolicyAttachment, "serviceId" | "serviceName">

export interface ServiceTunnel {
    id: string
    name: string
    policy?: Policy
    lastUpdatedAt: number
    activeConnectionsCount: number
    startsOnLogin: boolean
}

interface Policy {
    id: string
    name: string
    status: PolicyStatus
}

export enum PolicyStatus {
    ENFORCING = "ENFORCING",
    PERMISSIVE = "PERMISSIVE",
}

export enum NetworkSettingType {
    GLOBAL_EDGE = "GLOBAL_EDGE",
    PRIVATE_EDGE = "PRIVATE_EDGE",
    PRIVATE_EDGE_ATG = "PRIVATE_EDGE_ATG",
}

enum EdgeType {
    PRIVATE_EDGE = "private_edge",
    GLOBAL_EDGE = "global_edge",
}

export interface GlobalEdgeNetworkSetting {
    type: NetworkSettingType.GLOBAL_EDGE
    networks: ConnectorNetwork[]
    publicIncludeInfo: PublicInfo
    publicExcludeInfo: PublicInfo
}

export type PrivateEdgeNetworkSetting =
    | PrivateEdgeAccessTierNetworkSetting
    | PrivateEdgeATGNetworkSetting

export interface PrivateEdgeAccessTierNetworkSetting {
    type: NetworkSettingType.PRIVATE_EDGE
    network: AccessTierNetwork
    publicIncludeInfo: PublicInfo
    publicExcludeInfo: PublicInfo
}

export interface PrivateEdgeATGNetworkSetting {
    type: NetworkSettingType.PRIVATE_EDGE_ATG
    network: AccessTierGroupNetwork
    publicIncludeInfo: PublicInfo
    publicExcludeInfo: PublicInfo
}

export interface BaseNetwork {
    id: string
    name: string
    privateDomains: string[]
    privateIpRanges: string[]
    clusterName: string
}

export interface ConnectorNetwork extends BaseNetwork {
    type: "connector"
}

export interface AccessTierNetwork extends BaseNetwork {
    type: "accesstier"
}

export interface AccessTierGroupNetwork extends BaseNetwork {
    type: "accessTierGroup"
}

export type Network = ConnectorNetwork | AccessTierNetwork | AccessTierGroupNetwork

export interface PublicInfo {
    applications: Application[]
    domains: string[]
    ipRanges: string[]
}

export interface Application {
    id: string
    name: string
}

export interface ServiceTunnelRegisteredService extends RegisteredService {
    status: RegisteredServiceStatus
}

export interface ServiceTunnelDetail extends ServiceTunnelRegisteredService {
    networkSettings: NetworkSetting[]
    globalEdgeClusterName?: string
    extra: {
        nameResolution?: ServiceTunnelRes["spec"]["spec"]["name_resolution"]
    }
}

export type NetworkSetting =
    | GlobalEdgeNetworkSetting
    | PrivateEdgeNetworkSetting
    | PrivateEdgeATGNetworkSetting

export const emptyPublicExcludeIncludeInfo = {
    publicIncludeInfo: {
        applications: [],
        domains: [],
        ipRanges: [],
    },
    publicExcludeInfo: {
        applications: [],
        domains: [],
        ipRanges: [],
    },
}

export const emptyGlobalEdgeNetworkSetting: GlobalEdgeNetworkSetting = {
    type: NetworkSettingType.GLOBAL_EDGE,
    networks: [],
    ...emptyPublicExcludeIncludeInfo,
}

export const emptyPrivateEdgeNetworkSetting: PrivateEdgeNetworkSetting = {
    type: NetworkSettingType.PRIVATE_EDGE,
    network: {
        id: "",
        name: "",
        privateDomains: [],
        privateIpRanges: [],
        type: "accesstier",
        clusterName: "",
    },
    ...emptyPublicExcludeIncludeInfo,
}

// Helper Functions

function mapServiceTunnelResToServiceTunnel(serviceTunnelRes: ServiceTunnelRes): ServiceTunnel {
    return {
        id: serviceTunnelRes.id,
        name: serviceTunnelRes.name,
        policy: serviceTunnelRes.AttachedPolicy
            ? {
                  id: serviceTunnelRes.AttachedPolicy.policy_id,
                  name: serviceTunnelRes.AttachedPolicy.policy_name,
                  status: serviceTunnelRes.AttachedPolicy.policy_status
                      ? PolicyStatus.ENFORCING
                      : PolicyStatus.PERMISSIVE,
              }
            : undefined,
        lastUpdatedAt: DateUtil.convertLargeTimestamp(serviceTunnelRes.updated_at),
        activeConnectionsCount: serviceTunnelRes.active_connections_count,
        startsOnLogin: serviceTunnelRes.spec?.metadata?.autorun ?? false,
    }
}

function maybeMapServiceTunnelResToServiceTunnelDetails(
    res: ServiceTunnelDetailsRes,
    networks: Network[],
    applications: Application[]
): ServiceTunnelDetail | undefined {
    const parsed = parseSpec(res.spec)

    if (!parsed) {
        console.warn(`Service Tunnel spec could not be parsed for ${res.id}`)
        return undefined
    }

    if (!parsed.spec?.peer_access_tiers) {
        console.warn(`Service Tunnel spec doesn't include peer_access_tiers for ${res.id}`)
        return undefined
    }

    const globalEdge = parsed.spec.peer_access_tiers.reduce<NetworkSetting[]>((acc, pat) => {
        if (pat.access_tiers && pat.access_tiers[0] === "*") {
            const connectors = pat.connectors ?? []
            const newConnectors = connectors.map<ConnectorNetwork>((connectorName) => {
                const network = networks?.find((n) => n.name === connectorName)
                return {
                    id: network?.id ?? "",
                    name: connectorName,
                    clusterName: pat.cluster,
                    privateIpRanges: network?.privateIpRanges ?? [],
                    privateDomains: network?.privateDomains ?? [],
                    type: "connector",
                }
            })

            const networkSetting: GlobalEdgeNetworkSetting = {
                networks: newConnectors,
                type: NetworkSettingType.GLOBAL_EDGE,
                publicExcludeInfo: {
                    applications:
                        applications.filter((app) => pat.applications?.exclude?.includes(app.id)) ??
                        [],
                    domains: pat.public_domains?.exclude ?? [],
                    ipRanges: pat.public_cidrs?.exclude ?? [],
                },
                publicIncludeInfo: {
                    applications:
                        applications.filter((app) => pat.applications?.include?.includes(app.id)) ??
                        [],
                    domains: pat.public_domains?.include ?? [],
                    ipRanges: pat.public_cidrs?.include ?? [],
                },
            }
            return [...acc, networkSetting]
        }
        return acc
    }, [])

    const privateEdgeATG = parsed.spec.peer_access_tiers.reduce<NetworkSetting[]>((acc, pat) => {
        if (pat.access_tier_group) {
            const network = networks?.find((n) => n.name === pat.access_tier_group)
            const networkSetting: PrivateEdgeATGNetworkSetting = {
                network: {
                    id: network?.id ?? "",
                    name: pat.access_tier_group,
                    clusterName: pat.cluster,
                    privateIpRanges: network?.privateIpRanges ?? [],
                    privateDomains: network?.privateDomains ?? [],
                    type: "accessTierGroup",
                },
                type: NetworkSettingType.PRIVATE_EDGE_ATG,
                publicExcludeInfo: {
                    applications:
                        applications.filter((app) => pat.applications?.exclude?.includes(app.id)) ??
                        [],
                    domains: pat.public_domains?.exclude ?? [],
                    ipRanges: pat.public_cidrs?.exclude ?? [],
                },
                publicIncludeInfo: {
                    applications:
                        applications.filter((app) => pat.applications?.include?.includes(app.id)) ??
                        [],
                    domains: pat.public_domains?.include ?? [],
                    ipRanges: pat.public_cidrs?.include ?? [],
                },
            }
            return [...acc, networkSetting]
        }
        return acc
    }, [])

    const privateEdge = parsed.spec.peer_access_tiers.reduce<NetworkSetting[]>((acc, pat) => {
        if (pat.access_tiers && pat.access_tiers[0] !== "*" && pat.access_tier_group === "") {
            const accessTiers = pat.access_tiers ?? []
            const network = accessTiers.map<AccessTierNetwork>((accessTierName) => {
                const network = networks?.find((n) => n.name === accessTierName)
                return {
                    id: network?.id ?? "",
                    name: accessTierName,
                    clusterName: pat.cluster,
                    privateIpRanges: network?.privateIpRanges ?? [],
                    privateDomains: network?.privateDomains ?? [],
                    type: "accesstier",
                }
            })

            const networkSetting: PrivateEdgeNetworkSetting = {
                network: network[0],
                type: NetworkSettingType.PRIVATE_EDGE,
                publicExcludeInfo: {
                    applications:
                        applications.filter((app) => pat.applications?.exclude?.includes(app.id)) ??
                        [],
                    domains: pat.public_domains?.exclude ?? [],
                    ipRanges: pat.public_cidrs?.exclude ?? [],
                },
                publicIncludeInfo: {
                    applications:
                        applications.filter((app) => pat.applications?.include?.includes(app.id)) ??
                        [],
                    domains: pat.public_domains?.include ?? [],
                    ipRanges: pat.public_cidrs?.include ?? [],
                },
            }
            return [...acc, networkSetting]
        }
        return acc
    }, [])

    return {
        id: res.id,
        name: res.name,
        description: res.description ?? "",
        descriptionLink: parsed.metadata?.tags?.description_link ?? "",
        icon: parsed.metadata?.tags?.icon ?? "",
        autorun: Boolean(parsed.metadata.autorun),
        isAutorunLocked: Boolean(parsed.metadata.lock_autorun),
        createdAt: DateUtil.convertLargeTimestamp(res.created_at),
        createdBy: res.created_by,
        updatedAt: DateUtil.convertLargeTimestamp(res.updated_at),
        updatedBy: res.updated_by,
        networkSettings: [...globalEdge, ...privateEdge, ...privateEdgeATG],
        status: getServiceTunnelStatusFromRes(res.AttachedPolicy),
        dnsSearchDomains: parsed.spec.name_resolution?.dns_search_domains ?? [],
        extra: {
            nameResolution: parsed.spec.name_resolution,
        },
    }
}

function getServiceTunnelStatusFromRes(
    attachedPolicy?: AttachedPolicy | null
): RegisteredServiceStatus {
    if (!attachedPolicy) return RegisteredServiceStatus.NO_POLICY

    return attachedPolicy.policy_status
        ? RegisteredServiceStatus.POLICY_ENFORCING
        : RegisteredServiceStatus.POLICY_PERMISSIVE
}

export function parseSpec(stringified: string): ServiceTunnelSpecJson | null {
    try {
        return JSON.parse(stringified)
    } catch {
        return null
    }
}

enum IncludeExclude {
    INCLUDE = "Include",
    EXCLUDE = "Exclude",
}

interface SummaryServiceTunnel {
    domainsAppsIps: string
    type: string // for sorting
    network: string
    isInclude: boolean
}

export function mapToSummaryServiceTunnel(
    serviceTunnel: ServiceTunnelDetail,
    localization: LocalizationService
): SummaryServiceTunnel[] {
    const mappedSummary = serviceTunnel.networkSettings.map((serviceTunnel) => {
        let privateDomains: string[] = []
        let privateIpRanges: string[] = []

        if (
            serviceTunnel.type === NetworkSettingType.PRIVATE_EDGE ||
            serviceTunnel.type === NetworkSettingType.PRIVATE_EDGE_ATG
        ) {
            privateDomains = serviceTunnel.network.privateDomains
            privateIpRanges = serviceTunnel.network.privateIpRanges
        } else {
            privateDomains = serviceTunnel.networks.reduce<string[]>((acc, network) => {
                return [...acc, ...network.privateDomains]
            }, [])
            privateIpRanges = serviceTunnel.networks.reduce<string[]>((acc, network) => {
                return [...acc, ...network.privateIpRanges]
            }, [])
        }

        const included = [
            ...serviceTunnel.publicIncludeInfo.applications.map((app) => app.name),
            ...serviceTunnel.publicIncludeInfo.domains,
            ...serviceTunnel.publicIncludeInfo.ipRanges,
            ...privateDomains,
            ...privateIpRanges,
        ]
        const excluded = [
            ...serviceTunnel.publicExcludeInfo.applications.map((app) => app.name),
            ...serviceTunnel.publicExcludeInfo.domains,
            ...serviceTunnel.publicExcludeInfo.ipRanges,
        ]

        let network
        switch (serviceTunnel.type) {
            case NetworkSettingType.GLOBAL_EDGE:
                network = localization.getString("globalEdge")
                break
            case NetworkSettingType.PRIVATE_EDGE:
                network = serviceTunnel.network?.name
                break
            default:
                network = serviceTunnel.network?.name
                break
        }

        const includedSummary = createSummaryItems(included, IncludeExclude.INCLUDE, true, network)
        const excludedSummary = createSummaryItems(excluded, IncludeExclude.EXCLUDE, false, network)

        return [...includedSummary, ...excludedSummary]
    })

    return mapSortSummary(mappedSummary.flat())
}

function createSummaryItems(
    items: string[],
    type: IncludeExclude,
    isInclude: boolean,
    network: string
): SummaryServiceTunnel[] {
    return items.map((item) => ({
        domainsAppsIps: item,
        type,
        isInclude,
        network,
    }))
}
// primary sort by network,
// secondary sort by include/exclude within network,
// then by domains/apps/ips within include/exclude
function mapSortSummary(summary: SummaryServiceTunnel[]): SummaryServiceTunnel[] {
    return summary.sort((a, b) => {
        // Sort by network
        if (a.network < b.network) return -1
        if (a.network > b.network) return 1

        // If network is equal, sort by includeExclude
        if (a.type < b.type) return -1
        if (a.type > b.type) return 1

        // If includeExclude is also equal, sort by domain
        if (a.domainsAppsIps < b.domainsAppsIps) return -1
        if (a.domainsAppsIps > b.domainsAppsIps) return 1

        // If all are equal, return 0
        return 0
    })
}

function getClusterName(connectorRes: ConnectorRes): string {
    try {
        const data: ConnectorReq = JSON.parse(connectorRes.spec)
        return data.spec.peer_access_tiers[0].cluster
    } catch {
        // unparsable, ignore
        return ""
    }
}
