import { L4RuleExtended, PolicyAccessExtended } from "../../../v3/services/Policy.service"
import { v4 as uuidV4 } from "uuid"

export const findDuplicates = (list: string[]) => {
    const sortedArr = list.slice().sort()
    const duplicates = []

    for (let i = 0; i < sortedArr.length - 1; i++) {
        if (sortedArr[i + 1] === sortedArr[i]) {
            duplicates.push(sortedArr[i])
        }
    }

    return duplicates
}

export const emptyDestination: L4RuleExtended = {
    id: uuidV4(),
    ipRanges: "",
    fqdnList: "",
    protocols: [],
    ports: [],
    description: "",
}

export const emptyPolicyAccess: PolicyAccessExtended = {
    name: "",
    description: "",
    roles: [],
    rules: {
        l4_access: {
            allow: [emptyDestination],
            deny: [emptyDestination],
        },
        conditions: {},
    },
}
