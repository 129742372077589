import { BaseApi } from "./Base.api"

export class SignUpApi extends BaseApi {
    public sendForgotOrgNameEmail(body: SendForgotOrgNameEmailBody): Promise<void> {
        return this.post("/api/v2/signup/org_names", body, {
            4017: this.localization.getString("unexpectedVolumeOfRequests"),
            default: this.localization.getString("internalErrorOccurredRefreshTryAgain"),
        })
    }
}

interface SendForgotOrgNameEmailBody {
    email: string
}
