/**
 * @file Centralizes all routes in the application.
 *
 * WARNING: When changing a route, please add the previous route to the Redirect
 * component in src/pages/app/Redirect.tsx.
 */

import { Location } from "history"
import { ExtractRouteParams } from "react-router"
import { generatePath, matchPath } from "react-router-dom"

export enum ROUTE {
    ROOT = "/",

    // Authentication
    AUTH = "/auth",
    FORGOT_ORG_NAME = `${ROUTE.AUTH}/forgot-org-name`,
    LOGIN = `${ROUTE.AUTH}/login`,
    LOGIN_RESET = `${ROUTE.AUTH}/reset`,
    SAML = `${ROUTE.AUTH}/saml`,
    MSW_SSO = `${ROUTE.AUTH}/msw-sso`,

    // Login
    ORG_FORM = `${ROUTE.LOGIN}/org-form`,
    LOCAL_ACCOUNT_FORM = `${ROUTE.LOGIN}/local-account`,
    LOCAL_ACCOUNT_MFA = `${ROUTE.LOGIN}/mfa`,
    LOCAL_ACCOUNT_CONFIGURE_MFA = `${ROUTE.LOGIN}/configure-mfa`,
    RESET_PASSWORD_FORM = `${ROUTE.LOGIN}/reset-password`,

    // OIDC
    OIDC = "/oidc",
    OIDC_LOGIN = `${ROUTE.OIDC}/login`,
    OIDC_FORGOT_PASSWORD = `${ROUTE.OIDC}/forgot-password`,
    OIDC_CONFIRM_PASSWORD = `${ROUTE.OIDC}/confirm-password`,
    OIDC_NEW_PASSWORD = `${ROUTE.OIDC}/new-password`,
    OIDC_ORG_SELECTOR = `${ROUTE.OIDC}/org-select`,

    // Admin Console
    ADMIN_CONSOLE = "/admin-console",
    GRANULAR_TRUST_MIGRATION_EDUCATION = `${ROUTE.ADMIN_CONSOLE}/granular-trust-migration-education`,

    // Home
    HOME = `${ROUTE.ADMIN_CONSOLE}/home`,
    HOME_OVERVIEW = `${ROUTE.HOME}/overview`,
    EVENTS = `${ROUTE.HOME}/events`,
    INTERNET_ACCESS_EVENTS = `${ROUTE.EVENTS}/internet-access`,
    PRIVATE_ACCESS_EVENTS = `${ROUTE.EVENTS}/private-access`,
    SYSTEM_LOGS = `${ROUTE.HOME}/system-logs`,
    LICENSES = `${ROUTE.HOME}/licenses`,

    // Private Access
    PRIVATE_ACCESS = `${ROUTE.ADMIN_CONSOLE}/private-access`,

    PRIVATE_ACCESS_SERVICES_DETAILS = `${ROUTE.PRIVATE_ACCESS}/services/:id`,

    HOSTED_WEBSITES = `${ROUTE.PRIVATE_ACCESS}/hosted-websites`,
    HOSTED_WEBSITES_ADD = `${ROUTE.HOSTED_WEBSITES}/add`,
    HOSTED_WEBSITES_DETAILS = `${ROUTE.HOSTED_WEBSITES}/:id`,

    INFRASTRUCTURE = `${ROUTE.PRIVATE_ACCESS}/infrastructure`,
    INFRASTRUCTURE_ADD = `${ROUTE.INFRASTRUCTURE}/add`,
    INFRASTRUCTURE_DETAILS = `${ROUTE.INFRASTRUCTURE}/:id`,
    INFRASTRUCTURE_EDIT = `${ROUTE.INFRASTRUCTURE}/:id/edit`,

    SERVICE_TUNNELS = `${ROUTE.PRIVATE_ACCESS}/service-tunnels`,
    SERVICE_TUNNELS_ADD = `${ROUTE.SERVICE_TUNNELS}/add`,
    SERVICE_TUNNELS_DETAILS = `${ROUTE.SERVICE_TUNNELS}/:id`,
    SERVICE_TUNNELS_EDIT = `${ROUTE.SERVICE_TUNNELS}/:id/edit`,

    DISCOVERY = `${ROUTE.PRIVATE_ACCESS}/discovery`,
    DISCOVERY_DETAILS = `${ROUTE.DISCOVERY}/:id`,
    DISCOVERY_ACCESS_ACTIVITY = `${ROUTE.DISCOVERY}/:id/access-activity`,
    DISCOVERY_IPS = `${ROUTE.DISCOVERY}/:id/ips`,
    DISCOVERY_NETWORK = `${ROUTE.DISCOVERY}/:id/network`,

    IAAS_DISCOVERY = `${ROUTE.PRIVATE_ACCESS}/iaas-discovery`,
    IAAS_DISCOVERY_DETAILS = `${ROUTE.IAAS_DISCOVERY}/:id`,
    IAAS_DISCOVERY_PUBLISH = `${ROUTE.IAAS_DISCOVERY}/:id/publish`,

    ACCESS_POLICIES = `${ROUTE.PRIVATE_ACCESS}/access-policies`,
    ACCESS_POLICIES_ADD = `${ROUTE.ACCESS_POLICIES}/add`,
    ACCESS_POLICIES_DETAILS = `${ROUTE.ACCESS_POLICIES}/:id`,
    ACCESS_POLICIES_EDIT = `${ROUTE.ACCESS_POLICIES}/:id/edit`,

    //Service Tunnel Policy
    ACCESS_POLICIES_TUNNEL_POLICY = `${ROUTE.ACCESS_POLICIES}/tunnel-policy`,
    ACCESS_POLICIES_TUNNEL_POLICY_ADD = `${ROUTE.ACCESS_POLICIES_TUNNEL_POLICY}/add`,
    ACCESS_POLICIES_TUNNEL_POLICY_DETAILS = `${ROUTE.ACCESS_POLICIES_TUNNEL_POLICY}/:id`,
    ACCESS_POLICIES_TUNNEL_POLICY_EDIT = `${ROUTE.ACCESS_POLICIES_TUNNEL_POLICY}/:id/edit`,

    // Internet Access
    INTERNET_ACCESS = `${ROUTE.ADMIN_CONSOLE}/internet-access`,
    INTERNET_THREAT_PROTECTION = `${ROUTE.INTERNET_ACCESS}/internet-threat-protection`,
    INTERNET_THREAT_PROTECTION_ADD = `${ROUTE.INTERNET_THREAT_PROTECTION}/add`,
    INTERNET_THREAT_PROTECTION_DETAILS = `${ROUTE.INTERNET_THREAT_PROTECTION}/:id`,
    SAAS_APPS = `${ROUTE.INTERNET_ACCESS}/saas-apps`,
    SAAS_APPS_ADD = `${ROUTE.SAAS_APPS}/:type(cse|idp-routed)/add`,
    SAAS_APPS_CONFIGURATION = `${ROUTE.SAAS_APPS}/:id/configuration`,
    SAAS_APPS_EDIT = `${ROUTE.SAAS_APPS}/:id/edit`,
    SAAS_APPS_DETAILS = `${ROUTE.SAAS_APPS}/:id`,
    APP_DISCOVERY = `${ROUTE.INTERNET_ACCESS}/app-discovery`,
    APP_DISCOVERY_DETAILS = `${ROUTE.APP_DISCOVERY}/:id`,
    DATA_LOST_PREVENTION = `${ROUTE.INTERNET_ACCESS}/data-loss-prevention`,
    DATA_LOST_PREVENTION_DETAILS = `${ROUTE.DATA_LOST_PREVENTION}/:id`,

    // Trust
    TRUST = `${ROUTE.ADMIN_CONSOLE}/trust`,
    PROFILES = `${ROUTE.TRUST}/profiles`,
    PROFILES_CREATE = `${ROUTE.PROFILES}/create`,
    PROFILES_DETAILS = `${ROUTE.PROFILES}/:id`,
    FACTORS = `${ROUTE.TRUST}/factors`,
    FACTORS_FILE_CHECK = `${ROUTE.FACTORS}/file-check`,
    FACTORS_FILE_CHECK_CREATE = `${ROUTE.FACTORS_FILE_CHECK}/add`,
    FACTORS_FILE_CHECK_DETAILS = `${ROUTE.FACTORS_FILE_CHECK}/:id`,
    FACTORS_PLIST = `${ROUTE.FACTORS}/plist`,
    FACTORS_PLIST_CREATE = `${ROUTE.FACTORS_PLIST}/add`,
    FACTORS_PLIST_DETAILS = `${ROUTE.FACTORS_PLIST}/:id`,
    FACTORS_REGISTRY_CHECK = `${ROUTE.FACTORS}/registry-check`,
    FACTORS_REGISTRY_CHECK_CREATE = `${ROUTE.FACTORS_REGISTRY_CHECK}/add`,
    FACTORS_REGISTRY_CHECK_DETAILS = `${ROUTE.FACTORS_REGISTRY_CHECK}/:id`,
    INTEGRATIONS = `${ROUTE.TRUST}/integrations`,
    INTEGRATIONS_CREATE = `${ROUTE.INTEGRATIONS}/create`,
    INTEGRATIONS_DETAILS = `${ROUTE.INTEGRATIONS}/:id`,
    INTEGRATIONS_EDIT = `${ROUTE.INTEGRATIONS}/:id/edit`,
    REMEDIATION = `${ROUTE.TRUST}/remediation`,

    // Network
    NETWORKS = `${ROUTE.ADMIN_CONSOLE}/networks`,

    CONNECTORS = `${ROUTE.NETWORKS}/connectors`,
    CONNECTORS_ADD = `${ROUTE.NETWORKS}/connectors/add`,
    CONNECTORS_DETAILS = `${ROUTE.NETWORKS}/connectors/:id`,
    CONNECTORS_DETAILS_CONFIGURE = `${ROUTE.CONNECTORS_DETAILS}/configure`,
    CONNECTORS_DETAILS_SERVICES = `${ROUTE.CONNECTORS_DETAILS}/services`,
    CONNECTORS_DETAILS_INSTALL = `${ROUTE.CONNECTORS_DETAILS}/install`,

    ACCESS_TIERS = `${ROUTE.NETWORKS}/access-tiers`,
    ACCESS_TIERS_ADD = `${ROUTE.ACCESS_TIERS}/add`,
    ACCESS_TIERS_DETAILS = `${ROUTE.ACCESS_TIERS}/:id`,

    ACCESS_TIER_GROUPS = `${ROUTE.NETWORKS}/access-tier-groups`,
    ACCESS_TIER_GROUPS_CREATE = `${ROUTE.ACCESS_TIER_GROUPS}/create`,
    ACCESS_TIER_GROUPS_DETAILS = `${ROUTE.ACCESS_TIER_GROUPS}/:id`,

    CLUSTERS = `${ROUTE.NETWORKS}/clusters`,
    CLUSTERS_DETAILS = `${ROUTE.CLUSTERS}/:clusterName`,

    ON_PREM_GATEWAY = `${ROUTE.NETWORKS}/gateways`,

    TRUSTED_NETWORKS = `${ROUTE.NETWORKS}/trusted-networks`,

    // Directory
    DIRECTORY = `${ROUTE.ADMIN_CONSOLE}/directory`,

    USERS = `${ROUTE.DIRECTORY}/users`,
    USERS_ADD = `${ROUTE.USERS}/add`,
    USERS_DETAILS = `${ROUTE.USERS}/:id`,
    USERS_DETAILS_DEVICES = `${USERS_DETAILS}/devices`,
    USERS_DETAILS_ACTIVITY = `${USERS_DETAILS}/user-access-activity`,
    USERS_DETAILS_ROLES = `${USERS_DETAILS}/roles`,
    USERS_DETAILS_LICENSE = `${USERS_DETAILS}/license-info`,
    USERS_EDIT = `${ROUTE.USERS}/:id/edit`,

    DEVICES = `${ROUTE.DIRECTORY}/devices`,
    DEVICES_DETAILS = `${ROUTE.DEVICES}/:serialNumber`,

    SERVICE_ACCOUNTS = `${ROUTE.DIRECTORY}/service-accounts`,
    SERVICE_ACCOUNTS_ADD = `${ROUTE.SERVICE_ACCOUNTS}/add`,
    SERVICE_ACCOUNTS_DETAILS = `${ROUTE.SERVICE_ACCOUNTS}/:id`,

    UNREGISTERED_DEVICES = `${ROUTE.DIRECTORY}/unregistered-devices`,

    ROLES = `${ROUTE.DIRECTORY}/roles`,
    ROLES_ADD = `${ROUTE.ROLES}/add`,
    ROLES_DETAILS = `${ROUTE.ROLES}/:id`,

    ADMINS = `${ROUTE.DIRECTORY}/admins`,
    ADMINS_ADD = `${ROUTE.ADMINS}/add`,
    ADMINS_DETAILS = `${ROUTE.ADMINS}/:id`,

    // Settings
    SETTINGS = `${ROUTE.ADMIN_CONSOLE}/settings`,

    IDENTITY_AND_ACCESS = `${ROUTE.SETTINGS}/identity-and-access`,
    ADMIN = `${ROUTE.IDENTITY_AND_ACCESS}/admin`,
    END_USER = `${ROUTE.IDENTITY_AND_ACCESS}/end-user`,

    API_KEYS = `${ROUTE.IDENTITY_AND_ACCESS}/api-keys`,
    API_KEYS_ADD = `${ROUTE.API_KEYS}/add`,
    API_KEYS_DETAILS = `${ROUTE.API_KEYS}/:id`,

    SONICWALL_CSE_CLIENT = `${ROUTE.SETTINGS}/sonicwall-cse-client`,
    DEPLOYMENT = `${ROUTE.SONICWALL_CSE_CLIENT}/deployment`,
    SONICWALL_CSE_CLIENT_TRUST = `${ROUTE.SONICWALL_CSE_CLIENT}/trust`,
    DEVICE_MANAGER = `${ROUTE.SONICWALL_CSE_CLIENT}/device-manager`,

    BUNDLES = `${ROUTE.SONICWALL_CSE_CLIENT}/bundles`,
    BUNDLES_ADD = `${ROUTE.BUNDLES}/add`,
    BUNDLES_DETAILS = `${ROUTE.BUNDLES}/:id`,
    BUNDLES_EDIT = `${ROUTE.BUNDLES}/:id/edit`,

    CONFIGURATION = `${ROUTE.SETTINGS}/configuration`,
    PASSWORDLESS = `${ROUTE.CONFIGURATION}/passwordless`,
    UNREGISTERED_DEVICES_CONFIGURATION = `${ROUTE.CONFIGURATION}/unregistered-devices`,
    CUSTOMIZATION = `${ROUTE.CONFIGURATION}/customization`,
    ADVANCED = `${ROUTE.CONFIGURATION}/advanced`,
    SERVICE_TUNNELS_CONFIGURATION = `${ROUTE.CONFIGURATION}/service-tunnel`,
    LICENSING = `${ROUTE.CONFIGURATION}/licensing`,

    CERTIFICATES = `${ROUTE.SETTINGS}/certificates`,
    REGISTERED_DOMAINS = `${ROUTE.CERTIFICATES}/registered-domains`,
    REGISTERED_DOMAINS_ADD = `${ROUTE.REGISTERED_DOMAINS}/add`,
    REGISTERED_DOMAINS_DETAILS = `${ROUTE.REGISTERED_DOMAINS}/:id`,
    ISSUED_CERTIFICATES = `${ROUTE.CERTIFICATES}/issued-certificates`,

    // Get Help
    GET_HELP = `${ROUTE.ADMIN_CONSOLE}/get-help`,
    GET_HELP_HOME = `${ROUTE.GET_HELP}/home`,
    GET_HELP_SMART_SEARCH_RESULT = `${ROUTE.GET_HELP}/result`,
    GET_HELP_SMART_SEARCH_HISTORY = `${ROUTE.GET_HELP}/history`,

    // Profile
    PROFILE = `${ROUTE.ADMIN_CONSOLE}/profile`,
    MY_PROFILE = `${ROUTE.PROFILE}/my-profile`,

    // MOM Console
    MOM_CONSOLE = "/mom-console",
    ORG_MANAGEMENT = `${ROUTE.MOM_CONSOLE}/org-management`,
    ORG_MANAGEMENT_ADD = `${ROUTE.ORG_MANAGEMENT}/add`,
    ORG_MANAGEMENT_DETAILS = `${ROUTE.ORG_MANAGEMENT}/:id`,
    ORG_MANAGEMENT_EDIT = `${ROUTE.ORG_MANAGEMENT}/:id/edit`,
    MOM_SETTINGS = `${ROUTE.MOM_CONSOLE}/settings`,
    MOM_SIGN_ON = `${ROUTE.MOM_SETTINGS}/sign-on`,
    MOM_MANGE_ADMINS = `${ROUTE.MOM_SETTINGS}/admins`,
    MOM_MANGE_ADMINS_DETAILS = `${ROUTE.MOM_MANGE_ADMINS}/:id`,
    MOM_MANGE_ADMINS_ADD = `${ROUTE.MOM_MANGE_ADMINS}/add`,
    MOM_SYSTEM_LOGS = `${ROUTE.MOM_CONSOLE}/system-logs`,

    // MSP Org Management
    MSP_CONSOLE = "/msp-console",
    MSP_ORG_MANAGEMENT = `${ROUTE.MSP_CONSOLE}/org-management`,
    MSP_ORG_DETAILS = `${ROUTE.MSP_ORG_MANAGEMENT}/:id`,
    MSP_MY_PROFILE = `${ROUTE.MSP_ORG_MANAGEMENT}/my-msp-profile`,
    MSP_SETTINGS = `${ROUTE.MSP_ORG_MANAGEMENT}/settings`,
    MSP_SING_ON = `${ROUTE.MSP_SETTINGS}/sign-on`,
    MSP_MANAGE_ADMIN = `${ROUTE.MSP_SETTINGS}/admin`,
    MSP_MANAGE_ADMIN_ADD = `${ROUTE.MSP_MANAGE_ADMIN}/add`,
    MSP_MANAGE_ADMIN_DETAILS = `${ROUTE.MSP_MANAGE_ADMIN}/:id`,
    MSP_CUSTOMIZATION = `${ROUTE.MSP_SETTINGS}/customization`,
    MSP_IDENTITY_PROVIDER_SETTINGS = `${ROUTE.MSP_SETTINGS}/identity-provider`,
    MSP_TRUST_FACTOR_SETTINGS = `${ROUTE.MSP_SETTINGS}/trust-factor`,
    MSP_SYSTEM_LOGS = `${ROUTE.MSP_CONSOLE}/system-logs`,

    // MSP Assigned Organizations
    MSP_ASSIGNED_ORGS = `${ROUTE.MSP_CONSOLE}/assigned-orgs`,

    // Old Application Routes
    ADVANCED_SETTINGS = `${ROUTE.SETTINGS}/advanced-settings`,
    APP_DEPLOYMENT_SETTINGS = `${ROUTE.SETTINGS}/app-deployment`,
    DEVICE_MANAGER_SETTINGS = `${ROUTE.SETTINGS}/device-manager`,
    IDENTITY_PROVIDER_SETTINGS = `${ROUTE.SETTINGS}/identity-provider`,
    OIDC_SETTINGS = `${ROUTE.SETTINGS}/oidc`,
    SERVICE_TUNNEL_SETTINGS = `${ROUTE.SETTINGS}/service-tunnel`,
    SIGN_ON_SETTINGS = `${ROUTE.SETTINGS}/sign-on`,
    TRUST_SCORE_EXPIRY = `${ROUTE.SETTINGS}/trust-score-expiry`,
    UNREGISTERED_DEVICE_SETTINGS = `${ROUTE.SETTINGS}/unregistered-device`,

    // TODO: Remove these routes when all Orgs migrate to Granular Trust
    TRUST_FACTOR_SETTINGS = `${ROUTE.SETTINGS}/trust-factor`,
    OPERATING_SYSTEM_VERSIONS = `${ROUTE.TRUST_FACTOR_SETTINGS}/operating-system-versions`,
    APPLICATION_CHECK = `${ROUTE.TRUST_FACTOR_SETTINGS}/application-check`,
    APPLICATION_CHECK_ADD = `${ROUTE.APPLICATION_CHECK}/add`,
    APPLICATION_CHECK_DETAILS = `${ROUTE.APPLICATION_CHECK}/:id`,
}

export function formatRoutePath<Path extends ROUTE>(
    path: Path,
    pathParams: ExtractRouteParams<Path>,
    searchParams: Record<string, string | string[]> = {}
): ROUTE {
    const formattedPath = generatePath(path, pathParams) as ROUTE
    const searchParamsEntries = Object.entries(searchParams)

    if (searchParamsEntries.length <= 0) return formattedPath

    const urlSearchParams = new URLSearchParams()

    searchParamsEntries.forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((v) => urlSearchParams.append(key, v))
        } else {
            urlSearchParams.append(key, value)
        }
    })

    return `${formattedPath}?${urlSearchParams.toString()}` as ROUTE
}

export function doesRouteMatch<Route extends ROUTE>(route: Route, location: Location): boolean {
    return matchPath(location.pathname, route) !== null
}
