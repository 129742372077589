import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Loader } from "../../../v3/components/loader/Loader.component"
import {
    AccessTierDetails as AccessTier,
    InstallationMethod,
    useGetLatestNetagentVersion,
} from "../../../v3/services/AccessTier.service"
import { CloudFormationInstaller } from "../../../v3/views/access-tiers/create/installation-methods/CloudFormationInstaller.component"
import { DebRpmInstaller } from "../../../v3/views/access-tiers/create/installation-methods/DebRpmInstaller.component"
import { DockerInstaller } from "../../../v3/views/access-tiers/create/installation-methods/DockerInstaller.component"
import { TarballInstaller } from "../../../v3/views/access-tiers/create/installation-methods/TarballInstaller.component"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { AccessTierInstallationMethod } from "../shared/AccessTierInstallationMethod.component"
import { AccessTierInstallationInstruction } from "../shared/AccessTierInstallationInstruction.component"
import styles from "./AccessTierInstallTab.module.scss"

interface Props {
    id: string
    accessTier: AccessTier
    canAccessAccessTierInstallStep: boolean
}

export function AccessTierInstallTab(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const [installationMethod, setInstallationMethod] = React.useState(
        props.accessTier.installationMethod
    )

    const {
        data: latestNetagentVersion,
        status: latestVersionStatus,
        error: latestVersionError,
    } = useGetLatestNetagentVersion()

    if (latestVersionStatus === "loading") {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("netagentDetails")
                )}
            />
        )
    }

    if (latestVersionStatus === "error") {
        return <Banner label={String(latestVersionError)} variant={Variant.ERROR} />
    }

    return (
        <div id={props.id} className={styles.container}>
            <AccessTierInstallationMethod
                installationMethod={installationMethod}
                onInstallationMethodChange={setInstallationMethod}
            />
            <InstallationInstructions
                accessTier={props.accessTier}
                installationMethod={installationMethod}
                latestNetagentVersion={latestNetagentVersion}
                canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
            />
        </div>
    )
}

interface InstallationInstructionsProps {
    accessTier: AccessTier
    installationMethod: InstallationMethod
    latestNetagentVersion: string
    canAccessAccessTierInstallStep: boolean
}

function InstallationInstructions(props: InstallationInstructionsProps): JSX.Element {
    const localization = useServiceLocalization()

    switch (props.installationMethod) {
        case InstallationMethod.DOCKER_CONTAINER:
            return (
                <AccessTierInstallationInstruction
                    title={localization.getString(
                        "installingAccessTierUsing",
                        localization.getString("dockerContainer")
                    )}
                >
                    <DockerInstaller
                        apiSecretKey={props.accessTier.apiKey.secret}
                        accessTierName={props.accessTier.name}
                        netagentVersion={props.latestNetagentVersion}
                        udpPortNumber={props.accessTier.udpPortNumber}
                        canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
                    />
                </AccessTierInstallationInstruction>
            )

        case InstallationMethod.TARBALL_INSTALLER:
            return (
                <AccessTierInstallationInstruction
                    title={localization.getString(
                        "installingAccessTierUsing",
                        localization.getString("tarballInstaller")
                    )}
                >
                    <TarballInstaller
                        apiSecretKey={props.accessTier.apiKey.secret}
                        accessTierName={props.accessTier.name}
                        netagentVersion={props.latestNetagentVersion}
                        canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
                    />
                </AccessTierInstallationInstruction>
            )

        case InstallationMethod.DEB_RPM_PACKAGE:
            return (
                <AccessTierInstallationInstruction
                    title={localization.getString(
                        "installingAccessTierUsing",
                        localization.getString("debRpmPkg")
                    )}
                >
                    <DebRpmInstaller
                        apiSecretKey={props.accessTier.apiKey.secret}
                        accessTierName={props.accessTier.name}
                        canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
                    />
                </AccessTierInstallationInstruction>
            )

        case InstallationMethod.CLOUD_FORMATION:
            return (
                <AccessTierInstallationInstruction
                    title={localization.getString(
                        "installingAccessTierUsing",
                        localization.getString("cloudFormation")
                    )}
                >
                    <CloudFormationInstaller
                        apiSecretKey={props.accessTier.apiKey.secret}
                        accessTierName={props.accessTier.name}
                        canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
                    />
                </AccessTierInstallationInstruction>
            )
    }
}
