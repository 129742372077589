import { Singleton } from "../decorators/Singleton.decorator"
import { BaseV2Api } from "./BaseV2.api"

@Singleton("ServiceTunnelApi")
export class ServiceTunnelApi extends BaseV2Api {
    public getServiceTunnels(): Promise<ServiceTunnelRes> {
        return this.get("/service_tunnel")
    }
}

interface ServiceTunnelRes {
    service_tunnels: ServiceTunnelObjRes[]
    count: number
}

export interface ServiceTunnelObjRes {
    created_at: number
    created_by: string
    description: string
    enabled: boolean
    friendly_name: string
    id: string
    name: string
    spec: string
    updated_at: number
    updated_by: string
    AttachedPolicy: AttachedPolicy | null
}

export interface AttachedPolicy {
    attached_at: number
    attached_by: string
    policy_id: string
    policy_name: string
    policy_status: boolean
}

export interface ServiceTunnelReq {
    kind: "BanyanServiceTunnel"
    type: "origin"
    metadata: ServiceTunnelMetadataReq
    spec: ServiceTunnelSpecReq
}

interface ServiceTunnelMetadataReq {
    name: string
    friendly_name: string
    description: string
    tags: {
        icon: string
        description_link: string
    }
}

export interface ServiceTunnelSpecReq {
    peer_access_tiers: ServiceTunnelClusterReq[]
}

export interface ServiceTunnelClusterReq {
    cluster: string
    access_tiers: string[]
    connectors?: string[]
}
