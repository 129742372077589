import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"
import {
    InputWithHide,
    LargeMessage,
    LoadMask,
    Form,
    FormLabel,
    FormColumns,
    FormRow,
    ErrorBanner,
    InfoBanner,
    CopyButton,
} from "../../../../../components"
import styles from "./ApiKeysDetail.module.scss"
import { Input } from "../../../../../../v3/components/input/Input.component"

export default function () {
    return (
        <>
            {this.state.loading && <LoadMask />}
            {!this.state.apiKey && !this.state.loading && (
                <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                    {this.ls.getString("apiKeyNotFound")}
                </LargeMessage>
            )}
            {this.state.apiKey && (
                <Form onSubmit={this.onSubmit} display="grid" floating labelWidth={200}>
                    <FormColumns right={<h2>{this.ls.getString("apiKeySettings")}</h2>} />
                    <FormRow>
                        {this.state.error && <ErrorBanner>{this.state.error}</ErrorBanner>}
                        {this.state.success && <InfoBanner>{this.state.success}</InfoBanner>}
                    </FormRow>
                    <FormLabel title={this.ls.getString("name")} htmlFor="name">
                        <Input
                            defaultValue={this.state.apiKey.name}
                            className={styles.formInput}
                            required
                            name="name"
                            id="name"
                            disabled={!this.props.canAccessApiKey}
                        />
                    </FormLabel>
                    <FormLabel title={this.ls.getString("description")} htmlFor="description">
                        <Input
                            defaultValue={this.state.apiKey.description}
                            className={styles.formInput}
                            required
                            name="description"
                            id="description"
                            disabled={!this.props.canAccessApiKey}
                        />
                    </FormLabel>
                    <FormLabel
                        title={this.ls.getString("secret")}
                        htmlFor="secret"
                        valueClassName={styles.secretLabel}
                    >
                        <>
                            <InputWithHide
                                value={this.state.apiKey.secret}
                                className={styles.formInput}
                                disabled
                                hideEyeIcon={!this.props.canAccessApiKey}
                            />
                            {this.props.canAccessApiKey && (
                                <CopyButton
                                    copyText={this.state.apiKey.secret}
                                    className={styles.copyButton}
                                />
                            )}
                        </>
                    </FormLabel>
                    <FormLabel title={this.ls.getString("scope")} htmlFor="scope">
                        <Input
                            value={this.state.apiKey.scope}
                            disabled
                            className={styles.formInput}
                            name="scope"
                            id="scope"
                        />
                    </FormLabel>
                    {this.props.canAccessApiKey && (
                        <FormColumns
                            right={
                                <div className={styles.buttons}>
                                    <Button
                                        type="button"
                                        className={styles.deleteButton}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.DESTRUCTIVE}
                                        loading={this.state.submitting}
                                        onClick={this.handleOpenDeleteModal}
                                    >
                                        {this.ls.getString("delete")}
                                    </Button>
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.PRIMARY}
                                        type="submit"
                                        loading={this.state.submitting}
                                    >
                                        {this.ls.getString("update")}
                                    </Button>
                                </div>
                            }
                        />
                    )}
                </Form>
            )}
        </>
    )
}
