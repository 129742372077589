import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import {
    ConfirmationModalWithoutRef,
    ModalAction,
    ModalApi,
} from "../../../components/modal/ConfirmationModal.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { FormButtons } from "../../../v3/components/form/form-buttons/FormButtons.component"
import { Form } from "../../../v3/components/form/Form.component"
import { AdvancedSettings, NetworkSettings } from "../../../v3/services/shared/AccessTier"
import {
    AccessTierDetails as AccessTier,
    ApiKey,
    useGetAccessTiersGroups,
    useGetApiKeys,
    useUpdateAccessTier,
} from "../../../v3/services/AccessTier.service"
import { ErrorToast, SuccessToast, ToastApi } from "../../../components/toast/Toast.components"
import {
    AccessTierGeneralInformation,
    GeneralInformationValues,
} from "../shared/AccessTierGeneralInformation.component"
import { AccessTierNetworkSettings } from "../shared/AccessTierNetworkSettings.component"
import { AccessTierAdvancedConfiguration } from "./AccessTierAdvancedConfiguration.component"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { Loader } from "../../../v3/components/loader/Loader.component"

interface Props {
    id: string
    accessTier: AccessTier
    isEditMode: boolean
    canAccessAccessTierGroups: boolean
    onClose(): void
}

export function AccessTierConfigurationTab(props: Props): JSX.Element {
    const ls = useServiceLocalization()

    const successToastRef = React.useRef<ToastApi>(null)
    const errorToastRef = React.useRef<ToastApi>(null)
    const updateApiKeyDialogRef = React.useRef<ModalApi<void>>(null)
    const updateAdvancedSettingsDialogRef = React.useRef<ModalApi<void>>(null)

    const [editableAccessTier, setEditableAccessTier] = React.useState(props.accessTier)
    const [isServiceTunnelSecured, setIsServiceTunnelSecured] = React.useState(() =>
        getIsServiceTunnelSecured(props.accessTier)
    )

    const accessTier = props.isEditMode ? editableAccessTier : props.accessTier

    const { data: apiKeys, status: apiKeysStatus, error: apiKeyError } = useGetApiKeys()

    const {
        data: accessTierGroups,
        status: accessTierGroupsStatus,
        error: accessTierGroupsError,
    } = useGetAccessTiersGroups()

    const accessTierLabel = ls.getString("accessTier")

    const { mutate: updateAccessTier, isLoading: isUpdatingAccessTier } = useUpdateAccessTier(
        props.accessTier,
        {
            onSuccess: (accessTier) => {
                onClose(accessTier)
                successToastRef.current?.openToast(
                    ls.getString(
                        "somethingHasBeenSuccessfullyUpdated",
                        accessTierLabel,
                        accessTier.name
                    )
                )
            },
            onError: (error) =>
                typeof error === "string"
                    ? errorToastRef.current?.openToast(error)
                    : console.error(error),
        }
    )

    if (apiKeysStatus === "loading" || accessTierGroupsStatus === "loading") {
        return <Loader center medium title={ls.getString("loadingSomething", accessTierLabel)} />
    }

    if (apiKeysStatus === "error" || accessTierGroupsStatus === "error") {
        return (
            <Banner label={String(apiKeyError ?? accessTierGroupsError)} variant={Variant.ERROR} />
        )
    }

    const onClose = (accessTier: AccessTier) => {
        setEditableAccessTier(accessTier)
        props.onClose()
    }

    const onSubmit: React.FormEventHandler = async (event) => {
        event.preventDefault()

        if (props.accessTier.advancedSettings === editableAccessTier.advancedSettings) {
            return updateAccessTier(editableAccessTier)
        }

        const response = await updateAdvancedSettingsDialogRef.current?.open()

        if (response?.action === ModalAction.COMPLETE) {
            updateAccessTier(editableAccessTier)
        }
    }

    const onGeneralInformationChange = (values: GeneralInformationValues) => {
        setEditableAccessTier(updateGeneralInformation(editableAccessTier, values))
    }

    const onApiKeyChange = async (apiKey: ApiKey) => {
        const response = await updateApiKeyDialogRef.current?.open()

        if (response?.action === ModalAction.COMPLETE) {
            setEditableAccessTier({ ...editableAccessTier, apiKey })
        }
    }

    const onConfirmApiKeyChange = async () => updateApiKeyDialogRef.current?.complete()

    const onConfirmAdvancedSettingsChange = async () =>
        updateAdvancedSettingsDialogRef.current?.complete()

    const onServiceTunnelSecuredChange = (isServiceTunnelSecured: boolean) => {
        setIsServiceTunnelSecured(isServiceTunnelSecured)
        setEditableAccessTier({
            ...editableAccessTier,
            networkSettings: isServiceTunnelSecured
                ? editableAccessTier.networkSettings
                : undefined,
            isSecure: isServiceTunnelSecured,
        })
    }

    const onNetworkSettingsChange = (networkSettings: NetworkSettings) => {
        setEditableAccessTier({ ...editableAccessTier, networkSettings })
    }

    const onAdvancedSettingsChange = (advancedSettings?: AdvancedSettings) => {
        setEditableAccessTier({ ...editableAccessTier, advancedSettings })
    }

    const onCancel: React.MouseEventHandler = (e) => {
        e.preventDefault()
        onClose(props.accessTier)
    }

    return (
        <Form id={props.id} onSubmit={onSubmit} role="tabpanel">
            <AccessTierGeneralInformation
                values={accessTier}
                apiKeys={apiKeys}
                accessTierGroups={accessTierGroups}
                isEditMode={props.isEditMode}
                isServiceTunnelSecured={isServiceTunnelSecured}
                canAccessAccessTierGroups={props.canAccessAccessTierGroups}
                onChange={onGeneralInformationChange}
                onApiKeyChange={onApiKeyChange}
                onServiceTunnelSecuredChange={onServiceTunnelSecuredChange}
            />
            {isServiceTunnelSecured && (
                <AccessTierNetworkSettings
                    values={accessTier.networkSettings}
                    accessTierGroup={accessTier.accessTierGroup}
                    onChange={onNetworkSettingsChange}
                    isReadMode={!props.isEditMode}
                />
            )}
            <AccessTierAdvancedConfiguration
                advancedSettings={
                    accessTier.accessTierGroup?.advancedSettings ?? accessTier.advancedSettings
                }
                isDisabled={!props.isEditMode || !!accessTier.accessTierGroup}
                onChange={onAdvancedSettingsChange}
            />
            {props.isEditMode && (
                <FormButtons
                    leftButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={onCancel}
                        >
                            {ls.getString("cancel")}
                        </Button>
                    }
                    rightButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                            loading={isUpdatingAccessTier}
                        >
                            {ls.getString("save")}
                        </Button>
                    }
                />
            )}
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
            <ConfirmationDialog
                id={Id.UPDATE_API_KEY_DIALOG}
                title={ls.getString("updateSomething", ls.getString("apiKey"))}
                onComplete={onConfirmApiKeyChange}
                ref={updateApiKeyDialogRef}
            >
                {ls.getString("updateAccessTierApiKeyMessage")}
            </ConfirmationDialog>
            <ConfirmationDialog
                id={Id.UPDATE_ADVANCED_SETTINGS_DIALOG}
                title={ls.getString("updateSomething", ls.getString("advancedSettings"))}
                onComplete={onConfirmAdvancedSettingsChange}
                ref={updateAdvancedSettingsDialogRef}
            >
                {ls.getString("updateAdvancedSettingsDescription")}
            </ConfirmationDialog>
        </Form>
    )
}

enum Id {
    UPDATE_API_KEY_DIALOG = "updateApiKeyDialog",
    UPDATE_ADVANCED_SETTINGS_DIALOG = "updateAdvancedSettingsDialog",
}

function getIsServiceTunnelSecured(accessTier: AccessTier): boolean {
    const haveCidrs =
        accessTier.networkSettings?.cidrs && accessTier.networkSettings.cidrs.length > 0
    const haveDomains =
        accessTier.networkSettings?.domains && accessTier.networkSettings.domains.length > 0
    const haveNetworkSettings = haveCidrs || haveDomains
    const haveAccessTierGroup = !!accessTier.accessTierGroup
    const isSecure = accessTier.isSecure
    return (haveNetworkSettings || haveAccessTierGroup || isSecure) ?? false
}

function updateGeneralInformation(
    editableAccessTier: AccessTier,
    values: GeneralInformationValues
): AccessTier {
    const updatedAccessTier = { ...editableAccessTier, ...values }

    if (editableAccessTier.accessTierGroup?.id === values.accessTierGroup?.id) {
        return updatedAccessTier
    }

    if (values.accessTierGroup) {
        return { ...updatedAccessTier, networkSettings: undefined, advancedSettings: undefined }
    }

    return {
        ...updatedAccessTier,
        networkSettings: {
            cidrs: editableAccessTier.accessTierGroup?.cidrRanges,
            domains: editableAccessTier.accessTierGroup?.privateDomains,
        },
        advancedSettings: editableAccessTier.accessTierGroup?.advancedSettings,
    }
}

const ConfirmationDialog = React.forwardRef(ConfirmationModalWithoutRef)
