import React, { useState } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { useActionBar } from "../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Accordion } from "../../components/accordion/Accordion.component"
import { ErrorBanner, InfoBanner } from "../../components/banner/Banner.component"
import { ColorPicker } from "../../components/color-picker/ColorPicker.component"
import { Container } from "../../components/container/Container.component"
import { Form } from "../../components/form/Form.component"
import { FormButtons } from "../../components/form/form-buttons/FormButtons.component"
import { FormRow } from "../../components/form/FormRow.component"
import { ImageUpload } from "../../components/image-upload/ImageUpload.component"
import { Input } from "../../components/input/Input.component"
import { TextArea } from "../../components/input/TextArea.component"
import { Loader } from "../../components/loader/Loader.component"
import { TabBar, TabProps } from "../../components/tab-bar/TabBar.component"
import {
    Customization as ICustomization,
    useGetCustomization,
    useSetCustomization,
} from "../../services/Org.service"
import styles from "./Customization.module.scss"
import { AppMessagingPreview } from "./preview/AppMessagingPreview.component"
import { BrowserErrorPreview } from "./preview/BrowserErrorPreview.component"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { Tooltip } from "../../components/tooltip/Tooltip.component"
import { MSPLayout } from "./MspLayout.componet"

interface Props {
    canUpdateCustomization?: boolean
}

export function Customization(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const [localCustomization, setLocalCustomization] = React.useState<ICustomization>()
    const [tab, setTab] = React.useState(Tab.BROWSER_PREVIEW)
    const [isCustomBrandingOn, setIsCustomBrandingOn] = useState(true)
    const {
        data: originalCustomization,
        error: getCustomizationError,
        refetch: refetchCustomization,
    } = useGetCustomization()

    const {
        mutate: setCustomization,
        isLoading: isSetCustomizationLoading,
        isSuccess: isSetCustomizationSuccess,
        error: setCustomizationError,
    } = useSetCustomization()

    React.useEffect(() => {
        setIsCustomBrandingOn(
            Boolean(originalCustomization?.logoFile ?? originalCustomization?.primaryColor)
        )
        setLocalCustomization(originalCustomization)
    }, [originalCustomization])

    useActionBar({
        title: localization.getString("brandingAndMessageCustomization"),
        items: [
            {
                label: localization.getString("desktopAndMobile"),
            },
        ],
        fetchData: () => {
            refetchCustomization()
        },
    })

    const logoLabel = localization.getString("logo")

    if (typeof getCustomizationError === "string") {
        return (
            <Container>
                <ErrorBanner children={getCustomizationError} />
            </Container>
        )
    }

    if (!localCustomization) return <Loader isLoading children center medium />

    const browserPreviewTab: TabProps<Tab> = {
        id: Tab.BROWSER_PREVIEW,
        label: localization.getString("browserPreview"),
        ariaControls: browserPreviewPanelId,
    }

    const cseAppPreviewTab: TabProps<Tab> = {
        id: Tab.CSE_APP_PREVIEW,
        label: localization.getString("cseAppPreview"),
        ariaControls: cseAppPreviewPanelId,
    }

    const tabs: TabProps<Tab>[] = [browserPreviewTab, cseAppPreviewTab]

    const onLogoFileChange = (logoFile?: string) =>
        setLocalCustomization((oldCustomization) => ({ ...oldCustomization, logoFile }))
    const onPrimaryColorChange = (primaryColor: string) =>
        setLocalCustomization((oldCustomization) => ({ ...oldCustomization, primaryColor }))
    const onAppSupportMessageChange = (appSupportMessage: string) =>
        setLocalCustomization((oldCustomization) => ({ ...oldCustomization, appSupportMessage }))
    const onAppSupportLinkChange = (appSupportLink: string) =>
        setLocalCustomization((oldCustomization) => ({ ...oldCustomization, appSupportLink }))
    const onErrorMessageChange = (errorMessage: string) =>
        setLocalCustomization((oldCustomization) => ({ ...oldCustomization, errorMessage }))
    const onErrorLinkChange = (errorLink: string) =>
        setLocalCustomization((oldCustomization) => ({ ...oldCustomization, errorLink }))

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        setCustomization({
            ...localCustomization,
            logoFile: isCustomBrandingOn ? localCustomization.logoFile : undefined,
            primaryColor: isCustomBrandingOn ? localCustomization.primaryColor : undefined,
        })
    }

    const onReset = () => setLocalCustomization(originalCustomization)

    return (
        <MSPLayout isMspOrg={originalCustomization?.isMspOrg || false}>
            <Container as={Form} onSubmit={onSubmit} onReset={onReset} className={styles.container}>
                {!originalCustomization?.isMspOrg && (
                    <header className={styles.header}>
                        <PageHeading>
                            {localization.getString("brandingAndMessageCustomization")}
                        </PageHeading>
                        <Tooltip title={localization.getString("refresh")}>
                            <Button
                                icon={IconType.REDO}
                                onClick={() => refetchCustomization()}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={localization.getString("refresh")}
                            />
                        </Tooltip>
                    </header>
                )}
                <Accordion
                    onToggle={setIsCustomBrandingOn}
                    open={isCustomBrandingOn}
                    label={localization.getString("customBranding")}
                    type="switch"
                    disabled={!props.canUpdateCustomization}
                >
                    <div className={styles.accordionChildren}>
                        <FormRow
                            label={logoLabel}
                            description={localization.getString("logoDescription")}
                            className={styles.formRow}
                            childrenClassName={styles.imageUploadContainer}
                        >
                            <ImageUpload
                                label={localization.getString("logoFileUploadInstruction")}
                                removeLabel={localization.getString("removeSomething", logoLabel)}
                                value={localCustomization.logoFile}
                                onChange={onLogoFileChange}
                                accept=".svg, .png, .jpg, .jpeg"
                            />
                            <span className={styles.imageUploadSpecification}>
                                {localization.getString("logoSupportedFormats")}
                            </span>
                        </FormRow>
                        <FormRow
                            label={localization.getString("primaryColor")}
                            description={localization.getString("primaryColorDescription")}
                        >
                            <ColorPicker
                                color={localCustomization.primaryColor ?? "#FFFFFF"}
                                onColorChange={onPrimaryColorChange}
                            />
                        </FormRow>
                    </div>
                </Accordion>
                <Accordion label={localization.getString("appMessaging")} type="plain" defaultOpen>
                    <div className={styles.accordionChildren}>
                        <FormRow
                            label={localization.getString("appSupportMessage")}
                            description={localization.getString("appSupportMessageDescription")}
                            className={styles.formRow}
                            htmlFor={CustomizationField.APP_SUPPORT_MESSAGE}
                        >
                            <TextArea
                                id={CustomizationField.APP_SUPPORT_MESSAGE}
                                className={styles.textarea}
                                value={localCustomization.appSupportMessage ?? ""}
                                onChangeValue={onAppSupportMessageChange}
                                placeholder={localization.getString("supportExample")}
                                maxLength={1024}
                                disabled={!props.canUpdateCustomization}
                            />
                        </FormRow>
                        <FormRow
                            label={localization.getString("appSupportLink")}
                            description={localization.getString("appSupportLinkDescription")}
                            htmlFor={CustomizationField.APP_SUPPORT_LINK}
                        >
                            <Input
                                id={CustomizationField.APP_SUPPORT_LINK}
                                type="url"
                                value={localCustomization.appSupportLink ?? ""}
                                onChangeValue={onAppSupportLinkChange}
                                placeholder={localization.getString("supportLinkExample")}
                                maxLength={1024}
                                disabled={!props.canUpdateCustomization}
                            />
                        </FormRow>
                    </div>
                </Accordion>
                <Accordion
                    label={localization.getString("browserErrorMessaging")}
                    type="plain"
                    defaultOpen
                >
                    <div className={styles.accordionChildren}>
                        <FormRow
                            label={localization.getString("errorMessage")}
                            description={localization.getString("errorMessageDescription")}
                            className={styles.formRow}
                            htmlFor={CustomizationField.ERROR_MESSAGE}
                        >
                            <TextArea
                                id={CustomizationField.ERROR_MESSAGE}
                                className={styles.textarea}
                                value={localCustomization.errorMessage ?? ""}
                                onChangeValue={onErrorMessageChange}
                                placeholder={localization.getString("supportExample")}
                                maxLength={100}
                                disabled={!props.canUpdateCustomization}
                            />
                        </FormRow>
                        <FormRow
                            label={localization.getString("errorLink")}
                            description={localization.getString("errorLinkDescription")}
                            htmlFor={CustomizationField.ERROR_LINK}
                        >
                            <Input
                                id={CustomizationField.ERROR_LINK}
                                type="url"
                                value={localCustomization.errorLink ?? ""}
                                onChangeValue={onErrorLinkChange}
                                placeholder={localization.getString("supportLinkExample")}
                                maxLength={1024}
                                disabled={!props.canUpdateCustomization}
                            />
                        </FormRow>
                    </div>
                </Accordion>
                <TabBar tabs={tabs} selectedTabId={tab} onChange={setTab} />
                {tab === Tab.BROWSER_PREVIEW && (
                    <div id={browserPreviewPanelId} className={styles.previewContainer}>
                        {!localCustomization.logoFile && (
                            <InfoBanner
                                children={localization.getString("defaultViewOfEndUserExperiences")}
                            />
                        )}
                        <BrowserErrorPreview
                            className={styles.preview}
                            logoFile={isCustomBrandingOn ? localCustomization.logoFile : undefined}
                            primaryColor={
                                isCustomBrandingOn ? localCustomization.primaryColor : undefined
                            }
                            errorMessage={localCustomization.errorMessage}
                            errorLink={localCustomization.errorLink}
                        />
                    </div>
                )}
                {tab === Tab.CSE_APP_PREVIEW && (
                    <div id={cseAppPreviewPanelId} className={styles.previewContainer}>
                        {localCustomization.appSupportMessage ? (
                            <AppMessagingPreview
                                className={styles.preview}
                                appSupportMessage={localCustomization.appSupportMessage}
                            />
                        ) : (
                            <InfoBanner
                                children={localization.getString(
                                    "enterAppSupportMessageToSeeCustomizedHelpPage"
                                )}
                            />
                        )}
                    </div>
                )}
                {props.canUpdateCustomization && (
                    <FormButtons
                        rightButtons={
                            <React.Fragment>
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    type="reset"
                                >
                                    {localization.getString("cancel")}
                                </Button>
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                    type="submit"
                                    loading={isSetCustomizationLoading}
                                >
                                    {localization.getString("save")}
                                </Button>
                            </React.Fragment>
                        }
                    >
                        {typeof setCustomizationError === "string" && (
                            <ErrorBanner children={setCustomizationError} />
                        )}
                        {isSetCustomizationSuccess && (
                            <InfoBanner
                                children={localization.getString("customizationSuccessful")}
                            />
                        )}
                    </FormButtons>
                )}
            </Container>
        </MSPLayout>
    )
}

enum Tab {
    BROWSER_PREVIEW = "browserPreviewTab",
    CSE_APP_PREVIEW = "cseAppPreviewTab",
}

const browserPreviewPanelId = `${Tab.BROWSER_PREVIEW}Panel`
const cseAppPreviewPanelId = `${Tab.CSE_APP_PREVIEW}Panel`

enum CustomizationField {
    APP_SUPPORT_MESSAGE = "appSupportMessage",
    APP_SUPPORT_LINK = "appSupportLink",
    ERROR_MESSAGE = "errorMessage",
    ERROR_LINK = "errorLink",
}
