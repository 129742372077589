import React from "react"
import styles from "./IssuedCertificatesList.module.scss"
import { Toolbar } from "../../../components/toolbar/Toolbar.component"
import { Grid } from "../../../components/grid/Grid.component"
import { StatusCellRenderer } from "../../../components/cell-renderers"
import { Banner } from "../../../components/banner/Banner.component"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import AgGridUtil from "../../../utils/AgGrid.util"
import {
    ButtonElement,
    IconType,
    ButtonType,
    Button,
} from "../../../../components/button/Button.component"
import { Tooltip } from "../../../../v3/components/tooltip/Tooltip.component"

export default function () {
    return (
        <div className={styles.gridWithToolbar}>
            {this.state.error && (
                <Banner type="error" icon={faExclamationCircle} className={styles.gridBanner}>
                    {this.state.error}
                </Banner>
            )}
            <Toolbar onSearchChange={this.onFilter} className={styles.toolBar}>
                <div className={styles.actions}>
                    {this.props.canDeleteIssuedCertificate && (
                        <Tooltip title={this.ls.getString("delete")}>
                            <Button
                                icon={IconType.TRASH}
                                onClick={this.onDeleteCertificate}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                disabled={this.state.selectedCertificateLen === 0}
                                aria-label={this.ls.getString("delete")}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={this.ls.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={this.onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={this.ls.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </Toolbar>
            <Grid
                onGridReady={this.onGridReady}
                onRowSelected={this.rowSelectedHandler}
                columnDefs={this.columns}
                pagination
                context={{ history: this.props.history }}
                components={{
                    statusCellRenderer: StatusCellRenderer,
                    epochTimeRenderer: AgGridUtil.epochTimeFormatter,
                }}
            ></Grid>
        </div>
    )
}
