import { faSignIn, faUserShield } from "@fortawesome/pro-solid-svg-icons"
import React, { ReactNode } from "react"

import { ROUTE } from "../../../routes"
import { AppNavLink } from "../../services/shared/AppNavLink"
import SettingsTemplate from "./Settings.template"
import { LocalizationService } from "../../services/localization/Localization.service"
import { LanguageKey } from "../../services/localization/languages/en-US.language"

interface Props {
    canConfigureAdminSignOnSettings?: boolean
    canAccessSignOn: boolean
}
export class MomSettings extends React.Component<Props, SettingsState> {
    constructor(props: Props) {
        super(props)

        this.state = { leftNavLinks: this.getNavLinks(), isMom: true }
    }

    public render(): ReactNode {
        return SettingsTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()

    public async componentDidMount(): Promise<void> {
        this.setState({ leftNavLinks: this.getNavLinks() })
    }

    private sharedLinks = {
        adminSignOn: {
            label: this.localizationService.getString("adminSignOn"),
            href: ROUTE.MOM_SIGN_ON,
            icon: faSignIn,
        },
        manageAdmins: {
            label: this.localizationService.getString("manageAdmins"),
            href: ROUTE.MOM_MANGE_ADMINS,
            icon: faUserShield,
        },
    } as const

    private getDivider(label: LanguageKey): AppNavLink {
        return {
            divider: true,
            label: this.localizationService.getString(label),
        }
    }

    private getNavLinks(): AppNavLink[] {
        return [
            this.getDivider("adminSettings"),
            this.sharedLinks.adminSignOn,
            this.sharedLinks.manageAdmins,
        ]
    }
}

interface SettingsState {
    leftNavLinks: AppNavLink[]
    isMom?: boolean
}
