import React, { useMemo } from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
import { Accordion } from "../../../v3/components/accordion/Accordion.component"
import { Input } from "../../../v3/components/input/Input.component"
import {
    PillMultiSelect,
    PillMultiSelectOption as AccessTierOption,
} from "../../../v3/components/pill-multi-select/PillMultiSelect.component"
import { ToggleButton, ToggleButtonItem } from "../../../v3/components/toggle-button/ToggleButton"
import { AdvancedSettingsStatus } from "../../../v3/services/shared/AccessTier"
import {
    AccessTier,
    CreateAccessTierGroupVariables as AccessTierGroupFormValues,
} from "../../../v3/services/AccessTierGroup.service"
import { MultiInput } from "../../../components/form/inputs/MultiInput.component"
import { FormRow } from "../../../components/form/FormRow.component"
import styles from "./AccessTierGroupsForm.module.scss"

export { AdvancedSettingsStatus } from "../../../v3/services/shared/AccessTier"
export type { CreateAccessTierGroupVariables as AccessTierGroupFormValues } from "../../../v3/services/AccessTierGroup.service"

export interface Props<AccessTierGroup extends AccessTierGroupFormValues> {
    accessTierGroup: AccessTierGroup
    accessTiers?: AccessTier[]
    isEditMode: boolean
    onChange(accessTierGroup: AccessTierGroup): void
}

export function AccessTierGroupsForm<AccessTierGroup extends AccessTierGroupFormValues>(
    props: Props<AccessTierGroup>
): JSX.Element {
    const localization = useServiceLocalization()

    const [option, setOption] = React.useState(() =>
        getAdvancedSettingsOption(props.accessTierGroup)
    )

    const accessTiersInGroupIds = useMemo(
        () => props.accessTierGroup.accessTiers.map((accessTier) => accessTier.id),
        [props.accessTierGroup.accessTiers]
    )

    // TODO: This should be the responsibility of the MultiSelect component
    const accessTierOptions = React.useMemo(
        () =>
            props.accessTiers?.map(
                (accessTier: AccessTier): AccessTierOption => ({
                    label: accessTier.name,
                    value: accessTier.id,
                })
            ) ?? [],
        [props.accessTiers]
    )

    const onNameChange = (name: string) =>
        props.onChange({
            ...props.accessTierGroup,
            name,
        })
    const onDescriptionChange = (description: string) =>
        props.onChange({
            ...props.accessTierGroup,
            description,
        })
    const onSharedAddressChange = (sharedAddress: string) =>
        props.onChange({
            ...props.accessTierGroup,
            sharedAddress,
        })
    const onCidrsChange = (cidrs: string[]) =>
        props.onChange({
            ...props.accessTierGroup,
            groupLevelNetworkSettings: {
                ...props.accessTierGroup.groupLevelNetworkSettings,
                cidrs,
            },
        })
    const onDomainsChange = (domains: string[]) =>
        props.onChange({
            ...props.accessTierGroup,
            groupLevelNetworkSettings: {
                ...props.accessTierGroup.groupLevelNetworkSettings,
                domains,
            },
        })
    const onMetricsCollectionAddressChange = (metricsCollectionAddress: string) =>
        props.onChange({
            ...props.accessTierGroup,
            advancedSettings: {
                ...props.accessTierGroup.advancedSettings,
                metricsCollectionAddress,
            },
        })
    const onEventsRateLimitingChange = (eventsRateLimiting: AdvancedSettingsStatus) =>
        props.onChange({
            ...props.accessTierGroup,
            advancedSettings: {
                ...props.accessTierGroup.advancedSettings,
                eventsRateLimiting,
            },
        })
    const onEventKeyRateLimitingChange = (eventKeyRateLimiting: AdvancedSettingsStatus) =>
        props.onChange({
            ...props.accessTierGroup,
            advancedSettings: {
                ...props.accessTierGroup.advancedSettings,
                eventKeyRateLimiting,
            },
        })
    const onForwardTrustCookieChange = (forwardTrustCookie: AdvancedSettingsStatus) =>
        props.onChange({
            ...props.accessTierGroup,
            advancedSettings: {
                ...props.accessTierGroup.advancedSettings,
                forwardTrustCookie,
            },
        })
    const onEnableStrictTransportChange = (enableStrictTransport: AdvancedSettingsStatus) =>
        props.onChange({
            ...props.accessTierGroup,
            advancedSettings: {
                ...props.accessTierGroup.advancedSettings,
                enableStrictTransport,
            },
        })
    const onEnablePrivateResourceDiscoveryChange = (
        enablePrivateResourceDiscovery: AdvancedSettingsStatus
    ) =>
        props.onChange({
            ...props.accessTierGroup,
            advancedSettings: {
                ...props.accessTierGroup.advancedSettings,
                enablePrivateResourceDiscovery,
            },
        })
    const onAttachedAccessTierIdsChange = (attachedAccessTierIds: string[]) => {
        const attachedAccessTiers = props.accessTiers?.filter((accessTier) =>
            attachedAccessTierIds.includes(accessTier.id)
        )
        props.onChange({ ...props.accessTierGroup, accessTiers: attachedAccessTiers })
    }

    const advancedSettingsLabel = localization.getString("advancedSettings")
    const eventsRateLimitingLabel = localization.getString("eventsRateLimiting")
    const eventKeyRateLimitingLabel = localization.getString("eventKeyRateLimiting")
    const forwardTrustCookieLabel = localization.getString("forwardTrustCookie")

    const advancedSettingsToggleItems: ToggleButtonItem[] = [
        {
            label: localization.getString("default"),
            value: AdvancedSettingsOption.DEFAULT,
            ariaLabel: localization.getString("defaultSomething", advancedSettingsLabel),
            onClick: () => {
                setOption(AdvancedSettingsOption.DEFAULT)
                props.onChange({
                    ...props.accessTierGroup,
                    advancedSettings: {},
                })
            },
            selected: option === AdvancedSettingsOption.DEFAULT,
        },
        {
            label: localization.getString("custom"),
            value: AdvancedSettingsOption.CUSTOM,
            ariaLabel: localization.getString("customSomething", advancedSettingsLabel),
            onClick: () => setOption(AdvancedSettingsOption.CUSTOM),
            selected: option === AdvancedSettingsOption.CUSTOM,
        },
    ]

    return (
        <React.Fragment>
            <Accordion label={localization.getString("generalInformation")} type="plain" open>
                <div className={styles.accordionContent}>
                    <FormRow
                        label={localization.getString(
                            "somethingName",
                            localization.getString("accessTierGroup")
                        )}
                        htmlFor={Id.NAME}
                    >
                        <Input
                            id={Id.NAME}
                            value={props.accessTierGroup.name}
                            onChangeValue={onNameChange}
                            disabled={!props.isEditMode}
                            required
                        />
                    </FormRow>
                    <FormRow
                        label={localization.getString(
                            "somethingOptional",
                            localization.getString("description")
                        )}
                        htmlFor={Id.DESCRIPTION}
                    >
                        <Input
                            id={Id.DESCRIPTION}
                            value={props.accessTierGroup.description ?? ""}
                            onChangeValue={onDescriptionChange}
                            disabled={!props.isEditMode}
                        />
                    </FormRow>
                    <FormRow
                        label={localization.getString("sharedAddress")}
                        description={localization.getString("sharedAddressDescription")}
                        htmlFor={Id.SHARED_ADDRESS}
                    >
                        <Input
                            id={Id.SHARED_ADDRESS}
                            value={props.accessTierGroup.sharedAddress}
                            onChangeValue={onSharedAddressChange}
                            disabled={!props.isEditMode}
                            required
                            pattern={PatternUtil.DOMAIN.source}
                            title={localization.getString("fqdnIsInvalid")}
                        />
                    </FormRow>
                </div>
            </Accordion>
            <Accordion
                label={localization.getString(
                    "somethingOptional",
                    localization.getString("groupLevelNetworkSettings")
                )}
                type="plain"
                open
            >
                <div className={styles.accordionContent}>
                    <FormRow
                        label={localization.getString("whatNetworkWouldYouLikeToMakeAvailable")}
                        description={localization.getString(
                            "thisCanBeCidrRangeOrIPAddressInCidrFormat"
                        )}
                    >
                        <MultiInput
                            label={localization.getString("cidrAddressExample")}
                            onChange={onCidrsChange}
                            values={props.accessTierGroup.groupLevelNetworkSettings?.cidrs}
                            placeholder={localization.getString("cidrAddressExample")}
                            disabled={!props.isEditMode}
                            patternProps={{
                                pattern: PatternUtil.CIDR_REGEX.source,
                                errorMessage: localization.getString("cidrPlaceholder"),
                            }}
                        />
                    </FormRow>
                    <FormRow
                        label={localization.getString(
                            "somethingOptional",
                            localization.getString("whatDomainsExistOnNetworkProvidedAbove")
                        )}
                        description={localization.getString(
                            "thisAllowsEndUsersToContinueTheirUseOfFQDNWithinTheServiceTunnel"
                        )}
                    >
                        <MultiInput
                            label={localization.getString("privateDomains")}
                            onChange={onDomainsChange}
                            values={props.accessTierGroup.groupLevelNetworkSettings?.domains}
                            placeholder={localization.getString("egDomainNameDotCom")}
                            disabled={!props.isEditMode}
                        />
                    </FormRow>
                </div>
            </Accordion>
            <Accordion
                type="plain"
                open={option === AdvancedSettingsOption.CUSTOM}
                label={
                    <FormRow
                        label={localization.getString("somethingOptional", advancedSettingsLabel)}
                        className={styles.header}
                        childrenClassName={styles.flex}
                    >
                        <ToggleButton
                            items={advancedSettingsToggleItems}
                            disabled={!props.isEditMode}
                        />
                    </FormRow>
                }
            >
                <div className={styles.accordionContent}>
                    <FormRow
                        label={localization.getString("metricsCollectionAddress")}
                        description={localization.getString("metricsCollectionAddressDesc")}
                    >
                        <Input
                            type="text"
                            placeholder={localization.getString("metricsCollectionAddressExample")}
                            value={
                                props.accessTierGroup.advancedSettings?.metricsCollectionAddress ??
                                ""
                            }
                            onChangeValue={onMetricsCollectionAddressChange}
                            disabled={!props.isEditMode}
                        />
                    </FormRow>
                    <FormRow
                        label={eventsRateLimitingLabel}
                        description={localization.getString("eventsRateLimitingDesc")}
                        childrenClassName={styles.flex}
                    >
                        <AdvancedSettingsStatusToggle
                            label={eventsRateLimitingLabel}
                            status={
                                props.accessTierGroup.advancedSettings?.eventsRateLimiting ??
                                AdvancedSettingsStatus.DEFAULT
                            }
                            onStatusChange={onEventsRateLimitingChange}
                            isDisabled={!props.isEditMode}
                        />
                    </FormRow>
                    <FormRow
                        label={eventKeyRateLimitingLabel}
                        description={localization.getString("eventKeyRateLimitingDesc")}
                        childrenClassName={styles.flex}
                    >
                        <AdvancedSettingsStatusToggle
                            label={eventKeyRateLimitingLabel}
                            status={
                                props.accessTierGroup.advancedSettings?.eventKeyRateLimiting ??
                                AdvancedSettingsStatus.DEFAULT
                            }
                            onStatusChange={onEventKeyRateLimitingChange}
                            isDisabled={!props.isEditMode}
                        />
                    </FormRow>
                    <FormRow
                        label={forwardTrustCookieLabel}
                        description={localization.getString("forwardTrustCookieDesc")}
                        childrenClassName={styles.flex}
                    >
                        <AdvancedSettingsStatusToggle
                            label={forwardTrustCookieLabel}
                            status={
                                props.accessTierGroup.advancedSettings?.forwardTrustCookie ??
                                AdvancedSettingsStatus.DEFAULT
                            }
                            onStatusChange={onForwardTrustCookieChange}
                            isDisabled={!props.isEditMode}
                        />
                    </FormRow>
                    <FormRow
                        label={localization.getString("enableStrictTransport")}
                        description={localization.getString("enableStrictTransportDesc")}
                        childrenClassName={styles.flex}
                    >
                        <AdvancedSettingsStatusToggle
                            label={localization.getString("strictTransportSecurity")}
                            status={
                                props.accessTierGroup.advancedSettings?.enableStrictTransport ??
                                AdvancedSettingsStatus.DEFAULT
                            }
                            onStatusChange={onEnableStrictTransportChange}
                            isDisabled={!props.isEditMode}
                        />
                    </FormRow>
                    <FormRow
                        label={localization.getString("enablePrivateResourceDiscovery")}
                        description={localization.getString(
                            "enablePrivateResourceDiscoveryDescription"
                        )}
                        childrenClassName={styles.flex}
                    >
                        <AdvancedSettingsStatusToggle
                            label={localization.getString("privateResourceDiscovery")}
                            status={
                                props.accessTierGroup.advancedSettings
                                    ?.enablePrivateResourceDiscovery ??
                                AdvancedSettingsStatus.DEFAULT
                            }
                            onStatusChange={onEnablePrivateResourceDiscoveryChange}
                            isDisabled={!props.isEditMode}
                        />
                    </FormRow>
                </div>
            </Accordion>
            {props.isEditMode && (
                <Accordion
                    label={localization.getString(
                        "somethingOptional",
                        localization.getString("attachAccessTiers")
                    )}
                    type="plain"
                    open
                >
                    <PillMultiSelect
                        label={localization.getString("selectAccessTiersToAttachToThisGroup")}
                        placeholder={localization.getString("attachAnAccessTier")}
                        options={accessTierOptions}
                        value={accessTiersInGroupIds}
                        onChange={onAttachedAccessTierIdsChange}
                    />
                </Accordion>
            )}
        </React.Fragment>
    )
}

interface AdvancedSettingsStatusToggleProps {
    label: string
    status: AdvancedSettingsStatus
    isDisabled: boolean
    onStatusChange(value: AdvancedSettingsStatus): void
}

function AdvancedSettingsStatusToggle(props: AdvancedSettingsStatusToggleProps): JSX.Element {
    const localization = useServiceLocalization()

    const statusToggleItems: ToggleButtonItem[] = [
        {
            label: localization.getString("default"),
            ariaLabel: localization.getString("defaultSomething", props.label),
            onClick: () => props.onStatusChange(AdvancedSettingsStatus.DEFAULT),
            selected: props.status === AdvancedSettingsStatus.DEFAULT,
        },
        {
            label: localization.getString("enabled"),
            ariaLabel: localization.getString("enabledSomething", props.label),
            onClick: () => props.onStatusChange(AdvancedSettingsStatus.ENABLED),
            selected: props.status === AdvancedSettingsStatus.ENABLED,
        },
        {
            label: localization.getString("disabled"),
            ariaLabel: localization.getString("disabledSomething", props.label),
            onClick: () => props.onStatusChange(AdvancedSettingsStatus.DISABLED),
            selected: props.status === AdvancedSettingsStatus.DISABLED,
        },
    ]

    return <ToggleButton items={statusToggleItems} disabled={props.isDisabled} />
}

enum Id {
    NAME = "name",
    DESCRIPTION = "description",
    SHARED_ADDRESS = "sharedAddress",
}

enum AdvancedSettingsOption {
    DEFAULT = "default",
    CUSTOM = "custom",
}

function getAdvancedSettingsOption(values: AccessTierGroupFormValues): AdvancedSettingsOption {
    return !!values.advancedSettings?.metricsCollectionAddress ||
        isCustomSetting(values.advancedSettings?.eventsRateLimiting) ||
        isCustomSetting(values.advancedSettings?.eventKeyRateLimiting) ||
        isCustomSetting(values.advancedSettings?.forwardTrustCookie) ||
        isCustomSetting(values.advancedSettings?.enableStrictTransport) ||
        isCustomSetting(values.advancedSettings?.enablePrivateResourceDiscovery)
        ? AdvancedSettingsOption.CUSTOM
        : AdvancedSettingsOption.DEFAULT
}

function isCustomSetting(status?: AdvancedSettingsStatus): boolean {
    switch (status) {
        case undefined:
        case AdvancedSettingsStatus.DEFAULT:
            return false

        case AdvancedSettingsStatus.ENABLED:
        case AdvancedSettingsStatus.DISABLED:
            return true
    }
}
