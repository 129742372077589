import * as React from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { faExclamation } from "@fortawesome/pro-solid-svg-icons"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { PolicyAttr, PolicyMetadata } from "../../../api/Secure.api"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import {
    PolicySecure,
    PolicyType,
    useServiceLocalization,
    useServiceSecure,
} from "../../../services"
import { Loader } from "../../../../v3/components/loader/Loader.component"
import { PolicyForm } from "../form/PolicyForm"
import styles from "./PolicyEdit.module.scss"

export function PolicyEdit() {
    const history = useHistory()
    const match = useRouteMatch<{ id: string }>()
    const location = useLocation()

    // grab service references
    const secureService = useServiceSecure()
    const localization = useServiceLocalization()

    // we'll track our own loading and error states for the final request
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState<string | null>(null)

    const submitForm = async ({
        attr,
        metadata,
        type,
    }: {
        type: PolicyType
        attr: PolicyAttr
        metadata: PolicyMetadata
    }) => {
        const policy = await secureService.editPolicy(metadata, type, attr)
        history.push(formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, { id: policy.id }))
    }

    // load the current policy data
    const policyID = match.params.id
    const [policy, setPolicy] = React.useState<PolicySecure | null>(null)

    // the function to call to load the policy information
    const fetchData = React.useCallback(() => {
        setLoading(true)
        secureService
            .getPolicy(policyID)
            .then((policy: PolicySecure) => {
                setPolicy(policy)

                // if the spec cannot be parsed don't show the form
                try {
                    JSON.parse(policy.spec)
                } catch {
                    setError(localization.getString("failedToParseJson"))
                }
            })
            .finally(() => setLoading(false))
    }, [secureService, localization, policyID])

    // load the data and set the action bar
    React.useEffect(() => {
        fetchData()
    }, [fetchData])

    if (loading) return <Loader children isLoading center medium />

    if (!policy) {
        return (
            <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                {localization.getString("policyNotFound")}
            </LargeMessage>
        )
    }

    if (error) {
        return (
            <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                {error}
            </LargeMessage>
        )
    }

    const onCancel = () => {
        history.push(formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, { id: policyID }))
    }

    return <PolicyForm onSubmit={submitForm} edit={policy} onCancel={onCancel} />
}
