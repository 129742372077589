import * as React from "react"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { TrustLevel } from "../../../api/Entity.api"
import {
    PolicyAccess,
    PolicyConditions,
    PolicyL7Access,
    PolicyL7Action,
} from "../../../api/Secure.api"
import { FormLabel, Select, Button, FormSection, Tooltip } from "../../../components"
import { useServiceLocalization } from "../../../services"
import styles from "./PolicyForm.module.scss"
import classNames from "classnames"
import { MultiInput } from "../../../../v3/components/multi-input/MultiInput.component"

interface Props {
    disabled?: boolean
    initialState: PolicyAccess[]
    setAccess: (groups: PolicyAccess[]) => void
    loading: boolean
    roles: string[]
    hideRules?: boolean
}

export function AccessGroupList({
    initialState,
    setAccess,
    disabled,
    loading,
    roles,
    hideRules,
}: Props) {
    const localization = useServiceLocalization()
    const [accessGroups, setAccessGroups] = React.useState<AccessGroup[]>(
        initialState.length > 0
            ? initialState.map((group, i) => ({
                  id: i,
                  roles: group.roles,
                  rules: group.rules.l7_access
                      ? group.rules.l7_access.map((rule, j) => ({
                            id: j,
                            resources: rule.resources.filter(Boolean),
                            actions: rule.actions,
                            conditions: group.rules.conditions,
                        }))
                      : [],
                  conditions: group.rules.conditions,
              }))
            : [defaultAccessGroup(hideRules)]
    )

    React.useEffect(() => {
        //update parent state
        setAccess(
            accessGroups.map((group) => ({
                roles: group.roles,
                rules: {
                    l7_access: group.rules.map((rule) => ({
                        resources: rule.resources.filter(Boolean),
                        actions: rule.actions,
                    })),
                    conditions: group.conditions,
                },
            }))
        )
    }, [accessGroups, setAccess])

    const newAccessGroup = () => {
        // add the group to the list
        setAccessGroups([...accessGroups, defaultAccessGroup(hideRules)])
    }

    const deleteGroup = (id: number) => {
        setAccessGroups((groups) => {
            return groups.filter((target) => target.id !== id)
        })
    }

    return (
        <>
            <div>
                {accessGroups &&
                    accessGroups.map((group, i) => (
                        <AccessGroupInput
                            i={i + 1}
                            group={group}
                            roles={roles}
                            key={group.id}
                            setAccessGroups={setAccessGroups}
                            collapsible={accessGroups.length >= 3}
                            disabled={disabled}
                            onDelete={() => deleteGroup(group.id)}
                            loading={loading}
                            showDelete={accessGroups.length > 1}
                            hideRules={hideRules}
                        />
                    ))}
            </div>
            {!disabled && (
                <Button icon={faPlus} onClick={newAccessGroup}>
                    {localization.getString("newAccessGroup")}
                </Button>
            )}
        </>
    )
}

function AccessGroupInput({
    i,
    group,
    roles,
    setAccessGroups,
    collapsible,
    disabled,
    onDelete,
    loading,
    showDelete,
    hideRules,
}: {
    i: number
    group: AccessGroup
    roles: string[]
    setAccessGroups: React.Dispatch<React.SetStateAction<AccessGroup[]>>
    collapsible: boolean
    disabled?: boolean
    onDelete: () => void
    loading: boolean
    showDelete: boolean
    hideRules?: boolean
}) {
    const updateGroup = (updater: (group: AccessGroup) => void) => {
        setAccessGroups((groups) => {
            const newGroups = [...groups]

            for (const target of groups) {
                if (target.id !== group.id) {
                    continue
                }
                updater(target)
                break
            }

            return newGroups
        })
    }

    const newRule = () =>
        updateGroup((target) => {
            target.rules.push({
                id: new Date().getTime(),
                actions: [PolicyL7Action["*"]],
                resources: ["*"],
            })
        })

    const deleteRule = (id: number) => () =>
        updateGroup((target) => {
            target.rules = [...target.rules].filter((rule) => rule.id !== id)
        })

    const updateRule = (id: number, updater: (rule: AccessGroup["rules"][0]) => void) =>
        updateGroup((target) => {
            for (const rule of target.rules) {
                if (rule.id === id) {
                    updater(rule)
                    break
                }
            }
        })

    const updateActions = (id: number) => (newActions: string | string[]) => {
        updateRule(id, (rule) => {
            rule.actions = newActions as PolicyL7Action[]
        })
    }

    const updateResources = (id: number) => (newActions: string | string[]) => {
        updateRule(id, (rule) => {
            rule.resources = newActions as string[]
        })
    }

    const updateConditions = (conditions: PolicyConditions) =>
        updateGroup((target) => {
            target.conditions = conditions
        })

    const updateRoles = (newRoles: string | string[]) =>
        updateGroup((target) => (target.roles = newRoles as string[]))

    const localization = useServiceLocalization()

    return (
        <FormSection
            title={`${localization.getString("accessGroup#")}${i}`}
            className={classNames(styles.accessGroupContainer, styles.webAccessGroup)}
            collapsible={collapsible}
            startOpen
            hideLine
        >
            {!disabled && showDelete && (
                <Tooltip title={localization.getString("deleteAccessGroup#") + i} placement="top">
                    <button className={styles.closeButton} onClick={onDelete}>
                        X
                    </button>
                </Tooltip>
            )}
            <FormLabel
                title={localization.getString(
                    "onlyAllowUsersAndDevicesWIthTheFollowingTrustLevels"
                )}
                className={styles.ruleSection}
                inline={false}
                width={300}
                slim
                htmlFor="onlyAllowUsersAndDevicesWIthTheFollowingTrustLevels"
            >
                <Select
                    options={[
                        {
                            displayName: localization.getString("noTrustLevelIgnoreTrustScore"),
                            value: TrustLevel.NONE,
                        },
                        {
                            displayName: localization.getString("highTrustLevelsOnly"),
                            value: TrustLevel.HIGH,
                        },
                        {
                            displayName: localization.getString("mediumOrHighTrustLevelsOnly"),
                            value: TrustLevel.MID,
                        },
                        {
                            displayName: localization.getString("anyTrustLevelExceptAlwaysDeny"),
                            value: TrustLevel.LOW,
                        },
                    ]}
                    value={(group?.conditions.trust_level as TrustLevel) || TrustLevel.NONE}
                    onChange={(val: string | string[]) =>
                        updateConditions({
                            ...group.conditions,
                            trust_level: val as TrustLevel,
                        })
                    }
                    disabled={disabled}
                />
            </FormLabel>
            <FormLabel
                title={localization.getString("onlyAllowTheFollowingRoles")}
                className={styles.ruleSection}
                inline={false}
                width={300}
                slim
                htmlFor="onlyAllowTheFollowingRoles"
            >
                {!roles && !loading && (
                    <p className={styles.noRolesMessage}>
                        {localization.getString("noRolesFoundPleaseCreateARole")}
                    </p>
                )}
                {roles && (
                    <Select
                        multiple
                        options={roles}
                        value={group.roles as string[]}
                        onChange={updateRoles}
                        disabled={disabled}
                        required
                    />
                )}
            </FormLabel>
            {!hideRules && (
                <>
                    <div className={styles.ruleSection}>
                        {group.rules.map((rule, i) => (
                            <FormSection
                                title={`Rule #${i + 1}`}
                                hideLine
                                className={styles.rule}
                                key={rule.id}
                            >
                                {!disabled && group.rules.length > 1 && (
                                    <Tooltip
                                        title={localization.getString("deleteRule#") + (i + 1)}
                                        placement="top"
                                    >
                                        <button
                                            className={styles.closeButton}
                                            onClick={deleteRule(rule.id)}
                                        >
                                            X
                                        </button>
                                    </Tooltip>
                                )}
                                <FormLabel
                                    title={localization.getString("action(s)")}
                                    inline={false}
                                    htmlFor={`group-${group.id}-${rule.id}-actions`}
                                    className={styles.ruleLabel}
                                >
                                    <Select
                                        multiple
                                        options={Object.keys(PolicyL7Action)}
                                        value={rule.actions}
                                        disabled={disabled}
                                        required
                                        onChange={updateActions(rule.id)}
                                    />
                                </FormLabel>
                                <FormLabel
                                    title={localization.getString("resource(s)")}
                                    inline={false}
                                    htmlFor={`group-${group.id}-${rule.id}-resources`}
                                    className={styles.ruleLabel}
                                    tooltip={localization.getString("policyResourceDescription")}
                                >
                                    <MultiInput
                                        values={rule.resources}
                                        disabled={disabled}
                                        onChange={updateResources(rule.id)}
                                        required
                                        placeholder={localization.getString(
                                            "policyResourcePlaceholder"
                                        )}
                                    />
                                </FormLabel>
                            </FormSection>
                        ))}
                    </div>
                    {!disabled && (
                        <div className={styles.ruleSection}>
                            <Button icon={faPlus} onClick={newRule}>
                                {localization.getString("newRule")}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </FormSection>
    )
}

function defaultAccessGroup(hideRules?: boolean): AccessGroup {
    return {
        id: new Date().getTime(),
        roles: [],
        rules: hideRules
            ? []
            : [
                  {
                      id: new Date().getTime(),
                      actions: [PolicyL7Action["*"]],
                      resources: ["*"],
                  },
              ],
        conditions: {
            trust_level: TrustLevel.NONE,
        },
    }
}

type AccessGroup = {
    id: number
    roles: string[]
    rules: ({ id: number } & PolicyL7Access)[]
    conditions: PolicyConditions
}
