import React from "react"

import sonicWallCSELogo from "../../../images/logo-horiz-black.svg"
import { useServiceLinks, useServiceLocalization } from "../../../pre-v3/services"
import { Background } from "../../components/background/Background.component"
import { ErrorBanner } from "../../components/banner/Banner.component"
import { BanyanHeader } from "../../components/banyan-header/BanyanHeader.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { Card } from "../../components/card/Card.component"
import { OldInput } from "../../components/old-input/OldInput.component"
import { useSendForgotOrgNameEmail } from "../../services/SignUp.service"
import styles from "./ForgotOrgName.module.scss"

export function ForgotOrgName(): JSX.Element {
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()

    const [emailAddress, setEmailAddress] = React.useState("")

    const sendEmail = useSendForgotOrgNameEmail(emailAddress)

    if (sendEmail.isSuccess) {
        return (
            <Container>
                <h2>{localization.getString("theRecoveryEmailWasSent")}</h2>
                <p className={styles.description}>
                    {localization.replaceJSX(
                        "emailProvided",
                        <span className={styles.userEmailAddress}>{emailAddress}</span>
                    )}
                </p>
                <p>{localization.getString("theRecoveryEmailWasSentDescription")}</p>
                <Button
                    asElement={ButtonElement.LINK}
                    buttonType={ButtonType.PRIMARY}
                    to={linkService.getLink("emailSupport")}
                    className={styles.button}
                >
                    {localization.getString("contactSupport")}
                </Button>
            </Container>
        )
    }

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        sendEmail.mutate()
    }

    const emailAddressLabel = localization.getString("emailAddress")

    return (
        <Container>
            <img
                src={sonicWallCSELogo}
                alt={localization.getString("sonicWallCse")}
                className={styles.logo}
            />
            <p className={styles.textAlignCenter}>
                {localization.getString("pleaseEnterYourEmailAddress")}
            </p>
            {typeof sendEmail.error === "string" && (
                <ErrorBanner className={styles.errorBanner}>{sendEmail.error}</ErrorBanner>
            )}
            <form onSubmit={onSubmit} className={styles.form}>
                <OldInput
                    type="email"
                    required
                    autoFocus
                    value={emailAddress}
                    onChangeValue={setEmailAddress}
                    aria-label={emailAddressLabel}
                    placeholder={emailAddressLabel}
                    className={styles.spaceBelow}
                />
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.PRIMARY}
                    type="submit"
                    loading={sendEmail.isLoading}
                >
                    {localization.getString("sendRecoveryEmail")}
                </Button>
            </form>
        </Container>
    )
}

function Container(props: React.PropsWithChildren<{}>): JSX.Element {
    return (
        <div className={styles.page}>
            <BanyanHeader />
            <Background className={styles.container}>
                <Card className={styles.card}>{props.children}</Card>
            </Background>
        </div>
    )
}
