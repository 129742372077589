import React from "react"

import { useServiceLocalization, useServiceLinks } from "../../../../pre-v3/services"
import { AppText } from "../../../components/app-text/AppText.component"
import { FormGroup } from "../../../components/form/FormGroup.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { InfoIcon } from "../../../components/info-icon/InfoIcon.component"
import {
    InputWithCheckbox,
    CheckboxProps,
} from "../../../components/input-with-checkbox/InputWithCheckbox.component"
import { UrlInput, UrlItem } from "../../../components/url-input/UrlInput.component"
import { Cluster } from "../../../services/Cluster.service"
import { HostedServiceInfra, NetworkType } from "../../../services/HostedService.service"
import { RegisteredDomain } from "../../../services/RegisteredDomain.service"
import { HostedServiceInfraSelect } from "../shared/HostedServiceInfraSelect.component"
import styles from "./PrivateResource.module.scss"

export interface PrivateResourceFormValue {
    hostedServiceInfra?: HostedServiceInfra[]
    backendDomain?: string
    backendPort?: number
    tls?: boolean
    tlsInsecure?: boolean
    clientCertificate?: boolean
}

export interface PrivateResourceProps {
    initialValue?: PrivateResourceFormValue
    clusters: Cluster[]
    registeredDomains: RegisteredDomain[]
    disabled?: boolean
    onChange?(privateResource: PrivateResourceFormValue): void
    required?: boolean
    initialNetworkType: NetworkType | undefined
}

export function PrivateResource(props: PrivateResourceProps): JSX.Element {
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()

    const [selected, setSelected] = React.useState<PrivateResourceFormValue>(
        props.initialValue ?? {}
    )

    const onUpdateSelected = (updatedSelected: PrivateResourceFormValue) => {
        setSelected(updatedSelected)
        props.onChange?.(updatedSelected)
    }

    const onHostedInfraChange = (hostedServiceInfra: HostedServiceInfra[]) =>
        onUpdateSelected({ ...selected, hostedServiceInfra })

    const onUrlChange = (urlItem: UrlItem) =>
        onUpdateSelected({
            ...selected,
            ...(urlItem.protocol === "http"
                ? { tls: false, tlsInsecure: false, clientCertificate: false }
                : { tls: true }),
            backendDomain: urlItem.domain,
            backendPort: urlItem.port,
        })

    const tlsInsecure: CheckboxProps = {
        id: "tlsInsecure",
        checked: selected.tlsInsecure,
        onChange: (tlsInsecure) => onUpdateSelected({ ...selected, tlsInsecure }),
        children: (
            <CheckboxWithInfo
                label={localization.getString("tlsInsecure")}
                description={localization.getString("tlsInsecureDescription")}
            />
        ),
    }

    const clientCertificate: CheckboxProps = {
        id: "clientCertificate",
        checked: selected.clientCertificate,
        onChange: (clientCertificate) => onUpdateSelected({ ...selected, clientCertificate }),
        children: (
            <CheckboxWithInfo
                label={localization.getString("clientCertificate")}
                description={localization.getString("clientCertificateDescription")}
            />
        ),
    }

    const checkboxes = [tlsInsecure, clientCertificate]

    return (
        <FormGroup label={localization.getString("privateResource")}>
            <HostedServiceInfraSelect
                initialValue={selected.hostedServiceInfra}
                initialNetworkType={props.initialNetworkType}
                clusters={props.clusters}
                registeredDomains={props.registeredDomains}
                disabled={props.disabled}
                onChange={onHostedInfraChange}
                required={props.required}
            />
            <FormRow
                label={localization.getString("whatIsThePrivateHostnameOrIpOfYourWebService")}
                description={
                    <AppText
                        ls={{
                            key: "thisIsTheBackendServerYouWillBeProtectingAccessToLearnMoreAboutMultiDomainService",
                            replaceVals: [linkService.getLink("multiDomainWebServices")],
                        }}
                    />
                }
            >
                <InputWithCheckbox
                    checkboxes={selected.tls ? checkboxes : []}
                    disabled={props.disabled}
                >
                    <UrlInput
                        initialData={getUrlItem(selected)}
                        onChange={onUrlChange}
                        disabled={props.disabled}
                        portRequired={props.required}
                        domainRequired={props.required}
                        domainPlaceholder={localization.getString("egIntenalDomainExample")}
                    />
                </InputWithCheckbox>
            </FormRow>
        </FormGroup>
    )
}

interface CheckboxWithInfoProps {
    label: string
    description: string
}

function CheckboxWithInfo(props: CheckboxWithInfoProps): JSX.Element {
    return (
        <div className={styles.checkbox}>
            {props.label}
            <InfoIcon tooltip={props.description} />
        </div>
    )
}

function getUrlItem(privateResource: PrivateResourceFormValue): UrlItem {
    return {
        protocol: privateResource.tls ? "https" : "http",
        domain: privateResource.backendDomain,
        port: privateResource.backendPort,
    }
}
