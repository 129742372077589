import React, { ReactNode } from "react"
import { useHistory } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { useActionBar } from "../../../../pre-v3/services/ActionBar.service"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { SuperAdminOrgInfo, useAddOrg } from "../../../services/SuperAdmin.service"
import { OrgManagementSpecificationForm } from "../form/OrgManagementSpecificationForm.component"

export function AddOrg() {
    const localization = useServiceLocalization()
    const history = useHistory()

    const {
        mutate: onSubmit,
        isLoading: isSubmitLoading,
        error: addOrgError,
    } = useAddOrg({
        onSuccess: (orgInfo: SuperAdminOrgInfo) => {
            history.push(
                formatRoutePath(ROUTE.ORG_MANAGEMENT_DETAILS, {
                    id: encodeID(orgInfo.id),
                })
            )
        },
    })

    useActionBar({
        title: localization.getString("addOrg"),
        items: [
            {
                label: localization.getString("superAdmin"),
            },
            {
                label: localization.getString("orgManagement"),
                href: ROUTE.ORG_MANAGEMENT,
            },
        ],
    })

    const errors: ReactNode[] = [typeof addOrgError === "string" && addOrgError]

    function onCancel(): void {
        history.push(ROUTE.ORG_MANAGEMENT)
    }

    return (
        <OrgManagementSpecificationForm
            onCancel={onCancel}
            submitText={localization.getString("addOrg")}
            onSubmit={onSubmit}
            isSubmitLoading={isSubmitLoading}
            errors={errors}
        />
    )
}
