import React from "react"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import styles from "./TunnelPolicyCreate.module.scss"
import { Form } from "../../../../components/form/Form.component"
import { GeneralInfo, TunnelPolicyForm } from "../shared/TunnelPolicyForm.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { ROUTE, formatRoutePath } from "../../../../routes"
import {
    PolicyExtended,
    PolicyStatus,
    PolicyType,
    getNullPolicySpecRes,
    useCreatePolicy,
} from "../../../../v3/services/Policy.service"
import { useHistory } from "react-router-dom"
import { ErrorToast, ToastApi } from "../../../../components/toast/Toast.components"
import { encodeID } from "../../../../pre-v3/utils/Url.util"

const emptyPolicy: PolicyExtended = {
    id: "",
    name: "",
    type: PolicyType.TUNNEL,
    status: PolicyStatus.INACTIVE,
    lastUpdatedAt: 0,
    noOfAttachments: 0,
    description: "",
    spec: getNullPolicySpecRes(true),
    version: "",
    lastUpdatedBy: "",
    createdAt: 0,
    createdBy: "",
}
export function TunnelPolicyCreate(): JSX.Element {
    const localization = useServiceLocalization()
    const history = useHistory()
    const toastRef = React.useRef<ToastApi>(null)

    const [policy, setPolicy] = React.useState(emptyPolicy)
    const { mutate: createPolicy, isLoading: isCreatingTunnelPolicy } = useCreatePolicy({
        onSuccess: (newPolicy) => {
            history.push(
                formatRoutePath(ROUTE.ACCESS_POLICIES_TUNNEL_POLICY_DETAILS, {
                    id: encodeID(newPolicy.id),
                })
            )
        },
        onError: (error) =>
            typeof error === "string" ? toastRef.current?.openToast(error) : console.error(error),
    })

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        createPolicy({
            metadata: {
                name: policy.name,
                description: policy.description,
            },
            policyType: PolicyType.TUNNEL,
            spec: policy.spec,
        })
    }

    const onGeneralInfoChange = (generalInfo: GeneralInfo) => {
        setPolicy((prev) => ({
            ...prev,
            name: generalInfo.name,
            description: generalInfo.description || "",
        }))
    }

    const onPolicyChange = (updatedPolicy: PolicyExtended) => {
        setPolicy(updatedPolicy)
    }

    return (
        <div className={styles.container}>
            <Form
                onSubmit={onSubmit}
                aria-label={localization.getString(
                    "createSomething",
                    localization.getString("tunnelPolicy")
                )}
            >
                <TunnelPolicyForm
                    policy={policy}
                    onGeneralInfoChange={onGeneralInfoChange}
                    onPolicyChange={onPolicyChange}
                    disabled={false}
                />

                <div className={styles.actionButtons}>
                    <Button
                        asElement={ButtonElement.LINK}
                        buttonType={ButtonType.SECONDARY}
                        to={ROUTE.ACCESS_POLICIES}
                    >
                        {localization.getString("cancel")}
                    </Button>

                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.PRIMARY}
                        type="submit"
                        loading={isCreatingTunnelPolicy}
                    >
                        {localization.getString("save")}
                    </Button>
                </div>
                <ErrorToast ref={toastRef} />
            </Form>
        </div>
    )
}
