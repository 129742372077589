import * as React from "react"
import { useHistory } from "react-router-dom"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { PolicyAttr, PolicyMetadata } from "../../../api/Secure.api"
import { PolicyType, useServiceLocalization } from "../../../services"
import { useCreatePolicyOld } from "../../../../v3/services/Policy.service"
import { PolicyForm } from "../form/PolicyForm"

export function PolicyAdd() {
    const history = useHistory()

    // grab service references
    const localization = useServiceLocalization()

    const { mutateAsync: createPolicy } = useCreatePolicyOld()

    const submitForm = async ({
        attr,
        metadata,
        type,
    }: {
        type: PolicyType
        attr: PolicyAttr
        metadata: PolicyMetadata
    }) => {
        const policy = await createPolicy({ metadata, type, attr })
        history.push(formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, { id: policy.id }))
    }

    return <PolicyForm onSubmit={submitForm} submitText={localization.getString("createPolicy")} />
}
