export const MINUTE: number = 1000 * 60
export const HOUR: number = MINUTE * 60
export const DAY: number = HOUR * 24

export function convertFromServerTimestamp(timestamp: number): Date {
    return new Date(timestamp / 1_000_000)
}

export function convertToServerTimestamp(timestamp: Date): number {
    return timestamp.getTime() * 1_000_000
}

export function formatToLocalDateStr(date: Date): string {
    return date.toLocaleDateString() ?? ""
}

export function formatToLocalDateTimeStr(date: Date, locale: string): string {
    const datetimeFormat = new Intl.DateTimeFormat(locale, {
        timeStyle: "medium",
        dateStyle: "short",
    })
    return datetimeFormat.format(date)
}

export function formatToLocaleStr(date: Date): string {
    return date.toLocaleString() ?? "-"
}
