import React, { FormEvent, useRef } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { ErrorBanner } from "../../../../../pre-v3/components/banner/Banner.component"
import { LocalizationService, useServiceModal } from "../../../../../pre-v3/services"
import { CidrsInput, rfcAddresses } from "../../../../components/cidrs-input/CidrsInput.component"
import { Form } from "../../../../components/form/Form.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { Input } from "../../../../components/input/Input.component"
import { Loader } from "../../../../components/loader/Loader.component"
import { MultiInput } from "../../../../components/multi-input/MultiInput.component"
import { ApiKey, Connector, useGetApiKeys } from "../../../../services/Connector.service"
import styles from "./ConnectorConfigurationForm.module.scss"
import {
    MenuSelect,
    MenuSelectOption,
} from "../../../../components/menu-select/MenuSelect.component"
import { faLink, faPlus } from "@fortawesome/pro-regular-svg-icons"
import { ApiKeyAddModal } from "./ApiKeyAddModal.component"
import { ApiKeyScope } from "../../../../api/ApiKey.api"
import { PatternUtil } from "../../../../../pre-v3/utils/Pattern.util"

export function ConnectorConfigurationForm(props: ConnectorConfigurationFormProps) {
    const localization: LocalizationService = new LocalizationService()
    const modalService = useServiceModal()
    const [displayname, setDisplayname] = React.useState(
        props.initialValue?.displayName || props.initialValue?.name || ""
    )
    const [cidrs, setCidrs] = React.useState(props.initialValue?.cidrs || rfcAddresses || [])
    const [description, setDescription] = React.useState(props.initialValue?.description || "")
    const [domains, setDomains] = React.useState(props.initialValue?.domains || [])
    const [apikeyId, setApiKeyId] = React.useState(props.initialValue?.apiKeyId || "")
    const [newApiKey, setNewApiKey] = React.useState<ApiKey>()

    const nameInputRef = useRef<HTMLInputElement>(null)

    const { data: apiKeys, isFetching: isApiKeyLoading } = useGetApiKeys()

    React.useEffect(() => {
        if (!apiKeys) return

        const [firstApiKey, ...otherApiKeys] = apiKeys

        if (!props.initialValue?.apiKeyId && firstApiKey && otherApiKeys.length <= 0) {
            setApiKeyId(firstApiKey.id)
        }
    }, [apiKeys])

    const apiKeyMenuOptions = useApiKeyOptions(apiKeys, newApiKey)

    function onCancel(): void {
        props.onCancel?.()
    }

    function onSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        event.stopPropagation()
        const connector: Connector = props.initialValue ?? emptyConnector
        if (connector) {
            props.onSubmit({
                ...connector,
                displayName: displayname,
                description,
                cidrs,
                domains,
                apiKeyId: apikeyId,
            })
        }
    }

    function onAddApiKey(): void {
        modalService
            .open(localization.getString("createApiKey"), {
                component: ApiKeyAddModal,
                props: {
                    scope: ApiKeyScope.SATELLITE,
                },
                maxWidth: "lg",
            })
            .onClose((key: ApiKey) => {
                setNewApiKey(key)
                setApiKeyId(key.id)
            })
    }

    function onNameChange(value: string) {
        const inputRef = nameInputRef.current
        setDisplayname(value)

        if (PatternUtil.NO_URL_SPECIAL_CHARACTERS.test(value)) {
            inputRef?.setCustomValidity("")
        } else {
            inputRef?.setCustomValidity(localization.getString("connectorNameValidationError"))
        }
    }
    return (
        <Loader
            isLoading={isApiKeyLoading}
            title={localization.getString("loadingAPIKeysToAddConnectors")}
        >
            <Form
                onSubmit={onSubmit}
                id="configurationForm"
                aria-label={localization.getString("configuration")}
            >
                <FormRow
                    label={localization.getString("connectorName")}
                    description={localization.getString("connectorNameDescription")}
                    className={styles.formRow}
                    htmlFor={ConnectorFormFields.NAME}
                >
                    <Input
                        id={ConnectorFormFields.NAME}
                        type="text"
                        placeholder={localization.getString("connectorName")}
                        className={styles.flexGrow}
                        value={displayname}
                        onChangeValue={onNameChange}
                        disabled={props.disabled || !props.canCreateConnector}
                        title={localization.getString("enterValidNameNoSpecialCharactersAllowed")}
                        maxLength={256}
                        ref={nameInputRef}
                        required
                    />
                </FormRow>
                <FormRow
                    label={localization.getString(
                        "somethingOptional",
                        localization.getString("description")
                    )}
                    description={localization.getString(
                        "anOptionalFieldShownInTheWebConsoleToAdminUsersOnlyForYourOwnInternalNotes"
                    )}
                >
                    <Input
                        type="text"
                        className={styles.flexGrow}
                        value={description ?? ""}
                        onChangeValue={setDescription}
                        disabled={props.disabled || !props.canCreateConnector}
                        placeholder={localization.getString(
                            "somethingOptional",
                            localization.getString("description")
                        )}
                    />
                </FormRow>
                <FormRow
                    label={localization.getString("apiKey")}
                    description={localization.getString(
                        "pleaseSelectTheApiKeyWithSatelliteScopeYouWantToUseForThisInstallation"
                    )}
                    className={styles.formRow}
                >
                    <MenuSelect
                        icon={faLink}
                        options={apiKeyMenuOptions}
                        value={apikeyId}
                        label={
                            apiKeyMenuOptions.length > 0
                                ? localization.getString("selectApiKey")
                                : localization.getString("createApiKey")
                        }
                        disabled={props.disabled || !props.canCreateConnector}
                        required
                        onSelect={setApiKeyId}
                        additionalOption={{
                            onClick: onAddApiKey,
                            label: localization.getString("createApiKey"),
                            icon: faPlus,
                        }}
                    />
                </FormRow>
                <FormRow
                    label={localization.getString("whatNetworkYouWouldLikeToMakeAvailable")}
                    description={localization.getString("cidrRangeDescription")}
                    className={styles.formRow}
                >
                    <CidrsInput
                        cidrs={cidrs}
                        onChange={setCidrs}
                        isDisabled={props.disabled || !props.canCreateConnector}
                    />
                </FormRow>
                <FormRow
                    label={localization.getString(
                        "somethingOptional",
                        localization.getString("whatDomainsExistOnNetworkProvidedAbove")
                    )}
                    description={localization.getString(
                        "whatDomainsExistOnNetworkProvidedAboveDesc"
                    )}
                    className={styles.formRow}
                >
                    <div className={styles.flexGrow}>
                        <MultiInput
                            values={domains}
                            onChange={setDomains}
                            disabled={props.disabled || !props.canCreateConnector}
                            placeholder={localization.getString("egDomainNameDotCom")}
                        />
                    </div>
                </FormRow>
                {props.canCreateConnector && !props.disabled && (
                    <FormButtons
                        leftButtons={
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                type="button"
                                onClick={onCancel}
                            >
                                {localization.getString("cancel")}
                            </Button>
                        }
                        rightButtons={
                            <Button
                                type="submit"
                                form="configurationForm"
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                loading={props.submitting}
                            >
                                {props.submitLabel}
                            </Button>
                        }
                    >
                        {props.error && <ErrorBanner>{props.error}</ErrorBanner>}
                    </FormButtons>
                )}
            </Form>
        </Loader>
    )
}

enum ConnectorFormFields {
    NAME = "connectorName",
}

interface ConnectorConfigurationFormProps {
    initialValue?: Connector
    isEdit?: boolean
    disabled?: boolean
    submitting?: boolean
    submitLabel: string
    error?: string
    onCancel?: () => void
    onSubmit: (connector: Connector) => void
    canCreateConnector?: boolean
}

const emptyConnector: Connector = {
    name: "",
    displayName: "",
    apiKeyId: "",
    clusterName: "",
    cidrs: rfcAddresses,
    domains: [],
    services: [],
    accessTierIds: [],
    isSonicOs: false,
}

function useApiKeyOptions(
    apiKeys: ApiKey[] = [],
    newApiKey: ApiKey | undefined
): MenuSelectOption[] {
    return React.useMemo(() => {
        if (newApiKey) {
            apiKeys?.push(newApiKey)
        }
        return apiKeys?.map((apiKey) => ({ label: apiKey.name, value: apiKey.id })) || []
    }, [apiKeys, newApiKey])
}
