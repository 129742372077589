import { BaseApi } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { UrlUtil } from "../../pre-v3/utils/Url.util"

@Singleton("MspOrgApi")
export class MspOrgApi extends BaseApi {
    public getMspOrgs(): Promise<MspOrgInfoRes[]> {
        return this.get("/api/v2/msp/orgs", {
            default: this.localization.getString("errorCouldNotFetchOrgs"),
        })
    }

    public getMspOrgById(id: string): Promise<MspOrgInfoRes> {
        return this.get(`/api/v2/msp/orgs/${id}`, {
            default: this.localization.getString("errorCouldNotFetchOrg"),
        })
    }

    public getMspAdminsByOrgId(id: string): Promise<MspAdminInfoRes[]> {
        return this.get(`/api/v2/msp/orgs/${id}/admins`, {
            default: this.localization.getString("errorCouldNotFetchAdmins"),
        })
    }

    public getMspAdminOrgs(email?: string): Promise<MspAdminOrgInfoRes[]> {
        const params: URLSearchParams = UrlUtil.convertToURLSearchParams({ email })
        return this.get("/api/v2/msp/admin/orgs?" + params.toString(), {
            default: this.localization.getString("errorCouldNotFetchOrgs"),
        })
    }

    public createMspOrg(org: MspOrgInfoPostBody): Promise<MspOrgInfoRes> {
        return this.post("/api/v2/msp/orgs", org, {
            default: this.localization.getString("errorCouldNotCreateOrg"),
            4006: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("organization"),
                org.org_name
            ),
        })
    }

    public updateMspOrg(
        orgId: string,
        org: Omit<MspOrgInfoPostBody, "org_name">
    ): Promise<MspOrgInfoRes> {
        return this.put(`/api/v2/msp/orgs/${orgId}`, org, {
            default: this.localization.getString("failedToUpdateAnOrg"),
        })
    }

    public editMspAdmin({ email, orgId, profile }: EditMspAdminData): Promise<string> {
        return this.patch(
            `/api/v2/msp/orgs/${orgId}/admins?email=${encodeURIComponent(email)}`,
            {
                profile,
            },
            { default: this.localization.getString("errorCouldNotUpdateMspAdmin") }
        )
    }

    public deleteMspAdmin({ email, orgId }: Omit<EditMspAdminData, "profile">): Promise<void> {
        return this.delete(
            `/api/v2/msp/orgs/${orgId}/admins?email=${encodeURIComponent(email)}`,
            {},
            {
                default: this.localization.getString("errorCouldNotDeleteMspAdmin"),
            }
        )
    }

    public crateMspAdmin({ orgId, email, profile }: CreateMspAdminData): Promise<void> {
        return this.post(
            `/api/v2/msp/orgs/${orgId}/admins`,
            {
                email,
                profile,
            },
            {
                default: this.localization.getString("errorCouldNotCreateMspAdmin"),
            }
        )
    }

    public masqueradeMspAdmin(ordId: string): Promise<string> {
        return this.post(
            `/api/v2/msp/orgs/${ordId}/masquerade`,
            {},
            {
                default: this.localization.getString("failedToLoginToOrganization"),
            }
        )
    }
}

export type EditionRes = "Team" | "Enterprise" | "Unlimited"
export type StatusRes = "Success" | "Failed" | "Pending"
export type TypeRes =
    | "Production"
    | "Trial"
    | "Staging"
    | "InternalTemporary"
    | "InternalPersistent"
    | "Testing"
    | "Internal"

export type MspOrgInfoPostBody = Pick<
    MspOrgInfoRes,
    "org_name" | "description" | "org_type" | "banyan_idp"
>

export interface MspOrgInfoRes {
    status: StatusRes
    org_name: string
    org_id: string
    edition: EditionRes
    license_count: number
    org_type: TypeRes
    description: string
    created_at: number
    updated_at: number
    global_edge: boolean
    private_edge: boolean
    banyan_idp?: boolean
}

export interface MspAdminInfoRes {
    first_name: string
    last_name: string
    profile: "Admin" | "ReadOnly"
    email: string
    last_login: number
}

export interface MspAdminOrgInfoRes {
    org_name: string
    org_id: string
    last_login: number
    role: "Admin" | "ReadOnly"
}

export interface EditMspAdminData {
    email: string
    orgId: string
    profile: MspAdminInfoRes["profile"]
}

export interface CreateMspAdminData extends Omit<EditMspAdminData, "profile"> {
    profile: "Admin" | "ReadOnly"
}

export interface UserListData {
    FullName: string
    Email: string
}
