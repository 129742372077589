import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"
import { HostedWebsiteList } from "./list/HostedWebsiteList.view"
import { ROUTE } from "../../../routes"
import { HostedWebsiteAdd } from "./create/HostedWebsiteAdd.component"
import { HostedWebsiteOverview as HostedWebsiteDetails } from "./overview/HostedWebsiteOverview.view"
import { Loader } from "../../components/loader/Loader.component"
import { Container } from "../../components/container/Container.component"
import { ErrorBanner } from "../../components/banner/Banner.component"

export function HostedWebsite(): JSX.Element {
    const featureFlagsQuery = useFeatureFlags()
    if (featureFlagsQuery.status === "loading") {
        return <Loader children isLoading center medium />
    }

    if (featureFlagsQuery.status === "error") {
        return (
            <Container>
                <ErrorBanner>{String(featureFlagsQuery.error)}</ErrorBanner>
            </Container>
        )
    }

    const {
        enableAccessTierGroups,
        canEditWebService,
        canDeleteWebService,
        canAttachPolicy,
        canDisableWebService,
        canCloneWebService,
        canAddRegisteredDomain,
    } = featureFlagsQuery.data.adminConsole
    return (
        <Switch>
            <Route path={ROUTE.HOSTED_WEBSITES} exact>
                <HostedWebsiteList enableAccessTierGroups={enableAccessTierGroups} />
            </Route>
            <Route path={ROUTE.HOSTED_WEBSITES_ADD} exact>
                <HostedWebsiteAdd
                    enableAccessTierGroups={enableAccessTierGroups}
                    canAddRegisteredDomain={canAddRegisteredDomain}
                />
            </Route>
            <Route exact path={ROUTE.HOSTED_WEBSITES_DETAILS}>
                <HostedWebsiteDetails
                    enableAccessTierGroups={enableAccessTierGroups}
                    canCloneWebService={canCloneWebService}
                    canAttachPolicy={canAttachPolicy}
                    canDeleteWebService={canDeleteWebService}
                    canDisableWebService={canDisableWebService}
                    canEditWebService={canEditWebService}
                    canAddRegisteredDomain={canAddRegisteredDomain}
                />
            </Route>
            <Redirect to={ROUTE.HOSTED_WEBSITES} />
        </Switch>
    )
}
