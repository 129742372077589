export enum FilterKey {
    APP_VERSION = "appVersion",
    LAST_LOGIN = "lastLogin",
    NAME = "deviceName",
    OWNERSHIP = "deviceOwnership",
    PLATFORM = "platform",
    ROLES = "roles",
    STATUS = "status",
    TRUST_LEVEL = "trustLevel",
    TRUST_PROFILE = "trustProfile",
    ACTIVE_SERVICE_TUNNEL = "activeServiceTunnel",
}

export const allKeys = Object.values(FilterKey)
