import {
    UseQueryResult,
    useMutation,
    useQuery,
    UseMutationResult,
    useQueryClient,
} from "@tanstack/react-query"
import { UserLicenseUsageRes, UserApi, LicenseTypeRes } from "../api/User.api"
import { convertFromServerTimestamp } from "../../utils/Date.utils"
import { ApiFunction } from "./shared/QueryKey"
import { FeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { LicenseInformation, OrgInfoWithLicense } from "./Org.service"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"

export function useGetUserLicenseUsageByEmail(
    email: string,
    options?: QueryOptions<UserLicenseByType, string, void>
): UseQueryResult<UserLicenseByType> {
    const userApi = new UserApi()

    return useQuery({
        ...options,
        queryKey: [queryKeys.USER_LICENSE_USAGE, email],
        queryFn: async (): Promise<UserLicenseByType | null> => {
            const { licenses } = await userApi.getUserLicenseUsageDetail({ email })

            return licenses.reduce<UserLicenseByType>(
                (acc, license) => {
                    const licenseType: LicenseType = licenseInfoTypeMap[license.license_type]
                    acc[licenseType] = mapLicenseInfo(license)
                    return acc
                },
                {
                    sia: { status: LicenseStatus.NOT_LICENSED, licenseType: LicenseType.SIA },
                    spa: { status: LicenseStatus.NOT_LICENSED, licenseType: LicenseType.SPA },
                } satisfies UserLicenseByType
            )
        },
    })
}

export function useGrantRevokeUserLicense(
    email: string,
    option?: QueryOptions<UserLicenseInformation>
): UseMutationResult<UserLicenseInformation, unknown, GrantRevokeLicense> {
    const queryClient = useQueryClient()

    return useMutation({
        ...option,
        mutationFn: async (
            grantRevokeLicense: GrantRevokeLicense
        ): Promise<UserLicenseInformation> => {
            const licenseInfo =
                grantRevokeLicense.userLicenseInformation?.[grantRevokeLicense.licenseType]
            const license = await updateOrCreateLicense(
                grantRevokeLicense.email,
                grantRevokeLicense.licenseType,
                licenseInfo
            )
            return mapLicenseInfo(license)
        },
        onSuccess: (license) => {
            option?.onSuccess?.(license)
            queryClient.setQueryData<UserLicenseByType>(
                [queryKeys.USER_LICENSE_USAGE, email],
                (licenseUsage) => {
                    return (
                        licenseUsage && {
                            ...licenseUsage,
                            [license.licenseType]: license,
                        }
                    )
                }
            )
            queryClient.setQueryData<FeatureFlags>(
                [ApiFunction.GET_FEATURE_FLAGS],
                (featureFlags) => {
                    const count = license.status === LicenseStatus.REVOKED ? -1 : 1
                    return updateFeatureFlagLicenseData(count, license, featureFlags)
                }
            )

            queryClient.setQueryData<OrgInfoWithLicense>(["orgService.getOrgInfo"], (orgInfo) => {
                const licenseLabelCount: keyof LicenseInformation = `${license.licenseType}UsageCount`
                return (
                    orgInfo && {
                        ...orgInfo,
                        license: {
                            ...orgInfo.license,
                            [licenseLabelCount]:
                                orgInfo.license[licenseLabelCount] +
                                (license.status === LicenseStatus.REVOKED ? -1 : 1),
                        },
                    }
                )
            })
        },
    })
}

function updateFeatureFlagLicenseData(
    count: number,
    license: UserLicenseInformation,
    featureFlags?: FeatureFlags
): FeatureFlags | undefined {
    return featureFlags?.adminConsole.showUserLicenseModal.canAccess
        ? {
              ...featureFlags,
              adminConsole: {
                  ...featureFlags.adminConsole,
                  showUserLicenseModal: {
                      ...featureFlags.adminConsole.showUserLicenseModal,
                      canAccess: true,
                      orgLicenseInfo: {
                          ...featureFlags.adminConsole.showUserLicenseModal.orgLicenseInfo,
                          [`${license.licenseType}UsageCount`]:
                              featureFlags.adminConsole.showUserLicenseModal.orgLicenseInfo[
                                  `${license.licenseType}UsageCount`
                              ] + count,
                      },
                  },
              },
          }
        : featureFlags
}

async function updateOrCreateLicense(
    email: string,
    licenseType: LicenseType,
    licenseInfo?: UserLicenseInformation
): Promise<UserLicenseUsageRes> {
    const userApi = new UserApi()
    switch (licenseInfo?.status) {
        case LicenseStatus.LICENSED:
            return await userApi.updateUserLicenseUsageDetail({
                id: licenseInfo.licenseId,
                is_suspended: true,
            })
        case LicenseStatus.REVOKED:
            return await userApi.updateUserLicenseUsageDetail({
                id: licenseInfo.licenseId,
                is_suspended: false,
            })
        default:
            return await userApi.createUserLicenseUsageDetail(
                email,
                licenseInfoTypeMapToUpper[licenseType]
            )
    }
}

function mapLicenseInfo(licenseUsageRes: UserLicenseUsageRes): UserLicenseInformation {
    return {
        licenseType: licenseInfoTypeMap[licenseUsageRes.license_type],
        licenseId: licenseUsageRes.id,
        status: licenseUsageRes.is_suspended ? LicenseStatus.REVOKED : LicenseStatus.LICENSED,
        dateOfAction: licenseUsageRes.is_suspended
            ? convertFromServerTimestamp(licenseUsageRes.suspended_at)
            : convertFromServerTimestamp(licenseUsageRes.activated_at),
        adminOfAction: licenseUsageRes.is_suspended
            ? licenseUsageRes.suspended_by
            : licenseUsageRes.activated_by,
    }
}

export type UserLicenseByType = Record<LicenseType, UserLicenseInformation>

export interface RevokedLicense {
    licenseId: string
    revoked: boolean
}

export enum LicenseStatus {
    NOT_LICENSED = "NOT_LICENSED",
    LICENSED = "LICENSED",
    REVOKED = "REVOKED",
}

interface NotLicensedInformation {
    licenseType: LicenseType
    status: LicenseStatus.NOT_LICENSED
}

interface LicensedInformation {
    status: LicenseStatus.LICENSED
    licenseType: LicenseType
    dateOfAction: Date
    adminOfAction: string
    licenseId: string
}

interface RevokedInformation {
    status: LicenseStatus.REVOKED
    licenseType: LicenseType
    dateOfAction: Date
    adminOfAction: string
    licenseId: string
}

export type UserLicenseInformation =
    | NotLicensedInformation
    | LicensedInformation
    | RevokedInformation

export enum LicenseType {
    SPA = "spa",
    SIA = "sia",
}

export const licenseInfoTypeMap: Record<LicenseTypeRes, LicenseType> = {
    SIA: LicenseType.SIA,
    SPA: LicenseType.SPA,
}

export const licenseInfoTypeMapToUpper: Record<LicenseType, LicenseTypeRes> = {
    sia: "SIA",
    spa: "SPA",
}

export const licenseLevelMap: Record<"basic" | "advanced", "Basic" | "Advanced"> = {
    basic: "Basic",
    advanced: "Advanced",
}

export const licenseStatusMap: Record<LicenseStatus, LanguageKey> = {
    NOT_LICENSED: "notLicensed",
    LICENSED: "licensed",
    REVOKED: "revoked",
}

interface GrantRevokeLicense {
    licenseType: LicenseType
    email: string
    userLicenseInformation?: UserLicenseByType
}

enum queryKeys {
    USER_LICENSE_USAGE = "userLicense.getUserLicenseUsage",
}
