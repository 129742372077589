import { BaseV2Api } from "./BaseV2.api"
import { Singleton } from "../decorators/Singleton.decorator"
import { RegisteredDomain } from "../services/Certs.service"

@Singleton("CertsApi")
export class CertsApi extends BaseV2Api {
    public getRegisteredDomains(): Promise<RegisteredDomainsRes> {
        return this.get("/registered_domain")
    }

    public createRegisteredDomain(domainData: RegisteredDomainsItemReq): Promise<RegisteredDomain> {
        return this.post("/registered_domain", domainData)
    }

    public createChallengeRecord(domainName: ChallengeRecordReq): Promise<ChallengeRecordRes> {
        return this.post("/registered_domain_challenge", domainName)
    }

    public getChallengeRecordById(id: string): Promise<ChallengeRecordRes> {
        return this.get("/registered_domain_challenge/" + encodeURIComponent(id))
    }

    public getRegisteredDomainById(id: string): Promise<RegisteredDomainsItemRes> {
        return this.get("/registered_domain/" + encodeURIComponent(id))
    }

    public validateRegisteredDomain(id: string): Promise<RegisteredDomain> {
        return this.post("/registered_domain/" + encodeURIComponent(id) + "/validate", {})
    }

    public deleteRegisteredDomain(id: string): Promise<RegisteredDomain> {
        return this.delete("/registered_domain/" + id, {})
    }

    public getExternalCertificate(domainName: string): Promise<ExternalCertificateRes> {
        return this.get("/externalcert?domain=" + domainName)
    }

    public getCertificates(): Promise<ExternalCertificateRes> {
        return this.get("/externalcert")
    }

    public deleteExternalCertificate(id: string): Promise<ExternalCertificateRes> {
        return this.delete("/externalcert/" + id, {})
    }
}

export interface RegisteredDomainsRes {
    registered_domains: RegisteredDomainsItemRes[]
    count: number
}

export interface RegisteredDomainsItemRes {
    id: string
    name: string
    cluster_name: string
    cname: string
    description: string
    registered_domain_challenge_id: string
    status: string
    created_at: number
    created_by: string
    updated_at: number
    updated_by: string
}

export interface RegisteredDomainsItemReq {
    id?: string
    name: string
    cluster_name: string
    cname: string
    description: string
    registered_domain_challenge_id?: string
}

export interface ChallengeRecordReq {
    registered_domain_name: string
}

export interface ExternalCertificateRes {
    certificates: ExternalCertificateItemRes[]
    count: number
}

export interface ExternalCertificateItemRes {
    id: string
    org_id: string
    domains: string[]
    status: string
    failed_reason?: string
    certificate: string
    expires_at: number
    created_at: number
    updated_at: number
}

export interface ChallengeRecordRes {
    created_at: number
    id: string
    label: string
    value: string
}
