import { BaseApi, PaginationOptions, PaginatedResponse } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { UrlUtil } from "../../pre-v3/utils/Url.util"

@Singleton("UserApi")
export class UserApi extends BaseApi {
    public getUsers(search: Partial<UserSearch>): Promise<PaginatedResponse<"endusers", EndUser>> {
        if (!search.limit) {
            search.limit = 100
        }
        const params: URLSearchParams = UrlUtil.convertToURLSearchParams(search)
        return this.get(`/api/v2/enduser?${params.toString()}`, {
            default: this.localization.getString("couldNotFetchUsers"),
        })
    }

    public getEndUsersCSV(): Promise<Blob> {
        return this.getBlob(`/api/v1/endusers/csv?active=true`, {
            default: this.localization.getString("failedToFetchUserLogs"),
        })
    }

    public deleteUsers(ids: string[]): Promise<void> {
        return this.delete(
            `/api/v2/endusers`,
            { enduser_ids: ids },
            {
                default: this.localization.getString("failedToDeleteUsers"),
            }
        )
    }

    public getUsersByGroup(groupName: string): Promise<IDPUsers> {
        return this.get(
            `/api/v1/banyanidp/admin/group/user?group_name=${encodeURIComponent(groupName)}`,
            {
                default: this.localization.getString("couldNotFetchUsers"),
            }
        )
    }

    public addUser(payload: UserReq): Promise<void> {
        return this.post("/api/v1/banyanidp/admin/user", payload, {
            default: this.localization.getString("failedToCreateUser"),
        })
    }

    public getUserGroupByEmail(email: string): Promise<IDPGroupRes> {
        return this.get(
            `/api/v1/banyanidp/admin/user/group?user_name=${encodeURIComponent(email)}`,
            {
                default: this.localization.getString("failedToGetUserGroup"),
            }
        )
    }

    public updateUser(email: string, groupNames: string[]): Promise<Group> {
        return this.put(
            `/api/v1/banyanidp/admin/user/group`,
            {
                email,
                groupNames,
            },
            {
                default: this.localization.getString("failedToUpdateUser"),
            }
        )
    }

    public switchBanyanIDPToLocalIDP(): Promise<void> {
        return this.post(
            "/api/v2/switch_idp",
            {},
            {
                default: this.localization.getString("failedToSwitchToLocalIdp"),
            }
        )
    }

    public getUserLicenseUsageDetail(
        searchParams: GetUserLicenseUsageParams = {}
    ): Promise<PaginatedResponse<"licenses", UserLicenseUsageRes>> {
        const params = `?${UrlUtil.convertToURLSearchParams(searchParams).toString()}`

        return this.get(`/api/v2/org/msw/license_usage${params}`, {
            default: this.localization.getString("errorCouldNotGetMswLicenseUsage"),
        })
    }

    public createUserLicenseUsageDetail(
        email: string,
        license_type: LicenseTypeRes
    ): Promise<UserLicenseUsageRes> {
        return this.post(
            "/api/v2/org/msw/license_usage",
            { email, license_type },
            {
                default: this.localization.getString("errorCouldNotCreateLicenseUsage"),
            }
        )
    }

    public updateUserLicenseUsageDetail(
        licenseInfo: UserLicenseUsageReq
    ): Promise<UserLicenseUsageRes> {
        return this.patch("/api/v2/org/msw/license_usage", licenseInfo, {
            default: this.localization.getString("errorCouldNotUpdateLicenseUsage"),
        })
    }

    public deleteUserLicenseUsageDetail(id: string): Promise<string> {
        return this.delete(
            "/api/v2/org/msw/license_usage",
            { id },
            {
                default: this.localization.getString("errorCouldNotDeleteLicenseUsage"),
            }
        )
    }
}

export interface GetUserLicenseUsageParams {
    id?: string
    email?: string
    device_serial_number?: string
    license_type?: string
    is_suspended?: string
    suspended_by?: string
    activated_by?: string
}

export interface UserLicenseUsageRes {
    id: string
    email: string
    license_type: LicenseTypeRes
    is_suspended: boolean
    suspended_by: string
    suspended_at: number
    updated_at: number
    activated_by: string
    activated_at: number
}

export interface UserLicenseUsageReq {
    id: string
    is_suspended: boolean
}

export type LicenseTypeRes = "SIA" | "SPA"
export type LicenseLevel = "Basic" | "Advanced"

export interface UserSearch extends PaginationOptions {
    last_login_after: number
    last_login_before: number
    active: "true"
    email: string
    exact_email: string
    name: string
    role_names: string
    serial_number: string
    sia: string
    spa: string
}

export interface EndUser {
    created_at: number
    email: string
    id: string
    invited_at: number
    last_login: number
    name: string
    org_id: string
    security_roles: string[]
    status: string
    SPA: LicenseStatusRes
    SIA: LicenseStatusRes
}

export type LicenseStatusRes = "NOTLICENSED" | "LICENSED" | "REVOKED"

export interface IDPUsers {
    data: {
        Users: {
            Username: string
        }[]
    }
}

export interface Group {
    data: {
        Email: string
        GroupNames: string[]
    }
}

export interface IDPGroupRes {
    data: {
        Groups: {
            GroupName: string
        }[]
    }
}
export interface UserReq {
    name: string
    email: string
    isAdmin?: boolean
}
