import React, { ReactNode, useMemo, useRef, useState } from "react"
import {
    GridReadyEvent,
    GridApi,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    ColDef,
    RowDoubleClickedEvent,
    ValueFormatterParams,
    RowSelectedEvent,
} from "ag-grid-community"
import { useHistory, useLocation } from "react-router-dom"

import { useFeatureFlags } from "../../../../../hooks/useFeatureFlags.hook"
import { FilterBar } from "../../../../../pre-v3/components/filter-bar/FilterBar.component"
import { Grid } from "../../../../../pre-v3/components/grid/Grid.component"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { IconType } from "../../../../../pre-v3/services/ActionBar.service"
import { ModalService } from "../../../../../pre-v3/services/Modal.service"
import AgGridUtil, {
    DataFilter,
    DataFilterType,
    FilterModel,
    SortBy,
} from "../../../../../pre-v3/utils/AgGrid.util"
import { decodeID, encodeID, UrlUtil } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { toLocalizedList } from "../../../../../utils/String.utils"
import { ErrorBanners, InfoBanner } from "../../../../components/banner/Banner.component"
import { Link } from "../../../../components/link/Link.component"
import { StringUtil } from "../../../../../pre-v3/utils/String.util"
import { DateUtil, TimeRange } from "../../../../../pre-v3/utils/Date.util"
import { DeleteCancelActions } from "../../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { MessageListContent } from "../../../../../pre-v3/components/modal/message/MessageListContent"
import { Loader } from "../../../../components/loader/Loader.component"
import { useGetRoles } from "../../../../services/Role.service"
import {
    LicenseStatus,
    licenseStatusMap,
    LicenseType,
    Role,
    useDeleteUsers,
    useGetEndUsersCSV,
    User,
    UserService,
    UserWithLicenses,
} from "../../../../services/User.service"
import styles from "./UsersList.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { Tooltip } from "../../../../components/tooltip/Tooltip.component"
import {
    ErrorLabel,
    GenericPrimaryLabel,
    InformationLabel,
} from "../../../../../components/label/Label.component"
import { SelectItem } from "../../../../../pre-v3/utils/SelectValue.util"

export function UsersList() {
    const ls = useServiceLocalization()
    const history = useHistory()
    const location = useLocation()
    const grid = useRef<GridApi>()
    const initialModel: FilterModel = UrlUtil.readFilter()

    const modalService: ModalService = new ModalService()

    const [selectedUsers, setSelectedUsers] = useState<User[]>([])

    const newUser: string = useMemo(() => {
        const params = new URLSearchParams(location.search)
        const email = params.get("newUser")
        if (email) {
            return decodeID(email)
        }
        return ""
    }, [location.search])

    const { data: roles, error: rolesError, isFetching: isRoleDataLoading } = useGetRoles()

    const roleNames: string[] = roles?.map((role) => role.name) || []

    const { data: featureFlags } = useFeatureFlags()

    const onGridReady = (event: GridReadyEvent) => {
        if (event?.api) {
            grid.current = event.api
        }
        onFilterChange(initialModel)
        fetchData()
    }

    function fetchData() {
        if (grid.current) {
            grid.current.setServerSideDatasource(DataSource(grid.current))
        }
    }

    function onRefresh() {
        onSelectionChange([])
        if (grid.current) {
            grid.current.refreshServerSide()
            grid.current.deselectAll()
        }
    }

    const {
        mutateAsync: downloadUsers,
        isLoading: isDownloadUserLoading,
        error: userDownloadError,
    } = useGetEndUsersCSV({
        onSuccess: (res) => {
            const blobUrl = URL.createObjectURL(new Blob([res], { type: "text/csv" }))
            const link: HTMLAnchorElement = document.createElement("a")
            link.download = `${ls.getString("users")}.csv`
            link.href = blobUrl
            link.click()
            URL.revokeObjectURL(blobUrl)
        },
    })

    function rowSelectedHandler(event: RowSelectedEvent) {
        if (event?.data && grid.current) {
            onSelectionChange(grid.current.getSelectedRows())
        }
    }

    function onSelectionChange(selections: User[]): void {
        setSelectedUsers(selections)
    }

    const {
        mutateAsync: deleteUsers,
        isLoading: isDeleteUserLoading,
        error: deleteUserError,
    } = useDeleteUsers({
        onSuccess: () => {
            modalService
                .open(
                    ls.getString("removeUsers"),
                    {
                        component: MessageListContent,
                        props: {
                            text: ls.getString("successfullyRemovedTheFollowingUsers"),
                            list: selectedUsers.map((res) => {
                                return res.email
                            }),
                        },
                    },
                    {
                        component: DeleteCancelActions,
                        props: {
                            hideCancel: true,
                            okString: ls.getString("ok"),
                        },
                    }
                )
                .onClose(() => {
                    onRefresh()
                })
                .onCancel(() => {
                    onRefresh()
                })
        },
    })

    function onDeleteUsers() {
        modalService
            .open(
                ls.getString("removeUsers"),
                {
                    component: MessageListContent,
                    props: {
                        text: ls.getString("removeMultipleUsersExplanation"),
                        list: selectedUsers.map((res) => {
                            return res.email
                        }),
                    },
                },
                DeleteCancelActions
            )
            .onClose(async () => {
                const ids: string[] = selectedUsers.map((res) => {
                    return res.id
                })
                await deleteUsers(ids)
            })
            .onCancel(() => {
                onRefresh()
            })
    }

    const isDataLoading: boolean =
        !featureFlags || isDeleteUserLoading || isDownloadUserLoading || isRoleDataLoading
    const errors: ReactNode[] = [
        userDownloadError,
        typeof rolesError === "string" && rolesError,
        typeof deleteUserError === "string" && deleteUserError,
    ]

    function onFilterChange(model: FilterModel) {
        grid.current?.setFilterModel(model)
        UrlUtil.writeFilter(model)
    }

    function mapStatusLabel(status?: LicenseStatus): JSX.Element {
        switch (status) {
            case LicenseStatus.LICENSED:
                return <InformationLabel>{ls.getString("licensed")}</InformationLabel>
            case LicenseStatus.REVOKED:
                return <ErrorLabel darkMode>{ls.getString("revoked")}</ErrorLabel>
            default:
                return <GenericPrimaryLabel>{ls.getString("notLicensed")}</GenericPrimaryLabel>
        }
    }

    const columns: ColDef[] = [
        {
            headerName: ls.getString("user"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 100,
            resizable: true,
            sortable: true,
            cellRenderer: NameCellRenderer,
            comparator: AgGridUtil.alphaBetComparator,
            filter: "agTextColumnFilter",
        },
        {
            headerName: ls.getString("email"),
            field: "email",
            flex: 100,
            sortable: true,
            filter: "agTextColumnFilter",
        },
        {
            headerName: ls.getString("roles"),
            field: "roles",
            sortable: false,
            valueFormatter: (params: ValueFormatterParams<User>) =>
                toLocalizedList(params.data?.roles.map(getRoleName), ls.getLocale(), "conjunction"),
            flex: 100,
            filter: "agTextColumnFilter",
        },
        ...(featureFlags?.adminConsole.userList.doShowSiaLicenseColumn
            ? [
                  {
                      headerName: ls.getString("license", ls.getString(LicenseType.SIA)),
                      field: "siaLicense",
                      sortable: false,
                      cellRenderer: (params: ValueFormatterParams<UserWithLicenses>) => {
                          return mapStatusLabel(params.data?.sia)
                      },
                      flex: 65,
                      filter: "agTextColumnFilter",
                  },
              ]
            : []),
        ...(featureFlags?.adminConsole.userList.doShowSpaLicenseColumn
            ? [
                  {
                      headerName: ls.getString("license", ls.getString(LicenseType.SPA)),
                      field: "spaLicense",
                      sortable: false,
                      cellRenderer: (params: ValueFormatterParams<UserWithLicenses>) => {
                          return mapStatusLabel(params.data?.spa)
                      },
                      flex: 65,
                      filter: "agTextColumnFilter",
                  },
              ]
            : []),
        ...(featureFlags?.adminConsole.userList.doShowLastLoginColumn
            ? [
                  {
                      headerName: ls.getString("lastLogin"),
                      field: "lastLoginAt",
                      sortable: true,
                      valueFormatter: AgGridUtil.dateFormatter,
                      flex: 75,
                      filter: "agTextColumnFilter",
                  },
              ]
            : []),
        ...(featureFlags?.adminConsole.userList.doShowStatusColumn
            ? [
                  {
                      headerName: ls.getString("status"),
                      field: "status",
                      sortable: false,
                      flex: 75,
                      valueFormatter: (params: ValueFormatterParams) =>
                          params?.value ? ls.getString(params.value) : "-",
                  },
              ]
            : []),
        ...(featureFlags?.adminConsole.userList.doShowInvitedAtColumn
            ? [
                  {
                      headerName: ls.getString("invitedAt"),
                      field: "invitedAt",
                      sortable: false,
                      valueFormatter: AgGridUtil.dateFormatter,
                      flex: 75,
                  },
              ]
            : []),
    ]

    const licenseFilterOptions = Object.values(LicenseStatus).map<SelectItem>((type) => ({
        displayName: ls.getString(licenseStatusMap[type]),
        value: type,
    }))

    const filters: DataFilter[] = [
        {
            key: FilterType.EMAIL,
            displayName: ls.getString("email"),
            type: DataFilterType.DEBOUNCEDINPUT,
        },
        {
            key: FilterType.NAME,
            displayName: ls.getString("userName"),
            type: DataFilterType.DEBOUNCEDINPUT,
        },
        {
            key: FilterType.ROLES,
            displayName: ls.getString("roles"),
            type: DataFilterType.MULTILOOKUP,
            dataSource: async (search: string) =>
                roleNames?.filter((role) => StringUtil.caseInsensitiveIncludes(role, search)) || [],
        },

        ...(featureFlags?.adminConsole.userList.doShowSiaLicenseFilter
            ? [
                  {
                      key: FilterType.SIA_LICENSE,
                      displayName: ls.getString("license", ls.getString(LicenseType.SIA)),
                      type: DataFilterType.MULTI_LIST,
                      options: licenseFilterOptions,
                  },
              ]
            : []),
        ...(featureFlags?.adminConsole.userList.doShowSpaLicenseFilter
            ? [
                  {
                      key: FilterType.SPA_LICENSE,
                      displayName: ls.getString("license", ls.getString(LicenseType.SPA)),
                      type: DataFilterType.MULTI_LIST,
                      options: licenseFilterOptions,
                  },
              ]
            : []),
    ]

    function onRowDoubleClicked(event: RowDoubleClickedEvent): void {
        if (event?.data) {
            history.push(formatRoutePath(ROUTE.USERS_DETAILS, { id: encodeID(event.data.email) }))
        }
    }

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{ls.getString("users")}</PageHeading>
                <div className={styles.actionButtons}>
                    {featureFlags?.adminConsole.canRemoveUsers && (
                        <Tooltip
                            title={ls.getString(
                                selectedUsers.length
                                    ? "removeUsers"
                                    : "removeUserDisabledExplanation"
                            )}
                        >
                            <Button
                                buttonType={ButtonType.SECONDARY}
                                asElement={ButtonElement.BUTTON}
                                icon={IconType.TRASH}
                                onClick={onDeleteUsers}
                                disabled={
                                    featureFlags.adminConsole.isScimEnabled || !selectedUsers.length
                                }
                                aria-label={ls.getString(
                                    selectedUsers.length
                                        ? "removeUsers"
                                        : "removeUserDisabledExplanation"
                                )}
                            />
                        </Tooltip>
                    )}
                    <Tooltip title={ls.getString("downloadUsers")}>
                        <Button
                            buttonType={ButtonType.SECONDARY}
                            asElement={ButtonElement.BUTTON}
                            icon={IconType.FILE_DOWNLOAD}
                            onClick={() => downloadUsers()}
                            aria-label={ls.getString("downloadUsers")}
                        />
                    </Tooltip>
                    <Tooltip title={ls.getString("refresh")}>
                        <Button
                            buttonType={ButtonType.SECONDARY}
                            asElement={ButtonElement.BUTTON}
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            aria-label={ls.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            {newUser && <InfoBanner>{ls.getString("userInvitedTemplate", newUser)}</InfoBanner>}
            <ErrorBanners errors={errors} />
            <div className={styles.filterContainer}>
                <FilterBar
                    filters={filters}
                    className={styles.filterBar}
                    onChange={onFilterChange}
                    initialModel={initialModel}
                />
                {featureFlags?.adminConsole.canAddUser && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.LINK}
                        to={ROUTE.USERS_ADD}
                        icon={IconType.PLUS}
                    >
                        {ls.getString("addUser")}
                    </Button>
                )}
            </div>
            <Loader isLoading={isDataLoading} center medium>
                <Grid
                    onGridReady={onGridReady}
                    columnDefs={columns}
                    serverSidePagination
                    context={{ history }}
                    onRowDoubleClicked={onRowDoubleClicked}
                    onRowSelected={rowSelectedHandler}
                    multiSelect
                />
            </Loader>
        </section>
    )
}

enum Id {
    HEADING = "heading",
}

function DataSource(gridApi: GridApi): IServerSideDatasource {
    const getRows = (params: IServerSideGetRowsParams) => {
        const skipLimit: { skip: number; limit: number } = {
            skip: 0,
            limit: 10_000,
        }
        if (params.request.startRow) {
            skipLimit.skip = params.request.startRow
        }

        if (params.request.endRow) {
            skipLimit.limit = params.request.endRow - skipLimit.skip
        }
        const sortModel: SortBy[] = params.request.sortModel
        const filterModel: FilterModel = params.request.filterModel

        const userService: UserService = new UserService()

        let startTime: number = 0
        let endTime: number = DateUtil.convertToLargeTimestamp(Date.now())
        if (filterModel?.lastLoginAt) {
            const timeRange: TimeRange = DateUtil.deserializeTimeRange(
                filterModel.lastLoginAt.filter
            )
            startTime = timeRange.start ? DateUtil.convertToLargeTimestamp(timeRange.start) : 0
            endTime = timeRange.end ? DateUtil.convertToLargeTimestamp(timeRange.end) : 0
        }

        gridApi.showLoadingOverlay()
        userService.getUsers({ ...skipLimit, sortModel, filterModel }, startTime, endTime).then(
            (res) => {
                params.success({ rowData: res.data, rowCount: res.total })
                gridApi.hideOverlay()
            },
            () => {
                params.fail()
                gridApi.showNoRowsOverlay()
            }
        )
    }

    return { getRows }
}

export enum FilterType {
    NAME = "name",
    EMAIL = "email",
    ROLES = "roles",
    LAST_LOGIN = "lastLoginAt",
    SIA_LICENSE = "siaLicense",
    SPA_LICENSE = "spaLicense",
}

interface CellRendererProps {
    value?: string
    data: User
}

export function NameCellRenderer(props: CellRendererProps) {
    if (!props.data.id) {
        return props.value
    }
    return (
        <Link to={formatRoutePath(ROUTE.USERS_DETAILS, { id: encodeID(props.data.email) })}>
            {props.value}
        </Link>
    )
}

function getRoleName(role: Role): string {
    return role.name
}
