export enum LicenseLevel {
    NONE = "none",
    BASIC = "basic",
    ADVANCED = "advanced",
}

export interface OrgLicenseInformation {
    sia: LicenseLevel
    spa: LicenseLevel
    spaCount: number
    siaCount: number
    spaUsageCount: number
    siaUsageCount: number
}
