import React, { FormEvent, useState } from "react"
import styles from "./TrustedNetworks.module.scss"
import { FormRow } from "../../../components/form/FormRow.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { TextArea } from "../../../v3/components/input/TextArea.component"
import { ToggleButton } from "../../../v3/components/toggle-button/ToggleButton"
import { Form } from "../../../v3/components/form/Form.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import {
    getSanitizedMacAddressList,
    useConfigureTrustedNetwork,
    useGetTrustedNetwork,
    useUpdateTrustedNetwork,
} from "../../../v3/services/TrustedNetworks.service"
import { Loader } from "../../../v3/components/loader/Loader.component"
import { ErrorToast, SuccessToast, ToastApi } from "../../../components/toast/Toast.components"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { HiddenInput } from "../../../components/input/HiddenInput.component"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
interface Props {
    canUpdateTrustedNetwork: boolean
}

export function TrustedNetworks(props: Props) {
    const localization = useServiceLocalization()
    const [macAddresses, setMacAddress] = useState<string>("")
    const [disableServiceTunnel, setDisableServiceTunnel] = useState<boolean>(false)
    const errorToastRef = React.useRef<ToastApi>(null)
    const successToastRef = React.useRef<ToastApi>(null)

    const {
        data: trustedNetworks,
        isLoading,
        error: trustedNetworkError,
        isError,
    } = useGetTrustedNetwork({
        onSuccess: (network) => {
            setMacAddress(network.macAddresses.toString())
            setDisableServiceTunnel(network.serviceTunnelStatus)
        },
    })
    const initialMacAddresses: string[] = trustedNetworks?.macAddresses || []
    const { mutateAsync: configureTrustedNetwork, isLoading: isConfigureTrustedNetworkLoading } =
        useConfigureTrustedNetwork({
            onSuccess: () => {
                successToastRef.current?.openToast(
                    localization.getString("successfullyConfiguredTrustedNetwork")
                )
            },
            onError: () => {
                errorToastRef.current?.openToast(
                    localization.getString("errorFailedToConfigureTrustedNetwork")
                )
            },
        })
    const domainInputRef = React.useRef<HTMLInputElement>(null)

    const {
        mutateAsync: updateTrustedNetworkConfig,
        isLoading: updateTrustedNetworkConfigLoading,
    } = useUpdateTrustedNetwork({
        onSuccess: () => {
            successToastRef.current?.openToast(
                localization.getString("successfullyUpdatedTrustedNetwork")
            )
        },
        onError: () => {
            errorToastRef.current?.openToast(
                localization.getString("errorFailedToUpdateTrustedNetwork")
            )
        },
    })

    async function onSubmit(e: FormEvent) {
        e.preventDefault()
        initialMacAddresses.length > 0
            ? await updateTrustedNetworkConfig({
                  macAddresses: getSanitizedMacAddressList(macAddresses),
                  serviceTunnelStatus: disableServiceTunnel,
              })
            : await configureTrustedNetwork({
                  macAddresses: getSanitizedMacAddressList(macAddresses),
                  serviceTunnelStatus: disableServiceTunnel,
              })
    }

    function onChangeMacAddress(value: string) {
        //append zero to single digit
        const updatedMacAddress = value.replace(PatternUtil.NUMBER_LETTER, "0$1").trim()
        if (PatternUtil.MAC_ADDRESS.test(updatedMacAddress)) {
            domainInputRef.current?.setCustomValidity("")
        } else if (updatedMacAddress === "") {
            domainInputRef.current?.setCustomValidity("")
        } else {
            domainInputRef.current?.setCustomValidity(
                localization.getString("provideTheMacAddressValidationError")
            )
        }
        setMacAddress(value)
    }

    return (
        <>
            <Loader isLoading={isLoading} center medium>
                <Form onSubmit={onSubmit} className={styles.container}>
                    <div className={styles.card}>
                        <div className={styles.header}>
                            {localization.getString("trustedNetworks")}
                        </div>
                        {isError ? (
                            <Banner
                                label={
                                    typeof trustedNetworkError === "string" && trustedNetworkError
                                }
                                variant={Variant.ERROR}
                            />
                        ) : (
                            <>
                                <FormRow
                                    label={localization.getString("provideNetworkMacAddresses")}
                                    description={localization.getString(
                                        "provideTheMacAddressOfYourCorporateNetworkDesc"
                                    )}
                                    htmlFor="macAddress"
                                >
                                    <HiddenInput
                                        type="text"
                                        ref={domainInputRef}
                                        className={styles.hiddenInput}
                                    />
                                    <TextArea
                                        id="macAddress"
                                        value={macAddresses}
                                        onChange={(value) => onChangeMacAddress(value.target.value)}
                                        placeholder={localization.getString(
                                            "enterSomething",
                                            localization.getString("macAddressesHere")
                                        )}
                                        className={styles.textArea}
                                        required={macAddresses === "" && disableServiceTunnel}
                                        disabled={!props.canUpdateTrustedNetwork}
                                    />
                                </FormRow>

                                <FormRow
                                    label={localization.getString(
                                        "disconnectServiceTunnelWhenOnTrustedNetwork"
                                    )}
                                    description={localization.getString(
                                        "disconnectServiceTunnelWhenOnTrustedNetworkDesc"
                                    )}
                                >
                                    <ToggleButton
                                        disabled={!props.canUpdateTrustedNetwork}
                                        items={[
                                            {
                                                label: localization.getString("yes"),
                                                onClick: () => {
                                                    setDisableServiceTunnel(true)
                                                },
                                                selected: disableServiceTunnel,
                                            },
                                            {
                                                label: localization.getString("no"),
                                                onClick: () => {
                                                    setDisableServiceTunnel(false)
                                                },
                                                selected: !disableServiceTunnel,
                                            },
                                        ]}
                                    />
                                </FormRow>
                            </>
                        )}
                    </div>
                    {props.canUpdateTrustedNetwork && !isError && (
                        <div className={styles.button}>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                loading={
                                    isConfigureTrustedNetworkLoading ||
                                    updateTrustedNetworkConfigLoading
                                }
                                type="submit"
                            >
                                {localization.getString("save")}
                            </Button>
                        </div>
                    )}
                </Form>
            </Loader>
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
        </>
    )
}
