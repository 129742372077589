import React from "react"

import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { Button, ButtonType } from "../button/Button.component"
import {
    ModalApi,
    ModalProps as BaseModalProps,
    ModalAction,
    ModalWithoutRef,
} from "./Modal.component"
import styles from "./ConfirmationModal.module.scss"

export { ButtonType } from "../button/Button.component"
export type { ModalApi } from "./Modal.component"
export { ModalAction } from "./Modal.component"

export interface ModalProps extends Omit<BaseModalProps, "footer"> {
    /**
     * @default "Confirm"
     */
    completeButtonLabel?: string
    /**
     * @default ButtonType.PRIMARY
     */
    completeButtonType?: ButtonType
    /**
     * @default "Cancel"
     */
    dismissButtonLabel?: string
    onComplete?(): Promise<void>
}

export function ConfirmationModalWithoutRef<Data>(
    props: ModalProps,
    ref: React.ForwardedRef<ModalApi<Data>>
): JSX.Element {
    const localization = useServiceLocalization()

    const modalRef = React.useRef<ModalApi<Data>>(null)
    const [isLoading, setIsLoading] = React.useState(false)

    React.useImperativeHandle(ref, () => ({
        open: () => modalRef.current?.open() ?? Promise.resolve({ action: ModalAction.DISMISS }),
        dismiss: () => modalRef.current?.dismiss(),
        complete: (data) => modalRef.current?.complete(data),
    }))

    const {
        completeButtonLabel = localization.getString("confirm"),
        completeButtonType = ButtonType.PRIMARY,
        dismissButtonLabel = localization.getString("cancel"),
        onComplete: originalOnComplete,
        ...modalProps
    } = props

    const onDismiss: React.MouseEventHandler = (e) => {
        e.preventDefault()
        modalRef.current?.dismiss()
    }

    const onComplete: React.MouseEventHandler = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            await originalOnComplete?.()
        } finally {
            setIsLoading(false)
        }
    }

    return ModalWithoutRef(
        {
            ...modalProps,
            footer: (
                <div className={styles.buttons}>
                    <Button buttonType={ButtonType.SECONDARY} onClick={onDismiss}>
                        {dismissButtonLabel}
                    </Button>
                    <Button
                        buttonType={completeButtonType}
                        onClick={onComplete}
                        loading={isLoading}
                    >
                        {completeButtonLabel}
                    </Button>
                </div>
            ),
        },
        modalRef
    )
}
