import React from "react"
import { Redirect, Route, RouteComponentProps, Switch } from "react-router"

import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import useTitle from "../../hooks/useTitle.hook"
import { ROUTE } from "../../routes"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { OrgAdminAdd as MomAdminAdd } from "../../pre-v3/views/settings/org/admin/add/OrgAdminAdd.component"
import {
    OrgAdminDetail as MomAdminDetails,
    OrgAdminDetailRouteParams as MomAdminDetailsRouteParams,
} from "../../pre-v3/views/settings/org/admin/detail/OrgAdminDetail.component"
import { OrgAdminSettings as MomAdminList } from "../../pre-v3/views/settings/org/admin/OrgAdminSettings.component"
import { Loader } from "../../v3/components/loader/Loader.component"
import { Banner, Variant } from "../../components/banner/Banner.component"
import styles from "./MomManageAdmins.module.scss"

export function MomManageAdmins(): JSX.Element {
    useTitle(["manageAdmins", "adminSettings", "momConsole"])

    const localization = useServiceLocalization()

    const featureFlagsQuery = useFeatureFlags()

    if (featureFlagsQuery.status === "loading") {
        return (
            <Loader
                center
                medium
                title={localization.getString("loadingSomething", localization.getString("admins"))}
            />
        )
    }

    if (featureFlagsQuery.status === "error") {
        return <Banner label={String(featureFlagsQuery.error)} variant={Variant.ERROR} />
    }

    const { canAddAdmin, canUpdateAdmin, canDeleteAdmin } = featureFlagsQuery.data.momConsole

    return (
        <Switch>
            <Route
                path={ROUTE.MOM_MANGE_ADMINS_ADD}
                component={(routeProps: RouteComponentProps): JSX.Element =>
                    canAddAdmin ? (
                        <MomAdminAdd {...routeProps} />
                    ) : (
                        <Redirect to={ROUTE.MOM_MANGE_ADMINS} />
                    )
                }
            />
            <Route
                path={ROUTE.MOM_MANGE_ADMINS_DETAILS}
                component={(
                    routeProps: RouteComponentProps<MomAdminDetailsRouteParams>
                ): JSX.Element => (
                    <MomAdminDetails
                        {...routeProps}
                        canUpdate={canUpdateAdmin}
                        canDelete={canDeleteAdmin}
                    />
                )}
            />
            <Route
                path={ROUTE.MOM_MANGE_ADMINS}
                component={(routeProps: RouteComponentProps): JSX.Element => (
                    <div className={styles.momAdminListContainer}>
                        <MomAdminList {...routeProps} canAdd={canAddAdmin} />
                    </div>
                )}
            />
            <Redirect to={ROUTE.MOM_MANGE_ADMINS} />
        </Switch>
    )
}
