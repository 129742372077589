import React, { FC, FormEvent, ReactNode, useRef, useState } from "react"
import { Input } from "../../../../components/input/Input.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import styles from "./CreateAppModal.module.scss"
import {
    PublicResourceDetail,
    useCreateCustomApplication,
    useUpdateCustomApplication,
} from "../../../../services/PublicResource.service"
import { PatternUtil } from "../../../../../pre-v3/utils/Pattern.util"
import { useHistory } from "react-router"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { encodeID } from "../../../../../pre-v3/utils/Url.util"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { MultiInput } from "../../../../../components/form/inputs/MultiInput.component"
import { FormRow } from "../../../../../components/form/FormRow.component"
import { Form } from "../../../../../components/form/Form.component"

interface Props {
    initialValue?: PublicResourceDetail
    appId?: string
    closeModal: () => void
}

export const CreateAppModal: FC<Props> = ({ initialValue, appId, closeModal }) => {
    const [name, setName] = useState<string>(initialValue?.name ?? "")
    const [description, setDescription] = useState<string>(initialValue?.description ?? "")
    const [domains, setDomains] = useState<string[]>(initialValue?.domains ?? [])
    const [homePage, setHomePage] = useState<string>(initialValue?.appUrl ?? "")
    const [helpfulLinks, setHelpfulLinks] = useState<string[]>(initialValue?.helpfulLinks ?? [])

    const homePageInputRef = useRef<HTMLInputElement>(null)

    const localization = useServiceLocalization()
    const history = useHistory()

    const {
        mutateAsync: createCustomApp,
        error,
        isLoading: isCreateAppLoading,
    } = useCreateCustomApplication()

    const {
        mutateAsync: editCustomApp,
        error: editAppError,
        isLoading: isUpdateAppLoading,
    } = useUpdateCustomApplication(appId || "")

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!name || !domains) return

        if (homePageInputRef.current?.validity.patternMismatch) {
            homePageInputRef.current.setCustomValidity(localization.getString("validUrlError"))
        }

        const logo = homePage
            ? `https://www.google.com/s2/favicons?domain_url=${homePage}`
            : undefined

        if (initialValue) {
            editCustomApp({
                name,
                description,
                domains,
                url: homePage,
                logo,
                helpfulLinks,
            }).then(() => {
                closeModal()
            })
        } else {
            createCustomApp({
                name,
                description,
                domains,
                url: homePage,
                logo,
                helpfulLinks,
            }).then((id) => {
                closeModal()
                history.replace(formatRoutePath(ROUTE.APP_DISCOVERY_DETAILS, { id: encodeID(id) }))
            })
        }
    }

    const errors: ReactNode[] = [
        typeof error === "string" && error,
        typeof editAppError === "string" && editAppError,
    ]
    return (
        <>
            <Form onSubmit={onSubmit}>
                <FormRow label={localization.getString("name")} required>
                    <Input value={name} onChangeValue={setName} required />
                </FormRow>
                <FormRow label={localization.getString("description")}>
                    <Input value={description} onChangeValue={setDescription} />
                </FormRow>
                <FormRow
                    label={localization.getString("domains")}
                    description={localization.getString("domainsDescription")}
                    required
                >
                    <MultiInput
                        values={domains}
                        onChange={setDomains}
                        patternProps={{
                            pattern: PatternUtil.DOMAIN.source,
                            errorMessage: localization.getString("domainFieldError"),
                        }}
                        required
                    />
                </FormRow>
                <FormRow
                    label={localization.getString("homePage")}
                    description={`${localization.getString(
                        "faviconExplanation"
                    )} ${localization.getString("exampleUrl")}`}
                >
                    <Input
                        value={homePage}
                        onChangeValue={setHomePage}
                        pattern={PatternUtil.HTTP_URL.source}
                        ref={homePageInputRef}
                    />
                </FormRow>
                <FormRow
                    label={localization.getString("helpfulLinks")}
                    description={localization.getString("exampleUrl")}
                >
                    <MultiInput
                        values={helpfulLinks}
                        onChange={setHelpfulLinks}
                        patternProps={{
                            pattern: PatternUtil.HTTP_URL.source,
                            errorMessage: localization.getString("validUrlError"),
                        }}
                    />
                </FormRow>
                <div className={styles.buttons}>
                    <Button
                        buttonType={ButtonType.SECONDARY}
                        asElement={ButtonElement.BUTTON}
                        onClick={() => closeModal()}
                        type="button"
                    >
                        {localization.getString("cancel")}
                    </Button>
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.BUTTON}
                        loading={isCreateAppLoading || isUpdateAppLoading}
                    >
                        {localization.getString("save")}
                    </Button>
                </div>
            </Form>
            <ErrorBanners errors={errors} className={styles.error} />
        </>
    )
}
