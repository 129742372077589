import React from "react"
import { Route, Switch, Redirect } from "react-router-dom"

import { ROUTE } from "../../../routes"
import { OrgManagementList } from "./list/OrgManagementList.view"
import { OrgManagementOverview } from "./overview/OrgManagementOverview.view"
import { Status } from "../../services/Org.service"
import { StatusType } from "../../components/status/Status.component"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { ActionBar } from "../../../pre-v3/components/action-bar/ActionBar.component"
import styles from "./OrgManagement.module.scss"
import { AddOrg } from "./add/AddOrg.view"
import { EditOrg } from "./edit/EditOrg.view"

export function OrgManagement() {
    return (
        <div className={styles.pageRightWithActionBar}>
            <ActionBar />
            <Switch>
                <Route exact path={ROUTE.ORG_MANAGEMENT} component={OrgManagementList} />
                <Route exact path={ROUTE.ORG_MANAGEMENT_ADD} component={AddOrg} />
                <Route exact path={ROUTE.ORG_MANAGEMENT_EDIT} component={EditOrg} />
                <Route
                    exact
                    path={ROUTE.ORG_MANAGEMENT_DETAILS}
                    component={OrgManagementOverview}
                />
                <Redirect to={ROUTE.ORG_MANAGEMENT} />
            </Switch>
        </div>
    )
}

export const orgStatusMap: Record<Status, StatusType> = {
    success: "success",
    error: "error",
    unknown: "unknown",
    partialSuccess: "warning",
    inProgress: "disabled",
}

export const labelMap: Record<Status, LanguageKey> = {
    success: "active",
    partialSuccess: "partialSuccess",
    inProgress: "inProgress",
    error: "error",
    unknown: "unknown",
}
