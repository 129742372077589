import React, { Fragment } from "react"
import styles from "./PolicyDetail.module.scss"
import { LoadMask } from "../../../components/load-mask/LoadMask.component"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { faExclamation, faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import { Banner } from "../../../components/banner/Banner.component"
import { ServicesCellRenderer } from "../../services/cell-renderer/ServicesCellRenderer"
import classNames from "classnames/bind"
import { PolicySecureStatus } from "../../../services/Secure.service"
import { SimpleTable } from "../../../components/simple-table/SimpleTable.component"
import { TabBar } from "../../../components/tab-bar/TabBar.component"
import { Grid } from "../../../components/grid/Grid.component"
import { PolicyInfo } from "../info/PolicyInfo.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { Tooltip } from "../../../../v3/components/tooltip/Tooltip.component"

export default function () {
    let statusBlockClassName = classNames(styles.statusBlock, {
        [styles.success]:
            this.state.policy && this.state.policy.status === PolicySecureStatus.ATTACHED,
        [styles.warning]:
            this.state.policy && this.state.policy.status === PolicySecureStatus.INACTIVE,
    })
    return (
        <>
            <header className={styles.header}>
                <PageHeading>{this.state.policy?.name}</PageHeading>
                <div className={styles.actions}>
                    {!this.state.readOnly && (
                        <>
                            <Tooltip
                                title={this.localizationService.getString("downloadPolicySpec")}
                            >
                                <Button
                                    icon={IconType.DOWNLOAD}
                                    onClick={this.downloadSpec}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={this.localizationService.getString(
                                        "downloadPolicySpec"
                                    )}
                                />
                            </Tooltip>
                            <Tooltip title={this.localizationService.getString("editPolicy")}>
                                <Button
                                    icon={IconType.PEN}
                                    onClick={this.onEdit}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={this.localizationService.getString("editPolicy")}
                                />
                            </Tooltip>
                        </>
                    )}
                    <Tooltip title={this.localizationService.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={this.fetchData}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={this.localizationService.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            <div className={styles.overviewContainer}>
                {this.state.loading && <LoadMask />}
                {!this.state.policy && !this.state.loading && (
                    <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                        {this.localizationService.getString("policyNotFound")}
                    </LargeMessage>
                )}
                {this.state.policy && (
                    <>
                        {this.state.error && (
                            <Banner type="error" icon={faExclamationCircle}>
                                {this.state.error}
                            </Banner>
                        )}
                        <div className={styles.leftContainer}>
                            <div className={statusBlockClassName}>
                                <p className={styles.statusLabel}>
                                    {this.localizationService.getString("currentStatus")}
                                </p>
                                <div className={styles.statusContent}>
                                    {this.state.reportingStatus}
                                </div>
                                <SimpleTable
                                    className={styles.statusTable}
                                    items={this.state.statusTableItems}
                                />
                            </div>
                            <SimpleTable
                                className={styles.SimpleTable}
                                items={this.state.dataTableItems}
                            />
                        </div>
                        <div className={styles.rightContainer}>
                            <TabBar tabs={this.getTabs()} onChange={this.onTabChange} />
                            <div className={styles.overviewRightGridContainer}>
                                {this.state.tab === 1 && (
                                    <PolicyInfo
                                        className={styles.info}
                                        policy={this.state.policy}
                                    />
                                )}
                                {this.state.tab === 2 && (
                                    <Grid
                                        rowData={this.state.policy.attachments}
                                        columnDefs={this.attachmentColumns}
                                        components={{
                                            servicesCellRenderer: ServicesCellRenderer,
                                        }}
                                        context={{ history: this.props.history }}
                                        pagination
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
