import {
    faCheckCircle,
    faExclamation,
    faLocationCircle,
    IconDefinition,
    faPauseCircle,
    faMinusCircle,
} from "@fortawesome/pro-solid-svg-icons"
import React, { Fragment, useCallback, useEffect, useState, useRef } from "react"
import { ErrorBanner, LargeMessage, LoadMask } from "../../../pre-v3/components"
import { LocalizationService, useServiceLocalization } from "../../../pre-v3/services"
import styles from "./DataPolicyOverview.module.scss"
import classNames from "classnames/bind"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormGroup } from "../../../v3/components/form/FormGroup.component"
import { FormRow } from "../../../v3/components/form/FormRow.component"
import { MultiInput } from "../../../v3/components/multi-input/MultiInput.component"
import { Select, FormLabel } from "../../../pre-v3/components"
import {
    SimpleTable,
    SimpleTableItem,
} from "../../../pre-v3/components/simple-table/SimpleTable.component"
import { RouteComponentProps } from "react-router"
import { DateUtil } from "../../../pre-v3/utils/Date.util"
import ActionBarService, {
    IconType,
    useServiceActionBar,
} from "../../../pre-v3/services/ActionBar.service"
import { TabBar } from "../../../pre-v3/components/tab-bar/TabBar.component"
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import { Grid } from "../../../pre-v3/components/grid/Grid.component"
import {
    DataPolicy,
    DataPolicyStatus,
    DataPolicyService,
    useServiceDataPolicy,
    FileTypes,
    DetectorLocationCategories,
    DetectorIndustryCategories,
    DetectorTypeCategories,
} from "../svc"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"

export function DataPolicyOverview(props: DataPolicyOverviewProps) {
    const ls: LocalizationService = useServiceLocalization()
    const actionBarService: ActionBarService = useServiceActionBar()
    const dataPolicyService: DataPolicyService = useServiceDataPolicy()

    const [loading, setLoading] = useState<boolean>(true)
    const [resource, setResource] = useState<DataPolicy>()
    const [statusIcon, setStatusIcon] = useState<IconDefinition>(faLocationCircle)
    const [statusText, setStatusText] = useState<string>()
    const [error, setError] = useState<string>("")

    const [statusInfo, setStatusInfo] = useState<SimpleTableItem[]>([])
    const [resourceInfo, setResourceInfo] = useState<SimpleTableItem[]>([])

    const [tab, setTab] = useState<number>(1)

    const setStatus = useCallback((info: DataPolicy) => {
        switch (info.status) {
            case DataPolicyStatus.ENFORCING:
                setStatusIcon(faCheckCircle)
                setStatusText(DataPolicyStatus.ENFORCING)
                break
            case DataPolicyStatus.PERMISSIVE:
                setStatusIcon(faMinusCircle)
                setStatusText(DataPolicyStatus.PERMISSIVE)
                break
            case DataPolicyStatus.INACTIVE:
                setStatusIcon(faPauseCircle)
                setStatusText(DataPolicyStatus.INACTIVE)
                break
            default:
                setStatusIcon(faPauseCircle)
                setStatusText(DataPolicyStatus.INACTIVE)
        }
    }, [])

    const setInfo = useCallback((info: DataPolicy) => {
        setStatusInfo([
            {
                label: "Created At",
                value: DateUtil.format(info.created_at),
            },
            {
                label: "Last Updated",
                value: DateUtil.format(info.updated_at),
            },
        ])

        setResourceInfo([
            {
                label: "",
                value: "",
            },
        ])
    }, [])

    const fetchData = useCallback(() => {
        let resourceId = props.match.params.id
        var item: DataPolicy = dataPolicyService.getDataPolicy(resourceId)
        // console.log(resourceId, isIgnoredResource, isSecuredResource)

        actionBarService.setTitle(item.name)

        setResource(item)
        setStatus(item)
        setInfo(item)
        setLoading(false)
    }, [props.match.params.id, setResource, setStatus, setInfo, actionBarService])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    // use in Input
    const fileTypes = Object.values(FileTypes).map((f) => {
        return { displayName: f, value: f }
    })
    const detectorLocs = Object.values(DetectorLocationCategories).map((f) => {
        return { displayName: f, value: f }
    })
    const detectorInds = Object.values(DetectorIndustryCategories).map((f) => {
        return { displayName: f, value: f }
    })
    const detectorTypes = Object.values(DetectorTypeCategories).map((f) => {
        return { displayName: f, value: f }
    })

    const gridApi = useRef<GridApi>()
    const svcDefs: ColDef[] = [
        {
            headerName: "Service Name",
            field: "name",
            flex: 150,
        },
        {
            headerName: "Service Type",
            field: "type",
            flex: 150,
        },
    ]

    function onGridReady(event: GridReadyEvent) {
        gridApi.current = event.api
        gridApi.current.setRowData(resource?.attachments || [])
    }

    return (
        <section aria-labelledby={Id.HEADING}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{ls.getString("dataLostPrevention")}</PageHeading>
                <Button
                    icon={IconType.REDO}
                    onClick={fetchData}
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                    aria-label={ls.getString("refresh")}
                />
            </header>
            <div className={styles.errorBanner}>{error && <ErrorBanner>{error}</ErrorBanner>}</div>
            <div className={styles.overviewContainer}>
                {loading && <LoadMask />}
                {!setResource && !loading && (
                    <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                        {ls.getString("cloudResourceNotFound")}
                    </LargeMessage>
                )}
                {resource && (
                    <Fragment>
                        <div className={styles.overviewLeftContainer}>
                            <div
                                className={classNames(styles.resource, {
                                    [styles.enforcing]:
                                        resource.status === DataPolicyStatus.ENFORCING,
                                    [styles.permissive]:
                                        resource.status === DataPolicyStatus.PERMISSIVE,
                                    [styles.inactive]:
                                        resource.status === DataPolicyStatus.INACTIVE,
                                })}
                            >
                                <p className={styles.statusHeader}>Policy Status</p>
                                <p>
                                    <FontAwesomeIcon
                                        className={styles.statusIcon}
                                        icon={statusIcon}
                                    />
                                    <span className={styles.statusText}>{statusText}</span>
                                </p>
                                <SimpleTable items={statusInfo} />
                            </div>
                            <SimpleTable items={resourceInfo} />
                        </div>
                        <div className={styles.overviewRightContainer}>
                            <TabBar
                                tabs={[
                                    {
                                        label: "Rules",
                                        value: 1,
                                        active: true,
                                    },
                                    {
                                        label: "Services",
                                        value: 2,
                                    },
                                ]}
                                onChange={setTab}
                            ></TabBar>
                            <div className={styles.overviewRightGridContainer}>
                                {tab === 1 && (
                                    <div className={classNames(styles.formFields)}>
                                        <br />
                                        {resource.download_rule && (
                                            <FormGroup label="Download Rules">
                                                {resource.download_rule.file && (
                                                    <div className={styles.container}>
                                                        <FormLabel title="File Types">
                                                            <Select
                                                                multiple
                                                                options={fileTypes}
                                                                value={
                                                                    resource.download_rule
                                                                        ?.file_types || [
                                                                        FileTypes.ANY,
                                                                    ]
                                                                }
                                                                disabled={true}
                                                            />
                                                        </FormLabel>

                                                        <FormLabel title="File Size">
                                                            <MultiInput
                                                                values={[
                                                                    resource.download_rule
                                                                        ?.file_size || "",
                                                                ]}
                                                                disabled={true}
                                                            />
                                                        </FormLabel>
                                                    </div>
                                                )}
                                                {resource.download_rule.detector && (
                                                    <div className={styles.container}>
                                                        <FormLabel title="Detector Locations">
                                                            <Select
                                                                multiple
                                                                options={detectorLocs}
                                                                value={
                                                                    resource.download_rule
                                                                        ?.detector_locations || [
                                                                        DetectorLocationCategories.GLOBAL,
                                                                    ]
                                                                }
                                                                disabled={true}
                                                            />
                                                        </FormLabel>

                                                        <FormLabel title="Detector Industries">
                                                            <Select
                                                                multiple
                                                                options={detectorInds}
                                                                value={
                                                                    resource.download_rule
                                                                        ?.detector_industries || [
                                                                        DetectorIndustryCategories.ALL,
                                                                    ]
                                                                }
                                                                disabled={true}
                                                            />
                                                        </FormLabel>
                                                        <FormLabel title="Detector Types">
                                                            <Select
                                                                multiple
                                                                options={detectorTypes}
                                                                value={
                                                                    resource.download_rule
                                                                        ?.detector_types || [""]
                                                                }
                                                                disabled={true}
                                                            />
                                                        </FormLabel>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        )}

                                        <br />
                                        {resource.upload_rule && (
                                            <FormGroup label="Upload Rules">
                                                {resource.upload_rule.file && (
                                                    <div className={styles.container}>
                                                        <FormLabel title="File Types">
                                                            <Select
                                                                multiple
                                                                options={fileTypes}
                                                                value={
                                                                    resource.upload_rule
                                                                        ?.file_types || [
                                                                        FileTypes.ANY,
                                                                    ]
                                                                }
                                                                disabled={true}
                                                            />
                                                        </FormLabel>

                                                        <FormLabel title="File Size">
                                                            <MultiInput
                                                                values={[
                                                                    resource.upload_rule
                                                                        ?.file_size || "",
                                                                ]}
                                                                disabled={true}
                                                            />
                                                        </FormLabel>
                                                    </div>
                                                )}
                                                {resource.upload_rule.detector && (
                                                    <div className={styles.container}>
                                                        <FormLabel title="Detector Locations">
                                                            <Select
                                                                multiple
                                                                options={detectorLocs}
                                                                value={
                                                                    resource.upload_rule
                                                                        ?.detector_locations || [
                                                                        DetectorLocationCategories.GLOBAL,
                                                                    ]
                                                                }
                                                                disabled={true}
                                                            />
                                                        </FormLabel>

                                                        <FormLabel title="Detector Industries">
                                                            <Select
                                                                multiple
                                                                options={detectorInds}
                                                                value={
                                                                    resource.upload_rule
                                                                        ?.detector_industries || [
                                                                        DetectorIndustryCategories.ALL,
                                                                    ]
                                                                }
                                                                disabled={true}
                                                            />
                                                        </FormLabel>
                                                        <FormLabel title="Detector Types">
                                                            <Select
                                                                multiple
                                                                options={detectorTypes}
                                                                value={
                                                                    resource.upload_rule
                                                                        ?.detector_types || [""]
                                                                }
                                                                disabled={true}
                                                            />
                                                        </FormLabel>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        )}

                                        <br />
                                        <FormGroup label="Assignment">
                                            <FormRow label="Roles">
                                                <MultiInput
                                                    values={resource.assignments}
                                                    disabled={true}
                                                />
                                            </FormRow>
                                        </FormGroup>
                                    </div>
                                )}
                                {tab === 2 && (
                                    <Grid onGridReady={onGridReady} columnDefs={svcDefs}></Grid>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </section>
    )
}

enum Id {
    HEADING = "heading",
}

interface DataPolicyOverviewProps extends RouteComponentProps<{ id: string }> {}
