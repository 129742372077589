import { EditionRes } from "../../api/Org.api"

export enum Edition {
    TEAM = "team",
    ENTERPRISE = "enterprise",
    UNLIMITED = "unlimited",
}

export const editionResMap: Record<EditionRes, Edition> = {
    Team: Edition.TEAM,
    Enterprise: Edition.ENTERPRISE,
    Unlimited: Edition.UNLIMITED,
}
