import * as React from "react"
import {
    ServiceConnectionStatus,
    ServiceManage,
    useServiceInfra,
    useServiceLocalization,
    useServiceManage,
} from "../../../services"
import styles from "./ServiceInfoTestConnectionModal.module.scss"
import classnames from "classnames/bind"
import { StatusError, StatusLoading, StatusSuccess, ErrorBanner } from "../../../components"

interface Props {
    service: ServiceManage
}

export function ServiceInfoTestConnectionModal({ service }: Props) {
    const localization = useServiceLocalization()
    // the manage service is responsible for testing the connection
    const manageService = useServiceManage()
    const infraService = useServiceInfra()

    // track the loading state
    const [loading, setLoading] = React.useState(true)
    // some error state
    const [error, setError] = React.useState<string>("")
    // we're going to check access tier versions - stored as independent state so that we don't have to worry
    // about modifying the result we got from the API
    const [outOfDate, setOutOfDate] = React.useState<{ [name: string]: boolean }>({})
    // the initial state should model a response from the API that matches the result but with the values
    // to show while loading
    const [result, setResult] = React.useState<ServiceConnectionStatus["response"]>({
        FrontendConnStatus: {
            DomainNameResolutionStatus: false,
            Reachability: false,
        },
        BackendConnStatus: [
            {
                SiteName: "Access Tier Name",
                AccessTierStatus: {
                    DomainNameResolutionStatus: false,
                    Reachability: false,
                },
                NetagentBackendStatus: [
                    {
                        ID: "",
                        SiteName: "Access Tier Name",
                        NetagentHostname: "asdf",
                        ConnStatus: {
                            DomainNameResolutionStatus: false,
                            Reachability: false,
                        },
                        ValidBefore: "",
                    },
                ],
            },
        ],
    })

    React.useEffect(() => {
        manageService
            .testServiceConnection(service)
            .then(async (result) => {
                // save the result
                setResult(result.response)

                // if we detected a failing check on the backend, make sure the access tier is up to date
                const failedATChecks = result.response.BackendConnStatus.filter(
                    (status) => status.AccessTierStatus.ErrorMsg
                )
                // look up the access tier meta data for every failure
                await Promise.all(
                    failedATChecks.map(async (failedAT) => {
                        const info = await infraService.getAccessTierByName(failedAT.SiteName)

                        // all instances of an access tier have the same version
                        const versionInfo = info.netagents[0].version.split(".")
                        const major = parseInt(versionInfo[0], 10)
                        const minor = parseInt(versionInfo[1], 10)

                        // an access tier must have at least 1.37 installed
                        if (major < 1 || (major === 1 && minor < 37)) {
                            setOutOfDate((current) => ({
                                ...current,
                                [failedAT.SiteName]: true,
                            }))
                        }
                    })
                )

                // we're done loading the tests
                setLoading(false)
            })
            .catch((err) => {
                setError(err.message || err)
                // we're done loading the tests
                setLoading(false)
            })
    }, [manageService, infraService, service])

    return (
        <>
            {error && <ErrorBanner>{error}</ErrorBanner>}
            <div className={styles.container}>
                <h2 className={classnames(styles.formLabel, styles.header)}>
                    {localization.getString("status")}
                </h2>
                <h2 className={classnames(styles.formLabel, styles.header)}>
                    {localization.getString("connectivity")}
                </h2>
                <h2 className={classnames(styles.formLabel, styles.header)}>
                    {localization.getString("test")}
                </h2>
                {/* frontend status - hostname */}
                <StatusCell
                    loading={loading}
                    success={result.FrontendConnStatus.DomainNameResolutionStatus}
                    reason={result.FrontendConnStatus.ErrorMsg}
                />
                <FrontendConnectivity />
                <Test
                    title={localization.getString("hostnameFound")}
                    description={localization.getString(
                        "serviceDomainNameIsResolvableAndRoutesToAccessTier"
                    )}
                />
                {/* frontend status - reachable */}
                <StatusCell
                    loading={loading}
                    success={result.FrontendConnStatus.Reachability}
                    reason={result.FrontendConnStatus.ErrorMsg}
                />
                <FrontendConnectivity />
                <Test
                    title={localization.getString("reachable")}
                    description={localization.getString("accessTierIsReachableOnSelectedIpAndPort")}
                />
                {/* 2 backend statuses for each access tier */}
                {result.BackendConnStatus.map((status) => {
                    // if the access tier is out of date, show a special error message
                    const errorMessage = outOfDate[status.SiteName]
                        ? localization.getString("accessTierOutOfDateDescription")
                        : status.AccessTierStatus.ErrorMsg

                    return (
                        <React.Fragment key={status.SiteName}>
                            {/* backend status - domain name resolution status */}
                            <StatusCell
                                loading={loading}
                                success={status.AccessTierStatus.DomainNameResolutionStatus}
                                reason={errorMessage}
                            />
                            <BackendConnectivity accessTier={status.SiteName} />
                            <Test
                                title={localization.getString("dnsOrIpFound")}
                                description={localization.getString(
                                    "backendHostnameOrIpIsResolvable"
                                )}
                            />
                            {/* backend status - reachable */}
                            <StatusCell
                                loading={loading}
                                success={status.AccessTierStatus.Reachability}
                                reason={errorMessage}
                            />
                            <BackendConnectivity accessTier={status.SiteName} />
                            <Test
                                title={localization.getString("reachable")}
                                description={localization.getString(
                                    "backendHostAndPortReachableFromAccessTier"
                                )}
                            />
                        </React.Fragment>
                    )
                })}
            </div>
        </>
    )
}

function FrontendConnectivity() {
    const localization = useServiceLocalization()
    return (
        <p>
            {localization.getString("internet")}
            <br />
            {localization.getString("toAccessTier")}
        </p>
    )
}
function BackendConnectivity({ accessTier }: { accessTier: string }) {
    const localization = useServiceLocalization()
    return (
        <div>
            <Bold>{accessTier}</Bold>
            <br />
            {localization.getString("toBackend")}
        </div>
    )
}

function Test({ title, description }: { title: string; description: string }) {
    return (
        <div>
            <Bold>{title}</Bold>
            <br />
            <p>{description}</p>
        </div>
    )
}

function StatusCell({
    loading,
    success,
    reason,
}: {
    loading: boolean
    success: boolean
    reason?: string
}) {
    let content = success ? <StatusSuccess /> : <StatusError tooltip={reason} />
    if (loading) {
        content = <StatusLoading />
    }

    return <div className={styles.statusCell}>{content}</div>
}

function Bold({ children }: { children: React.ReactNode }) {
    return <span className={styles.bold}>{children}</span>
}
