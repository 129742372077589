import React, { FormEvent, ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE } from "../../../../../../routes"
import { ApiKeyScope, SettingsApi } from "../../../../../api/Settings.api"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import { ModalService } from "../../../../../services/Modal.service"
import { ApiKey, SettingsService } from "../../../../../services/Settings.service"
import { FormUtil } from "../../../../../utils/Form.util"
import { decodeID } from "../../../../../utils/Url.util"
import ApiKeysDetailTemplate from "./ApiKeysDetail.template"

export class ApiKeysDetail extends React.Component<Props, ApiKeysDetailState> {
    constructor(props: Props) {
        super(props)
        try {
            this.id = decodeID(this.props.match.params.id)
        } catch {
            this.state.loading = false
        }
    }

    public state: ApiKeysDetailState = { loading: true }

    public render(): ReactNode {
        return ApiKeysDetailTemplate.call(this)
    }

    public componentDidMount(): void {
        if (this.id) this.fetchData()
    }

    private id: string
    private ls: LocalizationService = new LocalizationService()
    private settingsService: SettingsService = new SettingsService()
    private modalService: ModalService = new ModalService()
    private settingsApi: SettingsApi = new SettingsApi()

    @Bind
    private fetchData(): void {
        this.setState({ loading: true })

        this.settingsService.getOrgApiKey(this.id).then((apiKey: ApiKey) => {
            this.setState({ apiKey, loading: false })
        })
    }

    @Bind
    private onSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault()
        if (this.state.apiKey) {
            this.setState({ submitting: true, success: "", error: "" })
            this.settingsApi
                .updateApiKey(this.state.apiKey.id, {
                    name: FormUtil.getFieldValue(event, "name"),
                    description: FormUtil.getFieldValue(event, "description"),
                    scope: FormUtil.getFieldValue(event, "scope") as ApiKeyScope,
                })
                .then(
                    () => {
                        this.setState({
                            submitting: false,
                            success: this.ls.getString("apiKeyUpdateSuccessful"),
                        })
                    },
                    (err: Error) => {
                        this.setState({ submitting: false, error: err.message })
                    }
                )
        }
    }

    @Bind
    private handleOpenDeleteModal(): void {
        if (this.state.apiKey) {
            this.modalService
                .openConfirmation(
                    this.ls.getString("deleteApiKey"),
                    this.ls.getString("deleteApiKeyDescription")
                )
                .onClose(() => {
                    this.setState({ error: "", success: "", submitting: true })
                    this.settingsApi.deleteApiKey(this.state.apiKey!.id).then(
                        () => {
                            this.props.history.push(ROUTE.API_KEYS)
                        },
                        (err: Error) => {
                            this.setState({ submitting: false, error: err.message })
                        }
                    )
                })
        }
    }
}

export interface ApiKeysDetailParams {
    id: string
}

interface Props extends RouteComponentProps<ApiKeysDetailParams> {
    canAccessApiKey: boolean
}

export interface ApiKeysDetailState {
    loading: boolean
    apiKey?: ApiKey
    submitting?: boolean
    success?: string
    error?: string
}
