import * as React from "react"
import { Singleton } from "../decorators/Singleton.decorator"
import {
    ManageApi,
    RegisteredService,
    ServiceMetadata,
    ServiceAttr,
    HttpSettings,
    BackendAttr,
    CertSettings,
    SaasMetadata,
    SaasAttr,
    SaasService,
    SaasAuthProtocol,
    SaasAppType,
    SaasNameIdFormat,
    SaasNameIdValue,
    SaasNameIdValueOpts,
    ServiceSpec,
    ServiceConnectionStatusRes,
} from "../api/Manage.api"
import { LocalizationService } from "./localization/Localization.service"
import { DateUtil } from "../utils/Date.util"
import { MessageRes } from "../api/Base.api"
import ServiceContext from "./context"

@Singleton("ManageService")
export class ManageService {
    public readonly CIDR_REGEX: string = "[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/[0-9]{1,2}"
    public readonly CIDR_OR_IP_REGEX: string =
        "[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}(\\/[0-9]{1,2})?"
    public readonly PORT_REGEX: string = "^(\\*|\\d*|(\\d*-\\d*))$"

    public getRegisteredServices(): Promise<ServiceManage[]> {
        return new Promise((resolve, reject) => {
            this.manageApi.getRegisteredServices().then(
                (services) => {
                    services = services.filter(this.filterRegisteredService)
                    resolve(services.map((s) => this.mapRegisteredServiceToServiceManage(s)))
                },
                () => {
                    reject(new Error(this.localizationService.getString("failedToLoadServices")))
                }
            )
        })
    }

    public getRegisteredService(serviceId: string): Promise<ServiceManage> {
        return new Promise((resolve, reject) => {
            this.manageApi.getRegisteredService(serviceId).then(
                (services) => {
                    if (services && services.length > 0) {
                        resolve(this.mapRegisteredServiceToServiceManage(services[0]))
                    } else {
                        reject(Error(this.localizationService.getString("serviceNotFound")))
                    }
                },
                () => {
                    reject(Error(this.localizationService.getString("serviceNotFound")))
                }
            )
        })
    }
    public getInfraServices(hideAccesstierGroup?: boolean): Promise<ServiceManage[]> {
        return this.getRegisteredServices().then((services: ServiceManage[]) =>
            services.filter(
                (service: ServiceManage) =>
                    service.appType !== ServiceAppType.WEB &&
                    (hideAccesstierGroup
                        ? !service.spec?.spec.attributes.host_tag_selector.some(
                              (tag) => tag["com.banyanops.hosttag.access_tier_group"]
                          )
                        : true)
            )
        )
    }

    public testServiceConnection(serviceTestData: ServiceManage): Promise<ServiceConnectionStatus> {
        return this.manageApi
            .testServiceConnection({
                ServiceID: serviceTestData.id,
            })
            .then((response) => {
                let backEndConnection: boolean
                if (response.BackendConnStatus.length > 0) {
                    backEndConnection = response.BackendConnStatus[0].AccessTierStatus.Reachability
                } else {
                    backEndConnection = false
                }
                return {
                    isFrontendConnectionStatus: response.FrontendConnStatus.Reachability,
                    isBackendConnectionStatus: backEndConnection,
                    response,
                }
            })
    }

    private getRegisteredServiceByName(serviceName: string): Promise<ServiceManage> {
        return new Promise((resolve, reject) => {
            this.manageApi.getRegisteredServices().then(
                (services) => {
                    const service: RegisteredService | undefined = services.find(
                        (s) => s.ServiceName === serviceName
                    )
                    if (service) {
                        resolve(this.mapRegisteredServiceToServiceManage(service))
                    } else {
                        reject(new Error(this.localizationService.getString("serviceNotFound")))
                    }
                },
                () => {
                    reject(new Error(this.localizationService.getString("serviceNotFound")))
                }
            )
        })
    }

    public async createRegisteredService(
        service: {
            metadata: ServiceMetadata
            type: string
            attributes: ServiceAttr
            policyId?: string
            enabled?: boolean
        },
        editServiceFlag?: boolean
    ): Promise<ServiceManage> {
        let existingService: ServiceManage | undefined = undefined
        //check if service with same name exists before creating a new service and if we are not editing the service
        try {
            if (!editServiceFlag) {
                existingService = await this.getRegisteredServiceByName(service.metadata.name)
            }
        } catch {} //ignore

        if (existingService) {
            throw new Error(
                this.localizationService.getString(
                    "somethingNamedAlreadyExists",
                    this.localizationService.getString("service"),
                    service.metadata.name
                )
            )
        } else {
            const createRegisteredService = await this.manageApi.createRegisteredService({
                kind: "BanyanService",
                apiVersion: "rbac.banyanops.com/v1",
                type: service.type,
                metadata: service.metadata,
                spec: service.attributes,
            })

            if (service.policyId) {
                await this.manageApi.attachPolicyToService(
                    service.policyId,
                    createRegisteredService.ServiceID,
                    Boolean(service.enabled)
                )
            }

            return this.mapRegisteredServiceToServiceManage(createRegisteredService)
        }
    }

    public editRegisteredService(service: {
        metadata: ServiceMetadata
        id: string
        type: string
        attributes: ServiceAttr
        oldPolicyId?: string
        policyId?: string
        enabled?: boolean
        nameId: string
    }): Promise<ServiceManage> {
        if (service.oldPolicyId && service.oldPolicyId !== service.policyId) {
            return this.manageApi.detachPolicyFromService(service.oldPolicyId, service.id).then(
                () => {
                    return this.createRegisteredService(service, true)
                },
                () => {
                    return Promise.reject(
                        new Error(
                            this.localizationService.getString("errorDetachingServiceFromPolicy")
                        )
                    )
                }
            )
        } else {
            return this.createRegisteredService(service, true)
        }
    }

    public detachPolicyFromService(policyId: string, serviceId: string): Promise<MessageRes> {
        return this.manageApi.detachPolicyFromService(policyId, serviceId)
    }

    public enableRegisteredService(serviceId: string): Promise<MessageRes> {
        return this.manageApi.enableRegisteredService(serviceId)
    }

    public disableRegisteredService(serviceId: string): Promise<MessageRes> {
        return this.manageApi.disableRegisteredService(serviceId)
    }

    public deleteRegisteredService(serviceId: string): Promise<MessageRes> {
        return this.manageApi.deleteRegisteredService(serviceId)
    }

    public isInfraService(service: ServiceManage) {
        return service.spec?.metadata.tags.service_app_type !== ServiceAppType.WEB
    }

    public getNullServiceSpec(): ServiceSpec {
        return {
            kind: "",
            apiVersion: "",
            type: "",
            metadata: {
                name: "",
                description: "",
                cluster: "",
                tags: {
                    description_link: "",
                },
                autorun: false,
            },
            spec: this.getNullServiceAttr(),
        }
    }

    public getNullServiceMetadata(): ServiceMetadata {
        // This is for the empty Custom Metadata template which will not have name, description and clusterID
        return <any>{
            tags: {
                protocol: "",
                domain: "",
                port: "",
                service_app_type: "GENERIC",
            },
        }
    }

    public getNullServiceAttr(): ServiceAttr {
        return {
            attributes: {
                frontend_addresses: [],
                host_tag_selector: [],
                tls_sni: [],
                disable_private_dns: false,
            },
            backend: this.getNullBackendAttr(),
            cert_settings: this.getNullCertSettings(),
            http_settings: this.getNullHttpSettings(),
            client_cidrs: [],
        }
    }

    private getNullBackendAttr(): BackendAttr {
        return {
            target: {
                name: "",
                port: "",
                tls: false,
                tls_insecure: false,
                client_certificate: false,
            },
            dns_overrides: {},
            whitelist: [],
            http_connect: false,
            allow_patterns: [
                {
                    hostnames: [],
                    cidrs: [],
                    ports: {
                        port_list: [],
                        port_ranges: [],
                    },
                },
            ],
        }
    }

    private getNullCertSettings(): CertSettings {
        return {
            dns_names: [],
            custom_tls_cert: {
                enabled: false,
                cert_file: "",
                key_file: "",
            },
            letsencrypt: false,
        }
    }

    private getNullHttpSettings(): HttpSettings {
        return {
            enabled: false,
            oidc_settings: {
                enabled: false,
                service_domain_name: "",
                post_auth_redirect_path: "",
                api_path: "",
                suppress_device_trust_verification: false,
            },
            http_health_check: {
                enabled: false,
                method: "",
                path: "",
                user_agent: "",
                from_address: [],
                https: false,
            },
            exempted_paths: {
                enabled: false,
                paths: [],
                patterns: [
                    {
                        hosts: [
                            {
                                origin_header: [],
                                target: [],
                            },
                        ],
                        methods: [],
                        mandatory_headers: [],
                        paths: [],
                        source_cidrs: [],
                    },
                ],
            },
            headers: {},
        }
    }

    public createSaasApp(
        metadata: SaasMetadata,
        attr: SaasAttr,
        policyId?: string,
        policyEnabled?: boolean
    ): Promise<SaasManage> {
        return this.manageApi
            .createSaasApp({
                kind: "BanyanSaasApp",
                apiVersion: "rbac.banyanops.com/v1",
                metadata: metadata,
                spec: attr,
            })
            .then((app: SaasService) => {
                if (policyId) {
                    return this.manageApi
                        .attachSaasToPolicy(app.ID, policyId, policyEnabled!)
                        .then(() => {
                            return this.mapSaasServiceToSaasManage(app)
                        })
                } else {
                    return this.mapSaasServiceToSaasManage(app)
                }
            })
    }

    private getNameIdValueType(
        shouldPassthroughNameId: boolean,
        nameIdValueType?: SaasNameIdValueOpts
    ): SaasNameIdValueOpts {
        if (nameIdValueType) {
            return nameIdValueType
        }
        if (shouldPassthroughNameId) {
            return SaasNameIdValueOpts.PASSTHROUGH_NAME_ID
        }
        return SaasNameIdValueOpts.LEGACY
    }

    public editSaasApp(app: SaasManage, oldPolicyId?: string): Promise<SaasManage> {
        const metadata: SaasMetadata = {
            id: app.id,
            name: app.name,
            description: app.description,
            tags: {
                template: app.initialAuth,
            },
        }
        const shouldPassthroughNameId = app.nameIdPassthrough ? app.nameIdPassthrough : false
        const nameIdValue: SaasNameIdValue = {
            passthrough_name_id: shouldPassthroughNameId,
            name_id_attribute_selector: app.nameIdAttributeSelector
                ? app.nameIdAttributeSelector
                : "",
            name_id_value_type: this.getNameIdValueType(
                shouldPassthroughNameId,
                app.nameIdValueType
            ),
        }
        const attr: SaasAttr = {
            client_id: app.clientId,
            client_secret: app.clientSecret,
            redirect_url: app.redirectUri,
            protocol: app.protocol,
            name_id_format: app.nameIdFormat,
            name_id_value: nameIdValue,
            suppress_device_trust_verification: app.suppressDeviceTrustVerification,
            enable_service_level_passwordless: app.passwordlessAuthentication,
        }

        if (app.audienceUri) {
            attr.audience_uri = app.audienceUri
        }
        if (oldPolicyId && oldPolicyId !== app.policyId) {
            return this.manageApi.detachSaasToPolicy(app.id, oldPolicyId).then(
                () => {
                    return this.createSaasApp(metadata, attr, app.policyId, app.policyEnabled)
                },
                () => {
                    return Promise.reject(
                        new Error(
                            this.localizationService.getString("errorDetachingServiceFromPolicy")
                        )
                    )
                }
            )
        } else {
            return this.createSaasApp(metadata, attr, app.policyId, app.policyEnabled)
        }
    }

    public getSaasApp(id: string): Promise<SaasManage> {
        return this.manageApi.getSaasApp(id).then((apps) => {
            if (apps && apps.length > 0) {
                return this.mapSaasServiceToSaasManage(apps[0])
            }
            throw new Error("App not found.")
        })
    }

    public getSaasApps(): Promise<SaasManage[]> {
        return this.manageApi.getSaasApps().then((apps) => {
            return apps.map((app: SaasService) => {
                return this.mapSaasServiceToSaasManage(app)
            })
        })
    }

    public enableSaasApp(serviceId: string): Promise<boolean> {
        return this.manageApi.enableSaasApp(serviceId)
    }

    public disableSaasApp(serviceId: string): Promise<boolean> {
        return this.manageApi.disableSaasApp(serviceId)
    }

    public deleteSaasApp(serviceId: string): Promise<boolean> {
        return this.manageApi.deleteSaasApp(serviceId)
    }

    public mapToLocalizationString(value: SaasNameIdValueOpts): string {
        if (value === SaasNameIdValueOpts.CUSTOM) {
            return "custom"
        } else if (value === SaasNameIdValueOpts.PASSTHROUGH_NAME_ID) {
            return "usePassthroughNameId"
        } else {
            return "legacyCompatibilityMode"
        }
    }

    private manageApi: ManageApi = new ManageApi()
    private localizationService: LocalizationService = new LocalizationService()

    private getServiceAppType(spec: string): ServiceAppType {
        try {
            const metadata: ServiceMetadata = JSON.parse(spec).metadata

            if (metadata.tags?.service_app_type) {
                return ServiceAppType[metadata.tags.service_app_type]
            }
            return ServiceAppType.GENERIC
        } catch {
            return ServiceAppType.GENERIC
        }
    }

    private mapRegisteredServiceToServiceManage(service: RegisteredService): ServiceManage {
        let status: ServiceManageStatus = ServiceManageStatus.NO_POLICY
        if (service.AttachedPolicy && service.AttachedPolicy.PolicyID) {
            if (service.AttachedPolicy.PolicyStatus) {
                status = ServiceManageStatus.POLICY_ENFORCING
            } else {
                status = ServiceManageStatus.POLICY_PERMISSIVE
            }
        }
        if (service.Enabled !== "TRUE") {
            status = ServiceManageStatus.INACTIVE
        }

        let spec: ServiceSpec | undefined
        try {
            spec = JSON.parse(service.ServiceSpec)
        } catch {} // unparsable, ignore

        return {
            id: service.ServiceID,
            name: service.ServiceName,
            clusterName: service.ClusterName,
            enabled: service.Enabled === "TRUE",
            createdAt: DateUtil.convertLargeTimestamp(service.CreatedAt),
            createdBy: service.CreatedBy,
            description: service.Description,
            lastUpdatedAt: DateUtil.convertLargeTimestamp(service.LastUpdatedAt),
            lastUpdatedBy: service.LastUpdatedBy,
            oidcEnabled: service.OIDCEnabled,
            oidcSpec: service.OIDCClientSpec,
            appType: this.getServiceAppType(service.ServiceSpec),
            spec,
            type: service.ServiceType,
            version: service.ServiceVersion,
            policyEnabled: service.AttachedPolicy ? service.AttachedPolicy.PolicyStatus : false,
            policyId: service.AttachedPolicy ? service.AttachedPolicy.PolicyID : "",
            policyName: service.AttachedPolicy ? service.AttachedPolicy.PolicyName : "",
            policyAttachedAt: service.AttachedPolicy
                ? DateUtil.convertLargeTimestamp(service.AttachedPolicy.AttachedAt)
                : 0,
            status,
            userFacing: service.UserFacing,
        }
    }

    private mapSaasServiceToSaasManage(saas: SaasService): SaasManage {
        let status: ServiceManageStatus = ServiceManageStatus.NO_POLICY
        if (saas.AttachedPolicy && saas.AttachedPolicy.PolicyID) {
            if (saas.AttachedPolicy.PolicyStatus) {
                status = ServiceManageStatus.POLICY_ENFORCING
            } else {
                status = ServiceManageStatus.POLICY_PERMISSIVE
            }
        }
        if (saas.Enabled !== "TRUE") {
            status = ServiceManageStatus.INACTIVE
        }
        return {
            id: saas.ID,
            name: saas.Name,
            description: saas.Description,
            createdAt: DateUtil.convertLargeTimestamp(saas.CreatedAt),
            createdBy: saas.CreatedBy,
            lastUpdatedAt: DateUtil.convertLargeTimestamp(saas.LastUpdatedAt),
            lastUpdatedBy: saas.LastUpdatedBy,
            enabled: saas.Enabled === "TRUE",
            policyId: saas.AttachedPolicy ? saas.AttachedPolicy.PolicyID : "",
            policyName: saas.AttachedPolicy ? saas.AttachedPolicy.PolicyName : "",
            policyAttachedAt: saas.AttachedPolicy
                ? DateUtil.convertLargeTimestamp(saas.AttachedPolicy.AttachedAt)
                : 0,
            policyEnabled: saas.AttachedPolicy ? saas.AttachedPolicy.PolicyStatus : false,
            status,
            initialAuth: <SaasInitialAuth>saas.Spec.metadata.tags.template,
            redirectUri: saas.Spec.spec.redirect_url,
            audienceUri: saas.Spec.spec.audience_uri,
            nameIdFormat: saas.Spec.spec.name_id_format,
            nameIdPassthrough: saas.Spec.spec.name_id_value
                ? saas.Spec.spec.name_id_value.passthrough_name_id
                : false,
            nameIdAttributeSelector: saas.Spec.spec.name_id_value
                ? saas.Spec.spec.name_id_value.name_id_attribute_selector ?? ""
                : "",
            nameIdValueType: saas.Spec.spec.name_id_value
                ? saas.Spec.spec.name_id_value.name_id_value_type
                : this.mapUnknownNameIdAttributeType(saas.Spec.spec.name_id_value),
            metadataUrl: saas.MetadataUrl,
            clientId: saas.Spec.spec.client_id,
            clientSecret: saas.Spec.spec.client_secret,
            type: saas.Type,
            protocol: saas.Protocol,
            suppressDeviceTrustVerification: saas.Spec.spec.suppress_device_trust_verification,
            passwordlessAuthentication: saas.Spec.spec.enable_service_level_passwordless,
        }
    }

    private mapUnknownNameIdAttributeType(saasNameIdValue?: SaasNameIdValue): SaasNameIdValueOpts {
        if (saasNameIdValue?.passthrough_name_id) {
            return SaasNameIdValueOpts.PASSTHROUGH_NAME_ID
        }
        return SaasNameIdValueOpts.LEGACY
    }

    private filterRegisteredService(service: RegisteredService): boolean {
        return ![
            "deviceregistrationservice",
            "loginservice",
            "reportingservice",
            "externalservice",
        ].includes(service.ServiceName)
    }
}

export interface ServiceConnectionStatus {
    isFrontendConnectionStatus: boolean
    isBackendConnectionStatus: boolean
    response: ServiceConnectionStatusRes
}

export interface ServiceManage {
    id: string
    name: string
    nameId?: string
    clusterName: string
    enabled: boolean
    createdAt: number
    createdBy: string
    description: string
    lastUpdatedAt: number
    lastUpdatedBy: string
    oidcEnabled: boolean
    oidcSpec: string
    appType?: ServiceAppType
    spec?: ServiceSpec
    type: string
    version: number
    policyId?: string
    policyName?: string
    policyEnabled: boolean
    policyAttachedAt: number
    status: ServiceManageStatus
    userFacing?: string
}

export enum ServiceManageStatus {
    POLICY_ENFORCING,
    POLICY_PERMISSIVE,
    NO_POLICY,
    INACTIVE,
}

export enum ServiceType {
    WEB_USER = "WEB_USER",
    TCP_USER = "TCP_USER",
    TCP_WORKLOAD = "TCP_WORKLOAD",
    CUSTOM = "CUSTOM",
    TUNNEL = "TUNNEL",
}

export interface SaasManage {
    id: string
    name: string
    description: string
    createdAt: number
    createdBy: string
    lastUpdatedAt: number
    lastUpdatedBy: string
    enabled: boolean
    policyId?: string
    policyName?: string
    policyEnabled: boolean
    policyAttachedAt: number
    status: ServiceManageStatus
    initialAuth: SaasInitialAuth
    authProtocol?: SaasAuthProtocol
    redirectUri: string
    audienceUri?: string
    nameIdFormat?: SaasNameIdFormat
    nameIdPassthrough?: boolean
    nameIdAttributeSelector?: string
    nameIdValueType?: SaasNameIdValueOpts
    metadataUrl?: string
    clientId?: string
    clientSecret?: string
    type?: SaasAppType
    protocol: SaasAuthProtocol
    suppressDeviceTrustVerification?: boolean
    passwordlessAuthentication?: boolean
}

export enum SaasInitialAuth {
    IDP = "IDP",
    BANYAN_TRUST_PROVIDER = "BANYAN_TRUSTPROVIDER",
}

export enum ServiceAppType {
    WEB = "WEB",
    SSH = "SSH",
    RDP = "RDP",
    K8S = "K8S",
    GENERIC = "GENERIC",
    DATABASE = "DATABASE",
    ALL = "ALL",
}

export enum SSHServiceType {
    TRUSTCERT = "TRUSTCERT",
    SSHCERT = "SSHCERT",
    BOTH = "BOTH",
}

export enum TCPConnectMode {
    CHAIN = "CHAIN",
    BASTION = "BASTION",
    CONNECT = "CONNECT", // unused
    TCP = "TCP",
    RDPGATEWAY = "RDPGATEWAY",
}

export const useServiceManage = () =>
    React.useContext(ServiceContext)?.manage || new ManageService()

export function useRegisteredService(name: string) {
    const manage = useServiceManage()

    // some state to track what we need
    const [service, setService] = React.useState<ServiceManage | null>(null)
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState<string | null>(null)

    // look up the service with the matching name
    React.useEffect(() => {
        // look up the services
        manage
            .getRegisteredService(name)
            .then((remoteService) => {
                setLoading(false)
                // update our local state
                setService(remoteService)
            })
            .catch(() => {
                setLoading(false)
                setError(`Could not find service with name ${name}`)
            })
    }, [name, manage])

    // return the data
    return {
        data: service,
        loading,
        error,
    }
}
