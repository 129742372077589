import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import React, { FormEvent, ReactNode, useEffect, useState } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { LinkService } from "../../../../../pre-v3/services/link/Link.service"
import { LocalizationService } from "../../../../../pre-v3/services/localization/Localization.service"
import { ModalRef } from "../../../../../pre-v3/services/Modal.service"
import { CollectionUtil } from "../../../../../pre-v3/utils/Collection.util"
import { PatternUtil } from "../../../../../pre-v3/utils/Pattern.util"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { Form } from "../../../../components/form/Form.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { MenuButton } from "../../../../components/menu/menu-button/MenuButton.component"
import { MenuItemProps } from "../../../../components/menu/menu-item/MenuItem.component"
import { MultiInput } from "../../../../components/multi-input/MultiInput.component"
import { PillMultiSelect } from "../../../../components/pill-multi-select/PillMultiSelect.component"
import { getExemptionMethods, ServiceExemption } from "../../../../services/HostedService.service"
import { ErrorBanner } from "../../../../components/banner/Banner.component"
import { AppText } from "../../../../components/app-text/AppText.component"
import { HorizontalLine } from "../../../../components/form/HorizontalLine.component"
import styles from "./AccessPermissions.module.scss"

interface Props {
    serviceExemption?: ServiceExemption
    modalRef?: ModalRef
}

export function AddEditExemptionModal(props: Props) {
    const localization: LocalizationService = new LocalizationService()
    const linkService: LinkService = new LinkService()

    const [error, setError] = useState<string>("")

    const [availableRules, setAvailableRules] = useState<MenuItemWithComponent[]>([])
    const [selectedRules, setSelectedRules] = useState<MenuItemWithComponent[]>([])

    const [origin, setOrigin] = useState<string[]>([])
    const [target, setTarget] = useState<string[]>([])
    const [methods, setMethods] = useState<string[]>([])
    const [mandatoryHeaders, setMandatoryHeaders] = useState<string[]>([])
    const [paths, setPaths] = useState<string[]>([])
    const [sourceCidrs, setSourceCidrs] = useState<string[]>([])

    const rules: Record<string, MenuItemWithComponent> = {
        origin: {
            key: "origin",
            label: localization.getString("byOriginHeader"),
            onClick: () => onAddRule("origin"),
            component: (value, setValue) => (
                <FormRow
                    key="origin"
                    label={localization.getString("originHeader")}
                    description={localization.getString("originHeaderDescription")}
                    className={styles.multiInput}
                    removeLabel={localization.getString("removeRule")}
                    onRemove={() => onRemoveRule("origin")}
                >
                    <MultiInput
                        values={value}
                        onChange={setValue}
                        required
                        placeholder={localization.getString("originHeaderPlaceholder")}
                        patternProps={{
                            pattern: PatternUtil.HOST_SCHEME_PORT.source,
                            errorMessage: localization.getString("hostSchemePortRegex"),
                        }}
                    />
                </FormRow>
            ),
        },
        target: {
            key: "target",
            label: localization.getString("byTargetDomain"),
            onClick: () => onAddRule("target"),
            component: (value, setValue) => (
                <FormRow
                    key="target"
                    label={localization.getString("targetDomains")}
                    description={localization.getString("targetDomainsDescription")}
                    className={styles.multiInput}
                    removeLabel={localization.getString("removeRule")}
                    onRemove={() => onRemoveRule("target")}
                >
                    <MultiInput
                        values={value}
                        onChange={setValue}
                        required
                        placeholder={localization.getString("targetDomainsPlaceholder")}
                        patternProps={{
                            pattern: PatternUtil.HOST_SCHEME_PORT.source,
                            errorMessage: localization.getString("hostSchemePortRegex"),
                        }}
                    />
                </FormRow>
            ),
        },
        methods: {
            key: "methods",
            label: localization.getString("byMethods"),
            onClick: () => onAddRule("methods"),
            component: (value: string[], setValue: (v: string[]) => void) => (
                <PillMultiSelect
                    label={localization.getString("methods")}
                    placeholder={localization.getString("addMethod")}
                    description={localization.getString("methodsDescription")}
                    options={getExemptionMethods()}
                    value={value}
                    onChange={setValue}
                    removeLabel={localization.getString("removeRule")}
                    onRemove={() => onRemoveRule("methods")}
                    required
                />
            ),
        },
        mandatoryHeaders: {
            key: "mandatoryHeaders",
            label: localization.getString("byMandatoryHeaders"),
            onClick: () => onAddRule("mandatoryHeaders"),
            component: (value, setValue) => (
                <FormRow
                    key="mandatoryHeaders"
                    label={localization.getString("mandatoryHeaders")}
                    description={localization.getString("mandatoryHeadersDescription")}
                    className={styles.multiInput}
                    removeLabel={localization.getString("removeRule")}
                    onRemove={() => onRemoveRule("mandatoryHeaders")}
                >
                    <MultiInput
                        values={value}
                        onChange={setValue}
                        required
                        placeholder={localization.getString("mandatoryHeadersPlaceholder")}
                    />
                </FormRow>
            ),
        },
        paths: {
            key: "paths",
            label: localization.getString("byPaths"),
            onClick: () => onAddRule("paths"),
            component: (value, setValue) => (
                <FormRow
                    key="paths"
                    label={localization.getString("paths")}
                    description={localization.getString("pathsDescription")}
                    className={styles.multiInput}
                    removeLabel={localization.getString("removeRule")}
                    onRemove={() => onRemoveRule("paths")}
                >
                    <MultiInput
                        values={value}
                        onChange={setValue}
                        required
                        placeholder={localization.getString("pathsPlaceholder")}
                        patternProps={{
                            pattern: PatternUtil.STARTS_WITH_FORWARD_SLASH.source,
                            errorMessage: localization.getString("relativePathRegex"),
                        }}
                    />
                </FormRow>
            ),
        },
        sourceCidrs: {
            key: "sourceCidrs",
            label: localization.getString("bySourceCidrs"),
            onClick: () => onAddRule("sourceCidrs"),
            component: (value, setValue) => (
                <FormRow
                    key="sourceCidrs"
                    label={localization.getString("sourceCidrs")}
                    description={localization.getString("sourceCidrsDescription")}
                    className={styles.multiInput}
                    removeLabel={localization.getString("removeRule")}
                    onRemove={() => onRemoveRule("sourceCidrs")}
                >
                    <MultiInput
                        values={value}
                        onChange={setValue}
                        required
                        patternProps={{
                            pattern: PatternUtil.CIDR_REGEX.source,
                            errorMessage: localization.getString("cidrPlaceholder"),
                        }}
                    />
                </FormRow>
            ),
        },
    }

    useEffect(() => {
        const exemption = props.serviceExemption
        const selected: MenuItemWithComponent[] = []
        const available: MenuItemWithComponent[] = Object.values(rules)

        if (!exemption) {
            setAvailableRules(available)
            setSelectedRules(selected)
            return
        }

        if (!exemption.origin.includes("*")) {
            selected.push(rules.origin)
            setOrigin(exemption.origin)
        }

        if (!exemption.target.includes("*")) {
            selected.push(rules.target)
            setTarget(exemption.target)
        }

        if (!exemption.methods.includes("*") && exemption.methods.length < 6) {
            selected.push(rules.methods)
            setMethods(exemption.methods)
        }

        if (!exemption.mandatoryHeaders.includes("*")) {
            selected.push(rules.mandatoryHeaders)
            setMandatoryHeaders(exemption.mandatoryHeaders)
        }

        if (!exemption.paths.includes("/*")) {
            selected.push(rules.paths)
            setPaths(exemption.paths)
        }

        if (exemption.sourceCidrs.length > 0) {
            selected.push(rules.sourceCidrs)
            setSourceCidrs(exemption.sourceCidrs)
        }

        setAvailableRules(Object.values(rules).filter((r) => !selected.includes(r)))
        setSelectedRules(selected)
    }, [props.serviceExemption])

    function onAddRule(rule: string): void {
        setError("")
        if (!rules[rule]) {
            return
        }

        setSelectedRules((prev) => [...prev, rules[rule]])
        setAvailableRules((prev) => [...prev].filter((r) => r.label !== rules[rule].label))
    }

    function onRemoveRule(rule: string): void {
        setError("")
        if (!rules[rule]) {
            return
        }

        keyMap[rule].setValue([])
        setAvailableRules((prev) => [...prev, rules[rule]])
        setSelectedRules((prev) => [...prev].filter((r) => r.label !== rules[rule].label))
    }

    function onSubmit(event: FormEvent): void {
        event.preventDefault()
        setError("")

        if (selectedRules.length === 0) {
            setError(localization.getString("addAtLeastOneExemptionRuleBeforeContinuing"))
            return
        }

        const value: ServiceExemption = {
            origin: CollectionUtil.isTruthy(origin) ? origin : ["*"],
            target: CollectionUtil.isTruthy(target) ? target : ["*"],
            methods: CollectionUtil.isTruthy(methods) ? methods : ["*"],
            mandatoryHeaders: CollectionUtil.isTruthy(mandatoryHeaders) ? mandatoryHeaders : ["*"],
            paths: CollectionUtil.isTruthy(paths) ? paths : ["/*"],
            sourceCidrs: CollectionUtil.isTruthy(sourceCidrs) ? sourceCidrs : [],
        }

        if (isInsecure(value)) {
            setError(localization.getString("exemptionInsecureMessage"))
            return
        }

        props.modalRef?.close(value)
    }

    const keyMap: Record<
        string,
        {
            value: string[]
            setValue: (v: string[]) => void
        }
    > = {
        origin: {
            value: origin,
            setValue: setOrigin,
        },
        target: {
            value: target,
            setValue: setTarget,
        },
        methods: {
            value: methods,
            setValue: setMethods,
        },
        mandatoryHeaders: {
            value: mandatoryHeaders,
            setValue: setMandatoryHeaders,
        },
        paths: {
            value: paths,
            setValue: setPaths,
        },
        sourceCidrs: {
            value: sourceCidrs,
            setValue: setSourceCidrs,
        },
    }

    return (
        <Form onSubmit={onSubmit} className={styles.modalForm}>
            {availableRules.length > 0 && (
                <div className={styles.rowContainer}>
                    <AppText
                        className={styles.addRuleLabel}
                        ls={{
                            key: "addRuleDescription",
                            replaceVals: [linkService.getLink("exemptionsAndCors")],
                        }}
                    />
                    <MenuButton
                        icon={faPlus}
                        label={localization.getString("addExemptionRule")}
                        menuItems={availableRules}
                        className={styles.addRuleButton}
                    />
                </div>
            )}
            {selectedRules.length > 0 && <HorizontalLine />}
            {selectedRules.map((r) => r.component(keyMap[r.key].value, keyMap[r.key].setValue))}
            <FormButtons
                rightButtons={
                    <>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={props.modalRef?.cancel}
                        >
                            {localization.getString("cancel")}
                        </Button>
                        <Button
                            type="submit"
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                        >
                            {localization.getString("save")}
                        </Button>
                    </>
                }
            >
                {error && <ErrorBanner>{error}</ErrorBanner>}
            </FormButtons>
        </Form>
    )
}

interface MenuItemWithComponent extends MenuItemProps {
    key: string
    component: (value: string[], setValue: (v: string[]) => void) => ReactNode
}

function isInsecure(exemption: ServiceExemption): boolean {
    const isOriginInsecure = exemption.origin.includes("*")
    const isTargetInsecure = exemption.target.includes("*")
    const isMethodsInsecure = exemption.methods.includes("*") || exemption.methods.length >= 6
    const isMandatoryHeadersInsecure = exemption.mandatoryHeaders.includes("*")
    const isPathsInsecure = exemption.paths.includes("/*")
    const isSourceCidrsInsecure = exemption.sourceCidrs.length === 0

    return (
        isOriginInsecure &&
        isTargetInsecure &&
        isMethodsInsecure &&
        isMandatoryHeadersInsecure &&
        isPathsInsecure &&
        isSourceCidrsInsecure
    )
}
