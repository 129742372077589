import { ColDef, GridApi, GridReadyEvent, RowDoubleClickedEvent } from "ag-grid-community"
import React, { ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../../routes"
import { Bind } from "../../../../decorators/Bind.decorator"
import ActionBarService from "../../../../services/ActionBar.service"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import { ApiKey, SettingsService } from "../../../../services/Settings.service"
import AgGridUtil from "../../../../utils/AgGrid.util"
import { encodeID } from "../../../../utils/Url.util"
import ApiKeysSettingsTemplate from "./ApiKeysSettings.template"

interface Props extends Omit<RouteComponentProps, "location" | "match"> {
    canAccessApiKey: boolean
}

export class ApiKeysSettings extends React.Component<Props, {}> {
    public render(): ReactNode {
        return ApiKeysSettingsTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(
            this.ls.getString("apiKeys"),
            [
                {
                    label: this.ls.getString("adminSettings"),
                },
            ],
            this.fetchData
        )
    }

    private ls: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private settingsService: SettingsService = new SettingsService()
    private gridApi: GridApi

    private columns: ColDef[] = [
        {
            headerName: this.ls.getString("apiKeyName"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: "apiKeyCellRenderer",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: this.ls.getString("description"),
            field: "description",
        },
        {
            headerName: this.ls.getString("scope"),
            field: "scope",
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: this.ls.getString("createdBy"),
            field: "createdBy",
        },
        {
            headerName: this.ls.getString("createdAt"),
            field: "createdAt",
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    @Bind
    private onGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.fetchData()
    }

    @Bind
    private onFilter(value: string): void {
        if (this.gridApi) this.gridApi.setQuickFilter(value)
    }

    @Bind
    private onRowDoubleClicked(params: RowDoubleClickedEvent): void {
        this.props.history.push(
            formatRoutePath(ROUTE.API_KEYS_DETAILS, { id: encodeID(params.data.id) })
        )
    }

    @Bind
    private fetchData(): void {
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay()
            this.settingsService.getOrgApiKeys().then((keys: ApiKey[]) => {
                this.gridApi.setRowData(keys)
                this.gridApi.hideOverlay()
            })
        }
    }
}
