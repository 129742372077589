import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../../components/button/Button.component"
import { PageBreak } from "../../../../../../pre-v3/components/page-break/PageBreak.component"
import { useServiceLocalization } from "../../../../../../pre-v3/services/localization/Localization.service"
import { useGetAdminInfo } from "../../../../../services/Org.service"
import {
    TrustFactorRemediation as TrustFactor,
    useGetRemediationPerTrustFactor,
    useUpdateRemediationPerTrustFactor,
} from "../../../../../services/TrustFactor.service"
import { Accordion } from "../../../../../components/accordion/Accordion.component"
import { AppText } from "../../../../../components/app-text/AppText.component"
import { ErrorBanner } from "../../../../../components/banner/Banner.component"
import { Loader } from "../../../../../components/loader/Loader.component"
import { RemediationDetails } from "./RemediationDetails.component"
import styles from "./Remediation.module.scss"
import { PageHeading } from "../../../../../../components/page-heading/PageHeading.component"
import { Tooltip } from "../../../../../components/tooltip/Tooltip.component"
import {
    ErrorToast,
    SuccessToast,
    ToastApi,
} from "../../../../../../components/toast/Toast.components"

export function Remediation(): JSX.Element {
    const localization = useServiceLocalization()

    // We're only sending to the BE the Remediation that changed
    const [editableRemediation, setEditableRemediation] = React.useState<EditableRemediation>({})

    const adminInfoQuery = useGetAdminInfo()
    const errorToastRef = React.useRef<ToastApi>(null)
    const successToastRef = React.useRef<ToastApi>(null)
    const remediationQuery = useGetRemediationPerTrustFactor()
    const updateRemediationMutation = useUpdateRemediationPerTrustFactor({
        onSuccess: () => {
            setEditableRemediation({})
            successToastRef.current?.openToast(
                localization.getString("successfullyUpdatedRemediation")
            )
        },
        onError: () => {
            errorToastRef.current?.openToast(localization.getString("failedToUpdateRemediation"))
        },
    })

    async function onRefresh() {
        await remediationQuery.refetch()
        setEditableRemediation({})
    }

    const mapRemediationAccordion = useMapRemediationAccordion(
        editableRemediation,
        setEditableRemediation,
        adminInfoQuery.data?.canWriteAll
    )

    const onSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()
        updateRemediationMutation.mutate(Object.values(editableRemediation))
    }

    if (adminInfoQuery.status !== "success") return <Loader children isLoading center />

    switch (remediationQuery.status) {
        case "loading":
            return <Loader children isLoading center />

        case "error":
            return <ErrorBanner>{String(remediationQuery.error)}</ErrorBanner>

        case "success":
            const { canWriteAll } = adminInfoQuery.data

            return (
                <section aria-labelledby={Id.HEADING} className={styles.section}>
                    <header className={styles.header}>
                        <PageHeading id={Id.HEADING}>
                            {localization.getString("remediation")}
                        </PageHeading>
                        <Tooltip title={localization.getString("refresh")}>
                            <Button
                                icon={IconType.REDO}
                                onClick={onRefresh}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                aria-label={localization.getString("refresh")}
                            />
                        </Tooltip>
                    </header>
                    <PageBreak className={styles.pageBreak}>
                        {localization.getString("remediationMessagingForDesktopApps")}
                    </PageBreak>
                    <AppText ls="remediationMessagingForDesktopAppsDescription" />
                    <form onSubmit={onSubmit} className={styles.form}>
                        <div className={styles.accordion}>
                            {remediationQuery.data.map(mapRemediationAccordion)}
                        </div>
                        {canWriteAll && (
                            <React.Fragment>
                                <hr className={styles.horizontalLine} />
                                {typeof updateRemediationMutation.error === "string" && (
                                    <ErrorBanner>{updateRemediationMutation.error}</ErrorBanner>
                                )}
                                <Button
                                    type="submit"
                                    loading={updateRemediationMutation.isLoading}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                    className={styles.saveButton}
                                >
                                    {localization.getString("save")}
                                </Button>
                            </React.Fragment>
                        )}
                    </form>
                    <SuccessToast ref={successToastRef} />
                    <ErrorToast ref={errorToastRef} />
                </section>
            )
    }
}

type EditableRemediation = Record<string, TrustFactor>

function useMapRemediationAccordion(
    editableRemediation: EditableRemediation,
    setEditableRemediation: React.Dispatch<React.SetStateAction<EditableRemediation>>,
    canWriteAll = false
) {
    return React.useCallback(
        (trustFactor: TrustFactor): JSX.Element => {
            const onTrustFactorChange = (trustFactor: TrustFactor): void => {
                setEditableRemediation((oldRemediation) => ({
                    ...oldRemediation,
                    [trustFactor.id]: trustFactor,
                }))
            }

            return (
                <Accordion key={trustFactor.id} label={trustFactor.name} defaultOpen>
                    <div className={styles.details}>
                        <RemediationDetails
                            trustFactor={editableRemediation[trustFactor.id] ?? trustFactor}
                            onTrustFactorChange={onTrustFactorChange}
                            canWriteAll={canWriteAll}
                        />
                    </div>
                </Accordion>
            )
        },
        [editableRemediation, setEditableRemediation, canWriteAll]
    )
}

enum Id {
    HEADING = "heading",
}
