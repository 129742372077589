import { BaseApi } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"

@Singleton("OrgApi")
export class OrgApi extends BaseApi {
    public getUserOrgDetails(): Promise<UserOrgDetailsRes> {
        return this.get("/api/v1/user_org_details", {
            default: this.localization.getString("errorCouldNotFetchUserOrgDetails"),
        })
    }

    public getOrgDetails(): Promise<OrgDetailsRes> {
        return this.get("/api/v1/org_details", {
            default: this.localization.getString("errorCouldNotFetchOrgDetails"),
        })
    }

    public getOrgs(): Promise<OrgInfoRes[]> {
        return this.get("/api/v2/mom/org", {
            default: this.localization.getString("errorCouldNotFetchOrgs"),
        })
    }

    public getOrgById(id: string): Promise<OrgInfoRes> {
        return this.get(`/api/v2/mom/org/${id}`, {
            default: this.localization.getString("errorCouldNotFetchOrg"),
        })
    }

    public deleteOrg(id: string): Promise<void> {
        return this.delete(
            `/api/v2/mom/org/${encodeURIComponent(id)}`,
            {},
            {
                default: this.localization.getString("errorCouldNotDeleteOrg"),
            }
        )
    }

    public toggleArchive(orgName: string, archived: boolean): Promise<void> {
        return this.put(
            "/api/v2/mom/org",
            { org_name: orgName, archived },
            {
                default: this.localization.getString(
                    archived ? "errorCouldNotArchiveOrg" : "errorCouldNotUnArchiveOrg"
                ),
            }
        )
    }

    public updateOrgArchivalDate(orgId: string, archivalAt: number): Promise<void> {
        return this.put(
            `/api/v2/mom/org/${orgId}/settings`,
            { archive_at: archivalAt },
            {
                default: this.localization.getString("failedToUpdateArchivalDate"),
            }
        )
    }

    public addOrg(orgInfoReq: OrgInfoReq): Promise<OrgInfoRes> {
        return this.post("/api/v2/mom/org", orgInfoReq, {
            default: this.localization.getString("errorCouldNotAddOrg"),
            4006: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("organization"),
                orgInfoReq.org_name
            ),
            4018: this.localization.getString("invalidCustomerId"),
        })
    }

    public editOrg(orgInfoReq: OrgInfoReq): Promise<void> {
        return this.put("/api/v2/mom/org", orgInfoReq, {
            default: this.localization.getString("errorCouldNotEditOrg"),
            4022: this.localization.getString("editionDowngradeIsNotSupported"),
            4017: this.localization.getString("orgIsProvisioningPleaseWait"),
            4018: this.localization.getString("invalidCustomerId"),
        })
    }

    public patchOrgSettings(formData: PatchOrgSettingsData): Promise<void> {
        return this.postForm("/api/v1/update_org", formData, {
            default: this.localization.getString("failedToSaveOrgSettings"),
        })
    }

    public attachMspOrg({ msp_org_id, child_org_id }: AssignOrgToMspReq): Promise<void> {
        return this.post(
            `api/v2/mom/msp/child_org?msp_org_id=${msp_org_id}&child_org_id=${child_org_id}`,
            undefined,
            {
                default: this.localization.getString("errorAttachingOrgToMsp"),
            }
        )
    }

    public detachMspOrg({ msp_org_id, child_org_id }: AssignOrgToMspReq): Promise<void> {
        return this.delete(
            `api/v2/mom/msp/child_org?msp_org_id=${msp_org_id}&child_org_id=${child_org_id}`,
            undefined,
            {
                default: this.localization.getString("errorDetachingOrgToMsp"),
            }
        )
    }

    public superAdminGetOrgLicenseById(orgId: string): Promise<LicenseInformationRes | null> {
        return this.get(`api/v2/mom/org/${orgId}/licenses`, {
            default: this.localization.getString("failedToGetOrgLicense"),
        })
    }

    public getLicenseInformation(): Promise<LicenseInformationRes | null> {
        return this.get("api/v2/org/msw/licenses", {
            default: this.localization.getString("failedToGetOrgLicense"),
        })
    }

    public getOrgLicensesUsage(): Promise<LicenseUsageRes | null> {
        return this.get("api/v2/org/msw/license_usage/summary", {
            default: this.localization.getString("failedToGetOrgLicensesUsage"),
        })
    }
}

export interface OrgArchiveRes {
    archive_at: number
}

export interface UserOrgDetailsRes {
    Email: string
    First: string
    Last: string
    FullName: string
    OrgName: string
    ID: string
    OrgID: string
    LastUpdatedAt: number
    AccountType: "SAML" | "LOCAL"
    IDPType: "SAML" | "BANYAN"
    Profile: ProfileRes
    BrandingLogo: string
    BrandingBackgroundColor: string
    SupportMessage: string
    SupportLinkURL: string
    ErrorSupportMessage: string
    ErrorSupportLinkURL: string
    LastUpdatedBy: string
    MFAEnabled: boolean
    HasRefreshToken: boolean
    RefreshTokenForSAMLEnabled: boolean
    IsAIAssistEnabled: boolean
    IsMSPOrg: boolean
    IsSCIMEnabled: boolean
}

export type ProfileRes =
    | "SuperAdmin"
    | "MOM"
    | "ReadOnly"
    | "Admin"
    | "Owner"
    | "PolicyAuthor"
    | "ServiceAuthor"
export type EditionRes = "Team" | "Enterprise" | "Unlimited"
export type StatusRes = "Success" | "PartialSuccess" | "InProgress" | "Failed"
export type ProvisionStatusRes = "Success" | "Failed" | "Archived" | "Pending" | "Deleted"
export type TypeRes =
    | "Production"
    | "Trial"
    | "Staging"
    | "Testing"
    | "Internal"
    | "InternalTemporary"
    | "InternalPersistent"
export type IdpNameRes =
    | ""
    | "DEFAULT"
    | "OKTA"
    | "SAML"
    | "COGNITO"
    | "ONE LOGIN"
    | "OTHER"
    | "BANYAN"

export interface OrgDetailsRes {
    Edition: EditionRes
    OnboardingState?: OnboardingStateRes
    OrgName: string
    OrgID: string
    IsMSPOrg: boolean
    IsGranularTrustEnabled: boolean
    IsDNSFilterEnabled: boolean
    IDPName: IdpNameRes
    CreatedAt: number
    PrivateEdge: boolean
    GlobalEdge: boolean
    OrgType: OrgTypeRes
    IsAppDiscoveryEnabled: boolean
    IsMySonicWall: boolean
    IsTestDrive: boolean
    IsURLFilteringEnabled: boolean
    IsEventChartEnabled: boolean
    IsGeoLocationEnabled: boolean
}

export type OrgTypeRes =
    | "Production"
    | "Trial"
    | "Staging"
    | "InternalTemporary"
    | "InternalPersistent"
    | "Testing"
    | "Internal"

export interface OrgInfoRes {
    status?: StatusRes
    org_name: string
    org_id: string
    first_name: string
    last_name: string
    email: string
    edition: EditionRes
    type: TypeRes
    last_updated_at: number
    customer_id: string
    global_edge: boolean
    private_edge: boolean
    is_ai_assist_enabled: boolean
    is_dns_filter_enabled: boolean
    is_msp_org: boolean
    // cspell:disable-next-line
    is_appdiscovery_enabled: boolean
    // cspell:ignore mysonicwall
    is_mysonicwall: boolean
    created_at: number
    created_by: string
    last_updated_by: string
    archived: boolean
    banyan_idp: boolean
    parent_org_id?: string
    provision_status: {
        access_tier?: ProvisionStatusRes
        shield?: ProvisionStatusRes
        user_pool?: ProvisionStatusRes
        org_data?: ProvisionStatusRes
        registered_domain?: ProvisionStatusRes
        connector?: ProvisionStatusRes
        invite_code?: ProvisionStatusRes
        user_pool_domain?: ProvisionStatusRes
        user?: ProvisionStatusRes
        default_groups_user?: ProvisionStatusRes
        user_pool_client?: ProvisionStatusRes
        default_groups_user_pool?: ProvisionStatusRes
        update_user_pool?: ProvisionStatusRes
        invite_admin_user?: ProvisionStatusRes
        private_edge_shield?: ProvisionStatusRes
        [key: string]: ProvisionStatusRes | undefined
    }
    internal_owner: string | null
    archive_at: number | null
}

export interface OrgInfoReq {
    org_name: string
    first_name: string
    last_name: string
    email: string
    edition: EditionRes
    type: TypeRes
    customer_id: string
    global_edge: boolean
    private_edge: boolean
    is_ai_assist_enabled: boolean
    // cspell:disable-next-line
    is_appdiscovery_enabled: boolean
    archived: boolean
    password?: string
    banyan_idp?: boolean
    is_msp_org: boolean
    is_dns_filter_enabled: boolean
    is_urlfiltering_enabled: boolean
    internal_owner: string | null
    archive_at: number | null
}

export interface PatchOrgSettingsData {
    IsGranularTrustEnabled?: "TRUE"
    BrandingLogo?: string
    BrandingBackgroundColor?: string
    SupportMessage?: string
    SupportLinkURL?: string
    ErrorSupportMessage?: string
    ErrorSupportLinkURL?: string
    MFAEnabled?: boolean
    IsGeoLocationEnabled?: boolean
}

export interface OnboardingStateRes {
    type?: string
    connectorId?: string
    serviceId?: string
    state?: string
}

export interface AssignOrgToMspReq {
    msp_org_id: string
    child_org_id: string
}

export type LicenseTypeRes = "None" | "Basic" | "Advanced"

export interface LicenseInformationRes {
    spa: LicenseTypeRes
    sia: LicenseTypeRes
    spa_users: number
    sia_users: number
    spa_overage_count: number
    sia_overage_count: number
}

export interface LicenseUsageRes {
    spa_license_count: number
    sia_license_count: number
}
