import * as React from "react"
import { useHistory } from "react-router-dom"

import logoImage from "../../../images/logo-horiz-black.svg"
import { ROUTE } from "../../../routes"
import { Background } from "../../../v3/components/background/Background.component"
import styles from "./OIDC.module.scss"
import { Button, Input } from "../../components"
import { UrlUtil } from "../../utils/Url.util"
import { useServiceLocalization, useServiceOIDC } from "../../services"

export function OIDCOrgSelector(): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()
    const oidcService = useServiceOIDC()

    const [orgName, setOrgName] = React.useState<string>(oidcService.getOrgName())

    const orgDomain: string = UrlUtil.getOrgDomain()

    function validateForm(): boolean {
        return orgName.length > 0
    }

    function onSubmit(event: React.SyntheticEvent): void {
        event.preventDefault()

        oidcService.setOrgName(orgName)
        history.push(ROUTE.OIDC_LOGIN)
    }

    return (
        <div className={styles.oidcLogin}>
            <Background>
                <div className={styles.loginForm}>
                    <div className={styles.form}>
                        <form onSubmit={onSubmit} name="forgotPasswordForm">
                            <img src={logoImage} alt="" className={styles.logo} />
                            <p className={styles.orgname}>
                                {localization.getString("orgNameSelectDescription")}
                            </p>
                            <div className={styles.orgnameinput}>
                                <Input
                                    autoFocus
                                    name="orgName"
                                    id="orgName"
                                    placeholder={localization.getString("orgName")}
                                    onChange={(e) => setOrgName(e.target.value)}
                                    value={orgName}
                                    className={styles.domain}
                                />
                                <div className={styles.inputurl}>.{orgDomain}</div>
                            </div>
                            <Button
                                className={styles.formBtn}
                                type="submit"
                                disabled={!validateForm()}
                            >
                                {localization.getString("continueToLogin")}
                            </Button>
                            <br></br>
                            <div className={styles.message}>
                                <span className={styles.link}>
                                    {localization.getString("dontHaveAnOrg")}
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
            </Background>
        </div>
    )
}
