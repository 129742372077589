import React, { ReactNode } from "react"
import PolicyInfoTemplate from "./PolicyInfo.template"
import { PolicySecure, PolicyType } from "../../../services/Secure.service"
import { LocalizationService } from "../../../services/localization/Localization.service"
import { PolicySpec } from "../../../api/Secure.api"
import { TrustLevel } from "../../../api/Entity.api"
import { ServiceType, SecureService, RoleSecure } from "../../../services"

export class PolicyInfo extends React.Component<PolicyInfoProps, PolicyInfoState> {
    private ls: LocalizationService = new LocalizationService()
    private secureService: SecureService = new SecureService()

    public state: PolicyInfoState = { error: "" }

    public render(): ReactNode {
        return PolicyInfoTemplate.call(this)
    }

    public componentDidMount(): void {
        this.secureService.getRoles().then((roles: RoleSecure[]) => {
            const roleNames: string[] = roles.map((r) => r.name)
            roleNames.unshift("ANY")
            this.setState({ roles: roleNames })
        })

        if (this.props.policy) {
            try {
                const spec: PolicySpec = JSON.parse(this.props.policy.spec)
                this.unmarshallData(spec)
                this.setState({
                    selectedRoles: spec.spec.access.flatMap((aGroup) => aGroup.roles),
                    spec,
                })
            } catch {
                this.setState({ error: this.ls.getString("failedToParseJson") })
            }
        }
    }

    private unmarshallData(spec: PolicySpec): void {
        if (!spec.metadata.tags || spec.metadata.tags.template === "CUSTOM") {
            this.setState({ policyType: PolicyType.CUSTOM })
            return
        }

        let serviceType: ServiceType = ServiceType.TUNNEL
        const hasL4AccessRule: boolean = JSON.stringify(spec.spec.access).includes("l4_access")
        if (spec.spec.options) {
            if (spec.spec.options.l7_protocol === "http") {
                serviceType = ServiceType.WEB_USER
            } else {
                if (hasL4AccessRule) {
                    serviceType = ServiceType.TUNNEL
                } else {
                    serviceType = ServiceType.TCP_USER
                }
            }
        }

        const policyType: PolicyType = <PolicyType>spec.metadata.tags.template
        if (policyType === PolicyType.CUSTOM) {
            this.setState({ policyType, subType: serviceType })
            return
        }
        const roles: string[] = spec.spec.access[0].roles
        const trustLevel: TrustLevel = <TrustLevel>spec.spec.access[0].rules.conditions.trust_level

        this.setState({ policyType, subType: serviceType, roles, trustLevel })
    }
}

interface PolicyInfoProps {
    className?: string
    policy: PolicySecure
}

interface PolicyInfoState {
    error: string
    policyType?: PolicyType
    subType?: ServiceType
    roles?: string[]
    trustLevel?: TrustLevel
    jsonBlob?: string
    selectedRoles?: string[]
    spec?: PolicySpec
}
