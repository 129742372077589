import { IconName } from "@fortawesome/fontawesome-common-types"
import React, { FormEvent, useState } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { IconSelect } from "../../../../../pre-v3/components/icon-select/IconSelect.component"
import { useServiceLocalization } from "../../../../../pre-v3/services"
import { useServiceLinks } from "../../../../../pre-v3/services/link/Link.service"
import { PatternUtil } from "../../../../../pre-v3/utils/Pattern.util"
import { AppText } from "../../../../components/app-text/AppText.component"
import { ErrorBanner } from "../../../../components/banner/Banner.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { Form } from "../../../../components/form/Form.component"
import { FormGroup } from "../../../../components/form/FormGroup.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { Input } from "../../../../components/input/Input.component"
import { KvpMultiInput } from "../../../../components/kvp-multi-input/KvpMultiInput.component"
import { MultiInput } from "../../../../components/multi-input/MultiInput.component"
import { Switch } from "../../../../components/switch/Switch.component"
import { ToggleButton } from "../../../../components/toggle-button/ToggleButton"
import { SameSite, WebService } from "../../../../services/HostedService.service"
import styles from "./Advanced.module.scss"

interface Props {
    onSubmit?: (data: AdvancedConfigFormValues) => void
    initialValue: WebService
    disabled?: boolean
    nextStep?: () => void
    prevStep?: () => void
    onCancel?: () => void
    isEdit?: boolean
    isDataLoading?: boolean
    onSave?: (data: WebService) => void
    error?: string
}

export interface AdvancedConfigFormValues {
    customHeaders: StringMap
    icon: string
    descriptionLink: string
    disableDNS: boolean
    suppressDTV: boolean
    userFacing: boolean
    trustCookieSameSitePolicy?: SameSite
    trustCookiePath?: string
    postAuthRedirectPath: string
    tlsSni: string[]
}

export function Advanced(props: Props) {
    const { onSubmit, initialValue, disabled, prevStep, onCancel, isEdit, isDataLoading } = props

    const linkService = useServiceLinks()
    const ls = useServiceLocalization()

    const [userFacing, setUserFacing] = useState<boolean>(initialValue?.userFacing ?? true)
    const [suppressDTV, setSuppressDTV] = useState<boolean>(Boolean(initialValue?.suppressDtv))
    const [disableDNS, setDisableDNS] = useState<boolean>(Boolean(initialValue?.disablePrivateDns))
    const [descriptionLink, setDescriptionLink] = useState<string>(
        initialValue?.descriptionLink || ""
    )
    const [icon, setIcon] = React.useState(initialValue?.icon || "")

    const defaultCustomHeaders = Object.entries(initialValue?.customHeaders || {})
    const [customHeaders, setCustomHeaders] = React.useState<[string, string][]>(
        defaultCustomHeaders || []
    )

    const [sameSite, setSameSite] = React.useState(initialValue?.trustCookieSameSitePolicy)
    const [trustCookiePath, setTrustCookiePath] = React.useState(initialValue?.trustCookiePath)
    const [postAuthRedirectPath, setPostAuthRedirectPath] = React.useState(
        initialValue.postAuthRedirectPath
    )
    const [tlsSni, setTlsSni] = React.useState(initialValue.tlsSni)

    function submitFormData(e: FormEvent) {
        e.preventDefault()

        onSubmit?.({
            userFacing,
            suppressDTV,
            disableDNS,
            icon,
            descriptionLink,
            customHeaders: Object.fromEntries(
                customHeaders.filter((header) => header[0] && header[1])
            ),
            trustCookieSameSitePolicy: sameSite,
            trustCookiePath,
            postAuthRedirectPath,
            tlsSni,
        })
    }

    function onBack() {
        prevStep?.()
    }

    async function onAdvancedDataUpdate(e: FormEvent) {
        e.preventDefault()
        const updateServiceData = {
            ...initialValue,
            customHeaders: Object.fromEntries(
                customHeaders.filter((header) => header[0] && header[1])
            ),
            icon: icon,
            descriptionLink: descriptionLink,
            userFacing: userFacing,
            suppressDtv: suppressDTV,
            disablePrivateDns: disableDNS,
            trustCookieSameSitePolicy: sameSite,
            trustCookiePath,
            postAuthRedirectPath,
            tlsSni,
        }

        props.onSave?.(updateServiceData as WebService)
    }

    return (
        <Form onSubmit={isEdit ? onAdvancedDataUpdate : submitFormData}>
            <FormGroup label={ls.getString("cseAppSettingsOptional")}>
                <FormRow
                    label={ls.getString("link")}
                    description={ls.getString("thisIsTheLinkDisplayedDescription")}
                >
                    <Input
                        type="text"
                        placeholder={ls.getString("linkShownToEndUsersDescription")}
                        value={descriptionLink}
                        onChange={(e) => setDescriptionLink(e.target.value)}
                        disabled={disabled}
                    />
                </FormRow>
                <FormRow
                    label={ls.getString("icon")}
                    description={ls.getString("thisIsTheIconDisplayedDescription")}
                >
                    <IconSelect
                        onChange={setIcon}
                        initialValue={icon as IconName}
                        disabled={disabled}
                    />
                </FormRow>
                <FormRow label={ls.getString("showInCseAppServicesCatalog")}>
                    <Switch value={userFacing} onChange={setUserFacing} disabled={disabled} />
                </FormRow>
            </FormGroup>
            <FormGroup label={ls.getString("additionalConfigurationsOptional")}>
                <FormRow
                    label={ls.getString("customHttpHeadersToSendToBackend")}
                    description={ls.getString("thisIsUsedToProvideCredentialsDescription")}
                    className={styles.multiInput}
                >
                    <KvpMultiInput
                        values={customHeaders}
                        onChange={setCustomHeaders}
                        disabled={disabled}
                        keyPlaceholder={ls.getString("headerName")}
                        valuePlaceholder={ls.getString("value")}
                    />
                </FormRow>
                <FormRow
                    label={ls.getString("suppressDeviceTrustVerification")}
                    description={ls.getString("suppressDeviceTrustVerificationDescription")}
                >
                    <Switch value={suppressDTV} onChange={setSuppressDTV} disabled={disabled} />
                </FormRow>
                <FormRow
                    label={ls.getString("disablePrivateDns")}
                    description={ls.getString("disablePrivateDnsDescription")}
                >
                    <Switch value={disableDNS} onChange={setDisableDNS} disabled={disabled} />
                </FormRow>
                <FormRow
                    label={ls.getString("trustCookieSameSitePolicy")}
                    description={
                        <AppText
                            ls={{
                                key: "somethingLearnMore",
                                replaceVals: [
                                    ls.getString("trustCookieSameSitePolicyDescription"),
                                    linkService.getLink("sameSiteCookiesExplained"),
                                ],
                            }}
                        />
                    }
                >
                    <ToggleButton
                        items={Object.values(SameSite).map((policy) => ({
                            value: policy,
                            label: ls.getString(policy),
                            onClick: () => setSameSite(policy),
                            selected: sameSite === policy,
                        }))}
                        disabled={disabled}
                    />
                </FormRow>
                <FormRow
                    label={ls.getString("trustCookiePath")}
                    description={ls.getString("trustCookiePathDescription")}
                >
                    <Input
                        type="text"
                        placeholder={ls.getString("pathsPlaceholder")}
                        pattern={PatternUtil.STARTS_WITH_FORWARD_SLASH.source}
                        title={ls.getString("relativePathRegex")}
                        value={trustCookiePath ?? ""}
                        onChangeValue={setTrustCookiePath}
                        disabled={disabled}
                    />
                </FormRow>
                <FormRow
                    htmlFor={AdvancedField.POST_AUTH_REDIRECT_PATH}
                    label={ls.getString("postAuthRedirectPath")}
                    description={ls.getString("postAuthRedirectPathDescription")}
                >
                    <Input
                        id={AdvancedField.POST_AUTH_REDIRECT_PATH}
                        type="text"
                        value={postAuthRedirectPath}
                        onChangeValue={setPostAuthRedirectPath}
                        pattern={PatternUtil.STARTS_WITH_FORWARD_SLASH.source}
                        disabled={disabled}
                    />
                </FormRow>
                <FormRow
                    label={ls.getString("tlsSni")}
                    description={
                        <AppText
                            ls={{
                                key: "somethingLearnMore",
                                replaceVals: [
                                    ls.getString("tlsSniDescription"),
                                    linkService.getLink("registeredServiceApi"),
                                ],
                            }}
                        />
                    }
                >
                    <MultiInput
                        values={tlsSni}
                        onChange={setTlsSni}
                        label={ls.getString("tlsSni")}
                        disabled={disabled}
                    />
                </FormRow>
            </FormGroup>
            {!disabled && (
                <FormButtons
                    leftButtons={
                        !isEdit && (
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                onClick={onBack}
                            >
                                {ls.getString("back")}
                            </Button>
                        )
                    }
                    rightButtons={
                        <>
                            {onCancel && (
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    onClick={onCancel}
                                >
                                    {ls.getString("cancel")}
                                </Button>
                            )}
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                type="submit"
                                loading={isDataLoading}
                                disabled={isDataLoading}
                            >
                                {isEdit ? ls.getString("save") : ls.getString("saveAndValidate")}
                            </Button>
                        </>
                    }
                >
                    {props.error && <ErrorBanner>{props.error}</ErrorBanner>}
                </FormButtons>
            )}
        </Form>
    )
}

enum AdvancedField {
    POST_AUTH_REDIRECT_PATH = "postAuthRedirectPath",
}
