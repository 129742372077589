import { faPlus } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GridReadyEvent, GridApi, ColDef, ITooltipParams } from "ag-grid-community"
import React, { useRef, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { useActionBar } from "../../../../pre-v3/services/ActionBar.service"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { Button } from "../../../components/button/Button.component"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import { Status } from "../../../components/status/Status.component"
import styles from "./OrgManagementList.module.scss"

// Note: Old components, move them to v3
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { orgStatusMap, labelMap } from "../OrgManagement.view"
import { SuperAdminOrgInfo, useGetOrgs } from "../../../services/SuperAdmin.service"
import { Link } from "../../../components/link/Link.component"
import { InformationLabel } from "../../../../components/label/Label.component"

export function OrgManagementList() {
    const localization = useServiceLocalization()
    const history = useHistory()
    const grid = useRef<GridApi>()

    const [search, setSearch] = useState<string>("")

    const {
        data,
        refetch: fetchOrgs,
        isFetching: isOrgsLoading,
        isError: isOrgsError,
        error: orgsError,
    } = useGetOrgs()

    useEffect(() => {
        grid.current?.setQuickFilter?.(search)
    }, [search])

    useActionBar({
        title: localization.getString("orgManagement"),
        items: [
            {
                label: localization.getString("superAdmin"),
                href: "",
            },
        ],
        fetchData: () => {
            fetchOrgs()
        },
    })

    function onGridReady(event: GridReadyEvent) {
        grid.current = event.api
    }

    function addOrg() {
        history.push(ROUTE.ORG_MANAGEMENT_ADD)
    }

    const columns: (ColDef & { field: keyof SuperAdminOrgInfo })[] = [
        {
            headerName: localization.getString("status"),
            field: "status",
            flex: 65,
            cellRenderer: ({ value }: { value: SuperAdminOrgInfo["status"] }) => {
                return value ? (
                    <Status
                        type={orgStatusMap[value]}
                        label={localization.getString(labelMap[value])}
                    />
                ) : (
                    ""
                )
            },
            tooltipValueGetter: (tooltipProps: ITooltipParams) => {
                const value = tooltipProps.value as SuperAdminOrgInfo["status"]

                return value ? localization.getString(labelMap[value]) : ""
            },
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: localization.getString("orgName"),
            field: "orgName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: NameCellRenderer,
            comparator: AgGridUtil.alphaBetComparator,
            flex: 150,
        },
        {
            headerName: localization.getString("edition"),
            flex: 100,
            field: "edition",
            cellRenderer: (cellParams: { value: SuperAdminOrgInfo["edition"] }) => {
                if (!cellParams.value) return "-"
                return localization.getString(cellParams.value)
            },
            sortable: false,
        },
        {
            headerName: localization.getString("type"),
            field: "type",
            flex: 90,
            comparator: AgGridUtil.alphaBetComparator,
            valueFormatter: AgGridUtil.nullableStringFormatter,
        },
        {
            headerName: localization.getString("lastUpdated"),
            field: "lastUpdatedAt",
            flex: 100,
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    return (
        <div className={styles.gridWithToolbar}>
            <section className={styles.headerSection}>
                <SearchInput
                    placeholder={localization.getString("search")}
                    onChangeValue={setSearch}
                    value={search}
                />
                <Button className={styles.add} brand onClick={addOrg}>
                    <FontAwesomeIcon className={styles.addIcon} icon={faPlus} />
                    {localization.getString("addOrg")}
                </Button>
            </section>
            <Loader isLoading={isOrgsLoading} center medium>
                {!isOrgsError && (
                    <div className={styles.gridContainer}>
                        <Grid
                            onGridReady={onGridReady}
                            columnDefs={columns}
                            rowData={data}
                            pagination
                            context={{ history }}
                        />
                    </div>
                )}
                {typeof orgsError === "string" && <ErrorBanner>{orgsError}</ErrorBanner>}
            </Loader>
        </div>
    )
}

interface NameCellRendererProps {
    value: string
    data: SuperAdminOrgInfo
}

function NameCellRenderer(props: NameCellRendererProps) {
    const localization = useServiceLocalization()
    return (
        <Link
            className={styles.orgName}
            to={formatRoutePath(ROUTE.ORG_MANAGEMENT_DETAILS, { id: encodeID(props.data.id) })}
        >
            {props.value}
            {props.data.isSonicWallProvisioned && (
                <InformationLabel>
                    {localization.getString("sonicWallProvisioned")}
                </InformationLabel>
            )}
        </Link>
    )
}
