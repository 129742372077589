import React from "react"
import { Route, Switch } from "react-router-dom"
import { CloudResourceList } from "../../pre-v3/views/discovered/inventory/list/CloudResourceList.component"
import { CloudResourceOverview } from "../../pre-v3/views/discovered/inventory/overview/CloudResourceOverview.component"
import { PublishCloudResource } from "../../pre-v3/views/discovered/inventory/publish/PublishCloudResource"
import { ROUTE } from "../../routes"

export const IaasDiscovery = () => {
    return (
        <Switch>
            <Route path={ROUTE.IAAS_DISCOVERY_PUBLISH} component={PublishCloudResource} />
            <Route path={ROUTE.IAAS_DISCOVERY_DETAILS} component={CloudResourceOverview} />
            <Route path={ROUTE.IAAS_DISCOVERY} component={CloudResourceList} />
        </Switch>
    )
}
