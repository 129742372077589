export class PatternUtil {
    public static CIDR_REGEX: RegExp =
        /[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\/[0-9]{1,2},*/
    public static NO_SPECIAL_CHARACTERS = /^[A-Za-z0-9 \-]*$/
    public static RFC_1918_CIDR: RegExp =
        /^(10(?:\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\/(?:[8-9]|1[0-9]|2[0-9]|3[0-2])$)|(172\.(?:1[6-9]|2\d|3[0-1])(?:\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}\/(?:1[2-9]|2[0-9]|3[0-2])$)|(192\.168(?:\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}\/(?:1[6-9]|2[0-9]|3[0-2])$)/
    public static ORG_NAME: RegExp = /^[a-z][a-z0-9\-]{2,}[a-z0-9]$/
    public static PASSWORD =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};':"\\\|\/,\.<>\?]).*/
    public static POSITIVE_NUMBER_REGEX: RegExp = /^\d*$/
    public static SPACE: RegExp = / /
    public static IPV4_ADDRESS: RegExp =
        /(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3},*/
    public static I8N_TEMPLATE: RegExp = /{\s*(\S+)\s*}/g
    public static I8N_TEMPLATE_WITH_CHILDREN: RegExp = /<\s*(\S+)\s*>\s*([^<]*)\s*<\/\s*\S+\s*>/g
    public static I8N_TEMPLATE_REPLACEMENT = new RegExp(
        `${PatternUtil.I8N_TEMPLATE_WITH_CHILDREN.source}|${PatternUtil.I8N_TEMPLATE.source}`,
        "g"
    )
    public static I8N_TEMPLATE_REPLACEMENT_WITHOUT_GROUP = new RegExp(
        `(${PatternUtil.removeGroupsInRegex(PatternUtil.I8N_TEMPLATE_REPLACEMENT).source})`,
        "g"
    )
    public static removeGroupsInRegex(regex: RegExp) {
        return new RegExp(regex.source.replace(/[\(\)]/g, ""))
    }

    public static DOCKER_CONTAINER_NAME: RegExp = /[a-zA-Z0-9][a-zA-Z0-9_.-]*/
    public static getSanitizedDockerName(str: string): string {
        return (
            PatternUtil.DOCKER_CONTAINER_NAME.exec(str.replace(/[^a-zA-Z0-9_.-]*/g, ""))?.[0] || ""
        )
    }
    public static UNIX_FILE_PATH = /^(?!\/).*/
    public static WINDOWS_FILE_PATH = /^(?!\\).*/
    public static WORDS = /[A-Z]?[a-z]+|[A-Z]+(?![a-z])|\d+/g
    public static DOUBLE_QUOTES = /"/g
    public static SHA256 = /\b[A-Fa-f0-9]{64}\b/
    public static NO_SPACE: RegExp = /^\S+$/
    public static STARTS_WITH_FORWARD_SLASH = /^\/.*/
    public static HOST_SCHEME_PORT = /https?:\/\/.+:\d{1,5}/
    public static HTTP_URL =
        /^https?:\/\/(?:www\.)?[\-a-zA-Z0-9:%._+~#=]{1,256}\.[a-zA-Z0-9]{1,6}\b(?:[\-a-zA-Z0-9:%_+.~#?&\/=]*)$/
    public static SERVICE_NAME = /^[A-Za-z0-9\-_=.,]*$/
    public static ROLE_NAME = /^[\w\-]+$/
    public static HTTPS_REGEX = /^(https?):\/\/(www)?/i
    public static DOMAIN = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/
    public static MAC_ADDRESS =
        /^([0-9a-fA-F]{2}[:-]){5}[0-9a-fA-F]{2}(,\s*([0-9a-fA-F]{2}[:-]){5}[0-9a-fA-F]{2})*\s*,?$/
    public static FQDN = /^((?!-)[a-zA-Z0-9-_]{0,62}(?<!-)\.)+[a-zA-Z]{0,62},*$/
    public static NUMBER_LETTER = /\b([0-9a-fA-F])\b/
    public static VALID_PORTS = /^(?:\d+|\d+-\d+)(?:,\s*(?:\d+|\d+-\d+))*$/
    public static NO_URL_SPECIAL_CHARACTERS = /^[^<>"'#%{}|\\^`\[\]@:/?&=\s]+$/
}
