import React from "react"
import {
    AccessTierGroupNetwork,
    AccessTierNetwork,
    Application,
    ConnectorNetwork,
    emptyGlobalEdgeNetworkSetting,
    emptyPublicExcludeIncludeInfo,
    GlobalEdgeNetworkSetting,
    Network,
    NetworkSetting,
    NetworkSettingType,
    PrivateEdgeATGNetworkSetting,
    PrivateEdgeNetworkSetting,
    ServiceTunnelDetail,
} from "../../../v3/services/ServiceTunnelV2.service"
import { GlobalEdgeNetworkSettingCard } from "./GlobalEdgeNetworkSettingCard.component"
import { FormRow } from "../../../v3/components/form/FormRow.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Input } from "../../../v3/components/input/Input.component"
import { PrivateEdgeNetworkSettingCard } from "./PrivateEdgeNetworkSettingCard.component"
import { ServiceTunnelNetworkSelect } from "./ServiceTunnelNetworkSelect.component"

import styles from "./ServiceTunnelForm.module.scss"
import { Policy } from "../../../v3/services/Policy.service"
import { AssignmentCard } from "./AssignmentCard.component"

export interface Props {
    serviceTunnel: ServiceTunnelDetail
    onNetworkSettingsChange(networkSetting: NetworkSetting[]): void
    onGeneralInfoChange(generalInfo: GeneralInfo): void
    applications: Application[]
    disabled?: boolean
    networks: Network[]
    policies: Policy[]
    hideApplication: boolean
    hideAccessTier: boolean
}

export function ServiceTunnelForm(props: Props) {
    const localization = useServiceLocalization()
    const [selectedNetwork, setSelectedNetwork] = React.useState<Network[]>(
        mapToTunnelableNetwork(props.serviceTunnel) || []
    )

    const onAddNetworkSetting = (newNetwork: Network[]) => {
        const selectedNetwork = newNetwork[newNetwork.length - 1]

        switch (selectedNetwork.type) {
            case "connector":
                addConnectorNetworkSetting(selectedNetwork)
                break
            case "accesstier":
                addAccessTierNetworkSetting(selectedNetwork)
                break
            case "accessTierGroup":
                addAccessTierGroupNetworkSetting(selectedNetwork)
                break
        }
    }

    const addAccessTierGroupNetworkSetting = (selectedNetwork: AccessTierGroupNetwork) => {
        const newPrivateEdgeNetworkSetting: NetworkSetting = {
            type: NetworkSettingType.PRIVATE_EDGE_ATG,
            network: selectedNetwork,
            ...emptyPublicExcludeIncludeInfo,
        }

        const newNetworkSettings = [
            newPrivateEdgeNetworkSetting,
            ...props.serviceTunnel.networkSettings,
        ]

        props.onNetworkSettingsChange(newNetworkSettings)
    }

    const addAccessTierNetworkSetting = (selectedNetwork: AccessTierNetwork) => {
        const newPrivateEdgeNetworkSetting: NetworkSetting = {
            type: NetworkSettingType.PRIVATE_EDGE,
            network: selectedNetwork,
            ...emptyPublicExcludeIncludeInfo,
        }

        const newNetworkSettings = [
            newPrivateEdgeNetworkSetting,
            ...props.serviceTunnel.networkSettings,
        ]

        props.onNetworkSettingsChange(newNetworkSettings)
    }

    const addConnectorNetworkSetting = (selectedNetwork: ConnectorNetwork) => {
        const currentGlobalSettings = props.serviceTunnel.networkSettings.find(
            (setting): setting is GlobalEdgeNetworkSetting =>
                setting.type === NetworkSettingType.GLOBAL_EDGE
        )

        if (!currentGlobalSettings) {
            const newGlobalEdgeNetworkSetting = emptyGlobalEdgeNetworkSetting
            newGlobalEdgeNetworkSetting.networks = [selectedNetwork]

            const newNetworkSettings = [
                newGlobalEdgeNetworkSetting,
                ...props.serviceTunnel.networkSettings,
            ]
            props.onNetworkSettingsChange(newNetworkSettings)
        } else {
            const updateGlobalEdgeNetworkSetting = {
                ...currentGlobalSettings,
                networks: [selectedNetwork, ...currentGlobalSettings.networks],
            }
            const newNetworkSettings = [
                updateGlobalEdgeNetworkSetting,
                ...props.serviceTunnel.networkSettings.filter(
                    (setting) => setting !== currentGlobalSettings
                ),
            ]
            props.onNetworkSettingsChange(newNetworkSettings)
        }
    }

    const onRemoveNetworkSetting = (networkSetting: NetworkSetting) => {
        const newNetWorkSettings = props.serviceTunnel.networkSettings.filter(
            (setting) => setting !== networkSetting
        )

        props.onNetworkSettingsChange(newNetWorkSettings)
    }

    const onPrivateEdgeNetworkSettingChange = (
        updatedNetworkSetting: PrivateEdgeNetworkSetting | PrivateEdgeATGNetworkSetting
    ) => {
        let updatedNetworkIndex

        if (updatedNetworkSetting.type === NetworkSettingType.PRIVATE_EDGE_ATG) {
            updatedNetworkIndex = props.serviceTunnel.networkSettings.findIndex(
                (svcNetworkSetting) =>
                    svcNetworkSetting.type === NetworkSettingType.PRIVATE_EDGE_ATG &&
                    updatedNetworkSetting.network?.id === svcNetworkSetting.network?.id
            )
        } else {
            updatedNetworkIndex = props.serviceTunnel.networkSettings.findIndex(
                (svcNetworkSetting) =>
                    svcNetworkSetting.type === NetworkSettingType.PRIVATE_EDGE &&
                    updatedNetworkSetting.network?.id === svcNetworkSetting.network?.id
            )
        }

        let newNetworkSettings: NetworkSetting[] = updateNetworkSettings(
            updatedNetworkSetting,
            updatedNetworkIndex
        )
        props.onNetworkSettingsChange(newNetworkSettings)
    }

    const onGlobalEdgeNetworkSettingChange = (updatedNetworkSetting: GlobalEdgeNetworkSetting) => {
        const updatedNetworkIndex = props.serviceTunnel.networkSettings.findIndex(
            (svcNetworkSetting) => svcNetworkSetting.type === NetworkSettingType.GLOBAL_EDGE
        )
        let newNetworkSettings: NetworkSetting[] = updateNetworkSettings(
            updatedNetworkSetting,
            updatedNetworkIndex
        )
        props.onNetworkSettingsChange(newNetworkSettings)
    }

    function updateNetworkSettings(
        updatedNetworkSetting: NetworkSetting,
        networkSettingIndex: number
    ): NetworkSetting[] {
        const currentNetworkSettings = [...props.serviceTunnel.networkSettings]

        currentNetworkSettings[networkSettingIndex] = updatedNetworkSetting
        return currentNetworkSettings
    }

    React.useEffect(() => {
        setSelectedNetwork(mapToTunnelableNetwork(props.serviceTunnel))
    }, [props.serviceTunnel.networkSettings])

    return (
        <>
            <GeneralInformation
                generalInfo={{
                    name: props.serviceTunnel.name,
                    description: props.serviceTunnel.description,
                }}
                onGeneralInfoChange={props.onGeneralInfoChange}
                disabled={props.disabled}
            />

            {props.disabled && (
                <AssignmentCard
                    serviceTunnel={props.serviceTunnel}
                    policies={props.policies}
                    disabled
                />
            )}

            <div className={styles.card}>
                <div className={styles.cardTopHeader}>
                    <div className={styles.titleSide}>
                        <div className={styles.heading}>
                            {localization.getString("networkSettings")}
                        </div>
                        <div className={styles.subHeading}>
                            {localization.getString("addNetworkThatWillBeAccessibleViaThisTunnel")}
                        </div>
                    </div>
                    <ServiceTunnelNetworkSelect
                        networks={props.networks}
                        initialValue={mapToTunnelableNetwork(props.serviceTunnel)}
                        onChange={onAddNetworkSetting}
                        disabled={props.disabled}
                        hideLabel
                        selected={selectedNetwork}
                        setSelected={setSelectedNetwork}
                        hideAccessTier={props.hideAccessTier}
                    />
                </div>

                {props.serviceTunnel.networkSettings.map((networkSetting) => {
                    if (networkSetting.type === NetworkSettingType.GLOBAL_EDGE) {
                        return (
                            <GlobalEdgeNetworkSettingCard
                                key={networkSetting.networks
                                    .map((connector) => connector.id)
                                    .join("")}
                                networkSetting={networkSetting}
                                onNetworkSettingChange={onGlobalEdgeNetworkSettingChange}
                                applications={props.applications}
                                disabled={props.disabled}
                                hideApplication={props.hideApplication}
                            />
                        )
                    }

                    if (!props.hideAccessTier) {
                        return (
                            <PrivateEdgeNetworkSettingCard
                                key={networkSetting.network.id}
                                networkSetting={networkSetting}
                                onNetworkSettingChange={onPrivateEdgeNetworkSettingChange}
                                onRemoveAccessTier={onRemoveNetworkSetting}
                                applications={props.applications}
                                disabled={props.disabled}
                                hideApplication={props.hideApplication}
                            />
                        )
                    }

                    return null
                })}
            </div>
        </>
    )
}

export interface GeneralInfo {
    name: string
    description?: string
}

interface GeneralInfoProps {
    generalInfo: GeneralInfo
    disabled?: boolean
    onGeneralInfoChange(generalInfo: GeneralInfo): void
}

function GeneralInformation(props: GeneralInfoProps): JSX.Element {
    const localization = useServiceLocalization()

    const onGeneralInfoNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onGeneralInfoChange({
            ...props.generalInfo,
            name: event.target.value,
        })
    }

    const onGeneralInfoDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onGeneralInfoChange({
            ...props.generalInfo,
            description: event.target.value,
        })
    }

    return (
        <div className={styles.card}>
            <div className={styles.heading}>{localization.getString("generalInformation")}</div>
            <FormRow
                label={localization.getString("nameThisServiceTunnel")}
                description={localization.getString("thisIsTheNameDisplayedInTheCseAppForEndUsers")}
                htmlFor={Id.NAME}
            >
                <Input
                    id={Id.NAME}
                    value={props.generalInfo.name}
                    type="text"
                    name="name"
                    placeholder={localization.getString("name")}
                    disabled={props.disabled}
                    required
                    onChange={onGeneralInfoNameChange}
                />
            </FormRow>

            <FormRow
                label={localization.getString("description")}
                description={localization.getString("thisIsTheDescriptionDisplayedInCseApp")}
            >
                <Input
                    value={props.generalInfo.description ?? ""}
                    type="text"
                    name="description"
                    onChange={onGeneralInfoDescriptionChange}
                    placeholder={localization.getString("description")}
                    disabled={props.disabled}
                />
            </FormRow>
        </div>
    )
}

const mapToTunnelableNetwork = (serviceTunnel: ServiceTunnelDetail): Network[] => {
    const connectors =
        serviceTunnel.networkSettings
            .find(
                (networkSetting): networkSetting is GlobalEdgeNetworkSetting =>
                    networkSetting.type === NetworkSettingType.GLOBAL_EDGE
            )
            ?.networks.map<Network>((connector) => {
                return {
                    id: connector.id,
                    name: connector.name,
                    privateIpRanges: connector.privateIpRanges,
                    privateDomains: connector.privateDomains,
                    type: "connector",
                    clusterName: connector.clusterName,
                }
            }) ?? []

    const accessTiers =
        serviceTunnel.networkSettings
            .filter(
                (networkSetting): networkSetting is PrivateEdgeNetworkSetting =>
                    networkSetting.type === NetworkSettingType.PRIVATE_EDGE
            )
            .map<Network>((networkSetting) => {
                return {
                    id: networkSetting.network.id,
                    name: networkSetting.network.name,
                    privateIpRanges: networkSetting.network.privateIpRanges,
                    privateDomains: networkSetting.network.privateDomains,
                    type: "accesstier",
                    clusterName: networkSetting.network.clusterName,
                }
            }) ?? []

    const accessTierGroups =
        serviceTunnel.networkSettings
            .filter(
                (networkSetting): networkSetting is PrivateEdgeATGNetworkSetting =>
                    networkSetting.type === NetworkSettingType.PRIVATE_EDGE_ATG
            )
            .map<Network>((networkSetting) => {
                return {
                    id: networkSetting.network.id,
                    name: networkSetting.network.name,
                    privateIpRanges: networkSetting.network.privateIpRanges,
                    privateDomains: networkSetting.network.privateDomains,
                    type: "accessTierGroup",
                    clusterName: networkSetting.network.clusterName,
                }
            }) ?? []

    return [...connectors, ...accessTiers, ...accessTierGroups]
}

enum Id {
    NAME = "name",
}
