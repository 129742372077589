import { faFilter } from "@fortawesome/pro-regular-svg-icons"
import {
    ColDef,
    GridApi,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
} from "ag-grid-community"
import React, { useRef, useState } from "react"

import { Grid } from "../../../../../pre-v3/components/grid/Grid.component"
import {
    LocalizationService,
    useServiceLocalization,
} from "../../../../../pre-v3/services/localization/Localization.service"
import { IconType } from "../../../../../pre-v3/services/ActionBar.service"
import AgGridUtil, { FilterModel, SortBy } from "../../../../../pre-v3/utils/AgGrid.util"
import { SelectItem } from "../../../../../pre-v3/utils/SelectValue.util"
import { UrlUtil, encodeID } from "../../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../../routes"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import { MenuButton } from "../../../../components/menu/menu-button/MenuButton.component"
import { Menu } from "../../../../components/menu/Menu.component"
import { Status } from "../../../../components/status/Status.component"
import {
    ApplicationType,
    PublicResource,
    PublicResourceStatus,
    getPublicResources,
    iconMap,
    labelMap,
    statusMap,
} from "../../../../services/PublicResource.service"
import { StringUtil } from "../../../../../pre-v3/utils/String.util"
import { MenuItemProps } from "../../../../components/menu/menu-item/MenuItem.component"
import {
    PillContainer,
    PillProps,
} from "../../../../components/pill-container/PillContainer.component"
import { ErrorBanner } from "../../../../components/banner/Banner.component"
import { RowTitle } from "../../../../components/grid/RowTitle.component"
import { useDebouncedCallback } from "../../../../../pre-v3/utils/UseDebouncedCallback.hook"
import styles from "./PublicResourceList.module.scss"
import { PageHeading } from "../../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { CreateAppModal } from "../add/CreateAppModal.component"
import { Modal } from "../../../../../v4/components/modal/Modal.component"

interface Props {
    canCreateCustomApp?: boolean
}

export function PublicResourceList(props: Props) {
    const ls: LocalizationService = useServiceLocalization()
    const grid = useRef<GridApi>()

    const [statusFilterLabel, setStatusFilterLabel] = React.useState<string>(
        ls.getString("allStatuses")
    )

    const [appTypeFilterLabel, setAppTypeFilterLabel] = React.useState<string>(
        ls.getString("allApplications")
    )
    const initialModel: FilterModel = UrlUtil.readFilter()
    const [search, setSearch] = React.useState<string>(
        initialModel.name?.filter ? initialModel.name.filter : ""
    )
    const [error, setError] = useState<string>("")
    const [showModal, setShowModal] = useState(false)

    function onFilterChange(newFilter: FilterModel): void {
        const updatedModel: FilterModel = {
            ...initialModel,
            ...newFilter,
        }
        setFilterModel(updatedModel)
        UrlUtil.writeFilter(updatedModel)
    }

    const setFilterModel = useDebouncedCallback((filter: FilterModel) => {
        if (grid.current) {
            grid.current.setFilterModel(filter)
        }
    }, [])

    const onGridReady = (event: GridReadyEvent) => {
        grid.current = event.api
        onFilterChange(initialModel)
        fetchData()
    }

    function onSearchChange(updatedSearch: string) {
        const filter: FilterModel = { name: { filter: updatedSearch } }
        onFilterChange(filter)
        setSearch(updatedSearch)
    }

    const callBack = (error?: string) => {
        if (error) {
            setError(error)
        }
    }

    function fetchData() {
        if (grid.current) {
            grid.current.setServerSideDatasource(DataSource(grid.current, callBack))
        }
    }

    function onRefresh() {
        if (grid.current) {
            grid.current.refreshServerSide()
            grid.current.deselectAll()
        }
    }

    const columns: ColDef[] = [
        {
            headerName: ls.getString("status"),
            field: "status",
            flex: 40,
            cellRenderer: StatusCellRenderer,
            filter: "agTextColumnFilter",
            sortable: true,
        },
        {
            headerName: ls.getString("type"),
            field: "type",
            flex: 30,
            sortable: false,
            filter: "agTextColumnFilter",
        },
        {
            headerName: ls.getString("name"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 70,
            cellRenderer: NameCellRenderer,
            filter: "agTextColumnFilter",
            comparator: AgGridUtil.alphaBetComparator,
            sortable: true,
        },
        {
            headerName: ls.getString("category"),
            field: "category",
            flex: 70,
            sortable: false,
        },
        {
            headerName: ls.getString("uniqueDevices"),
            field: "numberOfUserDevice",
            flex: 30,
            sortable: true,
            sort: "desc",
        },
    ]

    const onCreateApp = () => {
        setShowModal(true)
    }

    const statusFilterOptions: SelectItem[] = [
        {
            displayName: ls.getString("allStatuses"),
            value: "",
        },
        {
            displayName: ls.getString("protected"),
            value: PublicResourceStatus.PROTECTED,
        },
        {
            displayName: ls.getString("ignored"),
            value: PublicResourceStatus.IGNORED,
        },
        {
            displayName: ls.getString("discovered"),
            value: PublicResourceStatus.DISCOVERED,
        },
    ]

    const appTypeFilterOptions: SelectItem[] = [
        {
            displayName: ls.getString("allApplications"),
            value: "",
        },
        {
            displayName: ls.getString("custom"),
            value: ApplicationType.CUSTOM,
        },
        {
            displayName: ls.getString("saas"),
            value: ApplicationType.SAAS,
        },
    ]
    function closeModal() {
        setShowModal(false)
    }

    if (error) {
        return (
            <div className={styles.container}>
                <ErrorBanner>{error}</ErrorBanner>
            </div>
        )
    }

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            <Modal open={showModal} onClose={closeModal} title={ls.getString("createACustomApp")}>
                <CreateAppModal closeModal={closeModal} />
            </Modal>
            <header className={styles.header}>
                <div className={styles.heading}>
                    <PageHeading id={Id.HEADING}>{ls.getString("appDiscovery")}</PageHeading>
                    <p>{ls.getString("publicResourcesDesc")}</p>
                </div>
                <Button
                    icon={IconType.REDO}
                    onClick={onRefresh}
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                    aria-label={ls.getString("refresh")}
                />
            </header>
            <div className={styles.toolbar}>
                <div className={styles.leftTools}>
                    <SearchInput
                        value={search}
                        onChangeValue={onSearchChange}
                        className={styles.search}
                        placeholder={ls.getString("searchByName")}
                    />
                    <MenuButton
                        className={styles.filterButton}
                        label={statusFilterLabel}
                        icon={faFilter}
                    >
                        <Menu
                            items={statusFilterOptions.map((option): MenuItemProps => {
                                return {
                                    label: option.displayName,
                                    onClick: () => {
                                        onFilterChange({ status: { filter: option.value } })
                                        setStatusFilterLabel(
                                            StringUtil.capitalize(option.displayName)
                                        )
                                    },
                                }
                            })}
                        />
                    </MenuButton>
                    <MenuButton
                        className={styles.filterButton}
                        label={appTypeFilterLabel}
                        icon={faFilter}
                    >
                        <Menu
                            items={appTypeFilterOptions.map((option): MenuItemProps => {
                                return {
                                    label: option.displayName,
                                    onClick: () => {
                                        onFilterChange({ type: { filter: option.value } })
                                        setAppTypeFilterLabel(
                                            StringUtil.capitalize(option.displayName)
                                        )
                                    },
                                }
                            })}
                        />
                    </MenuButton>
                </div>
                {props.canCreateCustomApp && (
                    <Button
                        type="button"
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        icon={IconType.PLUS}
                        onClick={onCreateApp}
                    >
                        {ls.getString("createApp")}
                    </Button>
                )}
            </div>
            <Grid onGridReady={onGridReady} columnDefs={columns} serverSidePagination />
        </section>
    )
}

function DataSource(gridApi: GridApi, callBack: Function): IServerSideDatasource {
    const getRows = (params: IServerSideGetRowsParams) => {
        const skipLimit: { skip: number; limit: number } = {
            skip: 0,
            limit: 10_000,
        }
        if (params.request.startRow) {
            skipLimit.skip = params.request.startRow
        }

        if (params.request.endRow) {
            skipLimit.limit = params.request.endRow - skipLimit.skip
        }
        const sortModel: SortBy[] = params.request.sortModel
        const filterModel: FilterModel = params.request.filterModel

        gridApi.showLoadingOverlay()
        getPublicResources({ ...skipLimit, sortModel, filterModel }).then(
            (res) => {
                const apps = res.data
                params.success({ rowData: apps, rowCount: apps.length })
                gridApi.hideOverlay()
                if (res.total === 0) {
                    gridApi.showNoRowsOverlay()
                }
            },
            (error) => {
                callBack(error)
                params.fail()
                gridApi.showNoRowsOverlay()
            }
        )
    }

    return { getRows }
}
interface CellRendererProps {
    value?: string
    data: PublicResource
}

export function SensitivityCellRenderer(props: CellRendererProps) {
    if (props.data.sensitivity.length === 0) {
        return "-"
    }
    return (
        <PillContainer
            className={styles.pillContainer}
            pills={props.data.sensitivity.map((app, appIndex): PillProps => {
                const appId = `${appIndex}`

                return {
                    id: appId,
                    label: app,
                    disabled: true,
                }
            })}
        />
    )
}

export function StatusCellRenderer(props: CellRendererProps) {
    const ls = useServiceLocalization()
    if (!props.data.status) {
        return props.value
    }
    return (
        <Status
            type={statusMap[props.data.status]}
            label={ls.getString(labelMap[props.data.status])}
            icon={iconMap[props.data.status]}
        />
    )
}

export function NameCellRenderer(props: CellRendererProps) {
    if (!props.data.id) {
        return props.value
    }

    return (
        <RowTitle
            title={props.value || "-"}
            route={formatRoutePath(ROUTE.APP_DISCOVERY_DETAILS, {
                id: encodeID(props.data.id),
            })}
            image={props.data.logoBitmap}
        />
    )
}

enum Id {
    HEADING = "heading",
}
