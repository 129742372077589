import { ColDef, ICellRendererParams, ValueGetterParams } from "ag-grid-community"
import * as React from "react"
import styles from "./ManagedBypassDomainGrid.module.scss"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import classNames from "classnames"
import { Icon, IconType } from "../../../../components/icon/Icon.component"
import { Loader } from "../../../components/loader/Loader.component"
import { Domain } from "../../../services/UrlFiltering.service"
import { Modal } from "../../../../v4/components/modal/Modal.component"

import {
    ManageDomainModalType,
    ManagedBypassDomainModal,
} from "./ManagedBypassDomainModal.component"

interface Props {
    domains: Domain[]
    className?: string
    editable?: boolean
    isDataLoading?: boolean
}

export function ManagedBypassDomainGrid(props: Props) {
    const localization = useServiceLocalization()
    const totalDomains: number = props.domains?.length || 0

    const columns: ColDef[] = [
        {
            headerName: localization.getString(
                "somethingCount",
                localization.getString("domains"),
                totalDomains.toString()
            ),
            field: "domains.domain",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            valueGetter: (params: ValueGetterParams): string => {
                return params.data.domain
            },
            flex: 75,
            comparator: AgGridUtil.alphaBetComparator,
        },
    ]

    if (props.editable) {
        columns.push({
            width: 120,
            cellRenderer: ActionsCellRenderer,
            sortable: false,
        })
    }

    return (
        <div className={classNames(styles.table, props.className)}>
            <Loader isLoading={props.isDataLoading} center medium>
                <Grid
                    rowData={props.domains}
                    columnDefs={columns}
                    pagination
                    paginationPageSize={10}
                    className={styles.domainsTable}
                />
            </Loader>
        </div>
    )
}

function ActionsCellRenderer(props: ICellRendererParams) {
    const selectedDomain = props.data
    // const modalService = new ModalService()
    const localization = useServiceLocalization()
    const [modalOpen, setModalOpen] = React.useState(false)
    const [modalType, setModalType] = React.useState<ManageDomainModalType>(
        ManageDomainModalType.EDIT
    )

    function openAddDomainModal(type: ManageDomainModalType) {
        setModalType(type)
        setModalOpen(true)
    }

    function closeModal() {
        setModalOpen(false)
    }

    return (
        <div className={styles.actions}>
            <button
                className={styles.actionButton}
                onClick={() => openAddDomainModal(ManageDomainModalType.EDIT)}
            >
                <Icon icon={IconType.PEN} />
            </button>
            <button
                className={styles.actionButton}
                onClick={() => openAddDomainModal(ManageDomainModalType.DELETE)}
            >
                <Icon icon={IconType.TRASH} />
            </button>

            <Modal
                open={modalOpen}
                onClose={closeModal}
                title={localization.getString(
                    "somethingManagedBypassDomain",
                    localization.getString(
                        modalType === ManageDomainModalType.EDIT ? "edit" : "delete"
                    )
                )}
            >
                <ManagedBypassDomainModal
                    modalType={modalType}
                    domain={selectedDomain}
                    closeModal={closeModal}
                    key={`${localization.getString(
                        "somethingManagedBypassDomain",
                        localization.getString("add")
                    )}_${modalType}`}
                />
            </Modal>
        </div>
    )
}
