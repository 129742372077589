import React from "react"
import styles from "./SettingsTab.module.scss"
import { Switch } from "../../../components/switch/Switch.component"
import { useServiceLinks } from "../../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { ManagedBypassDomainGrid } from "./ManagedBypassDomainGrid.component"
import {
    useGetPacFile,
    useGetAdminManagedBypassDomains,
    useGetBanyanManagedBypassDomains,
    useToggleUrlFiltering,
    useUploadPacFile,
} from "../../../services/UrlFiltering.service"
import classnames from "classnames"
import {
    ManageDomainModalType,
    ManagedBypassDomainModal,
} from "./ManagedBypassDomainModal.component"
import { Banner, Variant } from "../../../../components/banner/Banner.component"
import { Link, LinkElement } from "../../../../components/link/Link.component"
import { Icon } from "../../../../components/icon/Icon.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"
import { Input } from "../../../components/input/Input.component"
import { Form } from "../../../components/form/Form.component"
import { Modal } from "../../../../v4/components/modal/Modal.component"
import { ErrorToast, SuccessToast, ToastApi } from "../../../../components/toast/Toast.components"
import { Loader } from "../../../components/loader/Loader.component"
import { Upsell } from "../../../../shared/upsell/Upsell.component"
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags.hook"

interface SettingsTabProps {
    isUrlFilteringEnabled: boolean
}

export function SettingsTab(props: SettingsTabProps) {
    const {
        data: featureFlags,
        status: featureFlagsStatus,
        error: featureFlagsError,
    } = useFeatureFlags()
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()
    const [urlFilteringEnabled, setUrlFilteringEnabled] = React.useState<boolean>(
        props.isUrlFilteringEnabled
    )
    const { data: adminManagedDomains, isFetching: isFetchingBanyanDomains } =
        useGetAdminManagedBypassDomains()
    const { data: banyanManagedDomains, isFetching: isFetchingAdminDomains } =
        useGetBanyanManagedBypassDomains()
    const { data: pacFile } = useGetPacFile()
    const [anchorHref] = React.useState<string>("")
    const downloadRef = React.useRef<HTMLAnchorElement>(null)
    const successToastRef = React.useRef<ToastApi>(null)
    const errorToastRef = React.useRef<ToastApi>(null)
    const [pacFileData, setPacFileData] = React.useState<File | null>(null)
    const { mutateAsync: uploadPacFile } = useUploadPacFile({
        onSuccess: () =>
            successToastRef.current?.openToast(
                localization.getString("pacFileWasUploadedSuccessfully")
            ),
    })
    const { mutateAsync: toggleUrlFiltering, isLoading: isDataLoading } = useToggleUrlFiltering({
        onError: () => {
            errorToastRef.current?.openToast(
                localization.getString("failedToUpdateUrlFilteringStatus")
            )
        },
    })

    const [showModal, setShowModal] = React.useState(false)

    function openAddDomainModal() {
        setShowModal(true)
    }

    function closeModal() {
        setShowModal(false)
    }

    async function checkDownloadPacFile() {
        if (pacFile && downloadRef.current) downloadPacFile(pacFile, downloadRef.current)
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) return
        setPacFileData(event.target.files[0])
    }

    const onEnableUrlFilteringChange = async (isUrlFilteringEnabled: boolean) => {
        await toggleUrlFiltering([isUrlFilteringEnabled])
        setUrlFilteringEnabled(isUrlFilteringEnabled)
    }

    const onSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()
        if (pacFileData === null) return

        uploadPacFile(pacFileData)
        setPacFileData(null)
    }

    if (featureFlagsStatus === "loading") {
        return <Loader isLoading center medium children />
    }

    if (featureFlagsStatus === "error") {
        return <Banner label={String(featureFlagsError)} variant={Variant.ERROR} />
    }

    if (featureFlags.adminConsole.doShowUpsellForItpSettings) {
        return <Upsell />
    }

    return (
        <section className={styles.settingsTab}>
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
            <Banner
                label={localization.getString("urlFilteringPreviewOnlyFeature")}
                variant={Variant.INFORMATION}
            />

            <div className={styles.card}>
                <div className={styles.downloadPAC}>
                    <div className={styles.title}>
                        <span className={styles.label}>
                            {localization.getString("managePACfile")}
                        </span>
                        <span className={styles.description}>
                            {`${localization.getString("managePACfileDesc")} `}
                            <Link
                                to={linkService.getLink("enablingUrlFiltering")}
                                asElement={LinkElement.LINK}
                            >
                                {localization.getString("learnMore")}
                            </Link>
                        </span>
                    </div>

                    <div className={styles.pacFileButtons}>
                        <Form className={styles.pickerForm} onSubmit={onSubmit}>
                            <div className={styles.filePickerButton}>
                                <Tooltip title={"pick a file"}>
                                    <span>
                                        <label htmlFor="filePicker" className={styles.filePicker}>
                                            <Icon icon={IconType.FILE_EXPORT} />
                                            {pacFileData?.name && (
                                                <span className={styles.filename}>
                                                    {pacFileData?.name}
                                                </span>
                                            )}
                                        </label>
                                    </span>
                                </Tooltip>
                                <Input
                                    id="filePicker"
                                    type="file"
                                    onChange={handleFileChange}
                                    key={pacFileData?.name}
                                />
                            </div>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                icon={IconType.UPLOAD}
                                type="submit"
                                disabled={pacFileData === null}
                            >
                                {localization.getString("upload")}
                            </Button>
                        </Form>
                        {pacFile && (
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                icon={IconType.DOWNLOAD}
                                onClick={() => checkDownloadPacFile()}
                                type="button"
                            >
                                {localization.getString("download")}
                            </Button>
                        )}
                    </div>

                    <a href={anchorHref} ref={downloadRef} hidden>
                        {""}
                    </a>
                </div>

                <div className={styles.switcher}>
                    <span className={styles.label}>
                        {localization.getString("enableUrlFiltering")}
                        <Tooltip title={localization.getString("urlFilteringInfo")}>
                            <span className={styles.infoButton}>
                                <Icon icon={IconType.CIRCLE_INFO} />
                            </span>
                        </Tooltip>
                    </span>
                    <Loader isLoading={isDataLoading} className={styles.loader}>
                        <Switch value={urlFilteringEnabled} onChange={onEnableUrlFilteringChange} />
                    </Loader>
                </div>
            </div>

            {urlFilteringEnabled && (
                <div className={styles.managedDomains}>
                    <div className={classnames(styles.card, styles.managedDomainsCard)}>
                        <div className={styles.header}>
                            <span className={styles.title}>
                                {localization.getString("sonicWallCseManagedBypassDomains")}
                            </span>
                        </div>
                        <ManagedBypassDomainGrid
                            domains={banyanManagedDomains || []}
                            isDataLoading={isFetchingBanyanDomains}
                        />
                    </div>

                    <div className={classnames(styles.card, styles.managedDomainsCard)}>
                        <div className={styles.header}>
                            <span className={styles.title}>
                                {localization.getString("adminManagedBypassDomains")}
                            </span>

                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                icon={IconType.PLUS}
                                onClick={() => openAddDomainModal()}
                            >
                                {localization.getString("addDomain")}
                            </Button>
                        </div>
                        <ManagedBypassDomainGrid
                            domains={adminManagedDomains || []}
                            isDataLoading={isFetchingAdminDomains}
                            editable
                        />
                    </div>
                </div>
            )}
            <Modal
                open={showModal}
                onClose={closeModal}
                title={localization.getString(
                    "somethingManagedBypassDomain",
                    localization.getString("add")
                )}
            >
                <ManagedBypassDomainModal
                    modalType={ManageDomainModalType.ADD}
                    closeModal={closeModal}
                    key={`${localization.getString(
                        "somethingManagedBypassDomain",
                        localization.getString("add")
                    )}_${showModal}`}
                />
            </Modal>
        </section>
    )
}

function downloadPacFile(pac: string, link: HTMLAnchorElement): void {
    try {
        const blobUrl = URL.createObjectURL(
            new Blob([pac], {
                // cspell:ignore proxy-autoconfig
                type: "application/x-ns-proxy-autoconfig",
            })
        )

        link.download = "proxy.pac"
        link.href = blobUrl
        link.click()
        URL.revokeObjectURL(blobUrl)
    } catch (err) {
        console.error(err)
    }
}
