import React from "react"
import { FormRow } from "../../../../v3/components/form/FormRow.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { Input } from "../../../../components/input/Input.component"

import styles from "./TunnelPolicyForm.module.scss"
import { PolicyExtended, PolicySpecExtended } from "../../../../v3/services/Policy.service"
import { AccessGroup } from "../../shared/AccessGroup.component"

export interface Props {
    policy: PolicyExtended
    onGeneralInfoChange(generalInfo: GeneralInfo): void
    onPolicyChange(policy: PolicyExtended): void
    isEditMode?: boolean
    disabled: boolean
}

export function TunnelPolicyForm(props: Props) {
    function onPolicySpecChange(policySpec: PolicySpecExtended) {
        props.onPolicyChange({
            ...props.policy,
            spec: policySpec,
        })
    }

    return (
        <>
            <GeneralInformation
                generalInfo={{
                    name: props.policy.name,
                    description: props.policy.description,
                }}
                onGeneralInfoChange={props.onGeneralInfoChange}
                isEditMode={props.isEditMode}
                disabled={props.disabled}
            />
            <AccessGroup
                policySpec={props.policy.spec}
                onPolicySpecChange={onPolicySpecChange}
                disabled={props.disabled}
            />
        </>
    )
}

export interface GeneralInfo {
    name: string
    description: string
}

interface GeneralInfoProps {
    generalInfo: GeneralInfo
    disabled?: boolean
    isEditMode?: boolean
    onGeneralInfoChange(generalInfo: GeneralInfo): void
}

function GeneralInformation(props: GeneralInfoProps): JSX.Element {
    const localization = useServiceLocalization()

    const onGeneralInfoNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onGeneralInfoChange({
            ...props.generalInfo,
            name: event.target.value,
        })
    }

    const onGeneralInfoDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onGeneralInfoChange({
            ...props.generalInfo,
            description: event.target.value,
        })
    }

    return (
        <div className={styles.card}>
            <div className={styles.heading}>{localization.getString("generalInformation")}</div>
            <FormRow label={localization.getString("nameThisTunnelPolicy")} htmlFor={Id.NAME}>
                <Input
                    id={Id.NAME}
                    value={props.generalInfo.name}
                    type="text"
                    name="name"
                    placeholder={localization.getString("name")}
                    disabled={props.disabled || props.isEditMode}
                    required
                    onChange={onGeneralInfoNameChange}
                />
            </FormRow>

            <FormRow
                htmlFor={Id.DESCRIPTION}
                label={localization.getString(
                    "somethingOptional",
                    localization.getString("description")
                )}
                description={localization.getString(
                    "anOptionalFieldShownInTheWebConsoleToAdminUsersOnlyForYourOwnInternalNotes"
                )}
            >
                <Input
                    id={Id.DESCRIPTION}
                    value={props.generalInfo.description ?? ""}
                    type="text"
                    name="description"
                    onChange={onGeneralInfoDescriptionChange}
                    placeholder={localization.getString("description")}
                    disabled={props.disabled}
                />
            </FormRow>
        </div>
    )
}

enum Id {
    NAME = "tunnelPolicyName",
    DESCRIPTION = "tunnelPolicyDescription",
}
