import React, { FormEvent, ReactNode, useState } from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { Form } from "../../../components/form/Form.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { Input } from "../../../components/input/Input.component"
import {
    ConfigurationValues,
    HostedServiceInfra,
    WebService,
    updateHostedWebsiteConfiguration,
} from "../../../services/HostedService.service"
import {
    RegisteredDomain,
    useGetRegisteredDomains,
} from "../../../services/RegisteredDomain.service"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { useGetClusters } from "../../../services/Cluster.service"
import { ErrorBanner, ErrorBanners } from "../../../components/banner/Banner.component"
import { FormGroup } from "../../../components/form/FormGroup.component"
import { Loader } from "../../../components/loader/Loader.component"
import { HostedWebSummary } from "../summary/HostedWebSummary.component"
import { NameInput } from "../shared/NameInput.component"
import { AccessDetails, AccessDetailsFormValue } from "./AccessDetails.component"
import { PrivateResource, PrivateResourceFormValue } from "./PrivateResource.component"

interface Props {
    initialValue: WebService
    onSubmit?: (data: ConfigurationFormValues) => void
    nextStep?: () => void
    disabled?: boolean
    onCancel?: () => void
    isEdit?: boolean
    onSave?: (data: WebService) => void
    error?: string
    isDataLoading?: boolean
    enableAccessTierGroups: boolean
    canAddRegisteredDomain?: boolean
}

export interface ConfigurationFormValues {
    name: string
    description?: string
    accessDetails: AccessDetailsFormValue
    privateResourceDetails: PrivateResourceFormValue
}

export function Configuration(props: Props) {
    const { disabled, initialValue, nextStep, onCancel, isEdit, onSubmit } = props
    const ls = useServiceLocalization()
    const [serviceName, setServiceName] = useState<string>(initialValue?.name || "")
    const [description, setDescription] = useState<string>(initialValue?.description || "")
    const [accessDetails, setAccessDetails] = useState<AccessDetailsFormValue>()
    const [infra, setInfra] = useState<HostedServiceInfra[]>(initialValue?.infra || [])
    const [selectedRegisteredDomainId, setSelectedRegisteredDomainId] = useState<string>(
        initialValue.registeredDomainId || ""
    )

    const defaultPrivateResourceItem: PrivateResourceFormValue = {
        hostedServiceInfra: initialValue.infra,
        backendDomain: initialValue.backendDomain,
        backendPort: initialValue.backendPort,
        tls: initialValue.tls,
        tlsInsecure: initialValue.tlsInsecure,
        clientCertificate: initialValue.clientCertificate,
    }

    const [privateResource, setPrivateResourceItems] = useState<PrivateResourceFormValue>(
        defaultPrivateResourceItem
    )

    const registeredDomains = useGetRegisteredDomains(props.enableAccessTierGroups)
    const clusters = useGetClusters(props.enableAccessTierGroups)

    function submitFormData(e: FormEvent) {
        e.preventDefault()
        if (!accessDetails) {
            return
        }
        onSubmit?.({
            name: serviceName,
            description: description,
            accessDetails: accessDetails,
            privateResourceDetails: privateResource,
        })
        nextStep?.()
    }

    const errors: ReactNode[] = [
        typeof registeredDomains.error === "string" && registeredDomains.error,
        typeof registeredDomains.error === "string" && registeredDomains.error,
    ]

    function onSave(e: FormEvent) {
        e.preventDefault()
        if (!accessDetails) return

        const configuration: ConfigurationValues = {
            description,
            infra: privateResource.hostedServiceInfra ?? [],
            backendDomain: privateResource.backendDomain ?? "",
            backendPort: privateResource.backendPort ?? 0,
            isTls: privateResource.tls ?? false,
            isTlsInsecure: privateResource.tlsInsecure ?? false,
            hasClientCertificate: privateResource.clientCertificate ?? false,

            domain: accessDetails.domain,
            port: accessDetails.port,
            certificate: accessDetails.certificate,
            registeredDomainId: accessDetails.registeredDomainId,
        }

        props.onSave?.(updateHostedWebsiteConfiguration(initialValue, configuration))
    }

    function onPrivateResourceChange(data: PrivateResourceFormValue) {
        setInfra(data.hostedServiceInfra || [])
        setPrivateResourceItems(data)
    }

    function onAccessDetailsChange(data: AccessDetailsFormValue) {
        setAccessDetails(data)
        setSelectedRegisteredDomainId(data.registeredDomainId || "")
    }

    function onAddRegisteredDomain(domain: RegisteredDomain) {
        setSelectedRegisteredDomainId(domain.id || "")
    }

    const initialNetworkType = React.useMemo(() => {
        if (initialValue.infra.length === 0) {
            return
        }
        return initialValue.infra[0].type
    }, [initialValue])

    return (
        <Loader isLoading={clusters.isLoading || registeredDomains.isLoading}>
            <Form onSubmit={isEdit ? onSave : submitFormData}>
                <FormRow
                    label={ls.getString("whatWouldYouLikeToNameTheService")}
                    description={ls.getString(
                        "thisIsTheNameDisplayedInTheCseAppDirectoryForEndUsers"
                    )}
                >
                    <NameInput
                        placeholder={ls.getString("hostedWebsiteName")}
                        value={serviceName}
                        onChange={(e) => {
                            setServiceName(e.target.value)
                        }}
                        disabled={disabled || isEdit}
                    />
                </FormRow>
                <FormRow
                    label={ls.getString("somethingOptional", ls.getString("description"))}
                    description={ls.getString("thisIsTheDescriptionDisplayedInCseApp")}
                >
                    <Input
                        type="text"
                        placeholder={ls.getString("somethingOptional", ls.getString("description"))}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                        disabled={disabled}
                    />
                </FormRow>
                <PrivateResource
                    clusters={clusters.data ?? []}
                    registeredDomains={registeredDomains.data || []}
                    initialValue={defaultPrivateResourceItem}
                    initialNetworkType={initialNetworkType}
                    onChange={onPrivateResourceChange}
                    disabled={disabled}
                    required
                />

                <AccessDetails
                    selectedRegisteredDomainId={selectedRegisteredDomainId}
                    registeredDomains={registeredDomains.data || []}
                    initialValue={initialValue}
                    disabled={disabled}
                    infra={infra}
                    onChange={onAccessDetailsChange}
                    onAddRegisteredDomain={onAddRegisteredDomain}
                    canAddRegisteredDomain={props.canAddRegisteredDomain}
                />
                <FormGroup label={ls.getString("summary")}>
                    <HostedWebSummary
                        name={serviceName}
                        accessDetails={accessDetails}
                        privateResource={privateResource}
                    />
                </FormGroup>
                <ErrorBanners errors={errors} />
                {!disabled && (
                    <FormButtons
                        rightButtons={
                            <>
                                {onCancel && (
                                    <Button
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        onClick={onCancel}
                                    >
                                        {ls.getString("cancel")}
                                    </Button>
                                )}
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                    type="submit"
                                    loading={props.isDataLoading}
                                >
                                    {isEdit ? ls.getString("save") : ls.getString("next")}
                                </Button>
                            </>
                        }
                    >
                        {props.error && <ErrorBanner>{props.error}</ErrorBanner>}
                    </FormButtons>
                )}
            </Form>
        </Loader>
    )
}
