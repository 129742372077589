import { UseMutationResult, useMutation } from "@tanstack/react-query"

import { SignUpApi } from "../api/SignUp.api"

/**
 * Send an email reminding which the sender to which Organizations they are assigned to
 * @param emailAddress Email address to receive Org Names
 */
export function useSendForgotOrgNameEmail(
    emailAddress: string,
    options?: QueryOptions<void>
): UseMutationResult<void, unknown, void> {
    // TODO: Remove when we delete the previous Analytics service
    const signUpApi = new SignUpApi()

    return useMutation({
        ...options,
        mutationFn: () => {
            return signUpApi.sendForgotOrgNameEmail({ email: emailAddress })
        },
    })
}
