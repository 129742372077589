import React, { FormEvent, useEffect, useState } from "react"

import { Input } from "../../../../../../v3/components/input/Input.component"
import styles from "./UserExpiry.module.scss"
import { ErrorBanner, InfoBanner } from "../../../../../components"
import {
    LocalizationService,
    UserService,
    useServiceLocalization,
    useServiceUser,
} from "../../../../../services"
import { FormUtil } from "../../../../../utils/Form.util"
import { OrgDetails } from "../../../../../api/User.api"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../components/button/Button.component"
import { FormRow } from "../../../../../../components/form/FormRow.component"

export function UserExpiry(props: UserExpiryProps) {
    const ls: LocalizationService = useServiceLocalization()
    const userService: UserService = useServiceUser()
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [value, setValue] = useState("")

    useEffect(() => {
        userService.getOrgDetails().then((orgDetails: OrgDetails) => {
            if (orgDetails.EnduserInactivityThresholdDays) {
                setValue(orgDetails.EnduserInactivityThresholdDays)
            }
        })
    }, [userService])

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setError(false)
        setSuccess(false)
        setSubmitting(true)
        const days: string = FormUtil.getFieldValue(event, "userAndDeviceInactivity")
        userService
            .setUserInactivityInterval(days)
            .then(() => {
                setSuccess(true)
                // reset cache
                userService.getOrgDetails(true)
            })
            .catch(() => {
                setError(true)
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    return (
        <div>
            {error && (
                <ErrorBanner className={styles.banner}>
                    {ls.getString("errorThereWasAnIssueSettingTheUserAndDeviceInactivityInterval")}
                </ErrorBanner>
            )}
            {success && (
                <InfoBanner className={styles.banner}>
                    {ls.getString("successUserAndDeviceeInactivityIntervalSaved")}
                </InfoBanner>
            )}
            <form onSubmit={onSubmit} className={styles.form}>
                <FormRow label={ls.getString("userAndDeviceInactivityDescription")}>
                    <Input
                        className={styles.input}
                        type="number"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        disabled={props.disabled || !props.canUpdateUserExpiry}
                        required
                        name="userAndDeviceInactivity"
                    />
                    {ls.getString("days")}
                </FormRow>
                {props.canUpdateUserExpiry && !props.disabled && (
                    <FormRow label="">
                        <Button
                            buttonType={ButtonType.PRIMARY}
                            asElement={ButtonElement.BUTTON}
                            loading={submitting}
                            type="submit"
                        >
                            {ls.getString("update")}
                        </Button>
                    </FormRow>
                )}
            </form>
        </div>
    )
}

interface UserExpiryProps {
    disabled?: boolean
    canUpdateUserExpiry?: boolean
}
