// externals
import * as React from "react"
// locals
import { ServiceFormProps, useSetPolicyID, useSetPolicyEnabled, useServiceType } from ".."
import { FormLabel, FormSection, Select, ToggleButton } from "../../../../components"
import { useServiceLocalization, useServiceSecure } from "../../../../services"

type Props = ServiceFormProps & {
    required?: boolean
}

export default function ServicePolicyForm({ edit, required }: Props) {
    // grab the services we need
    const localization = useServiceLocalization()
    const secureService = useServiceSecure()

    // and some form context
    const serviceType = useServiceType()

    // the id of the policy chosen by the user
    const [policy, setPolicy] = React.useState(edit ? edit.policyId || "" : "")
    const [policyEnabled, setPolicyEnabled] = React.useState(edit ? edit.policyEnabled : true)

    // figure out the list of possible policies
    const [options, setOptions] = React.useState<{ displayName: string; value: string }[]>([])
    React.useEffect(() => {
        secureService.getPoliciesByServiceType(serviceType).then((policies) =>
            setOptions(
                policies
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((p) => {
                        return { displayName: p.name, value: p.id }
                    })
            )
        )
    }, [secureService, serviceType])

    // make sure the parent's state is up to date
    const setLocalPolicyID = useSetPolicyID()
    const updatePolicyEnabled = useSetPolicyEnabled()
    React.useEffect(() => {
        setLocalPolicyID(policy)
        updatePolicyEnabled(policyEnabled)
    }, [policy, policyEnabled, setLocalPolicyID, updatePolicyEnabled])

    const label = required
        ? localization.getString("attachAPolicyRequired")
        : localization.getString("attachAPolicyOptional")

    return (
        <FormSection title={localization.getString("attachPolicy")}>
            <FormLabel title={label} htmlFor="policy">
                <Select
                    noneOption={localization.getString("none")}
                    value={policy}
                    options={options}
                    onChange={(val) => setPolicy(val as string)}
                />
            </FormLabel>
            {policy && (
                <FormLabel
                    title={localization.getString("chooseAnEnforcementMode")}
                    htmlFor="chooseAnEnforcementMode"
                >
                    <ToggleButton
                        items={[
                            {
                                label: localization.getString("permissive"),
                                onClick: () => setPolicyEnabled(false),
                                selected: !policyEnabled,
                            },
                            {
                                label: localization.getString("enforcing"),
                                onClick: () => setPolicyEnabled(true),
                                selected: policyEnabled,
                            },
                        ]}
                    />
                </FormLabel>
            )}
        </FormSection>
    )
}
