import {
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { ScimProvisioningApi } from "../api/ScimProvisioning.api"
import { DateUtil } from "../../pre-v3/utils/Date.util"

enum ApiKeys {
    GET_SCIM_CREDENTIALS = "GET_SCIM_CREDENTIALS",
    GENERATE_SCIM_CREDENTIALS = "GENERATE_SCIM_CREDENTIALS",
}

export function useToggleScimProvisioning(options?: QueryOptions<void, string, boolean>) {
    const scimProvisioningApi = new ScimProvisioningApi()

    return useMutation<void, string, boolean>({
        ...options,
        mutationFn: async (isScimEnabled): Promise<void> => {
            await scimProvisioningApi.toggleScimProvisioning(isScimEnabled)
        },
    })
}

export function useGetScimCredentials(
    options?: QueryOptions<ScimCredentials>
): UseQueryResult<ScimCredentials> {
    const scimProvisioningApi = new ScimProvisioningApi()

    return useQuery<ScimCredentials, string>({
        ...options,
        queryKey: [ApiKeys.GET_SCIM_CREDENTIALS],
        queryFn: async (): Promise<ScimCredentials> => {
            const credentials = await scimProvisioningApi.getScimCredentials()
            return {
                baseUrl: credentials.base_url,
                tokens: credentials.tokens.map((token) => {
                    return {
                        id: token.uuid,
                        createdAt: DateUtil.formatLargeTimestamp(token.created_at),
                    }
                }),
            }
        },
    })
}

export function useDeleteScimToken(
    options?: QueryOptions<string>
): UseMutationResult<void, string, string> {
    const scimProvisioningApi = new ScimProvisioningApi()
    const client = useQueryClient()

    return useMutation<void, string, string>({
        ...options,
        mutationFn: async (tokenId: string): Promise<void> => {
            await scimProvisioningApi.deleteScimToken(tokenId)
        },
        onSuccess: () => {
            client.invalidateQueries([ApiKeys.GET_SCIM_CREDENTIALS])
        },
    })
}

export function useGenerateScimCredentials(): UseQueryResult<ScimCredential> {
    const scimProvisioningApi = new ScimProvisioningApi()
    return useQuery<ScimCredential, string>({
        queryKey: [ApiKeys.GENERATE_SCIM_CREDENTIALS],
        queryFn: async (): Promise<ScimCredential> => {
            const credentials = await scimProvisioningApi.generateScimCredentials()
            return {
                baseUrl: credentials.base_url,
                token: credentials.token,
            }
        },
        cacheTime: 0,
    })
}

export interface ScimCredentials {
    baseUrl: string
    tokens: Token[]
}

export interface Token {
    id: string
    createdAt: string
}

export interface ScimCredential {
    baseUrl: string
    token: string
}
