import React, { FC, useMemo } from "react"
import { useServiceLocalization } from "../../../../pre-v3/services"
import { LicenseInformation } from "../../../services/Org.service"

interface Props {
    orgLicense: LicenseInformation
}
export const OrgLicenseInfo: FC<Props> = ({ orgLicense }) => {
    const localization = useServiceLocalization()

    const spaLicenseText = useMemo(() => {
        if (orgLicense.spaCount === 0)
            return localization.getString("licenseDataText_zero", localization.getString("spa"))

        return localization.getPluralString(
            "licenseDataText",
            orgLicense.spaCount,
            localization.getString("spa"),
            localization.getString(orgLicense.spa)
        )
    }, [orgLicense])

    const siaLicenseText = useMemo(() => {
        if (orgLicense.siaCount === 0)
            return localization.getString("licenseDataText_zero", localization.getString("sia"))

        return localization.getPluralString(
            "licenseDataText",
            orgLicense.siaCount,
            localization.getString("sia"),
            localization.getString(orgLicense.sia)
        )
    }, [orgLicense])

    return (
        <div>
            <p>{spaLicenseText}</p>
            <p>{siaLicenseText}</p>
        </div>
    )
}
