import * as React from "react"

import styles from "./TunnelPolicyEdit.module.scss"
import { Policy } from "../../../../v3/services/Policy.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { ModalRef } from "../../../../pre-v3/services/Modal.service"
import { ColDef, Grid } from "../../../../pre-v3/components"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { AppText } from "../../../../v3/components/app-text/AppText.component"

interface Props {
    modalRef?: ModalRef
    policy: Policy
}

export function DeletePolicyModal(props: Props) {
    const ls = useServiceLocalization()

    const column: ColDef[] = [
        {
            headerName: ls.getString("serviceTunnel"),
            field: "serviceName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 75,
            comparator: AgGridUtil.alphaBetComparator,
        },
    ]

    return (
        <div className={styles.deleteContainer}>
            {props.policy.noOfAttachments > 0 ? (
                <AppText
                    ls={{
                        key: "deletePolicyAttachedServiceDesc",
                        replaceVals: [props.policy.name],
                    }}
                />
            ) : (
                <AppText ls="deletePolicyDescription" />
            )}
            {props.policy.attachedServices && props.policy.attachedServices.length > 0 && (
                <Grid
                    columnDefs={column}
                    rowData={props.policy.attachedServices}
                    pagination
                    autoHeight
                ></Grid>
            )}
        </div>
    )
}
