import React from "react"

import {
    ModalApi as BaseModalApi,
    ModalAction,
    ModalWithoutRef,
} from "../../../../../components/modal/Modal.component"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import {
    ErrorToast,
    SuccessToast,
    ToastApi,
} from "../../../../../components/toast/Toast.components"
import styles from "./UserLicenseModal.module.scss"
import { ErrorBanner } from "../../../../components/banner/Banner.component"
import { OrgLicenseInformation } from "../../../../services/shared/LicenseInformation"

import {
    LicenseStatus,
    useGrantRevokeUserLicense,
    LicenseType,
    UserLicenseByType,
} from "../../../../services/UserLicenseUsage.service"
import { licenseStatusMap } from "../../../../services/User.service"
import { Card } from "../../../../../components/card/Card.component"

export type ModalApi = BaseModalApi<void>

export interface Props {
    email: string
    footer?: React.ReactNode
    licenseInfo: OrgLicenseInformation
    userLicenseInformation: UserLicenseByType
}

export const UserLicenseModal = React.forwardRef<ModalApi, Props>((props, ref) => {
    const localization = useServiceLocalization()
    const modalRef = React.useRef<ModalApi>(null)
    const { spaCount, spaUsageCount, siaCount, siaUsageCount } = props.licenseInfo

    React.useImperativeHandle(ref, () => ({
        open: () => modalRef.current?.open() ?? Promise.resolve({ action: ModalAction.DISMISS }),
        dismiss: () => modalRef.current?.dismiss(),
        complete: () => modalRef.current?.complete(),
    }))

    const isSiaReachedNoSpaReached = siaCount <= siaUsageCount && spaCount > spaUsageCount
    const isSpaReachedNoSiaReached = spaCount <= spaUsageCount && siaCount > siaUsageCount
    const isBothReached = siaCount <= siaUsageCount && spaCount <= spaUsageCount

    const canShowSiaSpaBanner =
        props.userLicenseInformation.sia.status !== LicenseStatus.LICENSED &&
        props.userLicenseInformation.spa.status !== LicenseStatus.LICENSED
    const canShowSiaBanner = props.userLicenseInformation.sia.status !== LicenseStatus.LICENSED
    const canShowSpaBanner = props.userLicenseInformation.spa.status !== LicenseStatus.LICENSED

    const canShowSiaCard =
        siaUsageCount >= siaCount &&
        props.userLicenseInformation.sia.status !== LicenseStatus.LICENSED
    const canShowSpaCard =
        spaUsageCount >= spaCount &&
        props.userLicenseInformation.spa.status !== LicenseStatus.LICENSED

    return ModalWithoutRef(
        {
            id: Id.USER_LICENSE_MODAL,
            title: localization.getString("editLicenses"),
            childrenClassName: styles.children,
            children: (
                <React.Fragment>
                    {!canShowSpaCard && (
                        <GrantRevokeLicense
                            canRevoke={
                                props.userLicenseInformation.spa.status === LicenseStatus.LICENSED
                            }
                            licenseType={LicenseType.SPA}
                            title={localization.getString(
                                "license",
                                localization.getString(LicenseType.SPA)
                            )}
                            licenseStatusLabel={localization.getString("userLicenseStatus")}
                            userLicenseInformation={props.userLicenseInformation}
                            email={props.email}
                        />
                    )}

                    {!canShowSiaCard && (
                        <GrantRevokeLicense
                            canRevoke={
                                props.userLicenseInformation.sia.status === LicenseStatus.LICENSED
                            }
                            licenseType={LicenseType.SIA}
                            title={localization.getString(
                                "license",
                                localization.getString(LicenseType.SIA)
                            )}
                            licenseStatusLabel={localization.getString("userLicenseStatus")}
                            userLicenseInformation={props.userLicenseInformation}
                            email={props.email}
                        />
                    )}

                    {isSiaReachedNoSpaReached && canShowSiaBanner && (
                        <ErrorBanner
                            children={localization.getString("noRemainingSiaLicenseToGrant")}
                        />
                    )}

                    {isSpaReachedNoSiaReached && canShowSpaBanner && (
                        <ErrorBanner
                            children={localization.getString("noRemainingSpaLicenseToGrant")}
                        />
                    )}

                    {isBothReached && canShowSiaSpaBanner && (
                        <ErrorBanner
                            children={localization.getString("noRemainingSpaOrSiaLicenseToGrant")}
                        />
                    )}
                </React.Fragment>
            ),
            footer: <div className={styles.footer}>{props.footer}</div>,
        },
        modalRef
    )
})

enum Id {
    USER_LICENSE_MODAL = "userLicenseModal",
}

interface GrantRevokeLicenseProps {
    canRevoke: boolean
    licenseType: LicenseType
    title: string
    licenseStatusLabel: string
    userLicenseInformation: UserLicenseByType
    email: string
}

function GrantRevokeLicense(props: GrantRevokeLicenseProps): React.ReactElement {
    const localization = useServiceLocalization()
    const successToastRef = React.useRef<ToastApi>(null)
    const errorToastRef = React.useRef<ToastApi>(null)

    const { mutate: grantRevokeUserLicense, isLoading: isLoadingGrantRevokeLicense } =
        useGrantRevokeUserLicense(props.email, {
            onSuccess: (res) => {
                successToastRef.current?.openToast(
                    localization.getString(
                        res.status === LicenseStatus.REVOKED ? "somethingRevoked" : "granted",
                        localization.getString("license", localization.getString(res.licenseType))
                    )
                )
            },
        })

    const handleGrantRevokeUserLicense = () => {
        switch (props.userLicenseInformation[props.licenseType].status) {
            case LicenseStatus.LICENSED:
                grantRevokeUserLicense({
                    licenseType: props.licenseType,
                    email: props.email,
                    userLicenseInformation: props.userLicenseInformation,
                })

                break
            case LicenseStatus.REVOKED:
                grantRevokeUserLicense({
                    licenseType: props.licenseType,
                    email: props.email,
                    userLicenseInformation: props.userLicenseInformation,
                })
                break
            default:
                grantRevokeUserLicense({ licenseType: props.licenseType, email: props.email })
                break
        }
    }

    return (
        <>
            <ErrorToast ref={errorToastRef} />
            <SuccessToast ref={successToastRef} />
            <Card
                className={styles.grantRevokeLicense}
                aria-labelledby={props.licenseType}
                role="group"
            >
                <h2 className={styles.header} id={props.licenseType}>
                    {props.title}
                </h2>
                <div className={styles.licenseStatus}>
                    <div>
                        <span className={styles.licenseStateLabel}>{props.licenseStatusLabel}</span>
                        <span className={styles.licenseState}>
                            {props.userLicenseInformation[props.licenseType].status ===
                            LicenseStatus.NOT_LICENSED
                                ? localization.getString("notLicensed")
                                : localization.getString(
                                      licenseStatusMap[
                                          props.userLicenseInformation[props.licenseType].status
                                      ]
                                  )}
                        </span>
                    </div>
                    {props.canRevoke ? (
                        <Button
                            type="button"
                            buttonType={ButtonType.DESTRUCTIVE}
                            asElement={ButtonElement.BUTTON}
                            onClick={handleGrantRevokeUserLicense}
                            loading={isLoadingGrantRevokeLicense}
                        >
                            {localization.getString("license", localization.getString("revoke"))}
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={handleGrantRevokeUserLicense}
                            loading={isLoadingGrantRevokeLicense}
                        >
                            {localization.getString("license", localization.getString("grant"))}
                        </Button>
                    )}
                </div>
            </Card>
        </>
    )
}
