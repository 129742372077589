import React, { ReactNode } from "react"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import {
    PolicyStatusAttachedTemplate,
    PolicyStatusInactiveTemplate,
} from "./PolicyStatusCellRenderer.template"
import { PolicySecure, PolicySecureStatus } from "../../../../services/Secure.service"

export class PolicyStatusCellRenderer extends React.Component<PolicyStatusCellRendererProps, {}> {
    public render(): ReactNode {
        switch (this.props.data.status) {
            case PolicySecureStatus.ATTACHED:
                return PolicyStatusAttachedTemplate.call(this)
            default:
                return PolicyStatusInactiveTemplate.call(this)
        }
    }

    private localizationService: LocalizationService = new LocalizationService()
}

interface PolicyStatusCellRendererProps {
    data: PolicySecure
}
