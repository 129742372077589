import React, { FormEvent, ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE } from "../../../../../../routes"
import { ApiKeyScope, SettingsApi } from "../../../../../api/Settings.api"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { SettingsService } from "../../../../../services"
import { LocalizationService } from "../../../../../services/localization/Localization.service"
import { FormUtil } from "../../../../../utils/Form.util"
import ApiKeysAddTemplate from "./ApiKeysAdd.template"

export class ApiKeysAdd extends React.Component<RouteComponentProps, ApiKeysAddState> {
    public render(): ReactNode {
        return ApiKeysAddTemplate.call(this)
    }

    public state: ApiKeysAddState = {
        error: "",
        success: "",
        scope: ApiKeyScope.CONNECTOR,
        scopeOptions: [],
    }

    public componentDidMount(): void {
        this.settingsService
            .getApiKeyScopes()
            .then((scopeOptions) => this.setState({ scopeOptions }))
    }

    private ls: LocalizationService = new LocalizationService()
    private settingsApi: SettingsApi = new SettingsApi()
    private settingsService: SettingsService = new SettingsService()

    @Bind
    private onScopeChange(scope: ApiKeyScope) {
        this.setState({ scope })
    }

    @Bind
    private onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()
        const formTarget: HTMLFormElement = <HTMLFormElement>event.target
        this.setState({ success: "", error: "", submitting: true })
        this.settingsApi
            .createApiKey({
                name: FormUtil.getFieldValue(event, "name"),
                description: FormUtil.getFieldValue(event, "description"),
                scope: FormUtil.getFieldValue(event, "scope") as ApiKeyScope,
            })
            .then(
                () => {
                    formTarget.reset()
                    this.setState({
                        submitting: false,
                        success: this.ls.getString("apiKeyCreated"),
                    })

                    this.props.history.push(ROUTE.API_KEYS)
                },
                (err: Error) => {
                    this.setState({ submitting: false, error: err.message })
                }
            )
    }
}

interface ApiKeysAddState {
    submitting?: boolean
    success: string
    error: string
    scope: ApiKeyScope
    scopeOptions: ApiKeyScope[]
}
