import React from "react"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { CollectionUtil } from "../../../../pre-v3/utils/Collection.util"
import { AppText } from "../../../components/app-text/AppText.component"
import type { ProvisionStatus, SuperAdminOrgInfo } from "../../../services/SuperAdmin.service"
import styles from "./OrgManagementStatusForm.module.scss"

export interface Props {
    superAdminOrgInfo: SuperAdminOrgInfo
}

export function OrgManagementStatusForm(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const {
        accessTierComponents = {},
        provisionStatus = {},
        unknownComponents = {},
    } = props.superAdminOrgInfo

    const accessTierComponentsStatus = Object.entries(accessTierComponents)
    const provisionStatusStatus = CollectionUtil.entries(provisionStatus).filter(
        ([, status]) => status
    )
    const unknownComponentsStatus = Object.entries(unknownComponents)

    const noFieldValue =
        accessTierComponentsStatus.length <= 0 &&
        provisionStatusStatus.length <= 0 &&
        unknownComponentsStatus.length <= 0

    if (noFieldValue) return <AppText ls="noStatusToShow" className={styles.noStatus} />

    return (
        <div className={styles.container}>
            {accessTierComponentsStatus.map(([region, status]) => (
                <StatusInput
                    key={region}
                    title={localization.getString(
                        "somethingColonSomething",
                        localization.getString("accessTier"),
                        region
                    )}
                    status={status}
                />
            ))}
            {provisionStatusStatus.map(([component, status]) =>
                status ? (
                    <StatusInput
                        key={component}
                        title={localization.getString(component)}
                        status={status}
                    />
                ) : null
            )}
            {unknownComponentsStatus.map(([component, status]) => (
                <StatusInput key={component} title={component} status={status} />
            ))}
        </div>
    )
}

interface StatusInputProps {
    title: string
    status: ProvisionStatus
}

function StatusInput(props: StatusInputProps) {
    const localization = useServiceLocalization()

    return (
        <React.Fragment>
            <label htmlFor={props.title} className={styles.formLabel}>
                {props.title}
            </label>
            <input
                id={props.title}
                type="text"
                value={localization.getString(props.status)}
                readOnly
            />
        </React.Fragment>
    )
}
