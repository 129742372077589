import { faTachometerAltFast } from "@fortawesome/pro-regular-svg-icons"
import { faFillDrip, faSignIn, faUserShield } from "@fortawesome/pro-solid-svg-icons"
import React, { ReactNode } from "react"

import { ROUTE } from "../../../routes"
import { AppNavLink } from "../../services/shared/AppNavLink"
import SettingsTemplate from "./Settings.template"
import { LocalizationService } from "../../services/localization/Localization.service"
import { LanguageKey } from "../../services/localization/languages/en-US.language"
import { OrgService } from "../../../v3/services/Org.service"

interface Props {
    canAccessSignOn: boolean
    canConfigureAdminSignOnSettings?: boolean
    canUpdateCustomization?: boolean
    canConfigureEndUserSettings?: boolean
}
export class Settings extends React.Component<Props, SettingsState> {
    constructor(props: Props) {
        super(props)

        this.state = {
            leftNavLinks: this.getNavLinks(false),
        }
    }

    public render(): ReactNode {
        return SettingsTemplate.call(this)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private orgService = new OrgService()

    public async componentDidMount(): Promise<void> {
        const hasMigrated = await this.orgService.hasGranularTrustMigrationOcurred()
        this.setState({ leftNavLinks: this.getNavLinks(hasMigrated), hasMigrated })
    }

    private sharedLinks = {
        adminSignOn: {
            label: this.localizationService.getString("adminSignOn"),
            href: ROUTE.MSP_SING_ON,
            icon: faSignIn,
        },
        manageAdmins: {
            label: this.localizationService.getString("manageAdmins"),
            href: ROUTE.MSP_MANAGE_ADMIN,
            icon: faUserShield,
        },
        customization: {
            label: this.localizationService.getString("customization"),
            href: ROUTE.MSP_CUSTOMIZATION,
            icon: faFillDrip,
        },
        trustFactors: {
            label: this.localizationService.getString("trustFactors"),
            href: ROUTE.MSP_TRUST_FACTOR_SETTINGS,
            icon: faTachometerAltFast,
        },
    } as const

    private getDivider(label: LanguageKey): AppNavLink {
        return {
            divider: true,
            label: this.localizationService.getString(label),
        }
    }

    private getNavLinks(hasMigrated: boolean): AppNavLink[] {
        return [
            this.getDivider("adminSettings"),
            this.sharedLinks.adminSignOn,
            this.sharedLinks.manageAdmins,
            this.getDivider("desktopAndMobile"),
            this.sharedLinks.customization,
            ...(hasMigrated
                ? []
                : [this.getDivider("trustScoreSettings"), this.sharedLinks.trustFactors]),
        ].filter((link) => {
            if (
                !this.props.canAccessSignOn &&
                link.label === this.localizationService.getString("adminSignOn")
            ) {
                return false
            }
            return true
        })
    }
}

interface SettingsState {
    leftNavLinks: AppNavLink[]
    hasMigrated?: boolean
}
