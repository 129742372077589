import React from "react"
import { useHistory } from "react-router-dom"

import { ButtonElement, Button, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Form } from "../../../components/form/Form.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import {
    NewServiceAccount,
    useCreateServiceAccount,
} from "../../../services/ServiceAccount.service"
import { ServiceAccountForm } from "../shared/ServiceAccountForm.component"
import { Container } from "../../../components/container/Container.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import styles from "./ServiceAccountAdd.module.scss"

export function ServiceAccountAdd(): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()

    const [serviceAccount, setServiceAccount] = React.useState<Partial<NewServiceAccount>>()

    const createServiceAccount = useCreateServiceAccount({
        onSuccess: (newServiceAccount) =>
            history.push(
                formatRoutePath(ROUTE.SERVICE_ACCOUNTS_DETAILS, {
                    id: encodeID(newServiceAccount.id),
                })
            ),
    })

    const onSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()
        createServiceAccount.mutate(serviceAccount as NewServiceAccount)
    }

    return (
        <Container as={Form} onSubmit={onSubmit}>
            <section aria-labelledby={Id.HEADING}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>
                        {localization.getString("serviceAccounts")}
                    </PageHeading>
                </header>
                <ServiceAccountForm serviceAccount={serviceAccount} onChange={setServiceAccount} />
                {createServiceAccount.isError && (
                    <ErrorBanner>{String(createServiceAccount.error)}</ErrorBanner>
                )}
                <FormButtons
                    className={styles.buttons}
                    rightButtons={
                        <React.Fragment>
                            <Button
                                asElement={ButtonElement.LINK}
                                to={ROUTE.SERVICE_ACCOUNTS}
                                buttonType={ButtonType.SECONDARY}
                            >
                                {localization.getString("cancel")}
                            </Button>
                            <Button
                                type="submit"
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                loading={createServiceAccount.isLoading}
                            >
                                {localization.getString("addServiceAccount")}
                            </Button>
                        </React.Fragment>
                    }
                />
            </section>
        </Container>
    )
}
enum Id {
    HEADING = "heading",
}
