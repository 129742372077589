import classNames from "classnames/bind"
import React, { useEffect } from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { CollectionUtil } from "../../../pre-v3/utils/Collection.util"
import { Input, InputProps } from "../input/Input.component"
import { IconType, InputWithAction } from "../input-with-action/InputWithAction.component"
import styles from "./KvpMultiInput.module.scss"

export type KeyValuePair = [string, string]

type OmittedNewValueInputProps =
    | "aria-label"
    | "className"
    | "onChange"
    | "pattern"
    | "placeholder"
    | "required"
    | "type"
    | "value"
type NewValueInputProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    OmittedNewValueInputProps
>

export interface PatternProps {
    pattern: string
    errorMessage: string
}

export interface KvpMultiInputProps {
    values?: KeyValuePair[]
    max?: number
    required?: boolean
    patternProps?: PatternProps
    label?: string
    newValueInputProps?: NewValueInputProps
    onChange?: (updatedValues: KeyValuePair[]) => void

    disabled?: boolean
    keyPlaceholder?: string
    valuePlaceholder?: string
    className?: string
}

export function KvpMultiInput(props: KvpMultiInputProps): JSX.Element {
    const isMax = typeof props.max === "number" && props.max <= (props.values?.length ?? 0)
    const shouldShowNewValue = !props.disabled && !isMax

    return (
        <div className={classNames(styles.container, props.className)}>
            {props.values?.map((pair: KeyValuePair, index: number): JSX.Element => {
                const [key] = pair
                return <PreviousValueInput {...props} key={key} pair={pair} index={index} />
            })}
            {props.disabled && !CollectionUtil.isTruthy(props.values) && <KeyValueInput disabled />}
            {shouldShowNewValue && <NewValueInput {...props} />}
        </div>
    )
}

// Sub Components

interface PreviousValueInputProps extends KvpMultiInputProps {
    index: number
    pair: KeyValuePair
}

function PreviousValueInput(props: PreviousValueInputProps): JSX.Element {
    const { index, onChange, pair, values = [] } = props

    const localization = useServiceLocalization()

    const onRemove: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        onChange?.(CollectionUtil.removeValueByIndex(values, index))
    }

    const [, prevValue] = pair

    return (
        <KeyValueInput
            pair={pair}
            onAction={props.disabled ? undefined : onRemove}
            icon={props.disabled ? undefined : IconType.MINUS}
            actionAriaLabel={
                props.disabled ? undefined : localization.getString("removeValue", prevValue)
            }
            disabled
        />
    )
}

function NewValueInput(props: KvpMultiInputProps): JSX.Element {
    const {
        label,
        newValueInputProps,
        onChange,
        patternProps,
        keyPlaceholder,
        valuePlaceholder,
        required,
        values = [],
    } = props

    const localization = useServiceLocalization()

    const textInput = React.useRef<HTMLInputElement>(null)

    const [newPair, setNewPair] = React.useState(emptyPair)
    const [validationMessage, setValidationMessage] = React.useState<string>("")
    const [newKey, newValue] = newPair
    const trimmedValue = newValue.trim()
    const isNewValueEmpty = trimmedValue.length <= 0

    useEffect(() => {
        let updatedValidationMessage: string = ""
        const isFirstInput: boolean = values.length <= 0

        if (required && isFirstInput && isNewValueEmpty) {
            // Required is only for first input
            updatedValidationMessage =
                newValueInputProps?.title || localization.getString("pleaseFillOutThisField")
        }

        if (textInput.current?.validity.patternMismatch && patternProps) {
            updatedValidationMessage = patternProps.errorMessage
        }

        textInput.current?.setCustomValidity(updatedValidationMessage)
        setValidationMessage(updatedValidationMessage)
    }, [isNewValueEmpty, localization, patternProps, required, trimmedValue, values])

    const onKeyChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        setNewPair([event.target.value, newValue])
    }

    const onNewValueChange = () => {
        if (isNewValueEmpty || validationMessage || hasDuplicateKey(newKey, values)) return

        onChange?.(CollectionUtil.addValue(values, newPair))
        setNewPair(emptyPair)
    }

    const onNewValueChangeByBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        onNewValueChange()
    }

    const onNewValueChangeByEnter: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === "Enter") {
            event.preventDefault()
            if (validationMessage) {
                textInput.current?.reportValidity()
            } else {
                onNewValueChange()
            }
        }
    }

    const onValueChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        textInput.current?.setCustomValidity("")
        setNewPair([newKey, event.target.value])
    }

    const onAddNewInput: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        if (validationMessage) {
            textInput.current?.reportValidity()
        } else if (newPair !== emptyPair && newKey) {
            onNewValueChange()
        }
    }

    return (
        <KeyValueInput
            {...newValueInputProps}
            pairs={values}
            pair={newPair}
            icon={IconType.PLUS}
            actionDisabled={isNewValueEmpty || !onChange}
            onAction={onAddNewInput}
            onKeyChange={onKeyChange}
            onValueBlur={onNewValueChangeByBlur}
            onValueChange={onValueChange}
            onValueKeyDown={onNewValueChangeByEnter}
            aria-label={label}
            actionAriaLabel={localization.getString("addNewValue")}
            keyPlaceholder={keyPlaceholder}
            valuePlaceholder={valuePlaceholder}
            ref={textInput}
        />
    )
}

const emptyPair: KeyValuePair = ["", ""]

interface KeyValueInputProps {
    pairs?: KeyValuePair[]
    pair?: KeyValuePair
    icon?: IconType
    actionAriaLabel?: string
    keyPlaceholder?: string
    valuePlaceholder?: string
    required?: boolean
    disabled?: boolean
    actionDisabled?: boolean
    onAction?: React.MouseEventHandler<HTMLButtonElement>
    onKeyChange?: React.ChangeEventHandler<HTMLInputElement>
    onValueChange?: React.ChangeEventHandler<HTMLInputElement>
    onValueBlur?: React.FocusEventHandler<HTMLInputElement>
    onValueKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    newValueInputProps?: NewValueInputProps
}

const KeyValueInput = React.forwardRef<HTMLInputElement, KeyValueInputProps>(
    (props, ref): JSX.Element => {
        const localization = useServiceLocalization()
        const keyInput = React.useRef<HTMLInputElement>(null)

        const [key, value] = props.pair ?? emptyPair

        const onKeyChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
            keyInput.current?.setCustomValidity("")
            props.onKeyChange?.(event)
        }

        const validate = () => {
            const message = hasDuplicateKey(key, props.pairs)
                ? localization.getString("keysMustBeUnique")
                : ""

            keyInput.current?.setCustomValidity(message)

            keyInput.current?.reportValidity()
        }

        const onKeyBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
            if (keyInput.current?.validity.valid ?? true) {
                validate()
            }
        }

        const onValueBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
            validate()
            keyInput.current?.focus()

            if (keyInput.current?.validity.valid ?? true) {
                props.onValueBlur?.(event)
            }
        }

        const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
            if (event.key === "Enter") {
                validate()
                keyInput.current?.focus()
            }

            if (keyInput.current?.validity.valid ?? true) {
                props.onValueKeyDown?.(event)
            }
        }

        const valueInputProps: InputProps = {
            ...props.newValueInputProps,
            value,
            placeholder: props.valuePlaceholder,
            onChange: props.onValueChange,
            disabled: props.disabled,
            onBlur: onValueBlur,
            onKeyDown,
            required: props.required,
        }

        return (
            <div className={styles.row}>
                <Input
                    value={key}
                    onChange={onKeyChange}
                    onBlur={onKeyBlur}
                    placeholder={props.keyPlaceholder}
                    disabled={props.disabled}
                    className={styles.keyInput}
                    required={props.required || Boolean(value)}
                    ref={keyInput}
                />
                {props.icon && props.onAction ? (
                    <InputWithAction
                        {...valueInputProps}
                        onAction={props.onAction}
                        icon={props.icon}
                        actionDisabled={props.actionDisabled}
                        actionAriaLabel={props.actionAriaLabel}
                        className={styles.valueInputWithAction}
                        ref={ref}
                    />
                ) : (
                    <Input
                        {...valueInputProps}
                        className={styles.valueInputWithoutAction}
                        ref={ref}
                    />
                )}
            </div>
        )
    }
)

function hasDuplicateKey(newKey: string, pairs?: KeyValuePair[]): boolean {
    return pairs?.some(([prevKey]) => prevKey === newKey) ?? false
}
