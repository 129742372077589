import { useMutation } from "@tanstack/react-query"
import React, { useState, ReactNode, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"

import { useFeatureFlags } from "../../../../hooks/useFeatureFlags.hook"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    ActionBarItem,
    IconType,
    useActionBar,
} from "../../../../pre-v3/services/ActionBar.service"
import { useAuthService } from "../../../../pre-v3/services/Auth.service"
import { decodeID, encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import orgManagementStyles from "../OrgManagement.module.scss"
import classNames from "classnames"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import { SimpleTable } from "../../../../pre-v3/components/simple-table/SimpleTable.component"
import { TabBar, Tab } from "../../../../pre-v3/components/tab-bar/TabBar.component"
import { Status as StatusType } from "../../../services/Org.service"
import { OrgManagementSpecificationForm } from "../form/OrgManagementSpecificationForm.component"
import { Status } from "../../../components/status/Status.component"
import { orgStatusMap, labelMap } from "../OrgManagement.view"
import { OrgManagementStatusForm } from "../form/OrgManagementStatusForm.component"
import { MessageContent } from "../../../../pre-v3/components/modal/message/MessageContent"
import { DeleteCancelActions } from "../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { OkCancelActions } from "../../../../pre-v3/components/modal/ok-cancel/OkCancelActions.component"
import {
    useDeleteOrg,
    useGetAssignMspOptions,
    useGetOrgById,
    useToggleArchive,
} from "../../../services/SuperAdmin.service"
import { InformationLabel } from "../../../../components/label/Label.component"
import { SuccessToast, ToastApi } from "../../../../components/toast/Toast.components"

export function OrgManagementOverview() {
    const params = useParams<UrlParams>()
    const localization = useServiceLocalization()
    const history = useHistory()
    const modalService = useServiceModal()
    const authService = useAuthService()
    const successToastApi = useRef<ToastApi>(null)

    const [activeTab, setActiveTab] = useState<number>(1)
    const tabs: Tab[] = [
        {
            label: localization.getString("specification"),
            value: 1,
            active: activeTab === 1,
        },
        {
            label: localization.getString("status"),
            value: 2,
            active: activeTab === 2,
        },
    ]

    const {
        data: orgData,
        isFetching: isOrgLoading,
        refetch: refetchOrgData,
        error: orgError,
    } = useGetOrgById(decodeID(params.id))

    const handleRefetch = async () => {
        await refetchOrgData()
    }

    const {
        data: assignMspOption,
        isLoading: isAssignMspOptionLoading,
        error: assignMspOptionError,
    } = useGetAssignMspOptions(orgData?.isMspOrg)

    const orgId = orgData?.id || ""
    const orgArchived = orgData?.archived
    const orgName = orgData?.orgName || ""

    const { data: featureFlags } = useFeatureFlags()

    const { mutateAsync: impersonate, isLoading: isImpersonating } = useMutation(
        ["impersonate"],
        () => {
            return authService.impersonate(orgName)
        }
    )

    const { mutateAsync: deleteOrg, isLoading: isDeleting } = useDeleteOrg({
        onSuccess: () => {
            successToastApi.current?.openToast(
                localization.getString("aDeletionWasRequestedForThisOrg", orgData?.orgName ?? "")
            )
        },
    })

    const { mutateAsync: toggleArchive, isLoading: isArchiving } = useToggleArchive()

    const disableAllActions =
        isImpersonating ||
        isDeleting ||
        isArchiving ||
        isOrgLoading ||
        !orgData ||
        isAssignMspOptionLoading
    const isViewLoading =
        isImpersonating || isDeleting || isArchiving || isOrgLoading || isAssignMspOptionLoading
    const errors: ReactNode[] = [
        typeof orgError === "string" && orgError,
        typeof assignMspOptionError === "string" && assignMspOptionError,
    ]

    const actions: ActionBarItem[] = [
        {
            icon: IconType.USER,
            onClick: onImpersonate,
            tooltip: localization.getString("viewAsSomething", localization.getString("admin")),
            disabled: disableAllActions,
        },
        ...(orgData?.isSonicWallProvisioned
            ? []
            : [
                  {
                      icon: orgArchived ? IconType.BOX_OPEN : IconType.BOX_CLOSED,
                      onClick: onArchive,
                      tooltip: orgArchived
                          ? localization.getString("unarchive")
                          : localization.getString("archive"),
                      disabled: disableAllActions,
                  },
                  {
                      icon: IconType.TRASH,
                      onClick: onDelete,
                      tooltip: localization.getString("delete"),
                      disabled: disableAllActions,
                      hide: !featureFlags?.momConsole.canDeleteOrg,
                  },
              ]),
        {
            icon: IconType.PEN,
            onClick: onEdit,
            tooltip: localization.getString("edit"),
            disabled: disableAllActions,
        },
    ].filter((x) => !x.hide)

    useActionBar({
        title: orgData && (
            <div className={orgManagementStyles.orgName}>
                {orgData.orgName}
                {orgData.isSonicWallProvisioned && (
                    <InformationLabel>
                        {localization.getString("sonicWallProvisioned")}
                    </InformationLabel>
                )}
            </div>
        ),
        items: [
            {
                label: localization.getString("superAdmin"),
                href: "",
            },
            {
                label: localization.getString("orgManagement"),
                href: ROUTE.ORG_MANAGEMENT,
            },
        ],
        fetchData: () => {
            handleRefetch()
        },
        actions,
    })

    function onEdit(): void {
        history.push(
            formatRoutePath(ROUTE.ORG_MANAGEMENT_EDIT, {
                id: encodeID(orgId),
            })
        )
    }

    function onDelete(): void {
        modalService
            .open(
                localization.getString("deleteOrg"),
                {
                    component: MessageContent,
                    props: {
                        text: localization.getString("areYouSureYouWantToDeleteThisOrg"),
                    },
                },
                {
                    component: DeleteCancelActions,
                    props: { okString: localization.getString("delete") },
                }
            )
            .onClose(async () => {
                await deleteOrg(orgId)
            })
    }

    function onArchive(): void {
        modalService
            .open(
                localization.getString("archiveOrg"),
                {
                    component: MessageContent,
                    props: {
                        text: localization.getString("areYouSureYouWantToArchiveThisOrg"),
                    },
                },
                {
                    component: DeleteCancelActions,
                    props: {
                        okString: orgArchived
                            ? localization.getString("unarchive")
                            : localization.getString("archive"),
                    },
                }
            )
            .onClose(async () => {
                await toggleArchive({ orgName: orgName, archive: !orgArchived })
                await refetchOrgData()
            })
    }

    function onImpersonate(): void {
        modalService
            .open(
                localization.getString("confirmViewAsSomething", localization.getString("admin")),
                {
                    component: MessageContent,
                    props: {
                        text: localization.getString(
                            "confirmViewAsAdminExplanation",
                            localization.getString("admin")
                        ),
                    },
                },
                {
                    component: OkCancelActions,
                    props: {
                        okString: localization.getString(
                            "viewAsSomething",
                            localization.getString("admin")
                        ),
                    },
                }
            )
            .onClose(async () => {
                await impersonate()
                window.location.href = window.location.origin + authService.getLoginUrl()
            })
    }

    function onTabChange(value: number): void {
        setActiveTab(value)
    }

    return (
        <div className={orgManagementStyles.overviewContainer}>
            <SuccessToast ref={successToastApi} />
            <Loader
                isLoading={isViewLoading}
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("orgDetails")
                )}
                center
                medium
            >
                <ErrorBanners errors={errors} className={orgManagementStyles.errorContainer} />
                {orgData && (
                    <>
                        <div className={orgManagementStyles.overviewLeftContainer}>
                            <div
                                className={classNames(
                                    orgManagementStyles.statusBlock,
                                    orgData.status && mapStatusStyle[orgData.status]
                                )}
                            >
                                {orgData.status && (
                                    <div className={orgManagementStyles.status}>
                                        <p className={orgManagementStyles.statusLabel}>
                                            {localization.getString("status")}
                                        </p>
                                        <Status
                                            type={orgStatusMap[orgData.status]}
                                            label={localization.getString(labelMap[orgData.status])}
                                        />
                                    </div>
                                )}
                                <SimpleTable
                                    className={orgManagementStyles.statusTable}
                                    items={[
                                        {
                                            label: localization.getString("lastUpdated"),
                                            value: DateUtil.format(orgData.lastUpdatedAt),
                                        },
                                    ]}
                                />
                            </div>
                            <SimpleTable
                                className={orgManagementStyles.simpleTable}
                                items={[
                                    {
                                        label: localization.getString("createdAt"),
                                        value: DateUtil.format(orgData.createdAt || 0),
                                    },
                                    {
                                        label: localization.getString("createdBy"),
                                        value:
                                            orgData.createdBy ||
                                            localization.getString("notAvailable"),
                                    },
                                    {
                                        label: localization.getString("lastUpdatedBy"),
                                        value:
                                            orgData.lastUpdatedBy ||
                                            localization.getString("notAvailable"),
                                    },
                                ]}
                            />
                        </div>
                        <div className={orgManagementStyles.overviewRightContainer}>
                            <TabBar tabs={tabs} onChange={onTabChange} />
                            <div className={orgManagementStyles.tabContainer}>
                                {activeTab === 1 && (
                                    <OrgManagementSpecificationForm
                                        initialValue={orgData}
                                        readOnly
                                        hideTitle
                                        assignMspOptions={assignMspOption}
                                        isSonicWallProvisioned={orgData.isSonicWallProvisioned}
                                    />
                                )}
                                {activeTab === 2 && (
                                    <OrgManagementStatusForm superAdminOrgInfo={orgData} />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Loader>
        </div>
    )
}

interface UrlParams {
    id: string
}

const mapStatusStyle: Record<StatusType, string | undefined> = {
    success: orgManagementStyles.success,
    partialSuccess: orgManagementStyles.warning,
    error: orgManagementStyles.error,
    inProgress: orgManagementStyles.inactive,
    unknown: undefined,
}
