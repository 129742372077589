import React, { useState, ReactNode, useRef } from "react"

import {
    ConfirmationModalWithoutRef,
    ModalAction,
    ModalApi,
} from "../../../../components/modal/ConfirmationModal.component"
import { FormLabel } from "../../../../pre-v3/components/form-label/FormLabel"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { Form } from "../../../../pre-v3/components/form/Form"
import { Checkbox } from "../../../components/checkbox/Checkbox.component"
import { SelectInput } from "../../../components/select-input/SelectInput.component"
import { Button } from "../../../components/button/Button.component"
import { ErrorBanner, ErrorBanners } from "../../../components/banner/Banner.component"
import orgManagementStyles from "../OrgManagement.module.scss"
import { FormSection } from "../../../../pre-v3/components/form-section/FormSection"
import { PageBreak } from "../../../../pre-v3/components/page-break/PageBreak.component"
import { PatternUtil } from "../../../../pre-v3/utils/Pattern.util"
import {
    AddEditOrgInfo,
    NOT_ASSIGNED_VALUE,
    SuperAdminOrgInfo,
    useAttachMspOrg,
    useDetachMspOrg,
    useUpdateOrgArchivalDate,
} from "../../../services/SuperAdmin.service"
import styles from "./OrgManagementSpecificationForm.module.scss"
import { Edition } from "../../../services/shared/Edition"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import { OrgLicenseInfo } from "./OrgLicenseInfo.component"
import { HiddenInput } from "../../../../components/input/HiddenInput.component"
import {
    DAY,
    convertFromServerTimestamp,
    convertToServerTimestamp,
    formatToLocalDateStr,
} from "../../../../utils/Date.utils"
import { ErrorToast, SuccessToast, ToastApi } from "../../../../components/toast/Toast.components"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

export interface Props {
    initialValue?: InitialValue
    onSubmit?: (formState: AddEditOrgInfo) => void
    readOnly?: boolean
    onCancel?: () => void
    submitText?: string
    isSubmitLoading?: boolean
    errors?: ReactNode[]
    hideTitle?: boolean
    assignMspOptions?: SelectItem[]
    isSonicWallProvisioned?: boolean
}

export function OrgManagementSpecificationForm(props: Props): JSX.Element {
    const {
        initialValue,
        readOnly,
        onCancel,
        submitText,
        isSubmitLoading,
        errors,
        hideTitle,
        assignMspOptions,
        isSonicWallProvisioned,
    } = props

    const localization = useServiceLocalization()

    const modalRef = React.useRef<ModalApi<void>>(null)
    const orgId: string = initialValue?.id ?? ""
    const errorToastRef = React.useRef<ToastApi>(null)
    const successToastRef = React.useRef<ToastApi>(null)
    const [orgName, setOrgName] = useState<string>(initialValue?.orgName || "")
    const [firstName, setFirstName] = useState<string>(initialValue?.firstName || "")
    const [email, setEmail] = useState<string>(initialValue?.email || "")
    const [internalOwner, setInternalOwner] = useState<string>(initialValue?.internalOwner || "")
    const [archivalDate, setarchivalDate] = useState<number>(initialValue?.archiveAt || 0)

    const [lastName, setLastName] = useState<string>(initialValue?.lastName || "")
    const [edition, setEdition] = useState<Edition>(initialValue?.edition || Edition.TEAM)
    const [isMspOrg, setIsMspOrg] = useState<boolean>(initialValue?.isMspOrg || false)
    const [globalEdge, setGlobalEdge] = useState<boolean>(initialValue?.globalEdge || false)
    const [privateEdge, setPrivateEdge] = useState<boolean>(initialValue?.privateEdge || false)
    const [isAiAssistedAdminSearchEnabled, setIsAiAssistedAdminSearchEnabled] = useState<boolean>(
        initialValue?.isAiAssistedAdminSearchEnabled ?? false
    )
    const [isAppDiscoveryEnabled, setIsAppDiscoveryEnabled] = useState<boolean>(
        initialValue?.isAppDiscoveryEnabled ?? false
    )
    const [type, setType] = useState<FormState["type"]>(initialValue?.type || typeOptions[0])
    const [customerID, setCustomerID] = useState<string>(initialValue?.customerID || "")
    const [banyanIdp, setBanyanIdp] = useState<boolean>(initialValue?.isBanyanIdp || false)
    const [assignedMsp, setAssignedMsp] = useState<string>(
        initialValue?.parentOrgId || NOT_ASSIGNED_VALUE
    )
    const [showModal, setShowModal] = React.useState(MODAL_TYPE.ADD_TO_MSP)
    const isDisplayArchivalDate: boolean =
        initialValue?.type === "Trial" || initialValue?.type === "Internal Temporary"
    const onAssignedMspChanges = async (option: string) => {
        setAssignedMsp(option)
        setShowModal(
            option === NOT_ASSIGNED_VALUE ? MODAL_TYPE.REMOVE_FROM_MSP : MODAL_TYPE.ADD_TO_MSP
        )

        const response = await modalRef.current?.open()

        if (response?.action === ModalAction.DISMISS) {
            setAssignedMsp(initialValue?.parentOrgId ?? NOT_ASSIGNED_VALUE)
        }
    }

    const getMspOrgName = (id: string) => {
        const option = assignMspOptions?.find((o) => o.value === id)
        return option?.displayName || ""
    }
    const { mutateAsync: attachMspOrg } = useAttachMspOrg()
    const { mutateAsync: detachMspOrg } = useDetachMspOrg()

    const onAttachMspOrg = async () => {
        if (initialValue?.parentOrgId && assignedMsp !== initialValue.parentOrgId) {
            await detachMspOrg({
                mspId: initialValue.parentOrgId,
                childOrgId: initialValue.id,
            })
        }

        if (assignedMsp !== NOT_ASSIGNED_VALUE) {
            await attachMspOrg({
                mspId: assignedMsp,
                childOrgId: initialValue?.id ?? "",
            })
        }

        modalRef.current?.complete()
    }
    const hiddenInput = useRef<HTMLInputElement>(null)
    const isEditing = Boolean(initialValue)
    const disableForm = isSubmitLoading || readOnly
    const isTeamEdition = edition === Edition.TEAM

    function onSubmit(event: React.SyntheticEvent<HTMLFormElement, Event>) {
        event.preventDefault()
        const mspSpecific: Pick<
            AddEditOrgInfo,
            | "edition"
            | "privateEdge"
            | "globalEdge"
            | "isAppDiscoveryEnabled"
            | "isDnsFilterEnabled"
        > = isMspOrg
            ? {
                  edition: Edition.ENTERPRISE,
                  privateEdge: false,
                  globalEdge: false,
                  isAppDiscoveryEnabled: false,
              }
            : {
                  edition,
                  privateEdge: !isTeamEdition && privateEdge,
                  globalEdge: isTeamEdition || globalEdge,
                  isAppDiscoveryEnabled,
              }

        const formState: AddEditOrgInfo = {
            ...mspSpecific,
            orgName,
            firstName,
            lastName,
            email,
            type,
            customerID,
            archived: false,
            isAiAssistedAdminSearchEnabled,
            isMspOrg,
            isDnsFilterEnabled: initialValue?.isDnsFilterEnabled,
            internalOwner,
        }

        if (!isEditing) {
            formState.isBanyanIdp = banyanIdp
        }
        props.onSubmit?.(formState)
    }

    const orgTypeOptions = React.useMemo(() => {
        if (initialValue && existingOrgTypeOptions.includes(initialValue.type)) {
            return [initialValue.type, ...typeOptions]
        } else {
            return typeOptions
        }
    }, [initialValue])

    const {
        mutateAsync: updateArchiveDate,
        isLoading: isDateUpdating,
        error: dateUpdateError,
    } = useUpdateOrgArchivalDate({
        onSuccess: () => {
            successToastRef.current?.openToast(
                localization.getString("successfullyUpdatedArchivalDate")
            )
        },
        onError: () => {
            errorToastRef.current?.openToast(localization.getString("failedToUpdateArchivalDate"))
        },
    })

    const incrementArchivalDateBy: number = DAY * 45 * 1_000_000

    async function updateArchivalDate() {
        const currentDate: number = archivalDate || 0
        const updatedDate: number = currentDate + incrementArchivalDateBy
        await updateArchiveDate({ orgId: orgId, archiveAt: updatedDate })
        setarchivalDate(updatedDate)
    }

    function isArchivalDateButtonDisabled(): boolean {
        const todaysDate = convertToServerTimestamp(new Date())
        const existingarchival = archivalDate || 0
        const isDisabled: boolean =
            existingarchival > todaysDate + DAY * 365 * 1_000_000 - incrementArchivalDateBy
        if (isDisabled) return true
        return false
    }
    React.useEffect(() => {
        const validityMessage =
            type === "Internal" || type === "Testing"
                ? localization.getString("testingAndInternalOrgsAreNotSupportedMsg")
                : ""
        hiddenInput.current?.setCustomValidity(validityMessage)
    }, [type])

    return (
        <React.Fragment>
            <ConfirmationModal
                id={Id.CONFIRMATION_MODAL}
                title={localization.getString(TITLES[showModal])}
                onComplete={onAttachMspOrg}
                ref={modalRef}
            >
                <p>{localization.getString(MESSAGE[showModal], getMspOrgName(assignedMsp))}</p>
            </ConfirmationModal>
            <Form className={styles.form} onSubmit={onSubmit} labelWidth={177} display="grid">
                <FormSection title={localization.getString("orgDetails")} hideTitle={hideTitle}>
                    {initialValue?.id && (
                        <FormLabel inline title={localization.getString("orgId")}>
                            <input type="text" id="orgId" disabled value={initialValue.id} />
                        </FormLabel>
                    )}
                    <FormLabel
                        inline
                        title={localization.getString("orgName")}
                        tooltip={localization.getString("orgNameRequirements")}
                    >
                        <input
                            type="text"
                            id="orgName"
                            value={orgName}
                            onChange={(event) => {
                                setOrgName(event.target.value)
                            }}
                            minLength={4}
                            maxLength={30}
                            pattern={PatternUtil.ORG_NAME.source}
                            required
                            disabled={Boolean(disableForm || initialValue?.orgName)}
                        />
                    </FormLabel>
                    {!isEditing && (
                        <>
                            <FormLabel inline title={localization.getString("firstName")}>
                                <input
                                    type="text"
                                    id="firstName"
                                    required
                                    disabled={Boolean(disableForm)}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </FormLabel>
                            <FormLabel inline title={localization.getString("lastName")}>
                                <input
                                    type="text"
                                    id="lastName"
                                    required
                                    disabled={Boolean(disableForm)}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </FormLabel>
                            <FormLabel inline title={localization.getString("email")}>
                                <input
                                    type="email"
                                    id="email"
                                    required
                                    disabled={Boolean(disableForm)}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormLabel>
                        </>
                    )}
                    {disableForm && isDisplayArchivalDate && (
                        <FormLabel title={localization.getString("archivalDate")}>
                            <div className={styles.archivalDateContainer}>
                                {Boolean(archivalDate) ? (
                                    <>
                                        <div className={styles.archivalDate}>
                                            {formatToLocalDateStr(
                                                convertFromServerTimestamp(archivalDate)
                                            )}
                                        </div>
                                        <Tooltip
                                            title={
                                                isArchivalDateButtonDisabled()
                                                    ? localization.getString(
                                                          "archivalDateCannotBeIncrementedBy365Days"
                                                      )
                                                    : ""
                                            }
                                        >
                                            <span>
                                                <Button
                                                    onClick={updateArchivalDate}
                                                    loading={isDateUpdating}
                                                    disabled={isArchivalDateButtonDisabled()}
                                                >
                                                    {localization.getString("incrementBy45Days")}
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </>
                                ) : (
                                    "-"
                                )}
                            </div>
                        </FormLabel>
                    )}
                    <FormLabel inline title={localization.getString("internalOwner")}>
                        <input
                            type="email"
                            id="internalOwner"
                            disabled={Boolean(disableForm)}
                            value={internalOwner}
                            onChange={(e) => setInternalOwner(e.target.value)}
                        />
                    </FormLabel>
                    {(!isEditing || initialValue?.isMspOrg) && (
                        <FormLabel
                            inline
                            title={localization.getString("mspOrg")}
                            className={styles.formRow}
                        >
                            <Checkbox
                                disabled={disableForm}
                                checked={isMspOrg}
                                onChange={setIsMspOrg}
                            />
                        </FormLabel>
                    )}
                    {initialValue?.license ? (
                        <FormLabel
                            inline
                            title={localization.getString("licenses")}
                            ariaLabel="licenses"
                        >
                            <OrgLicenseInfo orgLicense={initialValue.license} />
                        </FormLabel>
                    ) : (
                        <FormLabel
                            inline
                            title={localization.getString("edition")}
                            htmlFor="edition"
                            ariaLabel="edition"
                        >
                            <SelectInput
                                disabled={disableForm || isMspOrg || isSonicWallProvisioned}
                                value={isMspOrg ? Edition.ENTERPRISE : edition}
                                options={Object.values(Edition)}
                                id="edition"
                                onChange={setEdition}
                            />
                        </FormLabel>
                    )}
                    {!isMspOrg && (
                        <>
                            {[Edition.ENTERPRISE, Edition.UNLIMITED].includes(edition) && (
                                <FormLabel
                                    inline
                                    title={localization.getString("sonicWallCseIdp")}
                                    className={styles.formRow}
                                    htmlFor="banyanIdp"
                                >
                                    <Checkbox
                                        id="banyanIdp"
                                        disabled={
                                            disableForm || isEditing || isSonicWallProvisioned
                                        }
                                        checked={banyanIdp}
                                        onChange={(checked) => {
                                            setBanyanIdp(checked)
                                        }}
                                    ></Checkbox>
                                </FormLabel>
                            )}
                            {[Edition.ENTERPRISE, Edition.UNLIMITED].includes(edition) && (
                                <FormLabel
                                    inline
                                    title={localization.getString("enableApplicationDiscovery")}
                                    className={styles.formRow}
                                    htmlFor="applicationDiscovery"
                                >
                                    <Checkbox
                                        id="applicationDiscovery"
                                        disabled={disableForm}
                                        checked={isAppDiscoveryEnabled}
                                        onChange={(checked) => {
                                            setIsAppDiscoveryEnabled(checked)
                                        }}
                                    ></Checkbox>
                                </FormLabel>
                            )}
                            <FormLabel
                                inline
                                title={localization.getString("networkConfiguration")}
                                className={styles.formRow}
                                valueClassName={styles.checkboxes}
                            >
                                <Checkbox
                                    disabled={disableForm || isTeamEdition}
                                    checked={isTeamEdition || globalEdge}
                                    onChange={(checked) => {
                                        setGlobalEdge(checked)
                                    }}
                                >
                                    {localization.getString("globalEdge")}
                                </Checkbox>
                                {!isTeamEdition && (
                                    <Checkbox
                                        disabled={disableForm}
                                        checked={privateEdge}
                                        onChange={(checked) => {
                                            setPrivateEdge(checked)
                                        }}
                                    >
                                        {localization.getString("privateEdge")}
                                    </Checkbox>
                                )}
                            </FormLabel>
                            <FormLabel
                                inline
                                title={localization.getString("enableAiAssistedAdminSearch")}
                                className={styles.formRow}
                                labelClassName={styles.formLabel}
                                htmlFor="aiAssisted"
                            >
                                <Checkbox
                                    id="aiAssisted"
                                    disabled={disableForm}
                                    checked={isAiAssistedAdminSearchEnabled}
                                    onChange={setIsAiAssistedAdminSearchEnabled}
                                />
                            </FormLabel>
                        </>
                    )}
                    <FormLabel inline title={localization.getString("type")}>
                        <SelectInput
                            disabled={disableForm}
                            value={type}
                            options={orgTypeOptions}
                            id="type"
                            onChange={setType}
                            required
                        />
                        <HiddenInput type="text" className={styles.hiddenInput} ref={hiddenInput} />
                    </FormLabel>
                    <FormLabel
                        inline
                        title={localization.getString("customerId")}
                        htmlFor="customerId"
                    >
                        <input
                            disabled={disableForm || isSonicWallProvisioned}
                            type="text"
                            id="customerId"
                            name="customerId"
                            value={customerID}
                            onChange={(event) => {
                                setCustomerID(event.target.value)
                            }}
                            required
                        />
                    </FormLabel>
                    {isEditing && !isMspOrg && (
                        <FormLabel inline title={localization.getString("assignToAnMsp")}>
                            <SelectInput
                                disabled={disableForm || isSonicWallProvisioned}
                                value={assignedMsp}
                                options={assignMspOptions || []}
                                id="assignToAnMsp"
                                onChange={onAssignedMspChanges}
                                required
                            />
                        </FormLabel>
                    )}
                </FormSection>
                {!readOnly && (
                    <>
                        <PageBreak />
                        {errors && (
                            <ErrorBanners
                                errors={errors}
                                className={orgManagementStyles.errorContainer}
                            />
                        )}
                        {typeof dateUpdateError === "string" && (
                            <ErrorBanner className={orgManagementStyles.errorContainer}>
                                {dateUpdateError}
                            </ErrorBanner>
                        )}
                        <section className={orgManagementStyles.formButtons}>
                            {onCancel && (
                                <Button onClick={onCancel}>
                                    {localization.getString("cancel")}
                                </Button>
                            )}
                            <Button brand type="submit" loading={isSubmitLoading}>
                                {submitText || localization.getString("save")}
                            </Button>
                        </section>
                    </>
                )}
            </Form>
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
        </React.Fragment>
    )
}

enum Id {
    CONFIRMATION_MODAL = "confirmationModal",
}

type InitialValue = Pick<
    SuperAdminOrgInfo,
    | "orgName"
    | "firstName"
    | "lastName"
    | "email"
    | "edition"
    | "license"
    | "globalEdge"
    | "privateEdge"
    | "isAiAssistedAdminSearchEnabled"
    | "isAppDiscoveryEnabled"
    | "type"
    | "customerID"
    | "id"
    | "isBanyanIdp"
    | "isMspOrg"
    | "isDnsFilterEnabled"
    | "parentOrgId"
    | "internalOwner"
    | "archiveAt"
>
type FormState = Required<
    Pick<
        SuperAdminOrgInfo,
        "orgName" | "edition" | "globalEdge" | "privateEdge" | "type" | "customerID"
    >
>

const existingOrgTypeOptions: FormState["type"][] = ["Internal", "Testing"]
const typeOptions: FormState["type"][] = [
    "Production",
    "Staging",
    "Trial",
    "Internal Persistent",
    "Internal Temporary",
]

const ConfirmationModal = React.forwardRef(ConfirmationModalWithoutRef)

enum MODAL_TYPE {
    ADD_TO_MSP = "addToMsp",
    REMOVE_FROM_MSP = "removeFromMsp",
}

const TITLES: Record<MODAL_TYPE, LanguageKey> = {
    [MODAL_TYPE.ADD_TO_MSP]: "confirmOrganizationAssignment",
    [MODAL_TYPE.REMOVE_FROM_MSP]: "confirmRemovalOfMspOrganizationAssignment",
}

const MESSAGE: Record<MODAL_TYPE, LanguageKey> = {
    [MODAL_TYPE.ADD_TO_MSP]: "assignToMspConfirmationMessage",
    [MODAL_TYPE.REMOVE_FROM_MSP]: "removeOrgFromMspConfirmationMessage",
}
