import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { EdgeClusters } from "../../../v3/services/shared/Cluster"
import {
    NetworkSetting,
    ServiceTunnelDetail,
    useUpdateServiceTunnel,
    Application,
    Network,
    NetworkSettingType,
    isValidNetworkSetting,
} from "../../../v3/services/ServiceTunnelV2.service"
import { GeneralInfo, ServiceTunnelForm } from "../shared/ServiceTunnelForm.component"
import { Policy, PolicyAttachment } from "../../../v3/services/Policy.service"
import { formatRoutePath, ROUTE } from "../../../routes"
import { encodeID } from "../../../pre-v3/utils/Url.util"
import styles from "./ServiceTunnelEdit.module.scss"
import { AssignmentCard } from "../shared/AssignmentCard.component"
import { Form } from "../../../components/form/Form.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import {
    OverviewTopContainer,
    StatusType,
} from "../../../components/overview-top-container/OverviewTopContainer.component"
import { RegisteredServiceStatus } from "../../../v3/services/shared/RegisteredService"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"

interface Props {
    isEditMode?: boolean
    hasGlobalEdgeConfigured: boolean
    applications: Application[]
    networks: Network[]
    edgeClusters: EdgeClusters
    policies: Policy[]
    serviceTunnel: ServiceTunnelDetail
    enableATG: boolean
    hideApplication: boolean
    hideAccessTier: boolean
}

export function ServiceTunnelFormWrapper(props: Props): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()
    const params = useParams<{ id: string }>()
    const toastRef = React.useRef<ToastApi>(null)
    const saveButtonRef = React.useRef<HTMLButtonElement & HTMLAnchorElement>(null)

    const { mutate: onUpdateServiceTunnel, isLoading: isUpdatingServiceTunnel } =
        useUpdateServiceTunnel(props.enableATG, {
            onSuccess: (updatedServiceTunnel) => {
                history.push(
                    formatRoutePath(ROUTE.SERVICE_TUNNELS_DETAILS, {
                        id: encodeID(updatedServiceTunnel.id!),
                    })
                )
            },
            onError: (error) =>
                typeof error === "string"
                    ? toastRef.current?.openToast(error)
                    : console.error(error),
        })

    const [editableServiceTunnel, setEditableServiceTunnelDetail] = React.useState(() =>
        getInitialServiceTunnel(props.serviceTunnel, props.hasGlobalEdgeConfigured)
    )

    const serviceTunnelDetail = props.isEditMode ? editableServiceTunnel : props.serviceTunnel

    React.useEffect(() => {
        const validityMessage = serviceTunnelDetail.networkSettings.some(isValidNetworkSetting)
            ? ""
            : "Network settings are required"

        saveButtonRef.current?.setCustomValidity(validityMessage)
    }, [serviceTunnelDetail.networkSettings])

    const onNetworkSettingsChange = (networkSettings: NetworkSetting[]) => {
        setEditableServiceTunnelDetail((prev) => ({
            ...prev,
            networkSettings: networkSettings,
        }))
    }

    const onGeneralInfoChange = (generalInfo: GeneralInfo) => {
        setEditableServiceTunnelDetail((prev) => ({
            ...prev,
            name: generalInfo.name,
            description: generalInfo.description,
        }))
    }

    const onAutoStartOnLoginChange = () => {
        setEditableServiceTunnelDetail((prev) => ({
            ...prev,
            autorun: !prev.autorun,
        }))
    }

    const onUserControlStartOnLoginChange = () => {
        setEditableServiceTunnelDetail((prev) => ({
            ...prev,
            isAutorunLocked: !prev.autorun ? false : !prev.isAutorunLocked,
        }))
    }

    const onPolicyAttachmentChange = (policyAttachment: PolicyAttachment | undefined) => {
        setEditableServiceTunnelDetail((prev) => ({
            ...prev,
            policyAttachment,
        }))
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const updatedServiceTunnel: ServiceTunnelDetail = {
            ...serviceTunnelDetail,
            globalEdgeClusterName: props.edgeClusters.global?.name,
        }
        onUpdateServiceTunnel({
            serviceTunnel: updatedServiceTunnel,
            oldPolicy: props.serviceTunnel?.policyAttachment,
        })
    }

    const onSearchDomainsChange = (domains: string[]) => {
        setEditableServiceTunnelDetail((prev) => ({
            ...prev,
            dnsSearchDomains: domains,
        }))
    }

    return (
        <React.Fragment>
            <Form
                onSubmit={onSubmit}
                className={styles.form}
                aria-label={localization.getString(
                    "editSomethingWithLabel",
                    serviceTunnelDetail.name,
                    localization.getString("serviceTunnel")
                )}
            >
                {!props.isEditMode && (
                    <OverviewTopContainer
                        statusType={statusMap[serviceTunnelDetail.status]}
                        statusLabel={localization.getString(labelMap[serviceTunnelDetail.status])}
                        icon={iconMap[serviceTunnelDetail.status]}
                        policyAttachment={serviceTunnelDetail.policyAttachment}
                        recordsLabelTitle={localization.getString("records")}
                        recordItems={[
                            {
                                type: localization.getString("createdAt"),
                                at: serviceTunnelDetail.createdAt,
                                by: serviceTunnelDetail.createdBy,
                            },
                            {
                                type: localization.getString("lastUpdated"),
                                at: serviceTunnelDetail.updatedAt,
                                by: serviceTunnelDetail.updatedBy,
                            },
                        ]}
                    />
                )}

                <ServiceTunnelForm
                    serviceTunnel={serviceTunnelDetail}
                    onNetworkSettingsChange={onNetworkSettingsChange}
                    onGeneralInfoChange={onGeneralInfoChange}
                    applications={props.applications}
                    networks={props.networks}
                    policies={props.policies}
                    disabled={!props.isEditMode}
                    hideApplication={props.hideApplication}
                    hideAccessTier={props.hideAccessTier}
                />

                {props.isEditMode && (
                    <AssignmentCard
                        serviceTunnel={serviceTunnelDetail}
                        policies={props.policies}
                        onPolicyAttachmentChange={onPolicyAttachmentChange}
                        onAutoStartOnLoginChange={onAutoStartOnLoginChange}
                        onUserControlStartOnLoginChange={onUserControlStartOnLoginChange}
                        onPrivateSearchDomainsChange={onSearchDomainsChange}
                    />
                )}

                {props.isEditMode && (
                    <div className={styles.actionButtons}>
                        <Button
                            asElement={ButtonElement.LINK}
                            buttonType={ButtonType.SECONDARY}
                            to={formatRoutePath(ROUTE.SERVICE_TUNNELS_DETAILS, {
                                id: params.id,
                            })}
                        >
                            {localization.getString("cancel")}
                        </Button>

                        <Button
                            ref={saveButtonRef}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            loading={isUpdatingServiceTunnel}
                            type="submit"
                        >
                            {localization.getString("save")}
                        </Button>
                    </div>
                )}
            </Form>
            <ErrorToast ref={toastRef} />
        </React.Fragment>
    )
}

function getInitialServiceTunnel(
    serviceTunnel: ServiceTunnelDetail,
    hasGlobalEdgeConfigured: boolean
): ServiceTunnelDetail {
    if (!hasGlobalEdgeConfigured || serviceTunnel.networkSettings.some(isGlobalEdge))
        return serviceTunnel

    return {
        ...serviceTunnel,
        networkSettings: [...serviceTunnel.networkSettings, emptyGlobalEdgeNetworkSetting],
    }
}

function isGlobalEdge(networkSetting: NetworkSetting): boolean {
    return networkSetting.type === NetworkSettingType.GLOBAL_EDGE
}

const emptyGlobalEdgeNetworkSetting: NetworkSetting = {
    type: NetworkSettingType.GLOBAL_EDGE,
    networks: [],
    publicIncludeInfo: { applications: [], domains: [], ipRanges: [] },
    publicExcludeInfo: { applications: [], domains: [], ipRanges: [] },
}

const statusMap: Record<RegisteredServiceStatus, StatusType> = {
    [RegisteredServiceStatus.INACTIVE]: StatusType.DISABLED,
    [RegisteredServiceStatus.NO_POLICY]: StatusType.ERROR,
    [RegisteredServiceStatus.POLICY_ENFORCING]: StatusType.SUCCESS,
    [RegisteredServiceStatus.POLICY_PERMISSIVE]: StatusType.WARNING,
}

export const labelMap: Record<RegisteredServiceStatus, LanguageKey> = {
    [RegisteredServiceStatus.INACTIVE]: "inactive",
    [RegisteredServiceStatus.NO_POLICY]: "noPolicy",
    [RegisteredServiceStatus.POLICY_ENFORCING]: "policyEnforcing",
    [RegisteredServiceStatus.POLICY_PERMISSIVE]: "policyPermissive",
}

export const iconMap: Record<RegisteredServiceStatus, IconType> = {
    [RegisteredServiceStatus.INACTIVE]: IconType.PAUSE_CIRCLE,
    [RegisteredServiceStatus.NO_POLICY]: IconType.TIMES_CIRCLE,
    [RegisteredServiceStatus.POLICY_ENFORCING]: IconType.CHECK_SOLID,
    [RegisteredServiceStatus.POLICY_PERMISSIVE]: IconType.MINUS_CIRCLE,
}
