import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"

import { useActionBar } from "../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../routes"
import { ErrorBanner } from "../../v3/components/banner/Banner.component"
import { Loader } from "../../v3/components/loader/Loader.component"
import { useGetAdminInfo } from "../../v3/services/Org.service"
import { ServiceTunnelList } from "./list/ServiceTunnelList.view"
import { ServiceTunnelAdd } from "./add/ServiceTunnelAdd.view"
import styles from "./ServiceTunnel.module.scss"
import { ServiceTunnelEdit } from "./edit/ServiceTunnelEdit.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"

export function ServiceTunnel(): JSX.Element {
    const localization = useServiceLocalization()
    const { data: featureFlags, status: ffStatus } = useFeatureFlags()

    const getAdminInfoQuery = useGetAdminInfo()

    useActionBar({
        title: localization.getString("serviceTunnels"),
        items: [{ label: localization.getString("manageServices") }],
        fetchData: () => {
            getAdminInfoQuery.refetch()
        },
    })

    if (getAdminInfoQuery.status === "loading" || ffStatus === "loading") {
        return (
            <Loader
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("serviceTunnel")
                )}
                medium
                center
            />
        )
    }

    if (getAdminInfoQuery.status === "error" || ffStatus === "error") {
        return <ErrorBanner className={styles.errorContainer} children={getAdminInfoQuery.error} />
    }

    const { canWriteServices } = getAdminInfoQuery.data
    const hideApplicationInServiceTunnnel: boolean =
        !featureFlags.adminConsole.canAccessApplicationInServiceTunnel

    return (
        <Switch>
            <Route exact path={ROUTE.SERVICE_TUNNELS}>
                <ServiceTunnelList
                    canAddServiceTunnel={canWriteServices}
                    enableATG={featureFlags?.adminConsole.enableAccessTierGroups}
                />
            </Route>
            {canWriteServices && (
                <Route exact path={ROUTE.SERVICE_TUNNELS_ADD}>
                    <ServiceTunnelAdd
                        enableATG={featureFlags.adminConsole.enableAccessTierGroups}
                        hideApplication={hideApplicationInServiceTunnnel}
                        hideAccessTier={
                            featureFlags.adminConsole.canAccessConnectorOnlyInServiceTunnel
                        }
                    />
                </Route>
            )}
            <Route exact path={ROUTE.SERVICE_TUNNELS_DETAILS}>
                <ServiceTunnelEdit
                    enableATG={featureFlags?.adminConsole.enableAccessTierGroups}
                    hideApplication={hideApplicationInServiceTunnnel}
                    hideAccessTier={featureFlags.adminConsole.canAccessConnectorOnlyInServiceTunnel}
                    canDeleteServiceTunnel={featureFlags.adminConsole.canDeleteServiceTunnel}
                    canEditServiceTunnel={featureFlags.adminConsole.canEditServiceTunnel}
                />
            </Route>
            {canWriteServices && (
                <Route exact path={ROUTE.SERVICE_TUNNELS_EDIT}>
                    <ServiceTunnelEdit
                        isEditMode
                        enableATG={featureFlags?.adminConsole.enableAccessTierGroups}
                        hideApplication={hideApplicationInServiceTunnnel}
                        hideAccessTier={
                            featureFlags.adminConsole.canAccessConnectorOnlyInServiceTunnel
                        }
                    />
                </Route>
            )}
            <Redirect to={ROUTE.SERVICE_TUNNELS} />
        </Switch>
    )
}
