import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import {
    TrustIntegrationApi,
    TrustIntegrationRes,
    TrustIntegrationSyncStatsRes,
    TrustIntegrationReq,
    TestCredentialsReq,
} from "../api/TrustIntegration.api"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import { LocalizationService } from "../../pre-v3/services/localization/Localization.service"
import {
    isCompliant,
    isNotActiveThreat,
    isRegisteredWith,
    isWS1RegisteredWith,
    isZtaScore,
    TrustProfileService,
} from "./TrustProfile.service"
import { TrustFactorType as TRUST_FACTOR_NAME } from "./shared/TrustFactorType"
import { OrgService } from "./Org.service"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

@Singleton("TrustIntegrationService")
export class TrustIntegrationService {
    public async getTrustIntegrations(): Promise<TrustIntegration[]> {
        const integrations = await this.trustIntegrationApi.getTrustIntegrations()
        return integrations.map(this.mapTrustIntegrationResToTrustIntegration)
    }

    public async getTrustIntegration(id: string): Promise<TrustIntegration> {
        const integrations = await this.trustIntegrationApi.getTrustIntegrations(id)
        const integration = integrations[0]

        if (integration) {
            return this.mapTrustIntegrationResToTrustIntegration(integration)
        } else {
            return Promise.reject(this.localization.getString("trustIntegrationNotFound"))
        }
    }

    public async getSyncStats(id: string): Promise<TrustIntegrationsSyncStats[]> {
        const integrations = await this.trustIntegrationApi.getSyncStats(id)
        return integrations.map(this.mapTrustIntegrationSyncStatsResToTrustIntegrationSyncStats)
    }

    public async downloadSyncStats(id: string, fileName: string): Promise<void> {
        const blob = await this.trustIntegrationApi.getSyncStatsCsv(id)

        const blobUrl = URL.createObjectURL(new Blob([blob], { type: "text/csv" }))
        const link: HTMLAnchorElement = document.createElement("a")
        link.download = `${fileName}.csv`
        link.href = blobUrl
        link.click()
        URL.revokeObjectURL(blobUrl)
    }

    public async deleteTrustIntegration(id: string) {
        await this.trustProfileService.removeTrustProfileIntegration("profileId", id)
        return this.trustIntegrationApi.deleteTrustIntegration(id)
    }

    public async create(trustIntegration: TrustIntegration): Promise<TrustIntegration> {
        // TODO: Remove once every Org has done the Granular Trust Migration
        await this.checkForDuplicatePartner(trustIntegration.integrationPartner)

        await this.testCredentials(trustIntegration)

        const trustIntegrationReq = this.mapTrustIntegrationToTrustIntegrationReq(trustIntegration)
        const res = await this.trustIntegrationApi.create({
            integrationPartner: trustIntegration.integrationPartner,
            data: trustIntegrationReq,
        })
        const integration: TrustIntegration = this.mapTrustIntegrationResToTrustIntegration(res)
        // TODO: update profile id in GTS phase 2
        await this.trustProfileService.updateTrustProfileIntegration("profileId", integration)

        return integration
    }

    public async update(trustIntegration: TrustIntegration): Promise<TrustIntegration> {
        await this.testCredentials(trustIntegration)

        const trustIntegrationReq = this.mapTrustIntegrationToTrustIntegrationReq(trustIntegration)
        const res = await this.trustIntegrationApi.update({
            integrationPartner: trustIntegration.integrationPartner,
            data: trustIntegrationReq,
            id: trustIntegration.id,
        })
        const integration: TrustIntegration = this.mapTrustIntegrationResToTrustIntegration(res)
        // TODO: update profile id in GTS phase 2
        await this.trustProfileService.updateTrustProfileIntegration("profileId", integration)

        return integration
    }

    private async testCredentials(trustIntegration: TrustIntegration): Promise<void> {
        const data = {} as TestCredentialsReq["data"]

        if (trustIntegration.integrationPartner === "crowdstrike") {
            const crowdStrikeIntegration = trustIntegration as CrowdStrikeIntegration
            data.id = crowdStrikeIntegration.apiId
            data.secret = crowdStrikeIntegration.apiSecret
            data.base_url = crowdStrikeIntegration.apiEndpoint
        } else if (trustIntegration.integrationPartner === "sentinelone") {
            const sentinelOneIntegration = trustIntegration as SentinelOneIntegration
            data.api_key = sentinelOneIntegration.apiKey
            data.partner_url = sentinelOneIntegration.apiEndpoint
        } else {
            const workspaceoneIntegration = trustIntegration as WorkspaceOneIntegration
            data.token_url = workspaceoneIntegration.tokenUrl
            data.saas_url = workspaceoneIntegration.saasUrl
            data.client_id = workspaceoneIntegration.clientId
            data.client_secret = workspaceoneIntegration.clientSecret
        }

        return this.trustIntegrationApi.testCredentials({
            integrationPartner: trustIntegration.integrationPartner,
            data,
        })
    }

    private localization = new LocalizationService()
    private orgService = new OrgService()
    private trustIntegrationApi = new TrustIntegrationApi()
    private trustProfileService: TrustProfileService = new TrustProfileService()

    private async checkForDuplicatePartner(integrationPartner: IntegrationPartner): Promise<void> {
        const hasMigrated = await this.orgService.hasGranularTrustMigrationOcurred()

        if (!hasMigrated) {
            const integrations = await this.getTrustIntegrations()

            if (integrations.some((i) => i.integrationPartner === integrationPartner)) {
                return Promise.reject(
                    this.localization.getString(
                        "anIntegrationWithThisPartnerAlreadyExistsUpdateOrDeleteThatIntegration"
                    )
                )
            }
        }
    }

    private mapTrustIntegrationResToTrustIntegration(
        integration: TrustIntegrationRes
    ): TrustIntegration {
        const active = integration.signals.filter((signal) => signal.active)?.length ?? 0
        const total = integration.signals.length

        const trustIntegration: TrustIntegration = {
            id: integration.id,
            syncStatus: integration.sync_status,
            name: integration.name,
            type: integration.type,
            lastBatchUpdatedAt: DateUtil.convertLargeTimestamp(integration.last_batch_update),
            enabledSignals: `${active}/${total}`,
            updatedAt: DateUtil.convertLargeTimestamp(integration.updated_at),
            // Turn empty strings into undefined
            description: integration.description || undefined,
            createdAt: DateUtil.convertLargeTimestamp(integration.created_at),
            createdBy: integration.created_by,
            lastUpdatedBy: integration.last_updated_by,
            integrationPartner: integration.integration_partner,
        }

        if (integration.integration_partner === "crowdstrike") {
            const ztaSeverity = integration.signals.find(isZtaScore)?.signal_min_threshold

            const crowdStrikeIntegration = {
                ...trustIntegration,
                apiId: integration.username,
                apiSecret: integration.password,
                apiEndpoint: integration.api_endpoint,
                factors: [
                    {
                        name: TRUST_FACTOR_NAME.ZTA_SCORE,
                        severity: ztaSeverity,
                        platforms:
                            integration.target_platforms?.map(({ platform }) => platform) || [],
                    },
                ],
            } as CrowdStrikeIntegration

            return crowdStrikeIntegration
        } else if (integration.integration_partner === "sentinelone") {
            const sentinelOneIntegration = {
                ...trustIntegration,
                apiEndpoint: integration.api_endpoint,
                apiKey: integration.api_key,
                apiKeyExpiry: integration.api_key_expiry,
                factors: [
                    {
                        name: TRUST_FACTOR_NAME.NOT_ACTIVE_THREAT,
                        platforms:
                            integration.signals
                                .find(isNotActiveThreat)
                                ?.target_platforms?.map?.(({ platform }) => platform) || [],
                    },
                    {
                        name: TRUST_FACTOR_NAME.REGISTERED_WITH,
                        platforms:
                            integration.signals
                                .find(isRegisteredWith)
                                ?.target_platforms?.map?.(({ platform }) => platform) || [],
                    },
                ],
            } as SentinelOneIntegration

            return sentinelOneIntegration
        } else {
            const workspaceoneIntegration: WorkspaceOneIntegration = {
                ...trustIntegration,
                tokenUrl: integration.authentication_endpoint,
                saasUrl: integration.api_endpoint,
                clientId: integration.username,
                clientSecret: integration.password,
                factors: [
                    {
                        name: TRUST_FACTOR_NAME.WS1_REGISTERED_WITH,
                        platforms:
                            integration.signals
                                .find(isWS1RegisteredWith)
                                ?.target_platforms?.map?.(({ platform }) => platform) || [],
                    },
                    {
                        name: TRUST_FACTOR_NAME.WS1_IS_COMPLIANT,
                        platforms:
                            integration.signals
                                .find(isCompliant)
                                ?.target_platforms?.map?.(({ platform }) => platform) || [],
                    },
                ],
            }

            return workspaceoneIntegration
        }
    }

    private mapTrustIntegrationToTrustIntegrationReq(
        integration: TrustIntegration
    ): TrustIntegrationReq {
        const trustIntegration = {
            name: integration.name,
            description: integration.description,
        } as TrustIntegrationReq

        if (integration.integrationPartner === "crowdstrike") {
            const crowdStrikeIntegration = integration as CrowdStrikeIntegration
            const ztaScore = crowdStrikeIntegration.factors.find(isZtaScore)

            return {
                ...trustIntegration,
                id: crowdStrikeIntegration.apiId,
                secret: crowdStrikeIntegration.apiSecret,
                base_url: crowdStrikeIntegration.apiEndpoint,
                signals: [
                    {
                        name: TRUST_FACTOR_NAME.ZTA_SCORE,
                        signal_min_threshold: parseInt(ztaScore?.severity || "65"),
                    },
                ],
                target_platforms: ztaScore?.platforms || [],
            }
        } else if (integration.integrationPartner === "sentinelone") {
            const sentinelOneIntegration = integration as SentinelOneIntegration
            const notActiveThreatPlatforms =
                sentinelOneIntegration.factors.find(isNotActiveThreat)?.platforms
            const registeredPlatforms =
                sentinelOneIntegration.factors.find(isRegisteredWith)?.platforms

            return {
                ...trustIntegration,
                api_key: sentinelOneIntegration.apiKey,
                partner_url: sentinelOneIntegration.apiEndpoint,
                signals: [
                    {
                        name: TRUST_FACTOR_NAME.NOT_ACTIVE_THREAT,
                        signal_min_threshold: 0,
                        target_platforms: notActiveThreatPlatforms || [],
                    },
                    {
                        name: TRUST_FACTOR_NAME.REGISTERED_WITH,
                        signal_min_threshold: 0,
                        target_platforms: registeredPlatforms || [],
                    },
                ],
            }
        } else {
            const workspaceoneIntegration = integration as WorkspaceOneIntegration
            const compliantPlatforms = workspaceoneIntegration.factors.find(isCompliant)?.platforms
            const registeredPlatforms =
                workspaceoneIntegration.factors.find(isWS1RegisteredWith)?.platforms
            return {
                ...trustIntegration,
                token_url: workspaceoneIntegration.tokenUrl,
                saas_url: workspaceoneIntegration.saasUrl,
                client_id: workspaceoneIntegration.clientId,
                client_secret: workspaceoneIntegration.clientSecret,
                signals: [
                    {
                        name: TRUST_FACTOR_NAME.WS1_REGISTERED_WITH,
                        signal_min_threshold: 0,
                        target_platforms: registeredPlatforms || [],
                    },
                    {
                        name: TRUST_FACTOR_NAME.WS1_IS_COMPLIANT,
                        signal_min_threshold: 0,
                        target_platforms: compliantPlatforms || [],
                    },
                ],
            }
        }
    }

    private mapTrustIntegrationSyncStatsResToTrustIntegrationSyncStats(
        syncStats: TrustIntegrationSyncStatsRes
    ): TrustIntegrationsSyncStats {
        return {
            id: syncStats.id,
            integrationId: syncStats.integration_id,
            numDevicesSynced: syncStats.num_devices_synced,
            failed: syncStats.num_devices_failing_all_signals,
            completed: syncStats.num_devices_passing_all_signals,
            partial: syncStats.num_devices_passing_some_signals,
            unevaluated: syncStats.num_devices_unevaluated,
            status: syncStats.status,
            lastSyncAt: syncStats.last_sync_at,
            createdAt: syncStats.created_at,
            updatedAt: syncStats.updated_at,
        }
    }
}

export function useGetTrustIntegrations(options?: QueryOptions<TrustIntegration[]>) {
    const trustIntegrationService = new TrustIntegrationService()
    return useQuery<TrustIntegration[], string>({
        ...options,
        queryKey: ["trustIntegrationService.getTrustIntegrations"],
        queryFn: trustIntegrationService.getTrustIntegrations.bind(trustIntegrationService),
    })
}

export function useGetTrustIntegration(id: string, options?: QueryOptions<TrustIntegration>) {
    const trustIntegrationService = new TrustIntegrationService()
    return useQuery<TrustIntegration, string>({
        ...options,
        queryKey: ["trustIntegrationService.getTrustIntegration", id],
        queryFn: async (): Promise<TrustIntegration> => {
            return trustIntegrationService.getTrustIntegration(id)
        },
        enabled: Boolean(id),
    })
}

export function useGetSyncStats(id: string, options?: QueryOptions<TrustIntegrationsSyncStats[]>) {
    const trustIntegrationService = new TrustIntegrationService()
    return useQuery<TrustIntegrationsSyncStats[], string>({
        ...options,
        queryKey: ["trustIntegrationService.getSyncStats", id],
        queryFn: async (): Promise<TrustIntegrationsSyncStats[]> => {
            return trustIntegrationService.getSyncStats(id)
        },
        enabled: Boolean(id),
    })
}

export function useCreateTrustIntegration(options?: QueryOptions<TrustIntegration>) {
    const trustIntegrationService = new TrustIntegrationService()
    const queryClient = useQueryClient()
    return useMutation<TrustIntegration, string, TrustIntegration>({
        ...options,
        mutationFn: async (data: TrustIntegration): Promise<TrustIntegration> => {
            return trustIntegrationService.create(data)
        },
        onSuccess: (newIntegration) => {
            if (newIntegration.id) {
                queryClient.setQueryData(
                    ["trustIntegrationService.getTrustIntegrations"],
                    (data: TrustIntegration[] | undefined): TrustIntegration[] | undefined => {
                        return data && [...data, newIntegration]
                    }
                )
            }
            options?.onSuccess?.(newIntegration)
        },
    })
}

export function useDownloadSyncStats(options?: QueryOptions<void, string, [string, string]>) {
    const trustIntegrationService = new TrustIntegrationService()
    const queryClient = useQueryClient()
    return useMutation<void, string, [string, string]>({
        ...options,
        mutationFn: async ([id, fileName]): Promise<void> => {
            return trustIntegrationService.downloadSyncStats(id, fileName)
        },
    })
}

export function useUpdateTrustIntegration(options?: QueryOptions<TrustIntegration>) {
    const trustIntegrationService = new TrustIntegrationService()
    const queryClient = useQueryClient()
    return useMutation<TrustIntegration, string, TrustIntegration>({
        ...options,
        mutationFn: async (data: TrustIntegration): Promise<TrustIntegration> => {
            return trustIntegrationService.update(data)
        },
        onSuccess: (editedIntegration) => {
            if (editedIntegration.id) {
                queryClient.setQueryData(
                    ["trustIntegrationService.getTrustIntegrations"],
                    (data: TrustIntegration[] | undefined): TrustIntegration[] | undefined => {
                        const updatedList = data?.map((accessTier) => {
                            return accessTier.id === editedIntegration.id
                                ? editedIntegration
                                : accessTier
                        })
                        return updatedList
                    }
                )
            }
            options?.onSuccess?.(editedIntegration)
        },
    })
}

export function useDeleteTrustIntegration(options?: QueryOptions) {
    const trustIntegrationService = new TrustIntegrationService()
    const queryClient = useQueryClient()

    return useMutation<void, string, string>({
        ...options,
        mutationFn: async (id: string): Promise<void> => {
            if (id) {
                await trustIntegrationService.deleteTrustIntegration(id)
            }
        },
        onSuccess: (_res, id) => {
            if (id) {
                queryClient.setQueryData(
                    ["trustIntegrationService.getTrustIntegrations"],
                    (list: TrustIntegration[] | undefined): TrustIntegration[] | undefined => {
                        return list?.filter((accessTier) => accessTier.id !== id)
                    }
                )
            }
            options?.onSuccess?.()
        },
    })
}

export type TrustIntegrationsSyncStats = {
    id: string
    integrationId: string
    numDevicesSynced: number
    completed: number
    partial: number
    failed: number
    unevaluated: number
    status: string
    lastSyncAt: number
    createdAt: number
    updatedAt: number
}

export interface TrustIntegration {
    name: string
    type: string
    id: string
    syncStatus: "inactive" | "pending" | "active" | "error"
    enabledSignals: string
    lastBatchUpdatedAt: number
    updatedAt: number
    description?: string
    createdAt: number
    createdBy: string
    lastUpdatedBy: string
    integrationPartner: IntegrationPartner
}

export type IntegrationPartner = "crowdstrike" | "sentinelone" | "workspaceone"

export interface SentinelOneIntegration extends TrustIntegration {
    apiKey: string
    apiEndpoint: string
    apiKeyExpiry: string
    factors: {
        name: TRUST_FACTOR_NAME.NOT_ACTIVE_THREAT | TRUST_FACTOR_NAME.REGISTERED_WITH
        platforms: Platform[]
    }[]
}
export type ZtaSeverity = "65" | "75"

export interface CrowdStrikeIntegration extends TrustIntegration {
    apiId: string
    apiSecret: string
    apiEndpoint: string
    factors: {
        name: TRUST_FACTOR_NAME.ZTA_SCORE
        severity: ZtaSeverity
        platforms: Platform[]
    }[]
}

export type Platform = "macos" | "windows" | "linux" | "ios" | "android"

export interface WorkspaceOneIntegration extends TrustIntegration {
    clientId: string
    clientSecret: string
    tokenUrl: string
    saasUrl: string
    factors: {
        name: TRUST_FACTOR_NAME.WS1_REGISTERED_WITH | TRUST_FACTOR_NAME.WS1_IS_COMPLIANT
        platforms: Platform[]
    }[]
}
