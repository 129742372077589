import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
import { SelectItem } from "../../../pre-v3/utils/SelectValue.util"
import { InfoIcon } from "../info-icon/InfoIcon.component"
import { MultiInput } from "../multi-input/MultiInput.component"
import { SelectInput } from "../select-input/SelectInput.component"
import styles from "./CidrsInput.module.scss"

export interface Props {
    cidrs: string[]
    className?: string
    isDisabled?: boolean
    onChange(cidrs: string[]): void
}

export function CidrsInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const [cidrType, setCidrType] = React.useState(() =>
        !isRfcAddresses(props.cidrs) ? CidrType.CUSTOM_CIDR : CidrType.RFC_ADDRESS
    )

    const onCidrTypeChange = (cidrType: CidrType) => {
        setCidrType(cidrType)
        props.onChange(cidrType === CidrType.RFC_ADDRESS ? rfcAddresses : [])
    }

    const cidrTypeOptions = useCidrTypeOptions()

    return (
        <div className={classNames(styles.container, props.className)}>
            <SelectInput
                className={styles.cidrType}
                value={cidrType}
                onChange={onCidrTypeChange}
                disabled={props.isDisabled}
                options={cidrTypeOptions}
            />
            <InfoIcon
                className={styles.infoIcon}
                tooltip={localization.getString("cidrRangeToolTipDesc")}
            />
            <div className={styles.multiInputWrapper}>
                {cidrType === CidrType.RFC_ADDRESS && (
                    <MultiInput max={3} values={rfcAddresses} disabled />
                )}
                {cidrType === CidrType.CUSTOM_CIDR && (
                    <MultiInput
                        required
                        onChange={props.onChange}
                        values={props.cidrs}
                        disabled={props.isDisabled}
                        placeholder={localization.getString("cidrAddressExample")}
                        patternProps={{
                            pattern: PatternUtil.RFC_1918_CIDR.source,
                            errorMessage: localization.getString("rfcAddressCidrPlaceholder"),
                        }}
                    />
                )}
            </div>
        </div>
    )
}

enum CidrType {
    RFC_ADDRESS = "RFC_ADDRESS",
    CUSTOM_CIDR = "CUSTOM_CIDR",
}

function useCidrTypeOptions(): SelectItem[] {
    const localization = useServiceLocalization()

    return React.useMemo(
        () => [
            {
                displayName: localization.getString("rfc1918Addresses"),
                value: CidrType.RFC_ADDRESS,
            },
            {
                displayName: localization.getString("customCidr"),
                value: CidrType.CUSTOM_CIDR,
            },
        ],
        [localization]
    )
}

export const rfcAddresses = ["10.0.0.0/8", "172.16.0.0/12", "192.168.0.0/16"]

function isRfcAddresses(cidrs: string[]): boolean {
    return (
        cidrs.length <= 0 ||
        cidrs === rfcAddresses ||
        (rfcAddresses.length === cidrs.length && rfcAddresses.every((cidr) => cidrs.includes(cidr)))
    )
}
