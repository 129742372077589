import React from "react"
import { AccessGroupInformation } from "./AccessGroupInfo.component"
import { CollapsibleCard } from "../../../components/collapsible-card/CollapsibleCard.component"
import {
    L4RuleExtended,
    PolicyAccessExtended,
    PolicyL4AccessExtended,
} from "../../../v3/services/Policy.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { FormLabel } from "../../../components/form/FormLabel.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import styles from "./ConfigureAccessGroup.module.scss"
import { PolicyDestinationCard } from "./PolicyDestinationCard.component"
import { l4AccessKey } from "./AccessPolicy.types"
import { emptyDestination } from "./helper"

export interface Props {
    policyAccess: PolicyAccessExtended
    disabled?: boolean
    onAccessGroupChange(accessGroupInfo: PolicyAccessExtended): void
    onDeleteAccessGroup(): void
    id: number
}

export function ConfigureAccessGroup(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const onAccessGroupInfoChange = (accessGroupInfo: PolicyAccessExtended) => {
        props.onAccessGroupChange({
            ...props.policyAccess,
            name: accessGroupInfo.name,
            description: accessGroupInfo.description,
            roles: accessGroupInfo.roles,
            rules: accessGroupInfo.rules,
        })
    }

    const onDestinationChange = (
        type: keyof PolicyL4AccessExtended,
        policyDestinations: L4RuleExtended[]
    ) => {
        let l4Access: PolicyL4AccessExtended
        if (type === l4AccessKey.ALLOW) {
            l4Access = {
                deny: props.policyAccess.rules.l4_access?.deny || [],
                allow: policyDestinations,
            }
        } else {
            l4Access = {
                allow: props.policyAccess.rules.l4_access?.allow || [],
                deny: policyDestinations,
            }
        }

        props.onAccessGroupChange({
            ...props.policyAccess,
            rules: {
                ...props.policyAccess.rules,
                l4_access: l4Access,
            },
        })
    }

    return (
        <CollapsibleCard
            id={"accessGroup"}
            title={
                props.policyAccess.name ||
                `${localization.getString("accessGroup#")}${props.id + 1}`
            }
            key={props.id}
        >
            <div className={styles.labelContainer}>
                <FormLabel
                    labelClassName={styles.labelClassName}
                    label={localization.getString("configureAccessGroup")}
                    description={localization.getString("configureAccessGroupDesc")}
                ></FormLabel>

                {!props.disabled && (
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.DESTRUCTIVE}
                        onClick={props.onDeleteAccessGroup}
                        icon={IconType.MINUS}
                        className={styles.removeButton}
                        type="button"
                    >
                        {localization.getString("removeAccessGroup")}
                    </Button>
                )}
            </div>

            <AccessGroupInformation
                accessGroupInfo={props.policyAccess}
                onAccessGroupInfoChange={onAccessGroupInfoChange}
                disabled={props.disabled}
            />

            <PolicyDestinationCard
                policyDestinations={
                    props.policyAccess.rules.l4_access?.allow.length
                        ? props.policyAccess.rules.l4_access?.allow
                        : [emptyDestination]
                }
                disabled={props.disabled}
                onDestinationsChange={(destination) =>
                    onDestinationChange(l4AccessKey.ALLOW, destination)
                }
                id={`${props.policyAccess.name}-${localization.getString("allowList")}`}
                title={localization.getString("allowList")}
                disableDelete={props.policyAccess.rules.l4_access?.allow.length === 1}
            />

            <PolicyDestinationCard
                policyDestinations={
                    props.policyAccess.rules.l4_access?.deny?.length
                        ? props.policyAccess.rules.l4_access?.deny
                        : [emptyDestination]
                }
                disabled={props.disabled}
                onDestinationsChange={(destination) =>
                    onDestinationChange(l4AccessKey.DENY, destination)
                }
                id={`${props.policyAccess.name}-${localization.getString("exceptionList")}`}
                title={localization.getString(
                    "somethingOptional",
                    localization.getString("exceptionList")
                )}
                defaultClosed
            />
        </CollapsibleCard>
    )
}
