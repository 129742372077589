import { ColDef, ValueFormatterParams } from "ag-grid-community"
import React, { ReactNode } from "react"
import { RouteComponentProps } from "react-router"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { SimpleTableItem } from "../../../../v3/components/simple-table/SimpleTable.component"
import { PolicySecure, SecureService } from "../../../services/Secure.service"
import { LocalizationService } from "../../../services/localization/Localization.service"
import ActionBarService from "../../../services/ActionBar.service"
import { Bind } from "../../../decorators/Bind.decorator"
import { DateUtil } from "../../../utils/Date.util"
import { PolicyStatusCellRenderer } from "../cell-renderer/status/PolicyStatusCellRenderer.component"
import AgGridUtil from "../../../utils/AgGrid.util"
import { UserService } from "../../../services/User.service"
import { UserOrgDetails } from "../../../api/User.api"
import { Tab } from "../../../components/tab-bar/TabBar.component"
import PolicyDetailTemplate from "./PolicyDetail.template"

export class PolicyDetail extends React.Component<
    RouteComponentProps<PolicyDetailRouteParams>,
    PolicyDetailState
> {
    public state: PolicyDetailState = { loading: true, readOnly: true, tab: 1 }

    public render(): ReactNode {
        return PolicyDetailTemplate.call(this)
    }

    public componentDidMount(): void {
        this.policyId = this.props.match.params.id
        if (this.policyId) {
            this.fetchData()
        } else {
            this.setState({ loading: false })
        }

        this.userService.getUserOrgDetails().then((details: UserOrgDetails) => {
            const readOnly: boolean = !this.userService.canCreatePolicies(details.Profile)
            this.setState({ readOnly })
        })
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.fetchData)
    }

    private localizationService: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private secureService: SecureService = new SecureService()
    private userService: UserService = new UserService()

    private policyId: string

    private attachmentColumns: ColDef[] = [
        {
            headerName: this.localizationService.getString("serviceName"),
            field: "serviceName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: "servicesCellRenderer",
        },
        {
            headerName: this.localizationService.getString("attachedOn"),
            field: "attachedAt",
            valueFormatter: AgGridUtil.dateFormatter,
        },
        {
            headerName: this.localizationService.getString("status"),
            field: "enabled",
            valueFormatter: this.enforcementFormatter,
        },
    ]

    private getTabs(): Tab[] {
        return [
            {
                label: this.localizationService.getString("specification"),
                value: 1,
                active: true,
            },
            {
                label: `${this.localizationService.getString(
                    "services"
                )} (${this.getServicesCount()})`,
                value: 2,
            },
        ]
    }

    private getServicesCount(): number {
        return this.state.policy ? this.state.policy.attachments.length : 0
    }

    @Bind
    private onTabChange(value: any): void {
        this.setState({ tab: value })
    }

    private setDataTableItems(policy: PolicySecure): void {
        this.setState({
            statusTableItems: [
                {
                    label: this.localizationService.getString("version"),
                    value: "v" + policy.version,
                },
                {
                    label: this.localizationService.getString("lastUpdated"),
                    value: DateUtil.format(policy.lastUpdatedAt),
                },
            ],
            dataTableItems: [
                {
                    label: this.localizationService.getString("description"),
                    value: policy.description,
                },
                {
                    label: this.localizationService.getString("createdAt"),
                    value: DateUtil.format(policy.createdAt),
                },
                {
                    label: this.localizationService.getString("createdBy"),
                    value: policy.createdBy,
                },
                {
                    label: this.localizationService.getString("lastUpdatedBy"),
                    value: policy.lastUpdatedBy,
                },
            ],
            reportingStatus: React.createElement(PolicyStatusCellRenderer, { data: policy } as any),
        })
    }

    @Bind
    private enforcementFormatter(params: ValueFormatterParams): string {
        if (params.value) {
            return this.localizationService.getString("enforcing")
        } else {
            return this.localizationService.getString("permissive")
        }
    }

    @Bind
    private onEdit(): void {
        this.props.history.push(formatRoutePath(ROUTE.ACCESS_POLICIES_EDIT, { id: this.policyId }))
    }

    @Bind
    private downloadSpec(): void {
        if (!this.state.policy) {
            return
        }

        // create the blob object
        const blob: Blob = new Blob(
            [JSON.stringify(JSON.parse(this.state.policy.spec), null, 4) + "\n"],
            {
                type: "application/json",
            }
        )

        // download the blob with a ghost anchor
        const a = document.createElement("a")
        document.body.appendChild(a)
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = this.state.policy.name + ".json"
        a.click()
        setTimeout(() => {
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
        }, 0)
    }

    @Bind
    private fetchData(): void {
        this.setState({ loading: true, error: "" })
        if (this.policyId) {
            this.secureService.getPolicy(this.policyId).then(
                (policy: PolicySecure) => {
                    this.setState({ loading: false, policy: policy })
                    this.setDataTableItems(policy)
                },
                (err: Error) => {
                    this.setState({ loading: false, error: err.message })
                }
            )
        } else {
            this.setState({
                loading: false,
                error: this.localizationService.getString("policyNotFound"),
            })
        }
    }
}

interface PolicyDetailRouteParams {
    id: string
}

interface PolicyDetailState {
    loading?: boolean
    error?: string
    tab?: any
    policy?: PolicySecure
    statusTableItems?: SimpleTableItem[]
    dataTableItems?: SimpleTableItem[]
    readOnly: boolean
    reportingStatus?: any
}
