import { faShieldAlt, faVectorSquare } from "@fortawesome/pro-regular-svg-icons"
import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import { ROUTE } from "../../../routes"
import { useGetAdminInfo, useIsTeamEdition } from "../../services/Org.service"
import { Loader } from "../../components/loader/Loader.component"
import { TrustIntegrationsList } from "./list/TrustIntegrationList.view"
import { TrustIntegrationOverview } from "./overview/TrustIntegrationOverview.view"
import { CreateTrustIntegration } from "./add/CreateTrustIntegration.view"
import { EditTrustIntegration } from "./edit/EditTrustIntegration.view"
import {
    ContactUsScreen,
    ContactUsScreenSteps,
} from "../../components/page-screen/PageScreen.component"
import { LinkService, useServiceLocalization } from "../../../pre-v3/services"
import banyanRoute from "../../../images/banyan-route.svg"
import { Container } from "../../components/container/Container.component"

export function TrustIntegration() {
    const adminInfoQuery = useGetAdminInfo()
    const linkService = new LinkService()
    const { data: isTeamEdition, status: editionStatus } = useIsTeamEdition()
    const localization = useServiceLocalization()

    if (adminInfoQuery.status !== "success" || editionStatus !== "success")
        return <Loader children isLoading center />

    const { canWriteAll } = adminInfoQuery.data

    return (
        <Switch>
            {isTeamEdition ? (
                <Container>
                    <ContactUsScreen
                        image={banyanRoute}
                        title={localization.getString(
                            "upgradeForSomething",
                            localization.getString("trustIntegration")
                        )}
                        docsLink={linkService.getLink("trustIntegrationDoc")}
                        subText={localization.getString("expandDeviceSecurityPostureCheckDesc")}
                    >
                        <ContactUsScreenSteps
                            steps={[
                                {
                                    icon: faShieldAlt,
                                    label: localization.getString(
                                        "allowOrganizationsOtherSecurityDesc"
                                    ),
                                },
                                {
                                    icon: faVectorSquare,
                                    label: localization.getString("createASecureChannelDesc"),
                                },
                            ]}
                        />
                    </ContactUsScreen>
                </Container>
            ) : (
                <Route exact path={ROUTE.INTEGRATIONS}>
                    <TrustIntegrationsList canWriteAll={canWriteAll} />
                </Route>
            )}
            {canWriteAll ? (
                <Route exact path={ROUTE.INTEGRATIONS_CREATE} component={CreateTrustIntegration} />
            ) : (
                <Redirect from={ROUTE.INTEGRATIONS_CREATE} to={ROUTE.INTEGRATIONS} />
            )}
            {canWriteAll ? (
                <Route exact path={ROUTE.INTEGRATIONS_EDIT} component={EditTrustIntegration} />
            ) : (
                <Redirect from={ROUTE.INTEGRATIONS_EDIT} to={ROUTE.INTEGRATIONS_DETAILS} />
            )}
            <Route exact path={ROUTE.INTEGRATIONS_DETAILS}>
                <TrustIntegrationOverview canWriteAll={canWriteAll} />
            </Route>
            <Redirect to={ROUTE.INTEGRATIONS} />
        </Switch>
    )
}
