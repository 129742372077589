import React from "react"
import styles from "./ServiceTunnelConfigurationSettings.module.scss"
import { useServiceActionBar } from "../../pre-v3/services/ActionBar.service"
import { ErrorBanner } from "../../pre-v3/components/banner/Banner.component"
import {
    useUpdateNrptConfig,
    useGetAppConfig,
} from "../../pre-v3/services/ServiceTunnelConfigurationSettings.service"
import { SettingsService } from "../../pre-v3/services/Settings.service"
import { Toolbar } from "../../pre-v3/components/toolbar/Toolbar.component"
import { Grid } from "../../pre-v3/components/grid/Grid.component"
import { FormRow } from "../../v3/components/form/FormRow.component"
import { ToggleButton } from "../../pre-v3/components/toggle-button/ToggleButton.component"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import AgGridUtil from "../../pre-v3/utils/AgGrid.util"
import { useHistory } from "react-router-dom"
import { ErrorToast, SuccessToast, ToastApi } from "../../components/toast/Toast.components"

interface Props {
    canUpdateNrptSetting?: boolean
}

export function ServiceTunnelConfigurationSettings(props: Props) {
    const localization = useServiceLocalization()
    const history = useHistory()
    const [error, setError] = React.useState<string | null>(null)

    const actionBarService = useServiceActionBar()
    const settingsService: SettingsService = new SettingsService()
    const { data: appConfig, isLoading: nrptLoading } = useGetAppConfig()
    const { mutate: toggleNrptConfig } = useUpdateNrptConfig({
        onSuccess: () => {
            successToastRef.current?.openToast(
                localization.getString("nrptConfigUpdatedSuccessfully")
            )
        },
        onError: (error) => {
            errorToastRef.current?.openToast(error)
        },
    })

    const errorToastRef = React.useRef<ToastApi>(null)
    const successToastRef = React.useRef<ToastApi>(null)

    const gridApi = React.useRef<GridApi>()
    const columns: ColDef[] = [
        {
            headerName: localization.getString("tunnelEndpointType"),
            field: "usedFor",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
        },
        {
            headerName: localization.getString("cidrRanges"),
            field: "cidrRange",
        },
        {
            headerName: localization.getString("createdBy"),
            field: "createdBy",
        },
        {
            headerName: localization.getString("createdAt"),
            field: "createdAt",
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    React.useEffect(() => {
        actionBarService.setItems(
            localization.getString("serviceTunnelCidrRanges"),
            [
                {
                    label: localization.getString("adminSettings"),
                },
            ],
            fetchData
        )
    }, [])

    const onGridReady = (event: GridReadyEvent) => {
        gridApi.current = event.api
        fetchData()
    }

    async function fetchData(): Promise<void> {
        if (gridApi.current) {
            setError(null)
            gridApi.current.showLoadingOverlay()

            try {
                const epCidrs = await settingsService.getOrgTunnelEndpointCidrs()
                gridApi.current.setRowData(epCidrs)
                gridApi.current.hideOverlay()
            } catch (e) {
                setError(localization.getString("errorCouldNotFetchServiceTunnelSettings"))
                gridApi.current.hideOverlay()
            }
        }
    }

    const onChangeNrptConfig = async (value: boolean) => {
        toggleNrptConfig({ value, appConfig })
    }

    const toggleItems = React.useMemo(() => {
        return [
            {
                label: localization.getString("enable"),
                onClick: () => onChangeNrptConfig(true),
                selected: appConfig?.nrptConfig,
            },
            {
                label: localization.getString("disable"),
                onClick: () => onChangeNrptConfig(false),
                selected: !appConfig?.nrptConfig,
            },
        ]
    }, [appConfig])

    const onFilter = (value: string) => {
        if (gridApi.current) gridApi.current.setQuickFilter(value)
    }

    return (
        <>
            <div className={styles.gridWithToolbar}>
                {error && <ErrorBanner> {error} </ErrorBanner>}
                <Toolbar onSearchChange={onFilter} className={styles.toolBar}></Toolbar>
                <Grid
                    onGridReady={onGridReady}
                    columnDefs={columns}
                    context={{ history: history }}
                    autoHeight
                />

                {!nrptLoading && (
                    <FormRow
                        label={localization.getString("useNrptRulesForWindowsDnsResolution")}
                        description={localization.getString(
                            "useNrptRulesForWindowsDnsResolutionDesc"
                        )}
                        className={styles.nrptConfig}
                    >
                        <ToggleButton items={toggleItems} disabled={!props.canUpdateNrptSetting} />
                    </FormRow>
                )}
            </div>
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
        </>
    )
}
