import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    ITooltipParams,
    RowDoubleClickedEvent,
} from "ag-grid-community"
import React, { useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import UpgradeFullIllustration from "../../../../images/upgrade-full-page.svg"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil, { FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"
import { EmptyStateScreen } from "../../../components/page-screen/PageScreen.component"
import { Status, StatusType } from "../../../components/status/Status.component"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { Link } from "../../../components/link/Link.component"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { useDebouncedCallback } from "../../../../pre-v3/utils/UseDebouncedCallback.hook"
import { Loader } from "../../../components/loader/Loader.component"
import {
    Connector,
    ConnectorStatus,
    getConnectors,
    LinuxInstallationMethod,
    refreshGetConnectors,
    ServerEnvironments,
    useGetConnectors,
} from "../../../services/Connector.service"
import styles from "./ConnectorList.module.scss"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import { AppText } from "../../../components/app-text/AppText.component"
import { useServiceLinks } from "../../../../pre-v3/services"
import { formatToLocalDateTimeStr } from "../../../../utils/Date.utils"

interface Props {
    canCreateConnector: boolean
}

export function ConnectorList(props: Props) {
    const [error, setError] = useState<string>("")
    const [filter, setFilter] = useState<string>("")

    const gridApi = useRef<GridApi>()

    const localization = useServiceLocalization()
    const links = useServiceLinks()
    const history = useHistory()

    const connectorsLabel = localization.getString("connectors")

    const columns: ColDef[] = [
        {
            headerName: localization.getString("status"),
            field: "status",
            flex: 140,
            cellRenderer: StatusCellRenderer,
            tooltipValueGetter: (params: ITooltipParams<Connector>) => {
                if (!params.data?.status) return params.value
                return localization.getString(statusToolTipMap[params.data.status])
            },
            sortable: false,
        },
        {
            headerName: localization.getString("name"),
            field: "displayName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 150,
            cellRenderer: NameCellRenderer,
            filter: "agTextColumnFilter",
            sortable: false,
        },
        {
            headerName: localization.getString("version"),
            field: "connectorVersion",
            valueFormatter: AgGridUtil.nullableStringFormatter,
            sortable: false,
        },
        {
            headerName: localization.getString("installMethod"),
            field: "deployment",
            cellRenderer: InstallMethodCellRenderer,
            sortable: false,
        },
        {
            headerName: localization.getString("privateDomains"),
            field: "domains",
            valueFormatter: AgGridUtil.truncatedListFormatter,
            autoHeight: true,
            cellClass: "ag-cell-autoheight",
            sortable: false,
        },
        {
            headerName: localization.getString("lastReported"),
            field: "lastConnectTime",
            cellRenderer: LastReportedCellRender,
            sortable: false,
        },
    ]

    const {
        data: connectors,
        isLoading: isConnectorsLoading,
        refetch: refetchConnectors,
        error: connectorsError,
    } = useGetConnectors()

    function onRefresh() {
        // TODO: Remove when we stop using imperative Grid API
        refreshGetConnectors()
        refetchConnectors()
        gridApi.current?.refreshServerSide()
    }

    function onFilterChange(newFilter: string): void {
        setFilter(newFilter)
        setFilterModel(newFilter)
    }

    const setFilterModel = useDebouncedCallback((filter: string) => {
        if (gridApi.current) {
            gridApi.current.setFilterModel({ displayName: { filter } })
        }
    }, [])

    function onRowDoubleClicked(event: RowDoubleClickedEvent): void {
        if (event?.data) {
            history.push(formatRoutePath(ROUTE.CONNECTORS_DETAILS, { id: encodeID(event.data.id) }))
        }
    }

    function onGridReady(event: GridReadyEvent): void {
        if (event?.api) {
            gridApi.current = event.api

            fetchData()
        }
    }

    function onDataLoaded(error?: string): void {
        setError(error || "")
    }

    function fetchData(): void {
        gridApi.current?.setServerSideDatasource(Datasource(gridApi.current, onDataLoaded))
    }

    if (isConnectorsLoading) {
        return (
            <Loader
                children
                isLoading
                center
                medium
                title={localization.getString("loadingSomething", connectorsLabel)}
            />
        )
    }

    if (connectors?.total === 0) {
        return (
            <EmptyStateScreen
                title={localization.getString("createYourFirstConnector")}
                subText={localization.getString("createYourFirstConnectorDescription")}
                buttonProps={
                    props.canCreateConnector
                        ? {
                              buttonText: localization.getString("createConnector"),
                              buttonLink: ROUTE.CONNECTORS_ADD,
                          }
                        : undefined
                }
            >
                <img
                    src={UpgradeFullIllustration}
                    className={styles.screen}
                    alt={localization.getString("createYourFirstConnectorDescription")}
                />
            </EmptyStateScreen>
        )
    }

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("connectors")}</PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={onRefresh}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <ErrorBanners errors={[error, connectorsError ? String(connectorsError) : null]} />
            <div className={styles.toolbar}>
                <div className={styles.leftContainer}>
                    <AppText
                        ls={{
                            key: "clickHereToViewConnectorStatusDescription",
                            replaceVals: [links.getLink("connectorDoc")],
                        }}
                    />
                    <SearchInput
                        value={filter}
                        onChangeValue={onFilterChange}
                        placeholder={localization.getString("search")}
                    />
                </div>
                {props.canCreateConnector && <CreateConnectorButton />}
            </div>
            <Grid
                onGridReady={onGridReady}
                columnDefs={columns}
                serverSidePagination
                onRowDoubleClicked={onRowDoubleClicked}
            />
        </section>
    )
}

enum Id {
    HEADING = "heading",
}

function Datasource(gridApi: GridApi, callback: (error?: string) => void): IServerSideDatasource {
    return {
        getRows: async (params: IServerSideGetRowsParams) => {
            const skip: number = params.request.startRow || 0
            const limit: number = (params.request.endRow || 0) - skip
            const filterModel: FilterModel = params.request.filterModel

            gridApi.showLoadingOverlay()

            try {
                const res = await getConnectors({ skip, limit, filterModel })

                params.success({
                    rowData: res.data,
                    rowCount: res.total,
                })

                if (res.total === 0) {
                    gridApi.showNoRowsOverlay()
                } else {
                    gridApi.hideOverlay()
                }

                callback()
            } catch (error) {
                params.fail()
                gridApi.showNoRowsOverlay()

                if (typeof error === "string") {
                    callback(error)
                }
            }
        },
    }
}

function StatusCellRenderer(props: ICellRendererParams) {
    const localization = useServiceLocalization()
    const connector: Connector = props.data
    if (!connector.status) {
        return props.value
    }
    return (
        <Status
            type={statusMap[connector.status]}
            label={localization.getString(labelMap[connector.status])}
        />
    )
}

const statusMap: Record<ConnectorStatus, StatusType> = {
    Reporting: "success",
    PartiallyReporting: "warning",
    Terminated: "error",
    Pending: "disabled",
}

const statusToolTipMap: Record<ConnectorStatus, LanguageKey> = {
    Reporting: "reportingExplanation",
    PartiallyReporting: "partiallyReportingExplanation",
    Terminated: "terminatedExplanation",
    Pending: "pendingExplanation",
}

const labelMap: Record<ConnectorStatus, LanguageKey> = {
    Reporting: "reporting",
    PartiallyReporting: "partiallyReporting",
    Terminated: "terminated",
    Pending: "pending",
}

function NameCellRenderer(props: ICellRendererParams) {
    const connector: Connector = props.data
    if (!connector.id) {
        return props.value
    }
    return (
        <Link to={formatRoutePath(ROUTE.CONNECTORS_DETAILS, { id: encodeID(connector.id) })}>
            {props.value}
        </Link>
    )
}

function InstallMethodCellRenderer(props: ICellRendererParams) {
    const localization = useServiceLocalization()
    const connector: Connector = props.data
    const { deployment } = connector

    if (!deployment) return "-"

    if (deployment.platform === ServerEnvironments.WINDOWS)
        return localization.getString("windowsExecutable")

    if (deployment.platform === ServerEnvironments.LINUX) {
        if (deployment.method === LinuxInstallationMethod.DOCKER)
            return localization.getString("linuxDocker")
        return localization.getString("linuxTarball")
    }
}

function LastReportedCellRender(props: ICellRendererParams) {
    const localization = useServiceLocalization()
    const connector: Connector = props.data
    const { lastConnectTime } = connector
    if (!lastConnectTime) return "-"
    return formatToLocalDateTimeStr(lastConnectTime, localization.getLocale())
}

interface CreateConnectorButtonProps {
    isDisabled?: boolean
}

function CreateConnectorButton(props: CreateConnectorButtonProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <Button
            {...(props.isDisabled
                ? { asElement: ButtonElement.BUTTON, disabled: true }
                : { asElement: ButtonElement.LINK, to: ROUTE.CONNECTORS_ADD })}
            buttonType={ButtonType.PRIMARY}
            icon={IconType.PLUS}
        >
            {localization.getString("createConnector")}
        </Button>
    )
}
