import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { BaseApi } from "./Base.api"

@Singleton("RegisteredServiceApi")
export class RegisteredServiceApi extends BaseApi {
    public getRegisteredServices(): Promise<RegisteredServicesRes[]> {
        return this.get(`/api/v1/registered_services`, {
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("registeredServices")
            ),
        })
    }
}

export interface RegisteredServicesRes {
    ServiceSpec: string
}

export interface ServiceSpecJson {
    spec?: {
        attributes?: {
            host_tag_selector?: HostTagSelector[]
        }
    }
}

export interface HostTagSelector {
    ["com.banyanops.hosttag.site_name"]?: string
}
