import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import { useAdminInfo } from "../../hooks/useAdminInfo.hook"
import { getAssignedConsoleRoute } from "../../hooks/useProtectPages.hook"
import { ROUTE } from "../../routes"
import { Loader } from "../../v3/components/loader/Loader.component"

export function AllRedirects(): JSX.Element {
    const location = useLocation()

    return (
        <Switch>
            {Object.entries(redirectData).map(([from, to]) => (
                <Redirect
                    from={from}
                    to={{ pathname: to, search: location.search }}
                    key={`${from}-${to}`}
                />
            ))}
            <Route component={FallbackRoute} />
        </Switch>
    )
}

// Remember to order the paths from more specific to less specific when paths share a prefix
const redirectData = {
    "/app/directory/devices/:serialNumber": ROUTE.DEVICES_DETAILS,
    "/app/directory/devices": ROUTE.DEVICES,
    "/app/directory/service-accounts/add": ROUTE.SERVICE_ACCOUNTS_ADD,
    "/app/directory/service-accounts/:id": ROUTE.SERVICE_ACCOUNTS_DETAILS,
    "/app/directory/service-accounts": ROUTE.SERVICE_ACCOUNTS,
    "/app/directory/unregistered-devices": ROUTE.UNREGISTERED_DEVICES,
    "/app/directory/users/add": ROUTE.USERS_ADD,
    "/app/directory/users/edit/:id": ROUTE.USERS_EDIT,
    "/app/directory/users/:id": ROUTE.USERS_DETAILS,
    "/app/directory/users": ROUTE.USERS,
    "/app/directory": ROUTE.DIRECTORY,
    "/app/everboarding/get-started": ROUTE.HOME,
    "/app/everboarding/smart-search/history": ROUTE.GET_HELP_SMART_SEARCH_HISTORY,
    "/app/everboarding/smart-search/result": ROUTE.GET_HELP_SMART_SEARCH_RESULT,
    "/app/everboarding/smart-search": ROUTE.GET_HELP_SMART_SEARCH_RESULT,
    "/app/everboarding": ROUTE.HOME,
    "/app/get-started": ROUTE.HOME,
    "/app/granular-trust-migration-education": ROUTE.GRANULAR_TRUST_MIGRATION_EDUCATION,
    "/admin-console/manage/iaas-inventory/:id/publish": ROUTE.IAAS_DISCOVERY_PUBLISH,
    "/admin-console/manage/iaas-inventory/:id": ROUTE.IAAS_DISCOVERY_DETAILS,
    "/admin-console/manage/iaas-inventory": ROUTE.IAAS_DISCOVERY,
    "/admin-console/manage/private-resource/:id/access-activity": ROUTE.DISCOVERY_ACCESS_ACTIVITY,
    "/admin-console/manage/private-resource/:id/ips": ROUTE.DISCOVERY_IPS,
    "/admin-console/manage/private-resource/:id/network": ROUTE.DISCOVERY_NETWORK,
    "/admin-console/manage/private-resource/:id": ROUTE.DISCOVERY_DETAILS,
    "/admin-console/manage/private-resource": ROUTE.DISCOVERY,
    "/admin-console/manage/service-tunnel": ROUTE.SERVICE_TUNNELS,
    "/admin-console/manage/services/:id": ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS,
    "/admin-console/manage": ROUTE.PRIVATE_ACCESS,
    "/admin-console/private-access/hosted-websites": ROUTE.HOSTED_WEBSITES,
    "/admin-console/private-access/hosted-websites/add": ROUTE.HOSTED_WEBSITES_ADD,
    "/admin-console/private-access/hosted-websites/:id": ROUTE.HOSTED_WEBSITES_DETAILS,
    "/admin-console/private-access/infrastructure": ROUTE.INFRASTRUCTURE,
    "/admin-console/private-access/infrastructure/add": ROUTE.INFRASTRUCTURE_ADD,
    "/admin-console/private-access/infrastructure/:id": ROUTE.INFRASTRUCTURE_DETAILS,
    "/admin-console/private-access/infrastructure/:id/edit": ROUTE.INFRASTRUCTURE_EDIT,
    "/admin-console/private-access/service-tunnel/add": ROUTE.SERVICE_TUNNELS_ADD,
    "/admin-console/private-access/service-tunnel/list": ROUTE.SERVICE_TUNNELS,
    "/admin-console/private-access/service-tunnel/:id/edit": ROUTE.SERVICE_TUNNELS_EDIT,
    "/admin-console/private-access/service-tunnel/:id": ROUTE.SERVICE_TUNNELS_DETAILS,
    "/admin-console/private-access/service-tunnel": ROUTE.SERVICE_TUNNELS,
    "/admin-console/private-access/discovery/:id/access-activity": ROUTE.DISCOVERY_ACCESS_ACTIVITY,
    "/admin-console/private-access/discovery/:id/ips": ROUTE.DISCOVERY_IPS,
    "/admin-console/private-access/discovery/:id/network": ROUTE.DISCOVERY_NETWORK,
    "/admin-console/private-access/discovery/:id": ROUTE.DISCOVERY_DETAILS,
    "/admin-console/private-access/discovery": ROUTE.DISCOVERY,
    "/admin-console/private-access/iaas-discovery/:id": ROUTE.IAAS_DISCOVERY_DETAILS,
    "/admin-console/private-access/iaas-discovery/:id/publish": ROUTE.IAAS_DISCOVERY_PUBLISH,
    "/admin-console/private-access/iaas-discovery": ROUTE.IAAS_DISCOVERY,
    "/admin-console/private-access/access-policies/add": ROUTE.ACCESS_POLICIES_ADD,
    "/admin-console/private-access/access-policies/:id/edit": ROUTE.ACCESS_POLICIES_EDIT,
    "/admin-console/private-access/access-policies/:id": ROUTE.ACCESS_POLICIES_DETAILS,
    "/admin-console/private-access/access-policies": ROUTE.ACCESS_POLICIES,
    "/admin-console/secure/trust-scoring/remediation": ROUTE.REMEDIATION,
    "/admin-console/secure/trust-scoring/trust-integrations/add": ROUTE.INTEGRATIONS_CREATE,
    "/admin-console/secure/trust-scoring/trust-integrations/:id/edit": ROUTE.INTEGRATIONS_EDIT,
    "/admin-console/secure/trust-scoring/trust-integrations/:id": ROUTE.INTEGRATIONS_DETAILS,
    "/admin-console/secure/trust-scoring/trust-integrations": ROUTE.INTEGRATIONS,
    "/admin-console/secure/trust-scoring/trust-factor/file-check/add":
        ROUTE.FACTORS_FILE_CHECK_CREATE,
    "/admin-console/secure/trust-scoring/trust-factor/file-check/:id":
        ROUTE.FACTORS_FILE_CHECK_DETAILS,
    "/admin-console/secure/trust-scoring/trust-factor/file-check": ROUTE.FACTORS_FILE_CHECK,
    "/admin-console/secure/trust-scoring/trust-factor/plist/add": ROUTE.FACTORS_PLIST_CREATE,
    "/admin-console/secure/trust-scoring/trust-factor/plist/:id": ROUTE.FACTORS_PLIST_DETAILS,
    "/admin-console/secure/trust-scoring/trust-factor/plist": ROUTE.FACTORS_PLIST,
    "/admin-console/secure/trust-scoring/trust-factor/registry-check/add":
        ROUTE.FACTORS_REGISTRY_CHECK_CREATE,
    "/admin-console/secure/trust-scoring/trust-factor/registry-check/:id":
        ROUTE.FACTORS_REGISTRY_CHECK_DETAILS,
    "/admin-console/secure/trust-scoring/trust-factor/registry-check": ROUTE.FACTORS_REGISTRY_CHECK,
    "/admin-console/secure/trust-scoring/trust-factor": ROUTE.FACTORS,
    "/admin-console/secure/trust-scoring/trust-profile/create": ROUTE.PROFILES_CREATE,
    "/admin-console/secure/trust-scoring/trust-profile/:id": ROUTE.PROFILES_DETAILS,
    "/admin-console/secure/trust-scoring/trust-profile": ROUTE.PROFILES,
    "/admin-console/secure/trust-scoring": ROUTE.TRUST,
    "/admin-console/secure": ROUTE.TRUST,
    "/app/manage/application-inventory": ROUTE.APP_DISCOVERY,
    "/app/manage/hosted-websites/add": ROUTE.HOSTED_WEBSITES_ADD,
    "/app/manage/hosted-websites/:id": ROUTE.HOSTED_WEBSITES_DETAILS,
    "/app/manage/hosted-websites": ROUTE.HOSTED_WEBSITES,
    "/app/manage/infrastructure/:id/edit": ROUTE.INFRASTRUCTURE_EDIT,
    "/app/manage/infrastructure/:id": ROUTE.INFRASTRUCTURE_DETAILS,
    "/app/manage/infrastructure/add": ROUTE.INFRASTRUCTURE_ADD,
    "/app/manage/infrastructure": ROUTE.INFRASTRUCTURE,
    "/app/manage/inventory/:id": ROUTE.IAAS_DISCOVERY_DETAILS,
    "/app/manage/inventory": ROUTE.IAAS_DISCOVERY,
    "/app/manage/private-resource/:id/access-activity": ROUTE.DISCOVERY_ACCESS_ACTIVITY,
    "/app/manage/private-resource/:id/ips": ROUTE.DISCOVERY_IPS,
    "/app/manage/private-resource/:id/network": ROUTE.DISCOVERY_NETWORK,
    "/app/manage/private-resource/:id": ROUTE.DISCOVERY_DETAILS,
    "/app/manage/private-resource": ROUTE.DISCOVERY,
    "/app/manage/public-resources/:id": ROUTE.APP_DISCOVERY_DETAILS,
    "/app/manage/public-resources": ROUTE.APP_DISCOVERY,
    "/app/manage/publish-inventory/:id": ROUTE.IAAS_DISCOVERY_PUBLISH,
    "/app/manage/saas/:type/:id/config": ROUTE.SAAS_APPS_CONFIGURATION,
    "/app/manage/saas/:type/:id/edit": ROUTE.SAAS_APPS_EDIT,
    "/app/manage/saas/:type/:id": ROUTE.SAAS_APPS_DETAILS,
    "/app/manage/saas/:type/add": ROUTE.SAAS_APPS_ADD,
    "/app/manage/saas": ROUTE.SAAS_APPS,
    "/app/manage/services/:id": ROUTE.PRIVATE_ACCESS_SERVICES_DETAILS,
    "/app/manage/tunnel-inventory/:id": ROUTE.DISCOVERY_DETAILS,
    "/app/manage/tunnel-inventory": ROUTE.DISCOVERY,
    "/app/manage": ROUTE.PRIVATE_ACCESS,
    "/app/monitor/dashboard": ROUTE.HOME_OVERVIEW,
    "/app/monitor/events": ROUTE.EVENTS,
    "/app/monitor/system_logs": ROUTE.SYSTEM_LOGS,
    // cspell: disable-next-line
    "/app/myprofile": ROUTE.MY_PROFILE,
    "/app/networks/access-tiers/create": ROUTE.ACCESS_TIERS_ADD,
    "/app/networks/access-tiers/:id": ROUTE.ACCESS_TIERS_DETAILS,
    "/app/networks/access-tiers": ROUTE.ACCESS_TIERS,
    "/app/networks/clusters/:clusterName": ROUTE.CLUSTERS_DETAILS,
    "/app/networks/clusters": ROUTE.CLUSTERS,
    "/app/networks/connectors/:id": ROUTE.CONNECTORS_DETAILS,
    "/app/networks/connectors/add": ROUTE.CONNECTORS_ADD,
    "/app/networks/connectors": ROUTE.CONNECTORS,
    "/app/networks/issued-certificates": ROUTE.ISSUED_CERTIFICATES,
    "/app/networks/registered-domains/:id/add": ROUTE.REGISTERED_DOMAINS_ADD,
    "/app/networks/registered-domains/:id": ROUTE.REGISTERED_DOMAINS_DETAILS,
    "/app/networks/registered-domains": ROUTE.REGISTERED_DOMAINS,
    "/app/networks": ROUTE.NETWORKS,
    "/app/secure/policies/add": ROUTE.ACCESS_POLICIES_ADD,
    "/app/secure/policies/:id/edit": ROUTE.ACCESS_POLICIES_EDIT,
    "/app/secure/policies/:id": ROUTE.ACCESS_POLICIES_DETAILS,
    "/app/secure/policies": ROUTE.ACCESS_POLICIES,
    "/app/secure/protection-profile/add": ROUTE.INTERNET_THREAT_PROTECTION_ADD,
    "/app/secure/protection-profile/list": ROUTE.INTERNET_THREAT_PROTECTION,
    "/app/secure/protection-profile/overview/:id": ROUTE.INTERNET_THREAT_PROTECTION_DETAILS,
    "/app/secure/protection-profile": ROUTE.INTERNET_THREAT_PROTECTION,
    "/app/secure/roles/add": ROUTE.ROLES_ADD,
    "/app/secure/roles/overview/:id": ROUTE.ROLES_DETAILS,
    "/app/secure/roles/list": ROUTE.ROLES,
    "/app/secure/roles": ROUTE.ROLES,
    "/app/secure/trust-scoring/remediation": ROUTE.REMEDIATION,
    "/app/secure/trust-scoring/trust-factors/file-check/edit/:id": ROUTE.FACTORS_FILE_CHECK_DETAILS,
    "/app/secure/trust-scoring/trust-factors/file-check/add": ROUTE.FACTORS_FILE_CHECK_CREATE,
    "/app/secure/trust-scoring/trust-factors/file-check": ROUTE.FACTORS_FILE_CHECK,
    "/app/secure/trust-scoring/trust-factors/plist/edit/:id": ROUTE.FACTORS_PLIST_DETAILS,
    "/app/secure/trust-scoring/trust-factors/plist/add": ROUTE.FACTORS_PLIST_CREATE,
    "/app/secure/trust-scoring/trust-factors/plist": ROUTE.FACTORS_PLIST,
    "/app/secure/trust-scoring/trust-factors/registry-check/edit/:id":
        ROUTE.FACTORS_REGISTRY_CHECK_DETAILS,
    "/app/secure/trust-scoring/trust-factors/registry-check/add":
        ROUTE.FACTORS_REGISTRY_CHECK_CREATE,
    "/app/secure/trust-scoring/trust-factors/registry-check": ROUTE.FACTORS_REGISTRY_CHECK,
    "/app/secure/trust-scoring/trust-factors": ROUTE.FACTORS,
    "/app/secure/trust-scoring/trust-integrations/add": ROUTE.INTEGRATIONS_CREATE,
    "/app/secure/trust-scoring/trust-integrations/:id/edit": ROUTE.INTEGRATIONS_EDIT,
    "/app/secure/trust-scoring/trust-integrations/:id": ROUTE.INTEGRATIONS_DETAILS,
    "/app/secure/trust-scoring/trust-integrations": ROUTE.INTEGRATIONS,
    "/app/secure/trust-scoring/trust-profiles/create": ROUTE.PROFILES_CREATE,
    "/app/secure/trust-scoring/trust-profiles/:id": ROUTE.PROFILES_DETAILS,
    "/app/secure/trust-scoring/trust-profiles": ROUTE.PROFILES,
    "/app/secure/trust-scoring": ROUTE.TRUST,
    "/app/secure": ROUTE.TRUST,
    "/app/settings/app-support": ROUTE.CUSTOMIZATION,
    // cspell:disable-next-line
    "/app/settings/cloakexceptions": ROUTE.UNREGISTERED_DEVICE_SETTINGS,
    "/app/settings/customization": ROUTE.CUSTOMIZATION,
    // cspell:disable-next-line
    "/app/settings/network/servicetunnel": ROUTE.SERVICE_TUNNEL_SETTINGS,
    "/app/settings/org/admin/add": ROUTE.ADMINS_ADD,
    "/app/settings/org/admin/:id": ROUTE.ADMINS_DETAILS,
    "/app/settings/org/admin": ROUTE.ADMINS,
    "/app/settings/org/api-keys/add": ROUTE.API_KEYS_ADD,
    "/app/settings/org/api-keys/:id": ROUTE.API_KEYS_DETAILS,
    "/app/settings/org/api-keys": ROUTE.API_KEYS,
    "/app/settings/org/app-deployment": ROUTE.APP_DEPLOYMENT_SETTINGS,
    // cspell:disable-next-line
    "/app/settings/org/invitecode": ROUTE.APP_DEPLOYMENT_SETTINGS,
    "/app/settings/org/licensing": ROUTE.LICENSING,
    "/app/settings/org/service-bundles/overview/:id": ROUTE.BUNDLES_DETAILS,
    "/app/settings/org/service-bundles/edit/:id": ROUTE.BUNDLES_EDIT,
    "/app/settings/org/service-bundles/add": ROUTE.BUNDLES_ADD,
    "/app/settings/org/service-bundles": ROUTE.BUNDLES,
    // cspell: disable-next-line
    "/app/settings/org/signon": ROUTE.SIGN_ON_SETTINGS,
    "/app/settings/remediation": ROUTE.REMEDIATION,
    "/app/settings/trust-integrations/add": ROUTE.INTEGRATIONS_CREATE,
    "/app/settings/trust-integrations/list": ROUTE.INTEGRATIONS,
    "/app/settings/trust-integrations/:id/edit": ROUTE.INTEGRATIONS_EDIT,
    "/app/settings/trust-integrations/:id": ROUTE.INTEGRATIONS_DETAILS,
    "/app/settings/trust-integrations": ROUTE.INTEGRATIONS,
    "/app/settings/trustscore/os": ROUTE.OPERATING_SYSTEM_VERSIONS,
    // cspell: disable-next-line
    "/app/settings/trustscore/preferredapps/edit/:id": ROUTE.APPLICATION_CHECK_DETAILS,
    // cspell: disable-next-line
    "/app/settings/trustscore/preferredapps/add": ROUTE.APPLICATION_CHECK_ADD,
    // cspell: disable-next-line
    "/app/settings/trustscore/preferredapps": ROUTE.APPLICATION_CHECK,
    // cspell:ignore trustscore
    "/app/settings/trustscore": ROUTE.FACTORS,
    "/app/settings/trust-score-settings/remediation": ROUTE.REMEDIATION,
    "/app/settings/trust-score-settings/trust-factors/application-check/:id":
        ROUTE.APPLICATION_CHECK_DETAILS,
    "/app/settings/trust-score-settings/trust-factors/application-check/add":
        ROUTE.APPLICATION_CHECK_ADD,
    "/app/settings/trust-score-settings/trust-factors/application-check": ROUTE.APPLICATION_CHECK,
    "/app/settings/trust-score-settings/trust-factors/file-check/edit/:id":
        ROUTE.FACTORS_FILE_CHECK_DETAILS,
    "/app/settings/trust-score-settings/trust-factors/file-check/add":
        ROUTE.FACTORS_FILE_CHECK_CREATE,
    "/app/settings/trust-score-settings/trust-factors/file-check": ROUTE.FACTORS_FILE_CHECK,
    "/app/settings/trust-score-settings/trust-factors/operating-system-versions":
        ROUTE.OPERATING_SYSTEM_VERSIONS,
    "/app/settings/trust-score-settings/trust-factors/plist/edit/:id": ROUTE.FACTORS_PLIST_DETAILS,
    "/app/settings/trust-score-settings/trust-factors/plist/add": ROUTE.FACTORS_PLIST_CREATE,
    "/app/settings/trust-score-settings/trust-factors/plist": ROUTE.FACTORS_PLIST,
    "/app/settings/trust-score-settings/trust-factors": ROUTE.TRUST_FACTOR_SETTINGS,
    "/app/settings/trust-score-settings/trust-integrations/add": ROUTE.INTEGRATIONS_CREATE,
    "/app/settings/trust-score-settings/trust-integrations/list": ROUTE.INTEGRATIONS,
    "/app/settings/trust-score-settings/trust-integrations/:id/edit": ROUTE.INTEGRATIONS_EDIT,
    "/app/settings/trust-score-settings/trust-integrations/:id": ROUTE.INTEGRATIONS_DETAILS,
    "/app/settings/trust-score-settings/trust-integrations": ROUTE.INTEGRATIONS,
    "/app/settings/trust-score-settings/trust-score-expiry": ROUTE.TRUST_SCORE_EXPIRY,
    "/app/settings/trust-score-settings": ROUTE.TRUST_FACTOR_SETTINGS,
    // cspell:disable-next-line
    "/app/settings/trustprovider/advanced-settings": ROUTE.ADVANCED_SETTINGS,
    // cspell:disable-next-line
    "/app/settings/trustprovider/devicemanager": ROUTE.DEVICE_MANAGER_SETTINGS,
    // cspell:disable-next-line
    "/app/settings/trustprovider/identityprovider": ROUTE.IDENTITY_PROVIDER_SETTINGS,
    // cspell:disable-next-line
    "/app/settings/trustprovider/oidc": ROUTE.OIDC_SETTINGS,
    "/app/settings": ROUTE.SETTINGS,
    "/app": ROUTE.ADMIN_CONSOLE,
    "/forgot-org-name": ROUTE.FORGOT_ORG_NAME,
    "/login/configure-mfa": ROUTE.LOCAL_ACCOUNT_CONFIGURE_MFA,
    "/login/local-account": ROUTE.LOCAL_ACCOUNT_FORM,
    "/login/mfa": ROUTE.LOCAL_ACCOUNT_MFA,
    "/login/org": ROUTE.ORG_FORM,
    "/login/reset-password": ROUTE.RESET_PASSWORD_FORM,
    "/login/user": ROUTE.LOCAL_ACCOUNT_FORM,
    "/login": ROUTE.LOGIN,
    // cspell: disable-next-line
    "/oidc/forgotpassword": ROUTE.OIDC_FORGOT_PASSWORD,
    // cspell: disable-next-line
    "/oidc/confirmpassword": ROUTE.OIDC_CONFIRM_PASSWORD,
    // cspell: disable-next-line
    "/oidc/newpassword": ROUTE.OIDC_NEW_PASSWORD,
    // cspell: disable-next-line
    "/oidc/orgselect": ROUTE.OIDC_ORG_SELECTOR,
} satisfies Record<string, ROUTE>

function FallbackRoute(): JSX.Element {
    const { status, data } = useAdminInfo()

    switch (status) {
        case "loading":
            return <Loader medium center />
        case "error":
            return <Redirect to={ROUTE.LOGIN} />
        case "success":
            return <Redirect to={getAssignedConsoleRoute(data)} />
    }
}
