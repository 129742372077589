import React from "react"
import { useHistory, useLocation } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services"
import { UrlUtil } from "../../../../pre-v3/utils/Url.util"
import { ROUTE } from "../../../../routes"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { OldInput } from "../../../components/old-input/OldInput.component"
import { Link } from "../../../components/link/Link.component"
import { LoginService } from "../../../services/Login.service"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import styles from "../Login.module.scss"
import { RouteUtils } from "../Route.utils"
import { Actions, SamlLoginDialog } from "./SamlLoginDialog.component"
import { AdminIDPType } from "../../../../pre-v3/api/Auth.api"

export function OrgForm(): JSX.Element {
    const history = useHistory()
    const location = useLocation()
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const [orgName, setOrgName] = React.useState("")
    const [state, setState] = React.useState<State>(initial)

    const modalTitle = localization.getString("loginViaSamlIdentityProvider")

    const doOrgLoginEffect = React.useCallback(
        async (orgName: string, response: OrgLoginResponse): Promise<void> => {
            switch (response.type) {
                case OrgLoginResponseType.SAML_REDIRECT:
                    window.location.href = response.href
                    break
                case OrgLoginResponseType.SHOW_SAML_DIALOG:
                    modalService
                        .open(
                            modalTitle,
                            {
                                component: SamlLoginDialog,
                                props: { orgName },
                            },
                            { component: Actions }
                        )
                        .onCancel(() => setState(initial))
                        .onClose(async () => {
                            const response = await samlLogin(orgName)
                            await doOrgLoginEffect(orgName, response)
                        })
                    break
                case OrgLoginResponseType.SHOW_LOCAL_ACCOUNT:
                    history.push(RouteUtils.appendOrgToRoute(ROUTE.LOCAL_ACCOUNT_FORM, orgName))
                    break
                case OrgLoginResponseType.LOGIN_ERROR:
                    return setState(orgFormError)
            }
        },
        [history, modalService, modalTitle]
    )

    const doOrgLogin = React.useCallback(
        async (orgName: string): Promise<void> => {
            setState(orgFormLoading)
            const response = await orgLogin(orgName)
            doOrgLoginEffect(orgName, response)
        },
        [doOrgLoginEffect]
    )

    React.useEffect(() => {
        const orgName = getOrgNameFromUrl(location.search)
        if (orgName) doOrgLogin(orgName)
    }, [location.search, doOrgLogin])

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        doOrgLogin(orgName)
    }

    const isSubmitLoading = state.type === StateType.ORG_FORM_LOADING
    const isErrorShown = state.type === StateType.ORG_FORM_ERROR

    const orgNameLabel = localization.getString("orgName")

    return (
        <React.Fragment>
            <p className={styles.textAlignCenter}>{localization.getString("enterYourOrgName")}</p>
            {isErrorShown && (
                <ErrorBanner className={styles["space-below"]}>
                    {localization.getString("somethingWentWrongDescription")}
                </ErrorBanner>
            )}
            <form onSubmit={onSubmit} className={styles.form}>
                <OldInput
                    type="text"
                    value={orgName}
                    onChangeValue={setOrgName}
                    required
                    autoFocus
                    aria-label={orgNameLabel}
                    placeholder={orgNameLabel}
                    className={styles["space-below"]}
                />
                <Button
                    className={styles.submitButton}
                    buttonType={ButtonType.PRIMARY}
                    asElement={ButtonElement.BUTTON}
                    type="submit"
                    loading={isSubmitLoading}
                >
                    {localization.getString("continue")}
                </Button>
            </form>
            <p className={styles.textAlignCenter}>
                <Link to={ROUTE.FORGOT_ORG_NAME}>
                    {localization.getString("forgotYourOrgName")}
                </Link>
            </p>
        </React.Fragment>
    )
}

// Login helper functions

function getOrgNameFromUrl(search: string): string | null {
    if (UrlUtil.hasTeamEditionRedirectUrl()) {
        return UrlUtil.getOrgNameFromUrl()
    }

    const params = new URLSearchParams(search)
    return params.get("org")
}

async function orgLogin(orgName: string): Promise<OrgLoginResponse> {
    try {
        const loginService = new LoginService()
        const orgStatus = await loginService.getOrgStatus(orgName)

        switch (orgStatus.adminIDPType) {
            case AdminIDPType.LOCAL:
            case undefined:
                return showLocalAccount

            case AdminIDPType.SAML:
                return orgStatus.isAdminBanyanIDP
                    ? samlLogin(orgName)
                    : Promise.resolve(showSAMLDialog)
        }
    } catch (_error) {
        return loginError
    }
}

async function samlLogin(orgName: string): Promise<SamlLoginResponse> {
    try {
        const loginService = new LoginService()
        const href = await loginService.samlLogin(orgName)
        return samlRedirect(href)
    } catch (_error) {
        return loginError
    }
}

// Possible Responses

enum OrgLoginResponseType {
    SAML_REDIRECT = "SAML_REDIRECT",
    SHOW_SAML_DIALOG = "SHOW_SAML_DIALOG",
    SHOW_LOCAL_ACCOUNT = "SHOW_LOCAL_ACCOUNT",
    LOGIN_ERROR = "LOGIN_ERROR",
}

type OrgLoginResponse = SAMLRedirect | ShowSAMLDialog | ShowLocalAccount | LoginError

type SamlLoginResponse = SAMLRedirect | LoginError

interface SAMLRedirect {
    type: OrgLoginResponseType.SAML_REDIRECT
    href: string
}

function samlRedirect(href: string): SAMLRedirect {
    return { type: OrgLoginResponseType.SAML_REDIRECT, href }
}

interface ShowSAMLDialog {
    type: OrgLoginResponseType.SHOW_SAML_DIALOG
}

const showSAMLDialog: ShowSAMLDialog = { type: OrgLoginResponseType.SHOW_SAML_DIALOG }

interface ShowLocalAccount {
    type: OrgLoginResponseType.SHOW_LOCAL_ACCOUNT
}

const showLocalAccount: ShowLocalAccount = { type: OrgLoginResponseType.SHOW_LOCAL_ACCOUNT }

interface LoginError {
    type: OrgLoginResponseType.LOGIN_ERROR
}

const loginError: LoginError = { type: OrgLoginResponseType.LOGIN_ERROR }

// Org Form State

enum StateType {
    INITIAL = "INITIAL",
    ORG_FORM_LOADING = "ORG_FORM_LOADING",
    ORG_FORM_ERROR = "ORG_FORM_ERROR",
    SAML_MODAL = "SAML_MODAL",
}

type State = Initial | OrgFormLoading | OrgFormError

interface Initial {
    type: StateType.INITIAL
}

const initial: Initial = { type: StateType.INITIAL }

interface OrgFormLoading {
    type: StateType.ORG_FORM_LOADING
}

const orgFormLoading: OrgFormLoading = { type: StateType.ORG_FORM_LOADING }

interface OrgFormError {
    type: StateType.ORG_FORM_ERROR
}

const orgFormError: OrgFormError = { type: StateType.ORG_FORM_ERROR }
