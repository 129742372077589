import { faExclamation } from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { ActionBarItem, IconType } from "../../../../pre-v3/services/ActionBar.service"
import { LanguageKey } from "../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { decodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE } from "../../../../routes"
import { Container } from "../../../components/container/Container.component"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { Loader } from "../../../components/loader/Loader.component"
import { OverviewTopContainer } from "../../../components/overview/OverviewTopContainer/OverviewTopContainer.component"
import { TabBar, TabProps } from "../../../components/tab-bar/TabBar.component"
import {
    useGetServiceAccount,
    useDeleteServiceAccount,
} from "../../../services/ServiceAccount.service"
import { ServiceAccountAccessActivityTab } from "./ServiceAccountAccessActivityTab.component"
import styles from "./ServiceAccountOverview.module.scss"
import { ServiceAccountOverviewTab } from "./ServiceAccountOverviewTab.component"
import { ServiceAccountRolesTab } from "./ServiceAccountRolesTab.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"

export function ServiceAccountOverview(): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()
    const modalService = useServiceModal()
    const params = useParams<{ id: string }>()

    const serviceAccountId = decodeID(params.id)

    const getServiceAccount = useGetServiceAccount(serviceAccountId)
    const deleteServiceAccount = useDeleteServiceAccount({
        onSuccess: () => history.push(ROUTE.SERVICE_ACCOUNTS),
    })

    const [isEditing, setIsEditing] = React.useState(false)
    const [currentTab, setCurrentTab] = React.useState(Tab.OVERVIEW)

    const { data: serviceAccount } = getServiceAccount

    const deleteServiceAccountLabel = localization.getString(
        "deleteSomething",
        localization.getString("serviceAccount")
    )

    const formatter = new Intl.ListFormat(localization.getLocale(), { type: "conjunction" })

    const deleteServiceAccountAction: Pick<ActionBarItem, "tooltip" | "disabled"> = serviceAccount
        ? serviceAccount.roles.length > 0
            ? {
                  tooltip: localization.getPluralString(
                      "unlinkFollowingRoleBeforeDeleting",
                      serviceAccount.roles.length,
                      formatter.format(serviceAccount.roles)
                  ),
                  disabled: true,
              }
            : {
                  tooltip: deleteServiceAccountLabel,
                  disabled: false,
              }
        : {
              tooltip: deleteServiceAccountLabel,
              disabled: true,
          }

    function onEdit() {
        setCurrentTab(Tab.OVERVIEW)
        setIsEditing(true)
    }

    function onDelete() {
        if (!serviceAccount) return

        modalService
            .openConfirmation(
                localization.getString("deleteServiceAccount"),
                localization.getString("deleteServiceAccountExplanation")
            )
            .onClose(() => deleteServiceAccount.mutate(serviceAccount))
    }

    if (getServiceAccount.isLoading) {
        return <Loader isLoading center medium children />
    }

    if (getServiceAccount.isError) {
        return <LargeMessage icon={faExclamation} children={String(getServiceAccount.error)} />
    }

    if (!serviceAccount) {
        return (
            <LargeMessage
                icon={faExclamation}
                children={localization.getString(
                    "somethingNotFound",
                    localization.getString("serviceAccount")
                )}
            />
        )
    }

    const listFormatter = new Intl.ListFormat(localization.getLocale(), { type: "conjunction" })

    const getTabBarProps = (tab: Tab): TabProps<Tab> => ({
        id: tab,
        label: localization.getString(tabLabelDict[tab]),
        ariaControls: tab,
    })

    const onStopEditing = () => setIsEditing(false)

    return (
        <Container as="section" aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{serviceAccount.name}</PageHeading>
                <div className={styles.actionButtons}>
                    <Tooltip
                        title={localization.getString(
                            "editSomething",
                            localization.getString("serviceAccount")
                        )}
                    >
                        <Button
                            icon={IconType.PEN}
                            onClick={onEdit}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString(
                                "editSomething",
                                localization.getString("serviceAccount")
                            )}
                            disabled={isEditing}
                        />
                    </Tooltip>
                    <Tooltip title={deleteServiceAccountAction.tooltip}>
                        <Button
                            icon={IconType.TRASH}
                            onClick={onDelete}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={deleteServiceAccountAction.tooltip}
                            disabled={deleteServiceAccountAction.disabled}
                        />
                    </Tooltip>
                    <Tooltip title={localization.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={() => getServiceAccount.refetch()}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            <OverviewTopContainer
                statusType="success"
                hideStatusType
                statusItems={[
                    {
                        label: localization.getString("lastUpdated"),
                        value: DateUtil.format(serviceAccount.updatedAt),
                    },
                    {
                        label: localization.getString("updatedBy"),
                        value: serviceAccount.updatedBy,
                    },
                ]}
                listItems={[
                    {
                        label: localization.getString("description"),
                        value: serviceAccount.description ?? "-",
                    },
                    {
                        label: localization.getString("roles"),
                        value:
                            serviceAccount.roles.length > 0
                                ? listFormatter.format(serviceAccount.roles)
                                : "-",
                    },
                    {
                        label: localization.getString("lastAuthorizedAt"),
                        value: DateUtil.format(serviceAccount.lastAuthorizedAt),
                    },
                ]}
            />
            {deleteServiceAccount.isError && (
                <ErrorBanner children={String(deleteServiceAccount.error)} />
            )}
            <TabBar
                tabs={Object.values(Tab).map(getTabBarProps)}
                selectedTabId={currentTab}
                onChange={setCurrentTab}
            />
            {currentTab === Tab.OVERVIEW && (
                <ServiceAccountOverviewTab
                    serviceAccount={serviceAccount}
                    isEditing={isEditing}
                    onStopEditing={onStopEditing}
                />
            )}
            {currentTab === Tab.ACCESS_ACTIVITY && (
                <ServiceAccountAccessActivityTab serviceAccount={serviceAccount} />
            )}
            {currentTab === Tab.ROLES && <ServiceAccountRolesTab serviceAccount={serviceAccount} />}
        </Container>
    )
}

enum Id {
    HEADING = "heading",
}

enum Tab {
    OVERVIEW = "OVERVIEW",
    ACCESS_ACTIVITY = "ACCESS_ACTIVITY",
    ROLES = "ROLES",
}

const tabLabelDict: Record<Tab, LanguageKey> = {
    [Tab.OVERVIEW]: "overview",
    [Tab.ACCESS_ACTIVITY]: "accessActivity",
    [Tab.ROLES]: "roles",
}
