import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../../../../../../pre-v3/services/localization/Localization.service"
import { ModalRef } from "../../../../../../../../../pre-v3/services/Modal.service"
import { ErrorBanner } from "../../../../../../../../components/banner/Banner.component"
import { Container } from "../../../../../../../../components/container/Container.component"
import { NewPlist } from "../../../../../../../../services/TrustFactor.service"
import {
    Plist,
    useCreatePropertyListCheckTrustFactor,
    useUpdatePropertyListCheckTrustFactor,
} from "../../../../../../../../services/TrustProfile.service"
import { PlistForm } from "../../../../../../../trust-factors/trustFactorList/plist/PlistForm.component"
import styles from "./PlistModalContainer.module.scss"

interface Props {
    modalRef: ModalRef
    plist?: Plist
}

export function PlistModalContainer(props: Props) {
    const { modalRef, plist: defaultPlist } = props

    const { mutateAsync: createPlist } = useCreatePropertyListCheckTrustFactor({
        onError: (error: string) => {
            modalRef.updateActions({ error })
        },
        onSettled: () => modalRef.updateActions({ submitting: false }),
    })

    const { mutateAsync: updatePlist } = useUpdatePropertyListCheckTrustFactor({
        onError: (error: string) => {
            modalRef.updateActions({ error })
        },
        onSettled: () => modalRef.updateActions({ submitting: false }),
    })

    async function onSubmit(plist: NewPlist): Promise<void> {
        try {
            modalRef.updateActions({ submitting: true, error: "" })

            if (defaultPlist) {
                const updatedPlist = await updatePlist({
                    ...defaultPlist,
                    ...plist,
                })

                return modalRef.close(updatedPlist)
            } else {
                const newPlist = await createPlist(plist)

                return modalRef.close(newPlist)
            }
        } catch (error) {}
    }

    return (
        <Container className={styles.form}>
            <PlistForm
                id="plist-modal-form"
                defaultValue={defaultPlist}
                hideActions
                onSubmit={onSubmit}
            />
        </Container>
    )
}

interface PlistModalActionsProps {
    error?: string
    submitting?: boolean
}

export function PlistModalActions(props: PlistModalActionsProps) {
    const localization = useServiceLocalization()

    const { error, submitting } = props

    return (
        <div className={styles.actionContainer}>
            {error && <ErrorBanner className={styles.banner}>{error}</ErrorBanner>}
            <Button
                className={styles.button}
                form="plist-modal-form"
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.PRIMARY}
                type="submit"
                loading={submitting}
            >
                {localization.getString("save")}
            </Button>
        </div>
    )
}
