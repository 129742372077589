import classNames from "classnames/bind"
import React, { useMemo } from "react"
import { LocalizationService } from "../../../../pre-v3/services"
import { CollectionUtil } from "../../../../pre-v3/utils/Collection.util"
import { AppText } from "../../../components/app-text/AppText.component"
import { AccessDetailsFormValue } from "../configuration/AccessDetails.component"
import { PrivateResourceFormValue } from "../configuration/PrivateResource.component"
import styles from "./HostedWebSummary.module.scss"

export function HostedWebSummary(props: HostedWebSummaryProps) {
    const ls: LocalizationService = new LocalizationService()

    const serviceName: string = useMemo(
        () => props.name || ls.getString("serviceNamePlaceholder"),
        [props.name]
    )
    const publicUrl: string = useMemo(() => {
        return props.accessDetails?.domain || ls.getString("publicUrlPlaceholder")
    }, [props.accessDetails])
    const privateUrl: string = useMemo(() => {
        if (!props.privateResource || !props.privateResource.backendDomain) {
            return ls.getString("privateUrlPlaceholder")
        }
        const protocol: string = props.privateResource.tls ? "https://" : "http://"
        return protocol.concat(props.privateResource.backendDomain)
    }, [props.privateResource])
    const privateNetwork: string = useMemo(() => {
        if (
            !props.privateResource ||
            !CollectionUtil.isTruthy(props.privateResource.hostedServiceInfra)
        ) {
            return ls.getString("privateNetworkPlaceholder")
        }
        return props.privateResource.hostedServiceInfra!.map((h) => h.name).join(", ")
    }, [props.privateResource])

    return (
        <AppText
            className={classNames(styles.summary, props.className)}
            ls={{
                key: "hostedWebSummary",
                replaceVals: [serviceName, publicUrl, privateUrl, privateNetwork],
            }}
        />
    )
}

interface HostedWebSummaryProps {
    className?: string
    name?: string
    privateResource?: PrivateResourceFormValue
    accessDetails?: AccessDetailsFormValue
}
