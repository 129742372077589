import { Option } from "../../../../../components/toggle-buttons/ToggleButtons.component"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { LinuxInstallationMethod, ServerEnvironments } from "../../../../services/Connector.service"

export function useGetServerEnvironmentsOptions() {
    const localization = useServiceLocalization()

    const options: Record<ServerEnvironments, Option<ServerEnvironments>> = {
        [ServerEnvironments.LINUX]: {
            label: localization.getString("linux"),
            value: ServerEnvironments.LINUX,
            id: ServerEnvironments.LINUX,
        },
        [ServerEnvironments.WINDOWS]: {
            label: localization.getString("windowsServer"),
            value: ServerEnvironments.WINDOWS,
            id: ServerEnvironments.WINDOWS,
        },
    }

    return {
        getServerEnvironmentsOptions: (environment: ServerEnvironments) => options[environment],
        data: [ServerEnvironments.LINUX, ServerEnvironments.WINDOWS],
    }
}

export function useGetLinuxInstallationMethod() {
    const localization = useServiceLocalization()

    const options: Record<LinuxInstallationMethod, Option<LinuxInstallationMethod>> = {
        [LinuxInstallationMethod.DOCKER]: {
            label: localization.getString("dockerContainer"),
            value: LinuxInstallationMethod.DOCKER,
            id: LinuxInstallationMethod.DOCKER,
        },
        [LinuxInstallationMethod.TARBALL]: {
            label: localization.getString("tarballInstaller"),
            value: LinuxInstallationMethod.TARBALL,
            id: LinuxInstallationMethod.TARBALL,
        },
    }

    return {
        getLinuxInstallationMethodOptions: (method: LinuxInstallationMethod) => options[method],
        data: [LinuxInstallationMethod.TARBALL, LinuxInstallationMethod.DOCKER],
    }
}
