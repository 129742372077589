import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { FormUtil } from "../../../../pre-v3/utils/Form.util"

import { Form } from "../../../components/form/Form.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { Input } from "../../../components/input/Input.component"

import styles from "./SettingsTab.module.scss"
import { PatternUtil } from "../../../../pre-v3/utils/Pattern.util"
import {
    Domain,
    useAddManagedBypassDomain,
    useDeleteManagedBypassDomain,
    useEditManagedBypassDomain,
} from "../../../services/UrlFiltering.service"

interface Props {
    modalType: ManageDomainModalType
    closeModal: () => void
    domain?: Domain
}

export function ManagedBypassDomainModal({ modalType, domain, closeModal }: Props) {
    const [currentDomain, setCurrentDomain] = React.useState<Domain>(
        domain || { id: "", domain: "" }
    )

    const localization = useServiceLocalization()
    const {
        mutateAsync: addDomain,
        isLoading: isSubmitLoading,
        error: isAddDomainError,
    } = useAddManagedBypassDomain()
    const { mutate: editDomain } = useEditManagedBypassDomain()
    const { mutate: deleteDomain } = useDeleteManagedBypassDomain()

    const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const domainName = FormUtil.getFieldValue(event, "domain")

        switch (modalType) {
            case ManageDomainModalType.ADD:
                await addDomain(domainName)
                break
            case ManageDomainModalType.EDIT:
                await editDomain({
                    id: currentDomain?.id,
                    domain: currentDomain?.domain,
                })
                break
            case ManageDomainModalType.DELETE:
                await deleteDomain({
                    id: currentDomain?.id,
                    domain: currentDomain?.domain,
                })
                break
        }
        closeModal()
    }

    const label = React.useMemo(() => {
        switch (modalType) {
            case ManageDomainModalType.ADD:
                return localization.getString("enterADomain")
            case ManageDomainModalType.EDIT:
                return localization.getString("editADomain")
            case ManageDomainModalType.DELETE:
                return localization.getString("areYouSureYouWantToDeleteTheFollowingDomain")
        }
    }, [modalType, localization])

    return (
        <section className={styles.modalContainer}>
            <Form onSubmit={submitForm}>
                <label className={styles.domainInput}>
                    <span>{label}</span>
                    <Input
                        value={currentDomain?.domain}
                        onChange={(event) => {
                            setCurrentDomain({
                                id: currentDomain?.id ?? "",
                                domain: event.target.value,
                            })
                        }}
                        name="domain"
                        type="text"
                        required
                        autoFocus
                        pattern={PatternUtil.DOMAIN.source}
                        disabled={modalType === ManageDomainModalType.DELETE}
                    />
                </label>
                <FormButtons
                    rightButtons={
                        <>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                onClick={() => closeModal()}
                                type="button"
                            >
                                {localization.getString("cancel")}
                            </Button>
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={
                                    modalType === ManageDomainModalType.DELETE
                                        ? ButtonType.DESTRUCTIVE
                                        : ButtonType.PRIMARY
                                }
                                type="submit"
                            >
                                {modalType === ManageDomainModalType.DELETE
                                    ? localization.getString("delete")
                                    : localization.getString("ok")}
                            </Button>
                        </>
                    }
                />
            </Form>
        </section>
    )
}

export enum ManageDomainModalType {
    ADD = "add",
    EDIT = "edit",
    DELETE = "delete",
}
