import * as React from "react"
import { Singleton } from "../decorators/Singleton.decorator"
import {
    SecureApi,
    Role,
    RoleMetadata,
    RoleAttr,
    Policy,
    PolicyAttr,
    PolicyMetadata,
    SpecKind,
    PolicyServiceTypeRes,
    PolicyL7Action,
    PackageVersions,
} from "../api/Secure.api"
import { DateUtil } from "../utils/Date.util"
import { LocalizationService } from "./localization/Localization.service"
import { MessageRes } from "../api/Base.api"
import { ManageApi, ServiceToPolicy, PolicyAttachmentType } from "../api/Manage.api"
import ServiceContext from "./context"
import { ServiceType } from "./Manage.service"

@Singleton("SecureService")
export class SecureService {
    public getRoles(): Promise<RoleSecure[]> {
        return this.secureApi.getRoles().then((roles) => roles.map(this.mapRoleToRoleSecure))
    }

    private createRole(
        roleMetadata: RoleMetadata,
        roleType: string,
        roleAttr: RoleAttr
    ): Promise<RoleSecure> {
        // enforce uniqueness on groups & email
        if (Array.isArray(roleAttr.email) && roleAttr.email.length > 0) {
            roleAttr.email = [...new Set(roleAttr.email)]
        }
        if (Array.isArray(roleAttr.group) && roleAttr.group.length > 0) {
            roleAttr.group = [...new Set(roleAttr.group)]
        }
        return this.secureApi
            .insertRole({
                apiVersion: "rbac.banyanops.com/v1",
                kind: "BanyanRole",
                type: roleType,
                metadata: roleMetadata,
                spec: roleAttr,
            })
            .then(this.mapRoleToRoleSecure)
    }

    public isEmailRole(role: RoleSecure): boolean {
        const attr: RoleAttr = this.getRoleAttr(role)
        if (attr.group && attr.group.length) {
            return attr.group.length === 0
        }
        return true
    }

    public addUserToEmailRole(email: string, role: RoleSecure): Promise<RoleSecure> {
        if (!this.isEmailRole(role)) {
            return Promise.reject("Role is not email role")
        }

        const metadata: RoleMetadata = {
            name: role.name,
            description: role.description,
            tags: { template: role.type },
        }

        const attr: RoleAttr = this.getRoleAttr(role)
        if (Array.isArray(attr.email)) {
            attr.email.push(email)
        } else {
            attr.email = [email]
        }
        return this.createRole(metadata, SpecKind.USER, attr)
    }

    private getRoleAttr(role: RoleSecure): RoleAttr {
        try {
            const attr = JSON.parse(role.spec).spec
            return attr ? attr : {}
        } catch {
            return {}
        }
    }

    public getLatestVersions(): Promise<PackageVersions> {
        return this.secureApi.getLatestVersions()
    }

    public getPoliciesByServiceType(serviceType: ServiceType): Promise<PolicySecure[]> {
        const servicePolicyType: PolicyServiceTypeRes | undefined =
            this.mapServiceTypeToPolicyServiceType(serviceType)
        if (servicePolicyType) {
            return this.secureApi.getPoliciesByServiceType(servicePolicyType).then(
                (policies) => {
                    return policies.map((p) => this.mapPolicyToPolicySecure(p, {}))
                },
                () => {
                    throw new Error(this.localizationService.getString("failedToLoadPolicies"))
                }
            )
        } else {
            throw new Error(this.localizationService.getString("failedToLoadPolicies"))
        }
    }

    public getPolicy(policyId: string): Promise<PolicySecure> {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.secureApi.getPolicy(policyId),
                this.manageApi.getPolicies(policyId),
            ]).then(
                ([policies, servicePolicyArr]) => {
                    const policyToServiceMap: {
                        [key: string]: ServiceToPolicy[]
                    } = this.getPolicyToServiceMap(servicePolicyArr)
                    if (policies && policies.length > 0) {
                        resolve(this.mapPolicyToPolicySecure(policies[0], policyToServiceMap))
                    } else {
                        reject(Error(this.localizationService.getString("policyNotFound")))
                    }
                },
                () => {
                    reject(Error(this.localizationService.getString("policyNotFound")))
                }
            )
        })
    }

    public async editPolicy(
        metadata: PolicyMetadata,
        type: string,
        attr: PolicyAttr
    ): Promise<PolicySecure> {
        const p = await this.secureApi.insertPolicy({
            kind: "BanyanPolicy",
            apiVersion: "rbac.banyanops.com/v1",
            metadata: metadata,
            type: type,
            spec: attr,
        })
        return this.mapPolicyToPolicySecure(p, {})
    }

    public async createPolicy(
        metadata: PolicyMetadata,
        type: string,
        attr: PolicyAttr
    ): Promise<PolicySecure> {
        // look up if there is already a policy with that name
        const existing = await this.secureApi.getPolicyByName(metadata.name)
        if (existing) {
            throw new Error(this.localizationService.getString("aPolicyWithThatNameAlreadyExists"))
        }

        const p = await this.secureApi.insertPolicy({
            kind: "BanyanPolicy",
            apiVersion: "rbac.banyanops.com/v1",
            metadata: metadata,
            type: type,
            spec: attr,
        })
        return this.mapPolicyToPolicySecure(p, {})
    }

    public deletePolicy(id: string): Promise<MessageRes> {
        return this.secureApi.deletePolicy(id)
    }

    public getNullPolicyAttr(): PolicyAttr {
        return {
            access: [
                {
                    roles: [],
                    rules: {
                        l7_access: [
                            {
                                resources: ["*"],
                                actions: [PolicyL7Action["*"]],
                            },
                        ],
                        conditions: {
                            start_time: "",
                            end_time: "",
                            trust_level: "",
                        },
                    },
                },
            ],
            exception: {
                src_addr: [],
            },
            options: {
                disable_tls_client_authentication: false,
                l7_protocol: "http",
            },
        }
    }

    private mapServiceTypeToPolicyServiceType(
        serviceType: ServiceType
    ): PolicyServiceTypeRes | undefined {
        switch (serviceType) {
            case ServiceType.WEB_USER:
                return PolicyServiceTypeRes.WEB
            case ServiceType.TCP_USER:
            case ServiceType.TCP_WORKLOAD:
                return PolicyServiceTypeRes.TCP
            default:
                return PolicyServiceTypeRes.CUSTOM
        }
    }

    public isGeneratedRole(roleName: string): boolean {
        return Object.values(GeneratedRole).includes(roleName as GeneratedRole)
    }

    private secureApi: SecureApi = new SecureApi()
    private manageApi: ManageApi = new ManageApi()
    private localizationService: LocalizationService = new LocalizationService()

    private mapRoleToRoleSecure(role: Role): RoleSecure {
        let roleType: RoleType
        if (role.RoleType === SpecKind.WORKLOAD) {
            roleType = RoleType.WORKLOAD
        } else if (role.RoleType === SpecKind.CUSTOM) {
            roleType = RoleType.CUSTOM
        } else {
            roleType = RoleType.USER
        }

        return {
            id: role.RoleID,
            name: role.RoleName,
            description: role.Description,
            enabled: role.Enabled === "TRUE",
            spec: role.RoleSpec,
            version: role.RoleVersion,
            createdAt: DateUtil.convertLargeTimestamp(role.CreatedAt),
            createdBy: role.CreatedBy,
            lastUpdatedBy: role.LastUpdatedBy,
            lastUpdated: DateUtil.convertLargeTimestamp(role.LastUpdatedAt),
            type: roleType,
        }
    }

    private getPolicyToServiceMap(servicePolicyArr: ServiceToPolicy[]): {
        [key: string]: ServiceToPolicy[]
    } {
        const map: { [key: string]: ServiceToPolicy[] } = {}
        servicePolicyArr.forEach((sp) => {
            if (map[sp.PolicyID]) {
                map[sp.PolicyID].push(sp)
            } else {
                map[sp.PolicyID] = [sp]
            }
        })
        return map
    }

    private mapPolicyToPolicySecure(
        policy: Policy,
        policyToServiceMap: { [key: string]: ServiceToPolicy[] }
    ): PolicySecure {
        let attachments: PolicyAttachSecure[] = []
        if (policyToServiceMap[policy.PolicyID]) {
            attachments = policyToServiceMap[policy.PolicyID].map(
                this.mapServiceToPolicyToPolicyAttachSecure
            )
        }
        return {
            id: policy.PolicyID,
            name: policy.PolicyName,
            description: policy.Description,
            spec: policy.PolicySpec,
            version: policy.PolicyVersion,
            lastUpdatedAt: DateUtil.convertLargeTimestamp(policy.LastUpdatedAt),
            lastUpdatedBy: policy.LastUpdatedBy,
            createdAt: DateUtil.convertLargeTimestamp(policy.CreatedAt),
            createdBy: policy.CreatedBy,
            attachments: attachments,
            status:
                attachments.length > 0 ? PolicySecureStatus.ATTACHED : PolicySecureStatus.INACTIVE,
        }
    }

    private mapServiceToPolicyToPolicyAttachSecure(sp: ServiceToPolicy): PolicyAttachSecure {
        return {
            policyId: sp.PolicyID,
            policyName: sp.PolicyName,
            serviceId: sp.AttachedToID,
            serviceName: sp.AttachedToName,
            serviceFriendlyName: sp.AttachedToFriendlyName,
            attachedAt: DateUtil.convertLargeTimestamp(sp.AttachedAt),
            attachedToType: sp.AttachedToType,
            enabled: sp.Enabled === "TRUE",
        }
    }
}

export const useServiceSecure = () =>
    React.useContext(ServiceContext)?.secure || new SecureService()

export interface RoleSecure {
    id: string
    name: string
    description: string
    enabled: boolean
    spec: string
    type: RoleType
    version: number
    createdAt: number
    createdBy: string
    lastUpdatedBy: string
    lastUpdated: number
}

export enum RoleType {
    USER = "USER",
    WORKLOAD = "WORKLOAD",
    CUSTOM = "CUSTOM",
}

export interface PolicySecure {
    id: string
    name: string
    description: string
    spec: string
    version: number
    lastUpdatedAt: number
    lastUpdatedBy: string
    createdAt: number
    createdBy: string
    attachments: PolicyAttachSecure[]
    status: PolicySecureStatus
}

export interface PolicyAttachSecure {
    policyId: string
    policyName: string
    serviceId: string
    serviceName: string
    serviceFriendlyName: string
    attachedAt: number
    attachedToType?: PolicyAttachmentType
    enabled: boolean
}

export enum PolicySecureStatus {
    ATTACHED = "ATTACHED",
    INACTIVE = "INACTIVE",
}

export enum PolicyType {
    USER = "USER",
    WORKLOAD = "WORKLOAD",
    CUSTOM = "CUSTOM",
}

enum GeneratedRole {
    ALL_ADMINS = "AllAdmins",
    ALL_USERS = "AllUsers",
}
