import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { BaseApi } from "./Base.api"

@Singleton("BanyanSecurityApi")
export class BanyanSecurityApi extends BaseApi {
    public getMetadata(): Promise<Metadata> {
        return this.get("/docs/api.json", {
            default: this.localization.getString("failedToFetchLatestVersions"),
        })
    }
}

type LatestVersionKey = "connector" | "netagent"

export interface Metadata {
    latest_versions: Record<LatestVersionKey, string>
}
