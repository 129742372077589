import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { useDeletePolicy, useGetPolicyByIdExtended } from "../../../../v3/services/Policy.service"
import { formatRoutePath, ROUTE } from "../../../../routes"
import { decodeID } from "../../../../pre-v3/utils/Url.util"
import styles from "./TunnelPolicyEdit.module.scss"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { Loader } from "../../../../v3/components/loader/Loader.component"
import { Tooltip } from "../../../../v3/components/tooltip/Tooltip.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Banner, Variant } from "../../../../components/banner/Banner.component"
import { ErrorToast, ToastApi } from "../../../../components/toast/Toast.components"
import { TunnelPolicyFormWrapper } from "./TunnelPolicyFormWrapper.component"
import { useServiceModal } from "../../../../pre-v3/services"
import { DeleteCancelActions } from "../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { DeletePolicyModal } from "./DeletePolicyModal.component"

interface Props {
    isEditMode?: boolean
    canEditPolicy?: boolean
    canDeletePolicy?: boolean
}

export function TunnelPolicyEdit(props: Props): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()
    const params = useParams<{ id: string }>()
    const toastRef = React.useRef<ToastApi>(null)
    const modalService = useServiceModal()

    const { policy, isPolicyLoading, refetchPolicyData, policyError, policyFetchStatus } =
        useGetPolicyByIdExtended(decodeID(params.id))

    const {
        mutateAsync: deletePolicy,
        isLoading: isAccessTierDeleting,
        error: deleteError,
        status: deletePolicyStatus,
    } = useDeletePolicy()

    const onDelete: React.MouseEventHandler = (e) => {
        e.preventDefault()
        if (!policy) return
        modalService
            .open(
                localization.getString("deleteAccessPolicy"),
                {
                    component: DeletePolicyModal,
                    props: {
                        policy: policy,
                    },
                },
                {
                    component: DeleteCancelActions,
                    props: {
                        okString: localization.getString("delete"),
                        disabled: policy.noOfAttachments > 0,
                    },
                }
            )
            .onClose(async () => {
                await deletePolicy({ id: policy?.id! })
                history.push(ROUTE.ACCESS_POLICIES)
            })
    }

    const onDownload: React.MouseEventHandler = (e) => {
        e.preventDefault()
        if (!policy) {
            return
        }

        // create the blob object
        const blob: Blob = new Blob([JSON.stringify(policy.spec, null, 4) + "\n"], {
            type: "application/json",
        })

        // download the blob with a ghost anchor
        const a = document.createElement("a")
        document.body.appendChild(a)
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = policy.name + ".json"
        a.click()
        setTimeout(() => {
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
        }, 0)
    }

    const refetchData = () => {
        refetchPolicyData()
    }

    if (policyFetchStatus === "error" || deletePolicyStatus === "error") {
        return (
            <Page
                heading={localization.getString("tunnelPolicy")}
                headerRight={<RefreshButton onRefresh={refetchData} />}
            >
                <Banner
                    label={String(policyError) || String(deleteError)}
                    variant={Variant.ERROR}
                />
            </Page>
        )
    }

    if (isPolicyLoading || isAccessTierDeleting) {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("tunnelPolicy")
                )}
            />
        )
    }

    return (
        <Page
            heading={policy?.name || ""}
            headerRight={
                <ActionButtons
                    isEditMode={props.isEditMode}
                    paramsId={params.id}
                    onDelete={onDelete}
                    onDownload={onDownload}
                    refetch={refetchData}
                    canDeletePolicy={props.canDeletePolicy}
                    canEditPolicy={props.canEditPolicy}
                />
            }
        >
            <TunnelPolicyFormWrapper
                policy={policy!}
                isEditMode={props.isEditMode}
                refetch={refetchData}
            />

            <ErrorToast ref={toastRef} />
        </Page>
    )
}

type ActionButtonsProps = {
    isEditMode?: boolean
    paramsId: string
    onDelete: React.MouseEventHandler
    onDownload: React.MouseEventHandler
    refetch: () => void
    canEditPolicy?: boolean
    canDeletePolicy?: boolean
}

function ActionButtons(props: ActionButtonsProps): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <div className={styles.actions}>
            {props.canEditPolicy && (
                <Tooltip title={localization.getString("edit")}>
                    <Button
                        asElement={ButtonElement.LINK}
                        icon={IconType.PEN}
                        buttonType={ButtonType.SECONDARY}
                        to={formatRoutePath(ROUTE.ACCESS_POLICIES_TUNNEL_POLICY_EDIT, {
                            id: props.paramsId,
                        })}
                        disabled={props.isEditMode}
                    />
                </Tooltip>
            )}
            <Tooltip title={localization.getString("downloadPolicySpec")}>
                <Button
                    asElement={ButtonElement.BUTTON}
                    icon={IconType.FILE_DOWNLOAD}
                    onClick={props.onDownload}
                    buttonType={ButtonType.SECONDARY}
                    type="button"
                />
            </Tooltip>
            {props.canDeletePolicy && (
                <Tooltip title={localization.getString("delete")}>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        icon={IconType.TRASH}
                        onClick={props.onDelete}
                        buttonType={ButtonType.DESTRUCTIVE}
                        type="button"
                    />
                </Tooltip>
            )}
            <Tooltip title={localization.getString("refresh")}>
                <Button
                    asElement={ButtonElement.BUTTON}
                    icon={IconType.REDO}
                    onClick={props.refetch}
                    buttonType={ButtonType.SECONDARY}
                    type="button"
                />
            </Tooltip>
        </div>
    )
}

interface RefreshButtonProps {
    onRefresh(): void
}

export function RefreshButton(props: RefreshButtonProps): JSX.Element {
    const localization = useServiceLocalization()

    const onRefresh: React.MouseEventHandler = (e) => {
        e.preventDefault()
        props.onRefresh()
    }

    const refreshLabel = localization.getString("refresh")

    return (
        <Tooltip title={refreshLabel}>
            <Button
                icon={IconType.REDO}
                onClick={onRefresh}
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.SECONDARY}
                aria-label={refreshLabel}
                type="button"
            />
        </Tooltip>
    )
}

interface PageProps {
    heading: string
    headerRight?: React.ReactNode
    children?: React.ReactNode
}

function Page(props: PageProps): JSX.Element {
    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <div className={styles.labelContainer}>
                    <PageHeading id={Id.HEADING}>{props.heading}</PageHeading>
                </div>
                {props.headerRight}
            </header>
            {props.children}
        </div>
    )
}

enum Id {
    HEADING = "heading",
}
