import classNames from "classnames/bind"
import React from "react"
import { Redirect, Route, Switch } from "react-router"

import { MomManageAdmins } from "../../../pages/mom-manage-admins/MomManageAdmins.page"
import { MspManageAdmins } from "../../../pages/msp-manage-admins/MspManageAdmins.page"
import { ROUTE } from "../../../routes"
import { LeftNav } from "../../components/left-nav/LeftNav.component"
import { ActionBar } from "../../components/action-bar/ActionBar.component"
import { TrustScoreSettings } from "../../../v3/views/app/settings/trustScoreSettings/TrustScoreSettings.view"
import { Customization } from "../../../v3/views/customization/Customization.view"
import { OrgSignOnSettings } from "./org/sign-on/OrgSignOnSettings.component"
import { TrustProviderIdentitySettings } from "./trust-provider/identity/TrustProviderIdentitySettings.component"
import styles from "./Settings.module.scss"

export default function () {
    return (
        <div className={styles.pageWithLeftNav}>
            <LeftNav links={this.state.leftNavLinks} />
            <div className={styles.pageRightWithActionBar}>
                <ActionBar />
                <div className={classNames(styles.pageContent, styles.settingsContainer)}>
                    <Switch>
                        <Route path={ROUTE.MOM_MANGE_ADMINS} component={MomManageAdmins} />
                        <Route path={ROUTE.MSP_MANAGE_ADMIN} component={MspManageAdmins} />
                        <Route path={ROUTE.MSP_CUSTOMIZATION}>
                            <Customization
                                canUpdateCustomization={this.props.canUpdateCustomization}
                            />
                        </Route>

                        <Route path={ROUTE.MSP_IDENTITY_PROVIDER_SETTINGS}>
                            <TrustProviderIdentitySettings
                                canConfigureEndUserSettings={this.props.canConfigureEndUserSettings}
                            />
                        </Route>
                        <Route
                            path={ROUTE.MSP_TRUST_FACTOR_SETTINGS}
                            component={TrustScoreSettings}
                        />
                        {this.props.canAccessSignOn && (
                            <Route path={[ROUTE.MSP_SING_ON, ROUTE.MOM_SIGN_ON]}>
                                <OrgSignOnSettings
                                    canConfigureAdminSignOnSettings={
                                        this.props.canConfigureAdminSignOnSettings
                                    }
                                />
                            </Route>
                        )}
                        <Redirect
                            to={this.state.isMom ? ROUTE.MOM_MANGE_ADMINS : ROUTE.MSP_MANAGE_ADMIN}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    )
}
