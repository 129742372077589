import { faRocket, faVectorSquare } from "@fortawesome/pro-regular-svg-icons"
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community"
import React, { ReactNode, useRef } from "react"
import { useHistory } from "react-router-dom"

import HostedWebsiteEmptyState from "../../../../images/upgrade-full-page.svg"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import {
    LocalizationService,
    useServiceLocalization,
} from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Link } from "../../../../v3/components/link/Link.component"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import {
    ContactUsScreen,
    ContactUsScreenSteps,
    EmptyStateScreen,
} from "../../../components/page-screen/PageScreen.component"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { Status } from "../../../components/status/Status.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"
import { HostedService, useGetHostedServices } from "../../../services/HostedService.service"
import { useGetAdminInfo, useIsTeamEdition } from "../../../services/Org.service"
import {
    labelMap,
    RegisteredServiceStatus,
    statusMap,
} from "../../../services/shared/RegisteredService"
import styles from "./HostedWebsiteList.module.scss"
import { faMinusCircle } from "@fortawesome/pro-solid-svg-icons"
import { AppText } from "../../../components/app-text/AppText.component"
import banyanRoute from "../../../../images/banyan-route.svg"
import { Container } from "../../../components/container/Container.component"
import { useServiceLinks } from "../../../../pre-v3/services/link/Link.service"
import classnames from "classnames"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { IconType } from "../../../../components/icon/Icon.component"

interface Props {
    enableAccessTierGroups: boolean
}

export function HostedWebsiteList(props: Props) {
    const ls: LocalizationService = useServiceLocalization()
    const history = useHistory()
    const [search, setSearch] = React.useState<string>("")

    const linkService = useServiceLinks()

    const columnDefs: ColDef[] = [
        {
            headerName: ls.getString("status"),
            field: "status",
            cellRenderer: StatusCellRenderer,
            flex: 60,
        },
        {
            headerName: ls.getString("serviceName"),
            field: "name",
            comparator: AgGridUtil.alphaBetComparator,
            cellRenderer: ServiceNameCellRenderer,
            flex: 100,
        },
        {
            headerName: ls.getString("policyAttached"),
            valueGetter: PolicyNameCellRenderer,
            flex: 150,
        },
        {
            headerName: ls.getString("lastUpdated"),
            field: "updatedAt",
            valueFormatter: AgGridUtil.dateFormatter,
            flex: 150,
        },
    ]

    const gridApi = useRef<GridApi>()

    const {
        data: hostedServices,
        refetch: fetchHostedServices,
        isFetching: isHostedServicesLoading,
        isError: isHostedServiceError,
        error: hostedServiceError,
    } = useGetHostedServices(props.enableAccessTierGroups, "web")

    const {
        data: adminInfo,
        error: adminInfoError,
        isFetching: isAdminInfoLoading,
    } = useGetAdminInfo()

    const { data: isTeamEdition = false } = useIsTeamEdition()

    const isDataLoading: boolean = isHostedServicesLoading || isAdminInfoLoading

    const errors: ReactNode[] = [adminInfoError, hostedServiceError]

    function onGridReady(event: GridReadyEvent) {
        gridApi.current = event.api
    }

    React.useEffect(() => {
        gridApi.current?.setQuickFilter?.(search)
    }, [search])

    const onRefresh: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        fetchHostedServices()
    }

    if (isTeamEdition && !isHostedServicesLoading) {
        return (
            <Container>
                <ContactUsScreen
                    image={banyanRoute}
                    title={ls.getString("upgradeForSomething", ls.getString("hostedWebsites"))}
                    docsLink={linkService.getLink("securingPrivateResourcesDoc")}
                    subText={ls.getString("protectYourWebServicesDesc")}
                >
                    <ContactUsScreenSteps
                        steps={[
                            {
                                icon: faVectorSquare,
                                label: ls.getString("createWebServicesDesc"),
                            },
                            {
                                icon: faRocket,
                                label: ls.getString(
                                    "deploySonicWallCseAccessTierOrLeverageSonicWallCseGlobalEdgeWebServiceDesc"
                                ),
                            },
                        ]}
                    />
                </ContactUsScreen>
            </Container>
        )
    }

    if (hostedServices && hostedServices.length === 0 && !hostedServiceError) {
        return (
            <EmptyStateScreen
                title={ls.getString("registerYourFirstHostedWebsite")}
                subText={
                    <AppText
                        ls={{
                            key: "registerYourFirstHostedWebsiteDesc",
                            replaceVals: [linkService.getLink("hostedWebsiteDoc")],
                        }}
                    />
                }
                buttonProps={
                    adminInfo?.canWriteServices
                        ? {
                              buttonText: ls.getString("addHostedWebsite"),
                              buttonLink: ROUTE.HOSTED_WEBSITES_ADD,
                          }
                        : undefined
                }
            >
                <div>
                    <img
                        src={HostedWebsiteEmptyState}
                        className={styles.screen}
                        alt={ls.getString("registerYourFirstHostedWebsiteDesc")}
                    />
                </div>
            </EmptyStateScreen>
        )
    }

    const classes = classnames(styles.gridWithToolbar, styles.sectionContainer)

    function onAddHostedWebsite() {
        history.push(ROUTE.HOSTED_WEBSITES_ADD)
    }

    return (
        <section aria-labelledby={Id.HEADING} className={classes}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{ls.getString("hostedWebsites")}</PageHeading>
                <Tooltip title={ls.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={onRefresh}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                    />
                </Tooltip>
            </header>
            <div className={styles.container}>
                <SearchInput
                    className={styles.search}
                    value={search}
                    onChangeValue={setSearch}
                    placeholder={ls.getString("search")}
                />
                {adminInfo?.canWriteServices && (
                    <Tooltip title={ls.getString("addHostedWebsite")}>
                        <span className={styles.iconButton}>
                            <Button
                                buttonType={ButtonType.PRIMARY}
                                asElement={ButtonElement.BUTTON}
                                icon={IconType.PLUS}
                                onClick={onAddHostedWebsite}
                            >
                                {ls.getString("addHostedWebsite")}
                            </Button>
                        </span>
                    </Tooltip>
                )}
            </div>
            <Loader isLoading={isDataLoading} center medium>
                <ErrorBanners className={styles.errorBanner} errors={errors} />
                {!isHostedServiceError && (
                    <div className={styles.gridContainer}>
                        <Grid
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            context={{ history }}
                            rowData={hostedServices}
                            pagination
                        />
                    </div>
                )}
            </Loader>
        </section>
    )
}

function ServiceNameCellRenderer(props: NameCellRendererProps) {
    return props.data?.id ? (
        <Link to={formatRoutePath(ROUTE.HOSTED_WEBSITES_DETAILS, { id: encodeID(props.data.id) })}>
            {props.data.name}
        </Link>
    ) : (
        <span>{props.data.name}</span>
    )
}

function PolicyNameCellRenderer(props: NameCellRendererProps) {
    return props.data?.policyAttachment ? props.data.policyAttachment.policyName : "-"
}

export function StatusCellRenderer(props: NameCellRendererProps) {
    const ls = useServiceLocalization()
    if (!props.data.status) {
        return ""
    }

    let icon
    if (props.data.status === RegisteredServiceStatus.POLICY_PERMISSIVE) {
        icon = faMinusCircle
    }

    return (
        <Status
            type={statusMap[props.data.status]}
            label={ls.getString(labelMap[props.data.status])}
            icon={icon}
        />
    )
}

enum Id {
    HEADING = "heading",
}

interface NameCellRendererProps {
    data: HostedService
}
