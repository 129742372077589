import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTE } from "../../routes"
import { PolicyAdd } from "../../pre-v3/views/policy/add/PolicyAdd"
import { PolicyEdit } from "../../pre-v3/views/policy/edit/PolicyEdit"
import { PolicyDetail } from "../../pre-v3/views/policy/detail/PolicyDetail.component"
import { PolicyList } from "../../v3/views/policy/list/PolicyList.view"
import { Loader } from "../../v3/components/loader/Loader.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { TunnelPolicyEdit } from "./tunnel-policy/edit/TunnelPolicyEdit.component"
import { TunnelPolicyCreate } from "./tunnel-policy/create/TunnelPolicyCreate.component"

export function AccessPolicy(): JSX.Element {
    const { data: featureFlags } = useFeatureFlags()
    if (!featureFlags) {
        return <Loader center medium />
    }
    return (
        <Switch>
            <Route
                exact
                path={ROUTE.ACCESS_POLICIES_TUNNEL_POLICY_ADD}
                component={TunnelPolicyCreate}
            />
            <Route exact path={ROUTE.ACCESS_POLICIES_TUNNEL_POLICY_DETAILS}>
                <TunnelPolicyEdit
                    canDeletePolicy={featureFlags.adminConsole.canDeletePolicy}
                    canEditPolicy={featureFlags.adminConsole.canEditPolicy}
                />
            </Route>
            <Route exact path={ROUTE.ACCESS_POLICIES_TUNNEL_POLICY_EDIT}>
                <TunnelPolicyEdit isEditMode />
            </Route>
            <Route path={ROUTE.ACCESS_POLICIES_ADD} exact component={PolicyAdd} />
            <Route path={ROUTE.ACCESS_POLICIES_EDIT} component={PolicyEdit} />
            <Route path={ROUTE.ACCESS_POLICIES_DETAILS} component={PolicyDetail} />
            <Route path={ROUTE.ACCESS_POLICIES}>
                <PolicyList
                    canAccessOnlyTunnelPolicy={featureFlags.adminConsole.canAccessOnlyTunnelPolicy}
                />
            </Route>
            <Redirect to={ROUTE.ACCESS_POLICIES} />
        </Switch>
    )
}
