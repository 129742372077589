import { faLink } from "@fortawesome/pro-solid-svg-icons"
import React, { useEffect, useMemo, useState } from "react"
import { useServiceLinks } from "../../../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { AppText } from "../../../../components/app-text/AppText.component"
import { FormGroup } from "../../../../components/form/FormGroup.component"
import { FormRow } from "../../../../components/form/FormRow.component"
import { MenuSelect } from "../../../../components/menu-select/MenuSelect.component"
import { ToggleButton } from "../../../../components/toggle-button/ToggleButton"
import { WebService } from "../../../../services/HostedService.service"
import { Policy, PolicyAttachment } from "../../../../services/Policy.service"
import styles from "./AccessPermissions.module.scss"

interface Props {
    initialValue?: WebService
    onChange?: (data: PolicyDetailsFormValues) => void
    disabled?: boolean
    policies?: Policy[]
}

export interface PolicyDetailsFormValues {
    policyAttachment?: PolicyAttachment
}

export function PolicyDetails(props: Props) {
    const { initialValue, disabled } = props
    const localization = useServiceLocalization()
    const linkService = useServiceLinks()

    const initialPolicyId: string = initialValue?.policyAttachment?.policyId ?? ""
    const [isPolicyEnabled, setIsPolicyEnabled] = useState<boolean>(
        initialValue?.policyAttachment?.enabled ?? true
    )
    const [selectedPolicyId, setSelectedPolicyId] = useState<string>(initialPolicyId)

    const selectedPolicy: Policy | undefined = useMemo(() => {
        return props.policies?.find((policy) => {
            return policy.id === selectedPolicyId
        })
    }, [selectedPolicyId])

    useEffect(() => {
        props.onChange?.({
            policyAttachment: {
                serviceId: "",
                serviceName: "",
                policyId: selectedPolicy?.id || "",
                policyName: selectedPolicy?.name || "",
                enabled: isPolicyEnabled,
            },
        })
    }, [selectedPolicy, isPolicyEnabled])

    return (
        <FormGroup label={localization.getString("policyDetails")}>
            <FormRow
                label={localization.getString("whoShouldHaveAccessToThisHostedWebsite")}
                description={<AppText ls="policiesProvideAccessToUsersAndDevicesDescription" />}
            >
                <MenuSelect
                    icon={faLink}
                    defaultValue={initialPolicyId}
                    options={
                        disabled
                            ? [
                                  {
                                      label: initialValue?.policyAttachment?.policyName || "",
                                      value: initialValue?.policyAttachment?.policyId || "",
                                  },
                              ]
                            : props.policies?.map((p) => {
                                  return { label: p.name, value: p.id }
                              }) || []
                    }
                    label={localization.getString("attachAPolicy")}
                    onSelect={setSelectedPolicyId}
                    disabled={disabled}
                />
            </FormRow>
            {selectedPolicyId && (
                <FormRow
                    label={localization.getString("whatModeShouldThePolicyBeIn")}
                    description={
                        <AppText
                            ls={{
                                key: "readMoreAboutEnforcingVsPermissive",
                                replaceVals: [linkService.getLink("managePolicyGuide")],
                            }}
                        />
                    }
                    childrenClassName={styles.toggleButton}
                >
                    <ToggleButton
                        disabled={disabled}
                        items={[
                            {
                                label: localization.getString("enforcing"),
                                selected: isPolicyEnabled,
                                onClick: () => {
                                    setIsPolicyEnabled(true)
                                },
                            },
                            {
                                label: localization.getString("permissive"),
                                selected: !isPolicyEnabled,
                                onClick: () => {
                                    setIsPolicyEnabled(false)
                                },
                            },
                        ]}
                    />
                </FormRow>
            )}
        </FormGroup>
    )
}
