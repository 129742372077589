export const links = {
    appInstallDocs:
        "https://support.banyansecurity.io/hc/en-us/categories/5979747269787-Install-Banyan-Apps",
    applicationCheckSupportUrl:
        "https://support.banyanops.com/support/solutions/articles/44001965524-how-do-i-improve-my-trustscore-",
    authFailure: "https://product.banyansecurity.io/auth_failure/",
    autoUpdateLinuxSupportUrl: "https://help.ubuntu.com/community/AutomaticSecurityUpdates",
    autoUpdateMacOsSupportUrl:
        "https://support.apple.com/guide/mac-help/get-macos-updates-mchlpx1065/mac",
    autoUpdateWindowsSupportUrl:
        "https://support.microsoft.com/en-us/help/12373/windows-update-faq",
    banyanCluster: "https://docs.banyansecurity.io/docs/banyan-components/command-center/cluster/",
    banyanCommandCenter: "https://docs.banyansecurity.io/docs/banyan-components/command-center/",
    banyanComponents: "https://docs.banyansecurity.io/docs/banyan-components/ ",
    banyanInstallerGithubRepo:
        "https://github.com/banyansecurity/installer/tree/master/cloudformation",
    banyanStatus: "https://status.banyanops.com/",
    chromeBrowserVersionUpdateUrl: "https://www.google.com/chrome/update/",
    cloudFormationInstallDoc:
        "https://docs.banyansecurity.io/docs/banyan-components/accesstier/install/cloudformation/",
    commandCenterApiGuide: "https://docs.banyansecurity.io/docs/api-guide/",
    communityForum:
        "https://join.slack.com/t/banyancommunitygroup/shared_invite/zt-w9sbqe1g-CDFV93FNmsbsEaBDicMqPA",
    communitySupport:
        "https://join.slack.com/t/banyancommunitygroup/shared_invite/zt-1cl3uf1xd-O4V82iMpCr_d2R1vFVJQMQ",
    connectorDoc: "https://docs.banyansecurity.io/docs/banyan-components/connector/",
    corporate: "https://www.banyansecurity.io/",
    debInstallDoc:
        "https://docs.banyansecurity.io/docs/banyan-components/accesstier/install/packages/",
    deploymentKeyDocs:
        "https://docs.banyanops.com/docs/feature-guides/manage-users-and-devices/device-managers/distribute-desktopapp/",
    deploymentModelsDoc: "https://docs.banyansecurity.io/docs/intro/edge-deployment/",
    deviceManagerDocs:
        "https://docs.banyanops.com/docs/feature-guides/manage-users-and-devices/device-managers/",
    diskEncryptionLinuxSupportUrl:
        "https://help.ubuntu.com/community/Full_Disk_Encryption_Howto_2019",
    diskEncryptionMacOsSupportUrl: "https://support.apple.com/en-us/HT204837",
    diskEncryptionWindowsSupportUrl:
        "https://support.microsoft.com/en-gb/help/4028713/windows-10-turn-on-device-encryption",
    dlpPolicyDoc: "https://docs.banyansecurity.io/docs/content-policies/dlp/",
    docSite: "https://docs.banyanops.com",
    docSiteTeamEdition: "https://docs.banyanops.com/?edition=team",
    dockerInstallDocForAccessTier:
        "https://docs.banyansecurity.io/docs/banyan-components/accesstier/install/docker/",
    dockerInstallDocForConnector:
        "https://docs.banyansecurity.io/docs/banyan-components/connector/install/docker/",
    dockerInstallDocForConnectorPreInstallCheckList:
        "https://docs.banyansecurity.io/docs/banyan-components/connector/install/docker/#step-2-review-the-pre-install-checklist",
    documentation: "https://docs.banyansecurity.io/",
    documentationQuickStart: "https://docs.banyansecurity.io/docs/quickstart/",
    downloadLatestAndroidApp:
        "https://support.banyanops.com/support/solutions/articles/44001710285-android",
    downloadLatestLinuxApp:
        "https://support.banyanops.com/support/solutions/articles/44001926111-linux",
    downloadLatestMacOsApp:
        "https://support.banyansecurity.io/hc/en-us/articles/6340197889947-macOS",
    downloadLatestWindowsApp:
        "https://support.banyanops.com/support/solutions/articles/44001710305-windows",
    downloadLatestiOsApp:
        "https://support.banyanops.com/support/solutions/articles/44001710306-ios",
    emailSupport: "mailto:support@banyanops.com",
    enablingUrlFiltering:
        "https://docs.banyansecurity.io/docs/securing-internet-traffic/url-filtering/",
    exemptionsAndCors: "https://docs.banyansecurity.io/docs/feature-guides/hosted-websites/cors/",
    fedPolicyDoc:
        "https://docs.banyansecurity.io/docs/securing-public-applications/federated-saas-apps/banyan-federated/",
    fileATicket: "https://support.banyanops.com/support/tickets/new",
    firewallMacOsSupportUrl: "https://support.apple.com/en-us/HT201642",
    firewallWindowsSupportUrl:
        "https://support.microsoft.com/en-us/help/4028544/windows-10-turn-microsoft-defender-firewall-on-or-off",
    getBanyanAppNoDownload: "https://product.banyansecurity.io/download/?no",
    globalEdgeNetwork: "https://docs.banyansecurity.io/docs/banyan-components/edge-network/",
    hostedWebsiteDoc:
        "https://docs.banyansecurity.io/docs/feature-guides/hosted-websites/user-web/",
    iaasDiscoveryDoc: "https://docs.banyansecurity.io/docs/banyan-labs/discover-iaas/",
    internetThreatProtectionDoc: "https://docs.banyansecurity.io/docs/securing-internet-traffic/",
    itpDoc: "https://docs.banyansecurity.io/docs/feature-guides/administer-internet-threat-protection/",
    letsEncryptCertDoc:
        "https://docs.banyansecurity.io/docs/feature-guides/hosted-websites/lets-encrypt/",
    licensingDoc: "https://docs.banyansecurity.io/docs/support/licenses/",
    managePolicyGuide:
        "https://docs.banyansecurity.io/docs/feature-guides/administer-security-policies/policies/manage-policies/",
    manageRoles: "https://docs.banyansecurity.io/docs/administer-security-policies/roles/",
    mswDefaultRedirectUrl: "https://www.mysonicwall.com",
    multiDomainWebServices:
        "https://docs.banyansecurity.io/docs/feature-guides/hosted-websites/multi-domain/",
    netAgentConfigurationDoc:
        "https://docs.banyansecurity.io/docs/banyan-components/accesstier/netagent/configuration/",
    oneTimePasscode:
        "https://docs.banyansecurity.io/docs/banyan-components/command-center/one-time-passcode/",
    operatingSystemVersionLinuxSupportUrl:
        "https://help.ubuntu.com/community/CheckingYourUbuntuVersion",
    operatingSystemVersionMacOsSupportUrl: "https://support.apple.com/en-us/HT201260",
    operatingSystemVersionWindowsSupportUrl:
        "https://support.microsoft.com/en-us/help/13443/windows-which-version-am-i-running",
    passwordlessDocs:
        "https://docs.banyanops.com/docs/feature-guides/manage-users-and-devices/passwordless/",
    policyEnforcement:
        "https://docs.banyansecurity.io/docs/feature-guides/administer-security-policies/policies/policy-enforcement/",
    pricingDoc: "https://www.banyansecurity.io/pricing/",
    privacyPolicy: "https://www.banyansecurity.io/legal/",
    rdpProperties:
        "https://learn.microsoft.com/en-us/windows-server/remote/remote-desktop-services/clients/rdp-files",
    registeredDomain: "https://docs.banyansecurity.io/docs/feature-guides/hosted-websites/domains/",
    registeredServiceApi:
        "https://docs.banyansecurity.io/docs/api-guide/objects/registered_service/",
    reportingDocs: "https://docs.banyanops.com/docs/banyan-components/command-center/reporting",
    saasApplicationsDoc: "https://docs.banyansecurity.io/docs/saas-applications/",
    saasInstallDocs: "https://docs.banyanops.com/docs/quickstart/secure-services/user-saas/",
    salesContact: "mailto:sales@sonicwall.com",
    sameSiteCookiesExplained: "https://web.dev/samesite-cookies-explained/",
    securingPrivateResourcesDoc: "https://docs.banyansecurity.io/docs/securing-private-resources/",
    serviceAccounts:
        "https://docs.banyansecurity.io/docs/feature-guides/hosted-websites/serviceaccounts/",
    serviceTunnelDoc:
        "https://docs.banyansecurity.io/docs/securing-public-applications/ip-allowlisting/",
    serviceTunnelInfo: "https://docs.banyanops.com/docs/feature-guides/service-tunnels/",
    setUpDirectory: "https://docs.banyansecurity.io/docs/quickstart/set-up-directory/",
    signupIframe: "https://info.banyansecurity.io/hubfs/signup-iframe.html",
    slackCommunityForum: "https://app.slack.com/client/T01U8G91PEH/C01U8G92KRT",
    supportHome: "https://support.banyanops.com/support/home",
    supportLink: "https://support.banyansecurity.io/hc/en-us",
    tarballInstallDocForAccessTier:
        "https://docs.banyansecurity.io/docs/banyan-components/accesstier/install/tarball/",
    tarballInstallDocForConnector:
        "https://docs.banyansecurity.io/docs/banyan-components/connector/install/tarball/",
    tarballInstallDocForConnectorPreInstallCheckList:
        "https://docs.banyansecurity.io/docs/banyan-components/connector/install/tarball/#step-2-review-the-pre-install-checklist",
    teamEditionUpgrade: "https://www.banyansecurity.io/edition-upgrade/",
    trustIntegrationDoc: "https://docs.banyansecurity.io/docs/trust-scoring/trust-integrations/",
    unregisteredDevicesDoc:
        "https://docs.banyansecurity.io/docs/manage-users-and-devices/managed-registered-unregistered/",
    userIdentityDocs: "https://docs.banyanops.com/docs/quickstart/users/",
    usersAndDeviceInactivity:
        "https://docs.banyansecurity.io/docs/manage-users-and-devices/delete-users/",
    windowsInstallForConnector:
        //NOTE - this link don't exist but will be
        "https://docs.banyansecurity.io/docs/banyan-components/connector/install/windows-executable",
    windowsInstallForConnectorPreInstallCheckList:
        "https://docs.banyansecurity.io/docs/banyan-components/connector/install/windows-executable/#step-2-review-the-pre-install-checklist",
    windowsSubsystemsForLinuxGuide:
        "https://learn.microsoft.com/en-us/windows/wsl/install-on-server",
} as const
