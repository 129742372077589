import React, { FormEvent, ReactNode, useState } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../../pre-v3/services/localization/Localization.service"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import { FormButtons } from "../../../../components/form/form-buttons/FormButtons.component"
import { Form } from "../../../../components/form/Form.component"
import {
    ServiceAccountAccess,
    ServiceExemption,
    WebService,
} from "../../../../services/HostedService.service"
import { PolicyType, useGetPoliciesByType } from "../../../../services/Policy.service"
import { PolicyDetails, PolicyDetailsFormValues } from "./PolicyDetails.component"
import { ServiceAccountAccessContainer } from "./ServiceAccountAccessContainer.component"
import { Exemptions } from "./ServiceExemptions.component"

interface Props {
    initialValue?: WebService
    onSubmit?: (data: AccessPermissionsFormValues) => void
    disabled?: boolean
    onBack?: () => void
    onCancel?: () => void
    nextStep?: () => void
    prevStep?: () => void
    isEdit?: boolean
    onSave?: (data: WebService) => void
    error?: string
    isDataLoading?: boolean
}

export interface AccessPermissionsFormValues extends PolicyDetailsFormValues {
    exemptions?: ServiceExemption[]
    serviceAccount?: ServiceAccountAccess
}

export function AccessPermissions(props: Props) {
    const { initialValue, disabled, onCancel, nextStep, prevStep, isEdit, onSubmit } = props
    const localization = useServiceLocalization()

    const [accessPermissions, setAccessPermissions] = useState<AccessPermissionsFormValues>({
        policyAttachment: initialValue?.policyAttachment,
        serviceAccount: initialValue?.serviceAccountAccess,
        exemptions: initialValue?.exemptions,
    })
    const {
        isFetching: isPoliciesLoading,
        data: policies,
        error: policyError,
    } = useGetPoliciesByType(PolicyType.WEB)

    function onFormSubmit(e: FormEvent) {
        e.preventDefault()
        onSubmit?.(accessPermissions)
        nextStep?.()
    }

    function onBack() {
        prevStep?.()
    }

    function onAccessPermissionUpdate(e: FormEvent) {
        e.preventDefault()
        const updateServiceObj = {
            ...initialValue,
            policyAttachment: accessPermissions.policyAttachment,
            exemptions: accessPermissions.exemptions,
            serviceAccountAccess: accessPermissions.serviceAccount,
        }

        props.onSave?.(updateServiceObj as WebService)
    }

    const errors: ReactNode[] = [
        typeof policyError === "string" && String(policyError),
        props.error,
    ]

    return (
        <Form onSubmit={isEdit ? onAccessPermissionUpdate : onFormSubmit}>
            <PolicyDetails
                initialValue={initialValue}
                policies={policies}
                disabled={disabled}
                onChange={(policyDetails) =>
                    setAccessPermissions({
                        ...accessPermissions,
                        policyAttachment: policyDetails.policyAttachment,
                    })
                }
            />
            <ServiceAccountAccessContainer
                initialValue={initialValue}
                disabled={disabled}
                onChange={(serviceAccount) =>
                    setAccessPermissions({ ...accessPermissions, serviceAccount })
                }
            />
            <Exemptions
                initialValue={initialValue}
                disabled={disabled}
                onChange={(exemptions) =>
                    setAccessPermissions({ ...accessPermissions, exemptions })
                }
            />
            {!disabled && (
                <FormButtons
                    leftButtons={
                        !isEdit && (
                            <Button
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                                type="button"
                                onClick={onBack}
                            >
                                {localization.getString("back")}
                            </Button>
                        )
                    }
                    rightButtons={
                        <>
                            {onCancel && (
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    onClick={onCancel}
                                >
                                    {localization.getString("cancel")}
                                </Button>
                            )}
                            <Button
                                type="submit"
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                                loading={isPoliciesLoading || props.isDataLoading}
                            >
                                {isEdit
                                    ? localization.getString("save")
                                    : localization.getString("next")}
                            </Button>
                        </>
                    }
                >
                    <ErrorBanners errors={errors} />
                </FormButtons>
            )}
        </Form>
    )
}
